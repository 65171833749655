import React from 'react';
import { getTranslation } from '../../utils/getTranslation';
import { PRIVACY_POLICY, TERMS_AND_CONDITION } from '../../constants/externalLinks';
import './index.scss';

const AuthFooter = (): JSX.Element => {
  return (
    <div className="form-container-footer">
      {getTranslation('Authentication_FooterAllRights')}
      <br />
      <br />
      {getTranslation('Authentication_FooterTrademarks')}
      <br />
      <br />
      <a href={PRIVACY_POLICY} className="link">
        {getTranslation('Privacy_policy')}
      </a>
      <a href={TERMS_AND_CONDITION} className="link">
        {getTranslation('Terms_and_conditions')}
      </a>
    </div>
  );
};

export default AuthFooter;

import { DropdownViewModel } from './../../../../models/DropdownViewModel';

export const mapArrayToDropdownOption = (valuesArray: string[]): DropdownViewModel[] => {
  const arr = [] as DropdownViewModel[];
  valuesArray?.forEach((value: string) => {
    arr.push({ text: value, value: value, key: value });
  });

  return arr;
};

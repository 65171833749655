import React from 'react';
import { ReportTypeDto } from '../../api/graphql/models/ReportTypeDto';
import { PetUserDTO } from '../../api/graphql/models/Users';

export interface UserInfoContextProps {
  getUserInfo: () => Promise<void>;
  userReports: ReportTypeDto[];
  userInfo: PetUserDTO | null;
  isSpinnerShow: boolean;
  isErrorShow: boolean;
}

export const defaultUserInfoContextValue: UserInfoContextProps = {
  getUserInfo: async () => new Promise(() => void 0),
  userReports: [],
  userInfo: null,
  isSpinnerShow: true,
  isErrorShow: false,
};

const userInfoContext = React.createContext<UserInfoContextProps>(defaultUserInfoContextValue);

export { userInfoContext };

import React from 'react';
import { ReportTypeDto } from '../../api/graphql/models/ReportTypeDto';
import { RequestStatus, API_REQUEST_STATUS } from '../../utils/APIHandlers';

export interface UpdateReportContextProps {
  updateReport: (payload: { id: number; report: ReportTypeDto }) => Promise<void>;
  clearUpdateReportState: () => void;
  requestUpdateReportMessage: string;
  requestUpdateReportStatus: RequestStatus;
  notificationUpdateReportStatus: RequestStatus;
}

export const defaultUpdateReportContextValue: UpdateReportContextProps = {
  updateReport: async () => new Promise(() => void 0),
  clearUpdateReportState: () => void 0,
  requestUpdateReportMessage: 'string',
  requestUpdateReportStatus: API_REQUEST_STATUS.NOT_REQUESTED,
  notificationUpdateReportStatus: API_REQUEST_STATUS.NOT_REQUESTED,
};

const updateReportContext = React.createContext<UpdateReportContextProps>(defaultUpdateReportContextValue);

export { updateReportContext };

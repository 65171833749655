import React from 'react';
import { ReportNameViewModel, ReportViewModel } from '../../models/ReportsAggregate/Report';
import { RequestStatus, API_REQUEST_STATUS } from '../../utils/APIHandlers';

export interface ReportsContextProps {
  getReports: (isPaginated?: boolean) => Promise<void>;
  reportsData: ReportViewModel[];
  requestReportsStatus: RequestStatus;
  getReportsNames: () => Promise<void>;
  resetCachedReportsNames: () => void;
  reportsNamesData: ReportNameViewModel[];
  requestReportsNamesStatus: RequestStatus;
}

export const defaultReportsContextValue: ReportsContextProps = {
  getReports: async () => new Promise(() => void 0),
  reportsData: [],
  requestReportsStatus: API_REQUEST_STATUS.NOT_REQUESTED,
  getReportsNames: async () => new Promise(() => void 0),
  resetCachedReportsNames: () => void 0,
  reportsNamesData: [],
  requestReportsNamesStatus: API_REQUEST_STATUS.NOT_REQUESTED,
};

const reportsContext = React.createContext<ReportsContextProps>(defaultReportsContextValue);

export { reportsContext };

import React, { useContext, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { FieldError } from 'react-hook-form/dist/types';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { Divider, Header, Message } from 'semantic-ui-react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { WithApolloClient } from './../../../WithApolloClient/WithApolloClient';
import InputWithInfo from './../../../PetForm/InputWithInfo';
import { getTranslation } from './../../../../utils/getTranslation';
import { getAccessTypesOptions } from './helper';
import DropdownWithInfo from './../../../PetForm/DropdownWithInfo';
import DateInfo from './../../../PetForm/DateInfo';
import { getRequiredFieldRegister, mapResponseToDropdownOption } from './../../../PetForm/helper';
import { ReportAccessType } from './../../../../models/FeatureAccessGroupsAggregate/ReportAccessType';
import { getAccessTypesConfig, getAccessTypesReportConfig } from './../constants';
import {
  formNameDataAuthorizationManagementAccessType,
  formNameFeatureAccessManagementAccessType,
  formNameGroupName,
  formNameReportManagementAccessType,
  formNameReportManagementReports,
  formNameScheduledReportsManagementAccessType,
  formNameTicketsManagementAccessType,
  formNameUserManagementAccessType,
} from './constants';
import { FormProps } from './../../../PetForm/types';
import { FeatureAccessGroupFormViewModel } from '../../../../models/FeatureAccessGroupsAggregate/FeatureAccessGroup';
import { reportsContext } from '../../../../shared/store/reportsContext';
import { getRequestStatuses } from '../../../../utils/APIHandlers';
import TextButton from '../../../../shared/controls/TextButton/TextButton';
import { userContext } from '../../../../shared/store/userContext';
import { hasReportsManagementAccess } from '../../../../pages/Administration/helper';

import './styles.scss';
import './../../../PetForm/commonFormStyles.scss';

interface FeatureAccessGroupsFormProps
  extends FormProps<FeatureAccessGroupFormViewModel, UseFormRegister<FeatureAccessGroupFormViewModel>> {
  client?: ApolloClient<NormalizedCacheObject>;
  errors?: {
    groupName: FieldError;
    reportManagement: {
      reports: FieldError;
      accessType: FieldError;
    };
    ticketsManagement: {
      accessType: FieldError;
    };
    userManagement: {
      accessType: FieldError;
    };
    scheduledReportsManagement: {
      accessType: FieldError;
    };
    featureAccessManagement: {
      accessType: FieldError;
    };
    dataAuthorizationManagement: {
      accessType: FieldError;
    };
  };
}

const FeatureAccessGroupsForm: React.FC<FeatureAccessGroupsFormProps> = (props) => {
  const {
    editFormData: { updatedDate, updatedBy, createdDate, createdBy },
    watch,
    clearErrors,
    control,
    registerForm,
    setValue,
    errors,
  } = props;
  const { getReports, requestReportsStatus, reportsData } = useContext(reportsContext);
  const userContextValue = useContext(userContext);
  const requestReports = getRequestStatuses(requestReportsStatus);
  const requiredFieldRegister = getRequiredFieldRegister();
  const isEditForm = !!props.editFormData.groupName;
  const isReportsManagementAccess = hasReportsManagementAccess(userContextValue);
  const isReportTypeDisabled =
    watch?.(formNameReportManagementAccessType) === ReportAccessType.None || !isReportsManagementAccess;

  useEffect(() => {
    isReportsManagementAccess && void getReports();
  }, []);

  return (
    <>
      <Header as="h1" data-testid="featureAccessGroupForm-title">
        {getTranslation(
          isEditForm ? 'FeatureAccessGroupsForm_EditFeatureGroup' : 'FeatureAccessGroupsForm_AddFeatureGroup'
        )}
      </Header>

      <InputWithInfo
        required
        label={getTranslation('FeatureAccessGroupsForm_Name')}
        error={errors?.[formNameGroupName]}
        register={registerForm?.(formNameGroupName, requiredFieldRegister)}
        dataTestId="featureAccessGroupForm-name-field"
      />

      <Controller
        render={({ field: { onChange, value } }) => (
          <DropdownWithInfo
            options={getAccessTypesOptions(getAccessTypesConfig())}
            label={getTranslation('FeatureAccessGroupsDetails_UserManagement')}
            error={errors?.userManagement?.accessType}
            onChange={(e, { value }) => onChange(Number(value))}
            value={value as number}
            required
            dataTestId={formNameUserManagementAccessType}
            isSelection
          />
        )}
        name={formNameUserManagementAccessType}
        control={control}
        rules={requiredFieldRegister}
      />

      <Controller
        render={({ field: { onChange, value } }) => (
          <DropdownWithInfo
            options={getAccessTypesOptions(getAccessTypesConfig())}
            label={getTranslation('FeatureAccessGroupsDetails_TicketsManagement')}
            error={errors?.ticketsManagement?.accessType}
            onChange={(e, { value }) => onChange(Number(value))}
            value={value as number}
            required
            dataTestId={formNameTicketsManagementAccessType}
            isSelection
          />
        )}
        name={formNameTicketsManagementAccessType}
        control={control}
        rules={requiredFieldRegister}
      />

      <Controller
        render={({ field: { onChange, value } }) => (
          <DropdownWithInfo
            options={getAccessTypesOptions(getAccessTypesConfig())}
            label={getTranslation('FeatureAccessGroupsDetails_FeatureAccessGroupManagement')}
            error={errors?.featureAccessManagement?.accessType}
            onChange={(e, { value }) => onChange(Number(value))}
            value={value as number}
            required
            dataTestId={formNameFeatureAccessManagementAccessType}
            isSelection
          />
        )}
        name={formNameFeatureAccessManagementAccessType}
        control={control}
        rules={requiredFieldRegister}
      />

      <Controller
        render={({ field: { onChange, value } }) => (
          <DropdownWithInfo
            options={getAccessTypesOptions(getAccessTypesConfig())}
            label={getTranslation('FeatureAccessGroupsDetails_DataAuthorizationRolesManagement')}
            error={errors?.dataAuthorizationManagement?.accessType}
            onChange={(e, { value }) => onChange(Number(value))}
            value={value as number}
            required
            dataTestId={formNameDataAuthorizationManagementAccessType}
            isSelection
          />
        )}
        name={formNameDataAuthorizationManagementAccessType}
        control={control}
        rules={requiredFieldRegister}
      />

      <Controller
        render={({ field: { onChange, value } }) => (
          <DropdownWithInfo
            options={getAccessTypesOptions(getAccessTypesConfig())}
            label={getTranslation('FeatureAccessGroupsDetails_ExportJobsManagement')}
            error={errors?.scheduledReportsManagement?.accessType}
            onChange={(e, { value }) => onChange(Number(value))}
            value={value as number}
            required
            dataTestId={formNameScheduledReportsManagementAccessType}
            isSelection
          />
        )}
        name={formNameScheduledReportsManagementAccessType}
        control={control}
        rules={requiredFieldRegister}
      />

      <Divider />

      <Header as="h1">{getTranslation('FeatureAccessGroupsForm_Reports')}</Header>

      <Controller
        render={({ field: { onChange, value } }) => (
          <DropdownWithInfo
            options={getAccessTypesOptions(getAccessTypesReportConfig())}
            label={getTranslation('FeatureAccessGroupsForm_Permission')}
            error={errors?.reportManagement?.accessType}
            onChange={(e, { value }) => {
              if (value === ReportAccessType.None) {
                setValue?.(formNameReportManagementReports, []);
                clearErrors?.(formNameReportManagementReports);
              }
              onChange(Number(value));
            }}
            value={value as number}
            required
            dataTestId={'editFeatureAccessGroupsForm-ReportsType-Field'}
            isSelection
          />
        )}
        name={formNameReportManagementAccessType}
        control={control}
        rules={requiredFieldRegister}
      />

      <Controller
        render={({ field: { onChange, value } }) => (
          <DropdownWithInfo
            disabled={isReportTypeDisabled}
            options={mapResponseToDropdownOption({ response: reportsData, text: 'displayName', value: 'id' })}
            required
            label={getTranslation('FeatureAccessGroupsForm_ReportTypes')}
            error={errors?.reportManagement?.reports ?? requestReports.REQUEST_FAILURE}
            onChange={(e, { value }) => onChange(value as number[])}
            value={value as number[]}
            dataTestId={'editFeatureAccessGroupsForm-Reports-Field'}
            isMultiple
            isSelection
            isSearched
            isOrderedValue
            isLoading={requestReports.REQUESTED}
            noAccessLabel={!isReportsManagementAccess}
          />
        )}
        name={formNameReportManagementReports}
        control={control}
        rules={isReportTypeDisabled ? { required: false } : requiredFieldRegister}
      />
      {requestReports.REQUEST_FAILURE && (
        <Message error data-testid="editFeatureAccessGroupsForm_LoadReports-messageError">
          <p>{getTranslation('Error')}</p>
          <TextButton
            text={getTranslation('Retry_Now')}
            onClick={() => void getReports()}
            dataTestId="editFeatureAccessGroupsForm_LoadReports-retryControl"
          />
        </Message>
      )}

      {(updatedDate || updatedBy || createdDate || createdBy) && (
        <div data-testid="featureAccessGroup-datesInfo-wrapper">
          <Divider />
          <DateInfo
            createdDate={createdDate}
            createdBy={createdBy}
            updatedDate={updatedDate}
            updatedBy={updatedBy}
          />
        </div>
      )}
    </>
  );
};

export default WithApolloClient<FeatureAccessGroupsFormProps>(FeatureAccessGroupsForm);

export enum DeliveryMethod {
  Ftp,
  Email,
}

export const DELIVERY_FTP = 'FTP';
export const DELIVERY_EMAIL = 'Email';

export const DeliveryMethodValue = {
  [DeliveryMethod.Ftp]: DELIVERY_FTP,
  [DeliveryMethod.Email]: DELIVERY_EMAIL,
};

export const getDeliveryMethodStatuses = (method: DeliveryMethod): { [key in DeliveryMethod]: boolean } => ({
  [DeliveryMethod.Ftp]: method === DeliveryMethod.Ftp,
  [DeliveryMethod.Email]: method === DeliveryMethod.Email,
});

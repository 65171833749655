import { gql } from '@apollo/client';

export const GET_ALL = gql`
  query ($limit: Int, $offset: Int, $sortBy: String, $sortOrder: String) {
    paginatedPetUsers(input: { limit: $limit, offset: $offset, sortBy: $sortBy, sortOrder: $sortOrder }) {
      total
      data {
        _id
        email
        dataAuthRoleId {
          _id
          roleName
          createdBy
          createdDate
          updatedBy
          updatedDate
          gcns {
            isInclusive
            values
          }
          lcns {
            isInclusive
            values
          }
          pccs {
            isInclusive
            values
          }
          smids {
            isInclusive
            values
          }
        }
        firstName
        lastName
        userName
        featureAccessGroupId {
          _id
          groupName
          userManagement {
            accessType
          }
          reportManagement {
            accessType
            reports
          }
          createdBy
          createdDate
          updatedBy
          updatedDate
        }
        isFederated
        isEnabled
        createdBy
        createdDate
        updatedBy
        updatedDate
      }
    }
  }
`;

export const GET_BY_USERNAME = gql`
  query ($userName: String) {
    petUser(query: { userName: $userName }) {
      _id
      email
      dataAuthRoleId {
        _id
        roleName
        createdBy
        createdDate
        updatedBy
        updatedDate
        gcns {
          isInclusive
          values
        }
        lcns {
          isInclusive
          values
        }
        pccs {
          isInclusive
          values
        }
        smids {
          isInclusive
          values
        }
      }
      firstName
      lastName
      userName
      featureAccessGroupId {
        _id
        groupName
        userManagement {
          accessType
        }
        reportManagement {
          accessType
          reports
        }
        ticketsManagement {
          accessType
        }
        scheduledReportsManagement {
          accessType
        }
        createdBy
        createdDate
        updatedBy
        updatedDate
      }
      isFederated
      isEnabled
      createdBy
      createdDate
      updatedBy
      updatedDate
    }
  }
`;

export const GET_BY_ID = gql`
  query ($id: String) {
    petUser(query: { _id: $id }) {
      _id
      email
      dataAuthRoleId {
        _id
        roleName
        createdBy
        createdDate
        updatedBy
        updatedDate
        gcns {
          isInclusive
          values
        }
        lcns {
          isInclusive
          values
        }
        pccs {
          isInclusive
          values
        }
        smids {
          isInclusive
          values
        }
      }
      firstName
      lastName
      userName
      featureAccessGroupId {
        _id
        groupName
        userManagement {
          accessType
        }
        reportManagement {
          accessType
          reports
        }
        createdBy
        createdDate
        updatedBy
        updatedDate
      }
      isFederated
      isEnabled
      createdBy
      createdDate
      updatedBy
      updatedDate
    }
  }
`;

export const GET_BY_EMAIL = gql`
  query ($email: String) {
    petUser(query: { email: $email }) {
      _id
      email
      dataAuthRoleId {
        _id
        roleName
        createdBy
        createdDate
        updatedBy
        updatedDate
        gcns {
          isInclusive
          values
        }
        lcns {
          isInclusive
          values
        }
        pccs {
          isInclusive
          values
        }
        smids {
          isInclusive
          values
        }
      }
      firstName
      lastName
      userName
      featureAccessGroupId {
        _id
        groupName
        userManagement {
          accessType
        }
        reportManagement {
          accessType
          reports
        }
        createdBy
        createdDate
        updatedBy
        updatedDate
      }
      isFederated
      isEnabled
      createdBy
      createdDate
      updatedBy
      updatedDate
    }
  }
`;

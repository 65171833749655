import React from 'react';
import { Icon } from 'semantic-ui-react';
import {
  ReportIconValue,
  ReportType,
  ReportTypeColor,
  ReportTypeValue,
} from './../../../../api/graphql/models/ReportsResponse';
import { ReportNameDTO } from './../../../../api/rest/models/Reports';
import { DropdownViewModel } from './../../../../models/DropdownViewModel';
import { ReportNameViewModel } from './../../../../models/ReportsAggregate/Report';
import { getTranslation } from './../../../../utils/getTranslation';
import TableLabel from './../../../TableLabel';

export const reportTypesDropdownOption = (): DropdownViewModel[] => {
  return Object.keys(ReportTypeValue).map((key: string) => {
    const typeKey = Number(key) as ReportType;
    const option: DropdownViewModel = {
      text: '',
      image: (
        <TableLabel text={getTranslation(ReportTypeValue[typeKey])} roundBg={ReportTypeColor[typeKey]} />
      ),
      value: key,
      key: key,
    };

    return option;
  });
};

export const reportIconsDropdownOption = (): DropdownViewModel[] => {
  return Object.keys(ReportIconValue).map((key: string) => {
    const typeKey = Number(key) as ReportType;
    const option: DropdownViewModel = {
      text: ReportIconValue[typeKey],
      image: <Icon name={`${ReportIconValue[typeKey]}`} />,
      value: key,
      key: key,
    };

    return option;
  });
};

export const reportNamesDropdownOption = (valuesArray: ReportNameDTO[]): DropdownViewModel[] => {
  const arr = [] as DropdownViewModel[];
  valuesArray.forEach(({ name }: ReportNameDTO) => {
    arr.push({ text: name, value: name, key: name });
  });

  return arr;
};

export const getReportIsPaginated = (
  reportsNamesData: ReportNameViewModel[],
  reportName: string
): boolean | undefined => reportsNamesData.filter((item) => item.name === reportName)[0]?.isPaginated;

export const getUnusedReportsNames = (
  reportsNamesData: ReportNameViewModel[],
  usedReportsNames: string[],
  editingReportName: string
): ReportNameViewModel[] =>
  reportsNamesData.filter((item) => !usedReportsNames.includes(item.name) || item.name === editingReportName);

import React, { useState } from 'react';

import { WithApolloClient } from '../../features/WithApolloClient/WithApolloClient';
import {
  defaultSftpProvidersContextValue,
  SftpProvidersContextProps,
  sftpProvidersContext,
} from '../store/sftpProvidersContext';
import { API_REQUEST_STATUS, RequestStatus } from '../../utils/APIHandlers';
import { SftpProvidersListDTO } from '../../api/rest/models/SftpProviders';
import { SftpProvidersService } from '../../services/SftpProvidersService';
import { ErrorFetchResponse, RestApiResponse } from '../../api/rest/models/RestApiResponse';
import { getFetchErrorMessage } from '../../utils/errorHandler';

interface Props {
  children: React.ReactChild;
}

const SftpProviders: React.FC<Props> = ({ children }) => {
  const [sftpProvidersData, setSftpProvidersData] = useState<SftpProvidersListDTO>([]);
  const [requestSftpProvidersStatus, setRequestSftpProvidersStatus] = useState<RequestStatus>(
    API_REQUEST_STATUS.NOT_REQUESTED
  );

  const getSftpProviders = async () => {
    try {
      const sftpProvidersService: SftpProvidersService = new SftpProvidersService();
      setRequestSftpProvidersStatus(API_REQUEST_STATUS.REQUESTED);
      const response = await sftpProvidersService.getSftpProviders();
      const successResponse = response as RestApiResponse<SftpProvidersListDTO>;

      setRequestSftpProvidersStatus(API_REQUEST_STATUS.REQUEST_SUCCESS);
      setSftpProvidersData(successResponse.result);
    } catch (e) {
      console.error(getFetchErrorMessage(e as ErrorFetchResponse));
      setRequestSftpProvidersStatus(API_REQUEST_STATUS.REQUEST_FAILURE);
    }
  };

  const getSftpProvidersContext = (): SftpProvidersContextProps => {
    return {
      ...defaultSftpProvidersContextValue,
      getSftpProviders,
      sftpProvidersData,
      requestSftpProvidersStatus,
    };
  };

  return (
    <sftpProvidersContext.Provider value={getSftpProvidersContext()}>
      {children}
    </sftpProvidersContext.Provider>
  );
};

const WithSftpProviders = WithApolloClient(SftpProviders);

export { WithSftpProviders };

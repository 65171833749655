import React from 'react';
import { DownloadDocumentRequestDTO, Document } from '../../api/rest/models/Document';
import { RequestStatus, API_REQUEST_STATUS } from '../../utils/APIHandlers';

export interface DocumentsProps {
  downloadDocument: (payload: DownloadDocumentRequestDTO) => Promise<void>;
  getDocuments: () => Promise<void>;
  documents: Document[];
  downloadDocumentName: string;
  requestDownloadDocumentStatus: RequestStatus;
  requestGetDocumentsStatus: RequestStatus;
}

export const defaultDocumentValue: DocumentsProps = {
  downloadDocument: async () => new Promise(() => void 0),
  getDocuments: async () => new Promise(() => void 0),
  documents: [],
  downloadDocumentName: '',
  requestDownloadDocumentStatus: API_REQUEST_STATUS.NOT_REQUESTED,
  requestGetDocumentsStatus: API_REQUEST_STATUS.NOT_REQUESTED,
};

const documentContext = React.createContext<DocumentsProps>(defaultDocumentValue);

export { documentContext };

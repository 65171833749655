import React, { useState } from 'react';
import { NormalizedCacheObject, ApolloClient } from '@apollo/client';

import { WithApolloClient } from '../../features/WithApolloClient/WithApolloClient';
import {
  defaultExportJobHistoryContextValue,
  exportJobHistoryContext,
  ExportJobHistoryContextProps,
} from '../store/exportJobHistoryContext';
import { ExportJobsService } from '../../services/ExportJobsService';
import { ExportJobHistoryListDTO } from './../../api/graphql/models/ExportJob';
import { useModal, useModalReturn } from '../hooks/modalHook';
import { API_REQUEST_STATUS } from '../../utils/APIHandlers';

interface Props {
  children: React.ReactChild;
  client?: ApolloClient<NormalizedCacheObject>;
}

const WithExportJobs: React.FC<Props> = ({ children, client }) => {
  const [
    { isOpen, requestStatus },
    { handleOpen, handleClose, setRequestedState, setSuccessState, setFailState },
  ]: useModalReturn = useModal();
  const [exportJobHistoryData, setExportJobHistoryData] = useState<ExportJobHistoryListDTO>([]);

  const getExportJobHistory = async (id: string) => {
    if (requestStatus !== API_REQUEST_STATUS.REQUESTED) {
      try {
        const clientApollo = client as ApolloClient<NormalizedCacheObject>;
        const exportJobsService: ExportJobsService = new ExportJobsService(clientApollo);
        setRequestedState();

        const response = await exportJobsService.getJobHistory(id);

        setSuccessState();
        setExportJobHistoryData(response?.data?.exportJobRunDetails || []);
      } catch (error) {
        console.error(error);
        setFailState();
      }
    }
  };

  const getExportJobHistoryContext = (): ExportJobHistoryContextProps => {
    return {
      ...defaultExportJobHistoryContextValue,
      isOpenJobHistory: isOpen,
      exportJobHistoryData,
      getExportJobHistory,
      requestExportJobHistoryStatus: requestStatus,
      handleOpenExportJobHistory: handleOpen,
      handleCloseExportJobHistory: (): void => {
        setExportJobHistoryData([]);
        handleClose();
      },
    };
  };

  return (
    <exportJobHistoryContext.Provider value={getExportJobHistoryContext()}>
      {children}
    </exportJobHistoryContext.Provider>
  );
};

const WithExportJobHistory = WithApolloClient(WithExportJobs);
export const WithoutClientExportJobHistory = WithExportJobs;

export { WithExportJobHistory };

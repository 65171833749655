import { gql } from '@apollo/client';

export const INSERT = gql`
  mutation ($group: FeatureAccessGroupInsertInput!) {
    insertOneFeatureAccessGroup(data: $group) {
      _id
    }
  }
`;

export const UPDATE = gql`
  mutation ($id: String!, $group: FeatureAccessGroupInsertInput!) {
    replaceOneFeatureAccessGroup(query: { _id: $id }, data: $group) {
      _id
    }
  }
`;

import React from 'react';

import { TripsTicketsSearchRequestDTO } from '../../api/rest/models/TripsTickets';
import { TripsTicketsSearchViewModel } from '../../models/TripAggregate/TripsTickets';
import { RequestStatus, API_REQUEST_STATUS } from '../../utils/APIHandlers';

export interface TripsTicketsContextProps {
  searchTripsTickets: (payload: TripsTicketsSearchRequestDTO) => Promise<void>;
  clearTripsTicketsSearch: () => void;
  setTripsTicketsSearchPage: (page: number) => void;
  tripsTicketsSearchPage: number;
  remainingRecords: number;
  tripsTicketsSearchData: TripsTicketsSearchViewModel[];
  requestTripsTicketsSearchStatus: RequestStatus;
}

export const defaultTripsTicketsContextValue: TripsTicketsContextProps = {
  searchTripsTickets: async () => new Promise(() => void 0),
  clearTripsTicketsSearch: () => void 0,
  setTripsTicketsSearchPage: () => void 0,
  tripsTicketsSearchPage: 1,
  remainingRecords: 0,
  tripsTicketsSearchData: [],
  requestTripsTicketsSearchStatus: API_REQUEST_STATUS.NOT_REQUESTED,
};

const tripsTicketsContext = React.createContext<TripsTicketsContextProps>(defaultTripsTicketsContextValue);

export { tripsTicketsContext };

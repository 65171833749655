import React, { useContext } from 'react';
import { Button, Form, Icon, Input, Message } from 'semantic-ui-react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';

import { userContext } from './../../../shared/store/userContext';
import { getTranslation } from './../../../utils/getTranslation';
import { getRequestStatuses } from './../../../utils/APIHandlers';
import { petUsersContext } from '../../../shared/store/PetUser/petUsersContext';
import {
  pathAdministrationUsers,
  pathAdministrationUsersCreate,
  pathAdministrationUsersEdit,
  pathAdministrationUsersView,
  petUserKey,
} from '../../../routes/constants';
import { WithPetUser } from '../../../shared/containers/PetUser/withPetUser';
import AuditInfoWrapper from './PetUserDetails/PetUserDetailsWrapper';
import CreatePetUserWrapper from './PetUserForm/CreatePetUserWrapper';
import EditPetUserWrapper from './PetUserForm/EditPetUserWrapper';
import { createPetUserContext } from '../../../shared/store/PetUser/createPetUserContext';
import { updatePetUserContext } from '../../../shared/store/PetUser/updatePetUserContext';
import PetUsersTable from './PetUserTable/PetUsersTable';
import { MIN_SEARCH_STRING_LENGTH } from '../../../utils/constants';
import PetUsersTableModals from './PetUserTable/PetUsersTableModals';
import PrivateRoute from '../../PrivateRoute';

import './styles.scss';

const PetUsers: React.FC = () => {
  const { isUserManagementHasReadAccess, isUserManagementHasReadWriteAccess } = useContext(userContext);
  const { requestCreatePetUserMessage, requestCreatePetUserStatus } = useContext(createPetUserContext);
  const {
    requestUpdatePetUserMessage,
    requestUpdatePetUserStatus,
    requestDeletePetUserStatus,
    requestDeletePetUserMessage,
  } = useContext(updatePetUserContext);
  const { requestPetUsersStatus, filterPetUsers, filterSearchString } = useContext(petUsersContext);
  const history = useHistory();
  const requestPetUsers = getRequestStatuses(requestPetUsersStatus);
  const requestCreatePetUser = getRequestStatuses(requestCreatePetUserStatus);
  const requestUpdatePetUser = getRequestStatuses(requestUpdatePetUserStatus);
  const requestDeletePetUser = getRequestStatuses(requestDeletePetUserStatus);

  const handleFilterPetUsers = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    if (value.length >= MIN_SEARCH_STRING_LENGTH) {
      filterPetUsers(value);
    } else {
      filterSearchString.length !== 0 && filterPetUsers('');
    }
  };

  return (
    <div className="administration-tab petUser" data-testid="administration-petUser-tab">
      <div className="buttons-container">
        <Form>
          <Input
            icon="search"
            size="small"
            placeholder={getTranslation('Search')}
            onChange={handleFilterPetUsers}
            name="filterSearch"
            data-testid="petUsersFilter-input"
          />
        </Form>

        {requestCreatePetUser.REQUEST_SUCCESS && (
          <Message
            success
            content={requestCreatePetUserMessage}
            data-testid="createPetUserResponse-message"
          />
        )}
        {requestUpdatePetUser.REQUEST_SUCCESS && (
          <Message
            success
            content={requestUpdatePetUserMessage}
            data-testid="updatePetUserResponse-message"
          />
        )}
        {requestDeletePetUser.REQUEST_SUCCESS && (
          <Message
            success
            content={requestDeletePetUserMessage}
            data-testid="deletePetUserResponse-message"
          />
        )}

        {!isUserManagementHasReadAccess && (
          <Button
            as={Link}
            to={pathAdministrationUsersCreate}
            primary
            size="small"
            disabled={
              !requestPetUsers.REQUEST_SUCCESS || history.location.pathname !== pathAdministrationUsers
            }
            data-testid="petUsersCreateUser-control"
          >
            <Icon name="plus circle" /> {getTranslation('PetUsers_AddUser')}
          </Button>
        )}
      </div>

      <div className="administration-tab-container">
        <div className="administration-tabContent-container">
          <PetUsersTable />

          <PetUsersTableModals />
        </div>
        <div className="administration-tabSidebar-container">
          <Switch>
            <PrivateRoute
              path={[pathAdministrationUsersCreate]}
              exact
              component={CreatePetUserWrapper}
              isAllow={isUserManagementHasReadWriteAccess}
            />

            <WithPetUser>
              <Switch>
                <PrivateRoute
                  path={[pathAdministrationUsersEdit(`:${petUserKey}`), pathAdministrationUsersCreate]}
                  exact
                  isAllow={isUserManagementHasReadWriteAccess}
                  component={EditPetUserWrapper}
                />

                <Route path={pathAdministrationUsersView(`:${petUserKey}`)}>
                  <AuditInfoWrapper />
                </Route>
              </Switch>
            </WithPetUser>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default PetUsers;

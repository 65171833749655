import { Amplify } from 'aws-amplify';
import { PetApi } from '../constants/apiParams';
import { AmplifyConfigurationModel } from '../models/AmplifyConfigurationModel';
import { URL_PARAMS_SESSION_NAME } from '../routes/constants';
import { getCognitoJWTToken } from '../shared/containers/withApolloClient';
import { ExternalConfigurationModel } from '../models/ExternalConfigurationModel';

async function getExternalConfig(apiBaseUrl: string): Promise<ExternalConfigurationModel> {
  const response = await fetch(`${apiBaseUrl}/v1/tickets/pet/configuration`);

  if (!response.ok) {
    throw new Error(`Fail to fetch external configuration. Status: ${response.status}`);
  }

  return (await response.json()) as ExternalConfigurationModel;
}

export async function buildConfiguration(): Promise<AmplifyConfigurationModel> {
  const response = await fetch(`${window.location.origin}/config.json`);
  sessionStorage.setItem(URL_PARAMS_SESSION_NAME, window.location.hash);

  if (!response.ok) {
    throw new Error(`Fail to fetch file. Status: ${response.status}`);
  }
  const json = (await response.json()) as AmplifyConfigurationModel;
  configureAmplify(json);

  const externalConfig = await getExternalConfig(json.apiBaseUrl);

  json.ticketNumbersLimit = externalConfig.ticketNumbersLimit;
  json.recordLocatorsLimit = externalConfig.recordLocatorsLimit;

  return json;
}

function configureAmplify(configuration: AmplifyConfigurationModel) {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: configuration.authRegion,
      userPoolId: configuration.authUserPoolId,
      userPoolWebClientId: configuration.authUserPoolWebClientId,
      oauth: {
        domain: configuration.cognitoEndpoint,
        scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
        redirectSignIn: configuration.redirectUrl,
        redirectSignOut: configuration.redirectUrl,
        responseType: 'token',
      },
    },

    API: {
      endpoints: [
        {
          name: PetApi,
          endpoint: configuration.apiBaseUrl,
          custom_header: async () => {
            const token = await getCognitoJWTToken();

            return {
              Authorization: `Bearer ${token ?? ''}`,
            };
          },
        },
      ],
    },
  });
}

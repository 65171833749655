import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Button, Loader, Message } from 'semantic-ui-react';

import { getScheduledReportContext } from './../../../../shared/store/getScheduledReportContext';
import { downloadScheduledReportContext } from './../../../../shared/store/downloadScheduledReportContext';
import { API_REQUEST_STATUS } from './../../../../utils/APIHandlers';
import { getTranslation } from './../../../../utils/getTranslation';
import TextButton from './../../../../shared/controls/TextButton';

import './styles.scss';

const DownloadReport: React.FC = () => {
  const { report, resetMFAAuthentication } = useContext(getScheduledReportContext);
  const { requestDownloadScheduledReportStatus, downloadScheduledReport } = useContext(
    downloadScheduledReportContext
  );
  const [isDownloadControlDisabled, setIsDownloadControlDisabled] = useState<boolean>(false);
  const isDownloadFailure = requestDownloadScheduledReportStatus === API_REQUEST_STATUS.REQUEST_FAILURE;
  const isDownloadSuccess = requestDownloadScheduledReportStatus === API_REQUEST_STATUS.REQUEST_SUCCESS;
  const isDownloadRequested = requestDownloadScheduledReportStatus === API_REQUEST_STATUS.REQUESTED;

  useEffect(() => {
    setIsDownloadControlDisabled(report === null || isDownloadFailure || isDownloadSuccess);
  }, [setIsDownloadControlDisabled, report, requestDownloadScheduledReportStatus]);

  const handleDownload = useCallback((): void => {
    report &&
      void downloadScheduledReport({
        reportLink: report.link,
        reportFileName: report.fileName,
      });
  }, [report]);

  return (
    <div className="DownloadReport">
      {isDownloadRequested && (
        <div
          className="container-downloadingScheduledReportLoader"
          data-testid="downloadingReportLoader-wrapper"
        >
          <Loader active size="large">
            {getTranslation('DownloadScheduledReport_Downloading')}
          </Loader>
        </div>
      )}

      {!isDownloadRequested && (
        <div data-testid="downloadReport-wrapper">
          <h2>{getTranslation('DownloadScheduledReport_Title')}</h2>
          <span className="DownloadScheduledReport_SubTitle">
            {getTranslation('DownloadScheduledReport_SubTitle')}
          </span>

          <Button
            disabled={isDownloadControlDisabled}
            primary
            fluid
            data-testid="downloadReport-control"
            onClick={handleDownload}
          >
            {getTranslation('Download')}
          </Button>

          {isDownloadFailure && (
            <div className="container-additionalControls">
              <TextButton
                className="resendMFATextControl"
                onClick={resetMFAAuthentication}
                text={getTranslation('MFA_Resend_Code')}
                dataTestId="resetMFAAuthentication-control"
              />
            </div>
          )}
          <div className="container-downloadScheduledReportMessage">
            {isDownloadFailure && (
              <Message color="red" data-testid="failureDownloadReportStatus-message">
                {getTranslation('DownloadScheduledReport_Error')}
              </Message>
            )}
            {isDownloadSuccess && (
              <Message color="green" data-testid="successDownloadReportStatus-message">
                {getTranslation('DownloadScheduledReport_Success')}
              </Message>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DownloadReport;

import React from 'react';

import { AmplifyConfigurationModel } from '../../../models/AmplifyConfigurationModel';
import GetScheduledReportRouter from '../../feature/GetReportContainer/GetScheduledReportRouter';
import { WithGetScheduledReport } from '../../../shared/containers/withGetScheduledReport';
import { defaultUserContextValue, userContext } from '../../../shared/store/userContext';
import Logo from '../../../shared/view/Logo/Logo';
import AuthFooter from '../../../features/Authentication/AuthFooter';
import AuthBannerContent from '../../../features/Authentication/AuthBannerContent';

import './styles.scss';

export interface GetScheduledReportProps {
  config: AmplifyConfigurationModel;
}

const GetScheduledReport: React.FC<GetScheduledReportProps> = ({ config }) => {
  return (
    <div className="container-GetReportForm">
      <div className="container-GetReportContent">
        <Logo />

        <userContext.Provider value={{ ...defaultUserContextValue, apiBaseUrl: config.apiBaseUrl }}>
          <WithGetScheduledReport>
            <div data-testid="public-getScheduledReportRouter-wrapper">
              <GetScheduledReportRouter />
            </div>
          </WithGetScheduledReport>
        </userContext.Provider>

        <AuthFooter />
      </div>

      <div className="banner banner-GetReport">
        <AuthBannerContent />
      </div>
    </div>
  );
};

export default GetScheduledReport;

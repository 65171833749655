import React from 'react';
import { RequestStatus, API_REQUEST_STATUS } from '../../../utils/APIHandlers';
import { FeatureAccessGroupViewModel } from '../../../models/FeatureAccessGroupsAggregate/FeatureAccessGroup';

export interface FeatureAccessGroupContextProps {
  getFeatureAccessGroup: (id: string) => Promise<void>;
  featureAccessGroupData: FeatureAccessGroupViewModel | null;
  requestFeatureAccessGroupStatus: RequestStatus;
  clearFeatureAccessGroupData: () => void;
}

export const defaultFeatureAccessGroupContextValue: FeatureAccessGroupContextProps = {
  getFeatureAccessGroup: async () => new Promise(() => void 0),
  featureAccessGroupData: null,
  requestFeatureAccessGroupStatus: API_REQUEST_STATUS.NOT_REQUESTED,
  clearFeatureAccessGroupData: () => void 0,
};

const featureAccessGroupContext = React.createContext<FeatureAccessGroupContextProps>(
  defaultFeatureAccessGroupContextValue
);

export { featureAccessGroupContext };

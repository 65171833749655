import { FeatureAccessGroupGridViewModel } from '../../../../models/FeatureAccessGroupsAggregate/FeatureAccessGroup';

export type FeatureAccessGroupsTableHeaderType = {
  field: keyof FeatureAccessGroupGridViewModel;
  title: string;
};

export const featureAccessGroupsTableHeader: FeatureAccessGroupsTableHeaderType[] = [
  {
    field: 'groupName',
    title: 'FeatureAccessGroupsGridHeader_GroupName',
  },
  {
    field: 'userManagement',
    title: 'FeatureAccessGroupsGridHeader_UserManagement',
  },
  {
    field: 'ticketsManagement',
    title: 'FeatureAccessGroupsGridHeader_TicketsManagement',
  },
  {
    field: 'featureAccessManagement',
    title: 'FeatureAccessGroupsGridHeader_FeatureAccessManagement',
  },
  {
    field: 'dataAuthorizationManagement',
    title: 'FeatureAccessGroupsGridHeader_DataAuthorizationManagement',
  },
  {
    field: 'scheduledReportsManagement',
    title: 'FeatureAccessGroupsGridHeader_ScheduledReportsManagement',
  },
  {
    field: 'reportManagement',
    title: 'FeatureAccessGroupsDetails_ReportsPermission',
  },
  {
    field: 'countReports',
    title: 'Reports',
  },
];

export const featureAccessGroupsTableHeaderFields: (keyof FeatureAccessGroupGridViewModel)[] =
  featureAccessGroupsTableHeader.map((item) => item.field);

export const featureAccessGroupsTableAdditionalHeaders = [
  {
    field: 'controls',
    title: '',
  },
];

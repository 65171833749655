import React from 'react';
import { Header, Button, Icon } from 'semantic-ui-react';
import { Route, Switch, Link } from 'react-router-dom';

import { getTranslation } from '../../utils/getTranslation';
import {
  pathCreateExportJob,
  pathExportJobsList,
  pathExportJob,
  ExportJobIdKey,
} from '../../routes/constants';
import EditExportJob from '../../features/ExportJobsContainer/EditExportJob';

import './styles.scss';

const ExportJobFormPage: React.FC = () => {
  return (
    <div className="editExportJobs container">
      <Header as="h1" className="container-editExportJobHeader">
        <div data-testid="createEditExportJob-mainTitle">
          <Switch>
            <Route path={pathCreateExportJob} exact>
              {getTranslation('Create_ExportJob_Title')}
            </Route>
            <Route path={pathExportJob(`:${ExportJobIdKey}`)} exact>
              {getTranslation('Edit_ExportJob_Title')}
            </Route>
          </Switch>
        </div>
        <div className="container-editExportJobGoBack">
          <Button
            as={Link}
            to={pathExportJobsList}
            basic
            size="small"
            color="blue"
            data-testid="goToExportJobsPage-link"
          >
            <Icon name="arrow left" />{' '}
            <span className="editExportJobGoBack_backText">{getTranslation('Edit_ExportJobs_GoBack')}</span>
          </Button>
        </div>
      </Header>

      <div className="container-editExportJobsWrapper">
        <Switch>
          <Route
            path={[pathCreateExportJob, pathExportJob(`:${ExportJobIdKey}`)]}
            component={EditExportJob}
            exact
          />
        </Switch>
      </div>
    </div>
  );
};

export default ExportJobFormPage;

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { buildConfiguration } from './services/configurationBuilder';
import { AmplifyConfigurationModel } from './models/AmplifyConfigurationModel';

import './index.scss';

buildConfiguration()
  .then((config: AmplifyConfigurationModel) =>
    renderComponentToRoot(
      <React.StrictMode>
        <App config={config} />
      </React.StrictMode>
    )
  )
  .catch((reason) => {
    console.error(reason);
  });

function renderComponentToRoot(component: JSX.Element) {
  const domNode = document.getElementById('root');

  if (!domNode) {
    throw new Error('Failed to find the root element');
  }

  const root = createRoot(domNode);
  root.render(component);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

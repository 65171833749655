import { ExportJobDTO } from '../../../api/graphql/models/ExportJob';
import { PeriodType, PeriodValue } from '../../../models/ExportJobsAggregate/ExportJobPeriodRange';
import { getTranslation } from '../../../utils/getTranslation';

export const getPeriodLabel = (data: ExportJobDTO): string => {
  if (data.reportDetails) {
    return data.reportDetails.period.type === PeriodType.CUSTOM
      ? `${data.reportDetails.period.offsetDays ?? 0} ${getTranslation('Day_Word')}`
      : getTranslation(PeriodValue[data.reportDetails.period.type]);
  } else {
    return getTranslation('TableLabel_None');
  }
};

import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Divider, Grid, Header, Label, Loader, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import DateInfo from '../../../PetForm/DateInfo';
import { FeatureAccessGroupViewModel } from '../../../../models/FeatureAccessGroupsAggregate/FeatureAccessGroup';
import TableLabel from '../../../TableLabel';
import { getAccessTypesConfig, getAccessTypesReportConfig } from '../constants';
import { getTranslation } from '../../../../utils/getTranslation';
import LabelWrapper from '../../../../shared/view/LabelWrapper/LabelWrapper';
import { reportsContext } from '../../../../shared/store/reportsContext';
import { getRequestStatuses } from '../../../../utils/APIHandlers';
import TextButton from '../../../../shared/controls/TextButton/TextButton';
import { pathAdministrationFeatureGroups } from '../../../../routes/constants';
import CloseControl from '../../../../shared/controls/CloseControl/CloseControl';
import { hasReportsManagementAccess } from '../../../../pages/Administration/helper';
import { userContext } from '../../../../shared/store/userContext';
import NoAccessLabel from '../../../../shared/view/NoAccessLabel/NoAccessLabel';
import { AccessType } from '../../../../models/FeatureAccessGroupsAggregate/AccessType';
import { ReportViewModel } from '../../../../models/ReportsAggregate/Report';

import './../../../PetForm/commonAuditInfoStyles.scss';

interface Props {
  viewFeatureAccessGroup: FeatureAccessGroupViewModel;
}

const FeatureAccessGroupDetails: React.FC<Props> = (props) => {
  const {
    groupName,
    createdDate,
    createdBy,
    updatedDate,
    updatedBy,
    userManagement,
    reportManagement,
    ticketsManagement,
    scheduledReportsManagement,
    featureAccessManagement,
    dataAuthorizationManagement,
    reports,
  } = props.viewFeatureAccessGroup;
  const userContextValue = useContext(userContext);
  const { getReports, reportsData, requestReportsStatus } = useContext(reportsContext);
  const [reportsToView, setReportsToView] = useState<ReportViewModel[]>([]);
  const requestReports = useMemo(() => getRequestStatuses(requestReportsStatus), [requestReportsStatus]);
  const isReportsManagementAccess = hasReportsManagementAccess(userContextValue);
  const accessTypesConfig = getAccessTypesConfig();
  const accessTypesReportConfig = getAccessTypesReportConfig();

  useEffect(() => {
    isReportsManagementAccess && reports.length !== 0 && void getReports();
  }, [reports]);

  useEffect(() => {
    requestReports.REQUEST_SUCCESS &&
      reportsData.length > 0 &&
      setReportsToView(reportsData.filter(({ id }) => reports.includes(id)));
  }, [requestReports, reportsData]);

  return (
    <>
      <Link to={pathAdministrationFeatureGroups}>
        <CloseControl />
      </Link>

      <Grid>
        <Grid.Row>
          <Grid.Column>
            <LabelWrapper label={getTranslation('FeatureAccessGroupsDetails_GroupName')}>
              <Header as="h1">{groupName}</Header>
            </LabelWrapper>
          </Grid.Column>
        </Grid.Row>

        <Divider />

        <Grid.Row>
          <Grid.Column>
            <LabelWrapper label={getTranslation('FeatureAccessGroupsDetails_UserManagement')}>
              <TableLabel accessTypeConfig={accessTypesConfig[userManagement]} />
            </LabelWrapper>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <LabelWrapper label={getTranslation('FeatureAccessGroupsDetails_TicketsManagement')}>
              <TableLabel accessTypeConfig={accessTypesConfig[ticketsManagement]} />
            </LabelWrapper>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <LabelWrapper label={getTranslation('FeatureAccessGroupsDetails_FeatureAccessGroupManagement')}>
              <TableLabel accessTypeConfig={accessTypesConfig[featureAccessManagement]} />
            </LabelWrapper>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <LabelWrapper
              label={getTranslation('FeatureAccessGroupsDetails_DataAuthorizationRolesManagement')}
            >
              <TableLabel accessTypeConfig={accessTypesConfig[dataAuthorizationManagement]} />
            </LabelWrapper>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <LabelWrapper label={getTranslation('FeatureAccessGroupsDetails_ExportJobsManagement')}>
              <TableLabel accessTypeConfig={accessTypesConfig[scheduledReportsManagement]} />
            </LabelWrapper>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <LabelWrapper label={getTranslation('FeatureAccessGroupsDetails_ReportsPermission')}>
              <TableLabel accessTypeConfig={accessTypesReportConfig[reportManagement]} />
            </LabelWrapper>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row data-testid="reportField-ViewFeatureAccessGroup">
          <Grid.Column>
            <LabelWrapper label={getTranslation('Reports')}>
              {requestReports.REQUESTED && (
                <Loader data-testid="reportsField-ViewFeatureAccessGroup-loader" size="small" active inline />
              )}
              {isReportsManagementAccess && reportsToView && (
                <div className="values" data-testid="reportsField-ViewFeatureAccessGroup-values">
                  {reportsToView.map(({ id, displayName }) => (
                    <Label
                      content={displayName}
                      key={id}
                      data-testid="reportsField-ViewFeatureAccessGroup-valueItem"
                    />
                  ))}
                </div>
              )}

              {isReportsManagementAccess && reports.length === 0 && (
                <TableLabel
                  accessTypeConfig={accessTypesConfig[AccessType.None]}
                  dataTestId="reportsField-ViewFeatureAccessGroup-noReports"
                />
              )}

              {!isReportsManagementAccess && (
                <NoAccessLabel dataTestId="reportsField-ViewFeatureAccessGroup" />
              )}
              {requestReports.REQUEST_FAILURE && (
                <Message error data-testid="errorLoadReports-ViewFeatureAccessGroup-message">
                  <p>{getTranslation('Error')}</p>
                  <TextButton
                    text={getTranslation('Retry_Now')}
                    onClick={() => void getReports()}
                    dataTestId="errorLoadReports-ViewFeatureAccessGroup-retryControl"
                  />
                </Message>
              )}
            </LabelWrapper>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <DateInfo
              createdDate={createdDate}
              createdBy={createdBy}
              updatedDate={updatedDate}
              updatedBy={updatedBy}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default FeatureAccessGroupDetails;

import React from 'react';
import { Header, Icon } from 'semantic-ui-react';
import { getTranslation } from '../../../utils/getTranslation';

const AccessDeniedMessage: React.FC = () => {
  return (
    <div data-testid="access-denied-message">
      <div className="header-icon-container">
        <Icon name="info circle" color="grey" size="large" />
        <Header as="h1">{getTranslation('Private_Title')}</Header>
      </div>
      <div className="text">{getTranslation('AccessDeniedMessage_Text')}</div>
    </div>
  );
};

export default AccessDeniedMessage;

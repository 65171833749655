export enum PeriodType {
  DAY = 1,
  WEEK,
  MONTH,
  QUARTER,
  HALF_A_YEAR,
  CUSTOM,
}

export const PERIOD_DAY = 'PastDay_Title';
export const PERIOD_WEEK = 'PastWeek_Title';
export const PERIOD_MONTH = 'PastMonth_Title';
export const PERIOD_QUARTER = 'PastQuater_Title';
export const PERIOD_HALF_A_YEAR = 'PastSixMonths_Title';
export const PERIOD_CUSTOM = 'CustomDays_Title';

export const PeriodValue = {
  [PeriodType.DAY]: PERIOD_DAY,
  [PeriodType.WEEK]: PERIOD_WEEK,
  [PeriodType.MONTH]: PERIOD_MONTH,
  [PeriodType.QUARTER]: PERIOD_QUARTER,
  [PeriodType.HALF_A_YEAR]: PERIOD_HALF_A_YEAR,
  [PeriodType.CUSTOM]: PERIOD_CUSTOM,
};

export const getPeriodTypeStatuses = (type: PeriodType): { [key in PeriodType]: boolean } => ({
  [PeriodType.DAY]: type === PeriodType.DAY,
  [PeriodType.WEEK]: type === PeriodType.WEEK,
  [PeriodType.MONTH]: type === PeriodType.MONTH,
  [PeriodType.QUARTER]: type === PeriodType.QUARTER,
  [PeriodType.HALF_A_YEAR]: type === PeriodType.HALF_A_YEAR,
  [PeriodType.CUSTOM]: type === PeriodType.CUSTOM,
});

import React, { ReactElement, useState } from 'react';
import DatePicker from 'react-datepicker';
import { FieldError, FieldValues } from 'react-hook-form/dist/types';
import { Form, Icon } from 'semantic-ui-react';
import FormFieldLabel from '../../view/FormFieldLabel/FormFieldLabel';

import './styles.scss';

interface DatePickerFieldProps {
  label: string | React.ReactChild;
  required?: boolean;
  error?: FieldValues;
  selectsRange?: boolean;
  isClearable?: boolean;
  className?: string;
  dataTestId?: string;
  defaultValue?: [Date, Date] | null;
  onChange: (date: [Date, Date]) => void;
  placeholderText?: string;
}

const DatePickerField: React.FC<DatePickerFieldProps> = ({
  label,
  required,
  error,
  selectsRange,
  isClearable,
  dataTestId,
  defaultValue,
  onChange,
  placeholderText,
}): ReactElement => {
  const [startIssueDate, setStartIssueDate] = useState<Date | undefined | null>(
    (defaultValue && defaultValue[0]) || undefined
  );
  const [endIssueDate, setEndIssueDate] = useState<Date | undefined | null>(
    (defaultValue && defaultValue[1]) || undefined
  );

  return (
    <Form.Field>
      <FormFieldLabel required={required}>{label}</FormFieldLabel>
      <div className="container-datePickerField" data-testid={dataTestId}>
        <DatePicker
          selectsRange={selectsRange}
          startDate={startIssueDate}
          endDate={endIssueDate}
          onChange={(date: [Date, Date]) => {
            setStartIssueDate(date[0]);
            setEndIssueDate(date[1]);
            onChange(date);
          }}
          isClearable={isClearable}
          className={`container-datePickerInput ${error ? 'error-datePicker-field' : ''}`}
          placeholderText={placeholderText}
        />
        <Icon className="calendar alternate outline" />
      </div>

      {!!error && <div className="formError">{(error as FieldError).message}</div>}
    </Form.Field>
  );
};

export default DatePickerField;

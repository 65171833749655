export enum GtrStatusValue {
  Unknown = 0,
  Voided,
  OpenAvailable,
  Exchanged,
  Suspended,
  UsedFlown,
  Refunded,
  Expired,
  AirportControl,
  Lost,
  Voucher,
  UnusedAvailable,
  Restricted,
  Exception,
  ServiceFee,
  Migrated,
  Inactive,
  BtaUatp,
}

interface FareDTO {
  amount?: number | null;
  currency?: string | null;
  description?: string | null;
}

export interface TripTicketDTO {
  creditAmount?: FareDTO | null;
  expiryDate?: string | null;
  fareAmount?: number | null;
  fareCalculation?: string | null;
  fareCurrency?: string | null;
  invoiceNumber?: string | null;
  isConjunctive?: boolean | null;
  isRefundable: boolean;
  lowFareReasonCode?: string | null;
  ticketBranchPcc?: string | null;
  ticketIssueDate?: string | null;
  ticketStock?: string | null;
  ticketNumber?: string | null;
  tourCode?: string | null;
  endorsements?: string[] | null;
  travelerNumber?: string | null;
  validatingCarrier?: string | null;
  formOfPayments?: {
    paymentNumber?: string | null;
  } | null;
  gtrStatus?: {
    value?: GtrStatusValue | null;
  } | null;
  fares?: FareDTO[] | null;
  taxes?:
    | {
        fare?: FareDTO | null;
      }[]
    | null;
  exchangeInfo?: {
    newTicket?: NewTicketExchangeInfoDTO | null;
    originalTicket?: OriginalTicketExchangeInfoDTO | null;
  } | null;
  coupons?: CouponDTO[] | null;
}

export interface CouponDTO {
  departureDateTime?: string | null;
  departureAirportCode?: string | null;
  departureAirportCountryCode?: string | null;
  arrivalDateTime?: string | null;
  arrivalAirportCode?: string | null;
  arrivalAirportCountryCode?: string | null;
  marketingFlightNumber?: string | null;
  vendorLocator?: string | null;
  ticketDesignator?: string | null;
  couponStatus?: string | null;
  fareBasisCode?: string | null;
  classOfService?: string | null;
  classOfServiceDescription?: string | null;
}

export interface NewTicketExchangeInfoDTO {
  ticketStock?: string | null;
  ticketNumber?: string | null;
  recordLocator?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  issueDate?: string | null;
  expiryDate?: string | null;
}

export interface OriginalTicketExchangeInfoDTO {
  amount?: FareDTO | null;
  issueDate?: string | null;
  ticketNumber?: string | null;
  ticketStock?: string | null;
  recordLocator?: string | null;
}

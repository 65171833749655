import { gql } from '@apollo/client';

export const INSERT = gql`
  mutation ($role: DataAuthorizationRoleInsertInput!) {
    insertOneDataAuthorizationRole(data: $role) {
      _id
      roleName
    }
  }
`;

export const UPDATE = gql`
  mutation ($id: String!, $role: DataAuthorizationRoleInsertInput!) {
    replaceOneDataAuthorizationRole(query: { _id: $id }, data: $role) {
      _id
    }
  }
`;

import { gql } from '@apollo/client';

export const GET_ALL = gql`
  query ($isNotIncludedPaginated: Boolean) {
    reports(query: { isPaginated_ne: $isNotIncludedPaginated }) {
      _id
      displayName
      reportName
      isPaginated
      icon
      reportType
      description
    }
  }
`;

import React, { useContext, useState } from 'react';
import { NormalizedCacheObject, ApolloClient } from '@apollo/client';

import { WithApolloClient } from '../../features/WithApolloClient/WithApolloClient';
import { ReportTypeDto } from '../../api/graphql/models/ReportTypeDto';
import { ReportTypesService } from '../../services/ReportTypesService';
import { PetUsersService } from '../../services/PetUsersService';
import { userContext } from '../../shared/store/userContext';
import {
  defaultUserInfoContextValue,
  UserInfoContextProps,
  userInfoContext,
} from '../store/profileUserInfoContext';
import { PetUserDTO } from '../../api/graphql/models/Users';

interface Props {
  children: React.ReactChild;
  client?: ApolloClient<NormalizedCacheObject>;
}

const ProfileUserInfo: React.FC<Props> = ({ children, client }) => {
  const [userReports, setUserReports] = useState<ReportTypeDto[]>(defaultUserInfoContextValue.userReports);
  const [userInfo, setUserInfo] = useState<PetUserDTO | null>(defaultUserInfoContextValue.userInfo);
  const [isSpinnerShow, setIsSpinnerShow] = useState(defaultUserInfoContextValue.isSpinnerShow);
  const [isErrorShow, setIsErrorShow] = useState(defaultUserInfoContextValue.isErrorShow);
  const { username, apiBaseUrl } = useContext(userContext);

  const getUserInfo = async () => {
    try {
      const clientApollo = client as ApolloClient<NormalizedCacheObject>;
      const reportTypesService: ReportTypesService = new ReportTypesService(clientApollo);
      const userService: PetUsersService = new PetUsersService(clientApollo, apiBaseUrl);

      const responseUserInfo = await userService.getByUserName(username);
      setUserInfo(responseUserInfo);

      const allReportTypes = await reportTypesService.getReportTypes();

      setUserReports(
        allReportTypes.filter(
          ({ _id }) =>
            typeof _id == 'number' &&
            responseUserInfo?.featureAccessGroupId?.reportManagement?.reports.includes(_id)
        )
      );
    } catch (error) {
      setIsErrorShow(true);
      console.error(error);
    }

    setIsSpinnerShow(false);
  };

  const getUserInfoContext = (): UserInfoContextProps => {
    return {
      ...defaultUserInfoContextValue,
      getUserInfo,
      userReports,
      userInfo,
      isSpinnerShow,
      isErrorShow,
    };
  };

  return <userInfoContext.Provider value={getUserInfoContext()}>{children}</userInfoContext.Provider>;
};

const WithProfileUserInfo = WithApolloClient(ProfileUserInfo);

export { WithProfileUserInfo };

export const ticketFormDefaultValues = {
  ticketNumber: '',
  travelerEmailAddress: '',
  recordLocator: '',
  firstName: '',
  lastName: '',
  globalCustomerNumber: '',
  accountId: '',
  customerNumber: '',
  ticketIssueDate: null,
};

export const additionalFields = [
  'globalCustomerNumber',
  'accountId',
  'customerNumber',
  'startIssueDate',
  'endIssueDate',
];

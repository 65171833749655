import React from 'react';
import { RequestStatus, API_REQUEST_STATUS } from '../../utils/APIHandlers';

export interface DeleteExportExportJobContextProps {
  isOpenDeleteCondition: boolean;
  deleteExportJobId: string | null;
  deleteExportJob: () => Promise<void>;
  requestDeleteExportJobStatus: RequestStatus;
  notificationDeleteExportJob: RequestStatus;
  handleOpenDelete: (id: string) => void;
  handleCloseDelete: () => void;
}

export const defaultDeleteExportJobContextValue: DeleteExportExportJobContextProps = {
  isOpenDeleteCondition: false,
  deleteExportJobId: null,
  deleteExportJob: async () => new Promise(() => void 0),
  requestDeleteExportJobStatus: API_REQUEST_STATUS.NOT_REQUESTED,
  notificationDeleteExportJob: API_REQUEST_STATUS.NOT_REQUESTED,
  handleOpenDelete: () => void 0,
  handleCloseDelete: () => void 0,
};

const deleteExportJobContext = React.createContext<DeleteExportExportJobContextProps>(
  defaultDeleteExportJobContextValue
);

export { deleteExportJobContext };

import React, { useContext, useMemo, useCallback } from 'react';

import { getRequestStatuses } from '../../../utils/APIHandlers';
import { documentContext } from '../../../shared/store/documentsContext';
import { Icon, Table } from 'semantic-ui-react';
import TableDocumentNameCell from './TableDocumentNameCell';
import { Document } from '../../../api/rest/models/Document';
import { getFormattedDate } from '../../../utils/formatDate';

import './styles.scss';

const TableDocumentRow: React.FC<Document> = ({ name: documentName, lastModified, sizeInBytes }) => {
  const { downloadDocument, requestDownloadDocumentStatus, downloadDocumentName } =
    useContext(documentContext);
  const requestDownloadDocument = useMemo(
    () => getRequestStatuses(requestDownloadDocumentStatus),
    [requestDownloadDocumentStatus]
  );
  const isPositiveDownload = useMemo(
    (): boolean => downloadDocumentName === documentName && requestDownloadDocument.REQUEST_SUCCESS,
    [downloadDocumentName, requestDownloadDocument, documentName]
  );
  const isNegativeDownload = useMemo(
    (): boolean => downloadDocumentName === documentName && requestDownloadDocument.REQUEST_FAILURE,
    [downloadDocumentName, requestDownloadDocument, documentName]
  );
  const handleDownloadDocument = useCallback(
    (documentName: string) => {
      void downloadDocument({ documentName });
    },
    [downloadDocument]
  );

  return (
    <Table.Row positive={isPositiveDownload} negative={isNegativeDownload} data-testid="tableDocumentRow">
      <Table.Cell className="text-center">
        <TableDocumentNameCell
          documentName={documentName}
          isPositiveDownload={isPositiveDownload}
          isNegativeDownload={isNegativeDownload}
        />
      </Table.Cell>
      <Table.Cell data-testid="tableDocumentRow-lastModified">
        {lastModified && getFormattedDate(new Date(lastModified))}
      </Table.Cell>
      <Table.Cell data-testid="tableDocumentRow-sizeInBytes" textAlign="right">
        {sizeInBytes / 1000}Kb
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Icon
          data-testid="tableDocumentRow-download-btn"
          name="download"
          onClick={() => handleDownloadDocument(documentName)}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default TableDocumentRow;

import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Card, Message } from 'semantic-ui-react';

import { DashboardReportViewModel } from '../../../models/ReportsAggregate/Report';
import { COUNT_LOADING_WIRE_FRAMES } from './constants';
import MessageWithControl from '../../../shared/controls/MessageWithControl';
import TextButton from '../../../shared/controls/TextButton';
import { reportsPageContext } from '../../../shared/store/reportsPageContext';
import EmptyMessage from '../../../shared/view/EmptyMessage/EmptyMessage';
import { getRequestStatuses } from '../../../utils/APIHandlers';
import { getTranslation } from '../../../utils/getTranslation';
import ReportCard from '../ReportCardsContainer/ReportCard';
import ReportCardWireFrame from '../ReportCardsContainer/ReportCardWireFrame';
import { mapDashboardReportsFromDTO } from '../../AdministrationContainer/Reports/reportsMapper';

import './styles.scss';

const DashboardContent: React.FC = () => {
  const [reports, setReports] = useState<DashboardReportViewModel[]>([]);
  const { reportsConfig, requestReportsStatus, getReportsConfig } = useContext(reportsPageContext);
  const wireFrames = Array.from(Array(COUNT_LOADING_WIRE_FRAMES).keys());
  const requestReports = getRequestStatuses(requestReportsStatus);
  const handleGetReportsConfig = useCallback(() => {
    void getReportsConfig();
  }, [getReportsConfig]);

  useEffect(() => {
    !requestReports.REQUEST_SUCCESS && !requestReports.REQUESTED && handleGetReportsConfig();
  }, []);

  useEffect(() => {
    reportsConfig?.reports && setReports(mapDashboardReportsFromDTO(reportsConfig.reports));
  }, [reportsConfig]);

  return (
    <>
      {requestReports.REQUEST_FAILURE && (
        <Message
          error
          data-testid="message-homeGetReportsConfig-error"
          className="homeGetReportsConfig-message"
        >
          <MessageWithControl message={getTranslation('Error')}>
            <TextButton
              dataTestId="retry-homeGetReportsConfig-btn"
              onClick={handleGetReportsConfig}
              text={getTranslation('Retry')}
            />
          </MessageWithControl>
        </Message>
      )}

      <Card.Group itemsPerRow={5} className="container-reportCards">
        {requestReports.REQUEST_SUCCESS &&
          reports.map((report) => (
            <ReportCard report={report} key={report.id} dataTestId="home-reportCard" />
          ))}
        {requestReports.REQUESTED &&
          wireFrames.map((item) => <ReportCardWireFrame key={item} dataTestId="home-reportCard-wireFrame" />)}
      </Card.Group>

      {(requestReports.REQUEST_FAILURE || (requestReports.REQUEST_SUCCESS && reports.length === 0)) && (
        <EmptyMessage optionalText={getTranslation('No_Reports')} dataTestId="home-empty-message" />
      )}
    </>
  );
};

export default DashboardContent;

import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

import { getTranslation } from './../../../utils/getTranslation';

import './styles.scss';

interface FullSizeLoaderProps {
  dataTestId?: string;
  blur?: boolean;
}

const FullSizeLoader: React.FC<FullSizeLoaderProps> = ({ dataTestId, blur = true }) => (
  <div className={`container-fullSizeLoader ${!blur ? 'transparent' : ''}`} data-testid="fullSizeLoader">
    <Dimmer active inverted data-testid={dataTestId}>
      <Loader size="big">{getTranslation('App_Loading')}</Loader>
    </Dimmer>
  </div>
);

export default FullSizeLoader;

import { useState } from 'react';
import { API_REQUEST_STATUS, RequestStatus } from '../../utils/APIHandlers';

let closeTimeout: ReturnType<typeof setTimeout>;
const DEFAULT_SUCCESS_TIMEOUT_MS = 4000;

export type useNotificationReturn = [
  {
    notificationStatus: RequestStatus;
  },
  {
    setNotRequestedNotificationStatus: () => void;
    setRequestedNotificationStatus: () => void;
    setSuccessNotificationStatus: (customDelay?: number) => void;
    setFailNotificationStatus: () => void;
  }
];

export const useNotification = (): useNotificationReturn => {
  const [notificationStatus, setNotificationStatus] = useState<RequestStatus>(
    API_REQUEST_STATUS.NOT_REQUESTED
  );

  const setNotRequestedNotificationStatus = (): void => {
    setNotificationStatus(API_REQUEST_STATUS.NOT_REQUESTED);
  };
  const setRequestedNotificationStatus = (): void => {
    setNotificationStatus(API_REQUEST_STATUS.REQUESTED);
  };
  const setSuccessNotificationStatus = (customDelay: number | undefined): void => {
    setNotificationStatus(API_REQUEST_STATUS.REQUEST_SUCCESS);

    clearInterval(closeTimeout);
    closeTimeout = setTimeout(() => {
      setNotRequestedNotificationStatus();
    }, customDelay || DEFAULT_SUCCESS_TIMEOUT_MS);
  };
  const setFailNotificationStatus = (): void => {
    setNotificationStatus(API_REQUEST_STATUS.REQUEST_FAILURE);
  };

  return [
    {
      notificationStatus,
    },
    {
      setNotRequestedNotificationStatus,
      setRequestedNotificationStatus,
      setSuccessNotificationStatus,
      setFailNotificationStatus,
    },
  ];
};

import { TripTicketDTO } from './TripTicket';

export enum Gds {
  Other,
  Sabre,
  Apollo,
  Worldspan,
  Amadeus,
  Galileo,
  Abacus,
}

export interface GetTripByIdResponse {
  canonicals: TripDTO[];
}

export interface TripRequestDTO {
  id: string;
}

export interface IdentificationDTO {
  accountCountryCode?: string | null;
  accountId?: string | null;
  creationOfficeId?: string | null;
  customerName?: string | null;
  customerNumber?: string | null;
  gds?: Gds | null;
  globalCustomerNumber?: string | null;
  isCancelled: boolean;
  pseudoCityCode?: string | null;
  recordLocator?: string | null;
}

export interface TravelerDTO {
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  primaryEmailAddress?: string | null;
  travelerNumber?: string | null;
}

export interface TripDTO {
  createdBy: string;
  createdDate: string;
  expirationFromDate: string;
  identification?: IdentificationDTO | null;
  tickets?: TripTicketDTO[] | null;
  travelers?: TravelerDTO[] | null;
  updatedBy?: string | null;
  updatedDate?: string | null;
}

import React, { useContext } from 'react';
import { Header, Segment } from 'semantic-ui-react';

import { WithReportsPage } from '../../shared/containers/withReportsPage';
import Dashboard from '../../features/Dashboard/DashboardContent/DashboardContent';
import { getTranslation } from '../../utils/getTranslation';
import { userContext } from '../../shared/store/userContext';

import './styles.scss';

const Home: React.FC = () => {
  const { firstName, isReportsReadAccess } = useContext(userContext);

  return (
    <div className="homePage container">
      <Header as="h1">
        {getTranslation('Welcome')} {firstName}!
      </Header>
      <Segment className="text">
        <p>
          Ticket Tracker is BCD Travel`s global ticket management tool meant to enhance our automation to
          include more modern solutions.
          <br />
          Providing an opportunity to reduce client spend by utilizing open tickets for future travel as well
          as identifying refunding tickets
        </p>
      </Segment>

      {isReportsReadAccess && (
        <div data-testid="homePage-dashboard-wrapper">
          <WithReportsPage>
            <Dashboard />
          </WithReportsPage>
        </div>
      )}
    </div>
  );
};

export default Home;

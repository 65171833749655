import { AccessType } from '../../../models/FeatureAccessGroupsAggregate/AccessType';
import {
  FeatureAccessGroupFormViewModel,
  FeatureAccessGroupViewModel,
} from '../../../models/FeatureAccessGroupsAggregate/FeatureAccessGroup';
import { ReportAccessType } from '../../../models/FeatureAccessGroupsAggregate/ReportAccessType';
import { getTranslation } from './../../../utils/getTranslation';
import { ConfigType } from './FeatureAccessGroupsForm/types';

export const getAccessTypesConfig = (): ConfigType => ({
  0: { access: getTranslation('TableLabel_None'), color: 'grey' },
  1: { access: getTranslation('TableLabel_Read'), color: 'orange' },
  2: { access: getTranslation('TableLabel_ReadWrite'), color: 'blue' },
});

export const getAccessTypesReportConfig = (): ConfigType => ({
  0: { access: getTranslation('TableLabel_None'), color: 'grey' },
  1: { access: getTranslation('TableLabel_Read'), color: 'orange' },
  2: { access: getTranslation('TableLabel_ReadExport'), color: 'blue' },
  3: { access: getTranslation('TableLabel_ReadWriteExport'), color: 'green' },
});

export const emptyViewFeatureAccessGroup: FeatureAccessGroupViewModel = {
  id: '',
  groupName: '',
  reportManagement: ReportAccessType.None,
  userManagement: AccessType.None,
  ticketsManagement: AccessType.None,
  scheduledReportsManagement: AccessType.None,
  featureAccessManagement: AccessType.None,
  dataAuthorizationManagement: AccessType.None,
  reports: [],
  createdBy: '',
  createdDate: new Date(),
};

export const emptyFormFeatureAccessGroup: FeatureAccessGroupFormViewModel = {
  groupName: '',
  reportManagement: {
    accessType: ReportAccessType.None,
    reports: [],
  },
  userManagement: {
    accessType: AccessType.None,
  },
  ticketsManagement: {
    accessType: AccessType.None,
  },
  scheduledReportsManagement: {
    accessType: AccessType.None,
  },
  featureAccessManagement: {
    accessType: AccessType.None,
  },
  dataAuthorizationManagement: {
    accessType: AccessType.None,
  },
};

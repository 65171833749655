import React, { useCallback } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { getTranslation } from '../../utils/getTranslation';

interface Props {
  isOpen: boolean;
  onClose: (isSuccess?: boolean) => void;
  titleText: string;
  contentText: string;
  textActionButton: string;
  isSaveBtn?: boolean;
}

const WarningModal: React.FC<Props> = ({
  isOpen,
  onClose,
  titleText,
  contentText,
  textActionButton,
  isSaveBtn,
}) => {
  const handleClose = useCallback(() => onClose(false), [onClose]);
  const handleSuccessClose = useCallback(() => onClose(true), [onClose]);

  return (
    <Modal
      open={isOpen}
      size="small"
      className="warningModal"
      closeIcon={true}
      closeOnEscape={true}
      closeOnDimmerClick={true}
      onClose={handleClose}
    >
      <Modal.Header>
        <h3>{titleText}</h3>
      </Modal.Header>
      <Modal.Content>{contentText}</Modal.Content>
      <Modal.Actions>
        <Button
          data-testid="cancel-btn"
          className="form-button"
          basic
          size="small"
          color="blue"
          onClick={handleClose}
        >
          {getTranslation('Cancel')}
        </Button>
        <Button
          data-testid="submit-btn"
          className="form-button"
          size="small"
          color={isSaveBtn ? 'blue' : 'red'}
          onClick={handleSuccessClose}
        >
          {textActionButton}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default WarningModal;

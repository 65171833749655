import React from 'react';

import { AmplifyConfigurationModel } from './../../models/AmplifyConfigurationModel';
import { User } from './../../types';
import { CurrentUser } from '../containers/withUser';

export interface UserContextProps {
  user?: User | null;
  isUserLoaded: boolean;
  setCurrentUser: React.Dispatch<React.SetStateAction<CurrentUser>>;
  isTicketsManagementHasReadWriteAccess: boolean;
  isTicketsManagementHasReadAccess: boolean;
  email: string;
  reportsAccessType: number;
  firstName: string;
  isUserManagementHasReadAccess: boolean;
  isUserManagementHasReadWriteAccess: boolean;
  isDataAuthorizationManagementReadAccess: boolean;
  isDataAuthorizationManagementReadWriteAccess: boolean;
  isFeatureAccessManagementReadAccess: boolean;
  isFeatureAccessManagementReadWriteAccess: boolean;
  isScheduledReportsManagementHasReadAccess: boolean;
  isScheduledReportsManagementHasReadWriteAccess: boolean;
  isReportsReadAccess: boolean;
  isReportsExportAccess: boolean;
  username: string;
  lastName: string;
  workSpaceId: string;
  environment: string;
  version: string;
  apiBaseUrl: string;
  config: AmplifyConfigurationModel;
  ticketNumbersLimit: number;
  recordLocatorsLimit: number;
  logOut: () => Promise<void>;
  updateUserData: () => Promise<void>;
}

export const defaultAppConfigs: AmplifyConfigurationModel = {
  authIdentityPoolId: '',
  authRegion: '',
  authUserPoolId: '',
  authUserPoolWebClientId: '',
  apiBaseUrl: '',
  environment: '',
  workSpaceId: '',
  version: '',
  cognitoEndpoint: '',
  redirectUrl: '',
  graphqlEndpoint: '',
  realmAppId: '',
  ticketNumbersLimit: 100,
  recordLocatorsLimit: 15,
};

export const defaultUserContextValue: UserContextProps = {
  user: null,
  isUserLoaded: false,
  setCurrentUser: () => void 0,
  isTicketsManagementHasReadWriteAccess: false,
  isTicketsManagementHasReadAccess: false,
  isUserManagementHasReadAccess: false,
  isUserManagementHasReadWriteAccess: false,
  isDataAuthorizationManagementReadAccess: false,
  isDataAuthorizationManagementReadWriteAccess: false,
  isFeatureAccessManagementReadAccess: false,
  isFeatureAccessManagementReadWriteAccess: false,
  isScheduledReportsManagementHasReadAccess: false,
  isScheduledReportsManagementHasReadWriteAccess: false,
  isReportsReadAccess: false,
  isReportsExportAccess: false,
  email: '',
  reportsAccessType: 0,
  firstName: '',
  lastName: '',
  workSpaceId: '',
  environment: '',
  version: '',
  apiBaseUrl: '',
  username: '',
  config: defaultAppConfigs,
  ticketNumbersLimit: 0,
  recordLocatorsLimit: 0,
  logOut: async () => new Promise(() => void 0),
  updateUserData: async () => new Promise(() => void 0),
};

const userContext = React.createContext<UserContextProps>(defaultUserContextValue);

export { userContext };

import React, { ChangeEvent, useState } from 'react';
import { Input, Icon, Label } from 'semantic-ui-react';

import TableLabel from '../../TableLabel';
import { getTranslation } from '../../../utils/getTranslation';
import { RestrictionFilter } from '../../../models/DataAuthorizationRolesAggregate/RestrictionFilter';
import { getRestrictionsToView, isShowAllRestrictionItems, searchRestrictions } from './helper';

import './styles.scss';

interface Props {
  restrictionFilter?: RestrictionFilter;
  numberDisplayedValues?: number;
}

const RestrictionFilterValue: React.FC<Props> = ({ restrictionFilter, numberDisplayedValues }) => {
  const [result, setResult] = useState<string[]>(restrictionFilter?.values || []);
  const [isResultOpen, setIsResultOpen] = useState<boolean>(false);
  const isShowAllItems = isShowAllRestrictionItems({
    numberDisplayedValues,
    countResultItems: result.length,
  });
  const restrictions = getRestrictionsToView({ numberDisplayedValues, isResultOpen, restrictions: result });

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>, { value }: { value: string }) => {
    restrictionFilter?.values && setResult(searchRestrictions(restrictionFilter.values, value));
  };

  return (
    <div className="grey">
      {restrictionFilter?.values?.length ? (
        <>
          <div className="searchRestrictionsHeader" data-testid="restriction-search-wrapper">
            <div className="search-container">
              <Input
                icon="search"
                color="grey"
                iconPosition="left"
                placeholder={getTranslation('RestrictionFilterValue_Search')}
                onChange={handleSearchChange}
                size="mini"
                data-testid="restriction-search-field"
              />
              {isShowAllItems && (
                <div
                  onClick={() => setIsResultOpen(!isResultOpen)}
                  className="pointer-text"
                  data-testid="restriction-toggleShow-allItems"
                >
                  <Icon
                    data-testid="restriction-toggleShowIcon-allItems"
                    name={`angle ${isResultOpen ? 'down' : 'right'}`}
                  />
                  {getTranslation('RestrictionFilterValue_Selected')} ({result.length})
                </div>
              )}
            </div>
            <div className="values">
              {restrictions.map((value, i) => (
                <Label content={value} key={`${value}${i}`} data-testid="restriction-item" color="grey" />
              ))}
            </div>
          </div>
          <TableLabel
            text={getTranslation(
              restrictionFilter?.isInclusive
                ? 'DataAuthorizationRoles_Inclusive'
                : 'DataAuthorizationRoles_NotInclusive'
            )}
            active={restrictionFilter?.isInclusive}
            dataTestId="restriction-isInclusive"
          />
        </>
      ) : (
        <b data-testid="restriction-noValues-wrapper">
          {getTranslation(
            restrictionFilter?.isInclusive ? 'DataAuthTableCell_None' : 'DataAuthTableCell_All'
          )}
        </b>
      )}
    </div>
  );
};

export default RestrictionFilterValue;

import { gql } from '@apollo/client';

export const GET_ALL = gql`
  query ($limit: Int, $offset: Int, $sortBy: String, $sortOrder: String) {
    paginatedFeatureAccessGroups(
      input: { limit: $limit, offset: $offset, sortBy: $sortBy, sortOrder: $sortOrder }
    ) {
      total
      data {
        _id
        groupName
        userManagement {
          accessType
        }
        ticketsManagement {
          accessType
        }
        reportManagement {
          accessType
          reports
        }
        scheduledReportsManagement {
          accessType
        }
        featureAccessManagement {
          accessType
        }
        dataAuthorizationManagement {
          accessType
        }
        createdBy
        createdDate
        updatedBy
        updatedDate
      }
    }
  }
`;

export const GET_BY_ID = gql`
  query ($id: String) {
    featureAccessGroup(query: { _id: $id }) {
      _id
      groupName
      userManagement {
        accessType
      }
      ticketsManagement {
        accessType
      }
      reportManagement {
        accessType
        reports
      }
      scheduledReportsManagement {
        accessType
      }
      featureAccessManagement {
        accessType
      }
      dataAuthorizationManagement {
        accessType
      }
      createdBy
      createdDate
      updatedBy
      updatedDate
    }
  }
`;

export const GET_FEATURE_ACCESS_GROUP = gql`
  query ($limit: Int, $offset: Int, $sortBy: String, $sortOrder: String) {
    paginatedFeatureAccessGroups(
      input: { limit: $limit, offset: $offset, sortBy: $sortBy, sortOrder: $sortOrder }
    ) {
      data {
        _id
        groupName
      }
    }
  }
`;

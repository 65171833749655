import { EmbedTokenResponseDto } from '../../api/rest/models/EmbedTokenResponseAggregate/EmbedTokenResponseDto';
import { DEFAULT_REPORT_NAME_FOR_VIEW } from '../../features/Dashboard/DashboardContent/constants';
import { parseReportName } from '../../features/Dashboard/DashboardContent/helper';

export const getReportName = (displayName: string): string => {
  const date = new Date().toISOString().replace(/:/g, '-');

  return `BCD_PeT_${displayName.replace(/\s/g, '')}Report_${date.substring(0, date.length - 1)}UTC.pdf`;
};

export const getDefaultReportForView = (reportsConfig: EmbedTokenResponseDto | null): string => {
  const userHasDefaultReport = Boolean(
    reportsConfig?.reports?.find((item) => item.reportName.includes(DEFAULT_REPORT_NAME_FOR_VIEW))
  );
  const firstUserReportName =
    (reportsConfig?.reports[0] && parseReportName(reportsConfig?.reports[0].reportName)) ||
    DEFAULT_REPORT_NAME_FOR_VIEW;

  return (reportsConfig && userHasDefaultReport && DEFAULT_REPORT_NAME_FOR_VIEW) || firstUserReportName;
};

import React, { useContext, useEffect, useCallback } from 'react';
import { Button, Icon, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import ExportJobsTable from './ExportJobsTable/ExportJobsTable';
import FullSizeLoader from '../../shared/view/Loader/FullSizeLoader';
import NotificationExportJobs from './Notifications/NotificationExportJobs';
import { API_REQUEST_STATUS } from '../../utils/APIHandlers';
import { getTranslation } from '../../utils/getTranslation';
import TextButton from '../../shared/controls/TextButton';
import MessageWithControl from '../../shared/controls/MessageWithControl';
import { exportJobsContext } from '../../shared/store/exportJobsContext';
import { pathCreateExportJob } from '../../routes/constants';
import { enableExportJobContext } from '../../shared/store/enableExportJobContext';
import { userContext } from '../../shared/store/userContext';

import './styles.scss';

const ExportJobsTableWrapper: React.FC = () => {
  const { isScheduledReportsManagementHasReadWriteAccess } = useContext(userContext);
  const { requestExportJobsStatus, exportJobsData, getExportJobsList } = useContext(exportJobsContext);
  const { requestEnableExportJobStatus } = useContext(enableExportJobContext);

  const handleGetExportJobsList = useCallback((): void => {
    void getExportJobsList();
  }, [getExportJobsList]);

  useEffect(() => {
    handleGetExportJobsList();
  }, []);

  useEffect(() => {
    requestEnableExportJobStatus === API_REQUEST_STATUS.REQUEST_SUCCESS && handleGetExportJobsList();
  }, [requestEnableExportJobStatus]);

  return (
    <div className="container-exportJobsWrapper" data-testid="exportJobs-grid-wrapper">
      {isScheduledReportsManagementHasReadWriteAccess && (
        <div className="container-topExportJobsBar">
          <div className="container-exportJobsNotifications">
            <NotificationExportJobs />
          </div>
          <div className="container-createExportJobsControl">
            <Button
              size="small"
              color="blue"
              className="createExportJobsButton"
              as={Link}
              to={pathCreateExportJob}
            >
              <Icon name="plus circle" /> {getTranslation('Create_ExportJob_Control')}
            </Button>
          </div>
        </div>
      )}

      {requestExportJobsStatus === API_REQUEST_STATUS.REQUEST_FAILURE && (
        <Message error data-testid="errorResponseExportJobs">
          <MessageWithControl message={getTranslation('Error')}>
            <TextButton text={getTranslation('Retry_Now')} onClick={handleGetExportJobsList} />
          </MessageWithControl>
        </Message>
      )}

      <div className="container-exportJobsTable">
        {requestExportJobsStatus === API_REQUEST_STATUS.REQUESTED && <FullSizeLoader />}
        {exportJobsData.length > 0 && <ExportJobsTable />}
      </div>

      {exportJobsData.length === 0 && requestExportJobsStatus === API_REQUEST_STATUS.REQUEST_SUCCESS && (
        <div className="container-empltyMessageExportJobs" data-testid="empryResponseSceduledReports">
          <Message warning>{getTranslation('Empty')}</Message>
        </div>
      )}
    </div>
  );
};

export default ExportJobsTableWrapper;

import { PetUserFormViewModel } from '../../../models/PetUsersAggregate/PetUser';
import { ASCENDING_VALUE } from '../../../utils/constants';
import { SortOrder } from '../../../utils/viewHelper';
import { PetUserDTO } from './../../../api/graphql/models/Users';

export const emptyUser: PetUserFormViewModel = {
  email: '',
  firstName: '',
  isEnabled: false,
  isFederated: false,
  lastName: '',
  userName: '',
  featureAccessGroupId: '',
  dataAuthRoleId: '',
};

export const DEFAULT_PET_USERS_SORT_ORDER: SortOrder = ASCENDING_VALUE;
export const DEFAULT_PET_USERS_SORT_BY: keyof PetUserDTO = 'firstName';

import * as H from 'history';

export const ERROR_CODE_NAME = 'error';
export const ERROR_DESCRIPTION_NAME = 'error_description';
export const ACCESS_TOKEN_URL_NAME = 'access_token';

const getClearHash = (hash: string): string => {
  return hash && hash.substring(1);
};

export const isHashHasErrorsFields = (hash: H.History.Hash): boolean => {
  const urlHash = new URLSearchParams(getClearHash(hash));

  return urlHash.has(ERROR_CODE_NAME) || urlHash.has(ERROR_DESCRIPTION_NAME);
};

export const isHashHasSuccessField = (hash: H.History.Hash): boolean => {
  const urlHash = new URLSearchParams(getClearHash(hash));

  return urlHash.has(ACCESS_TOKEN_URL_NAME);
};

export const getHashItem = (hash: H.History.Hash, field: string): string | null => {
  const hashUSP = new URLSearchParams(getClearHash(hash));

  return hashUSP.get(field);
};

export const removeSessionStorageField = (field: string): void => {
  sessionStorage.getItem(field) && sessionStorage.removeItem(field);
};

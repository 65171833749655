import React from 'react';
import { Divider, Grid, Header } from 'semantic-ui-react';

import DateInfo from './../../../PetForm/DateInfo';
import { DataAuthorizationRole } from './../../../../models/DataAuthorizationRolesAggregate/DataAuthorizationRole';
import RestrictionFilterValue from './../../../PetForm/RestrictionFilterValue';
import { getTranslation } from './../../../../utils/getTranslation';
import LabelWrapper from './../../../../shared/view/LabelWrapper/LabelWrapper';
import CloseControl from './../../../../shared/controls/CloseControl';

import './../../../PetForm/commonAuditInfoStyles.scss';
import './styles.scss';

interface Props {
  currentDataAuthorizationRole: DataAuthorizationRole;
  closeAuditInfo: () => void;
}

const AuditInfo: React.FC<Props> = (props) => {
  const { roleName, createdDate, createdBy, updatedDate, smids, updatedBy, lcns, gcns, pccs } =
    props.currentDataAuthorizationRole;

  return (
    <div className="auditInfo">
      <CloseControl onClick={props.closeAuditInfo} />

      <Grid>
        <Grid.Row>
          <Grid.Column>
            <LabelWrapper label={getTranslation('AuditInfo_DataAuthorizationGroup')}>
              <Header as="h1">{roleName}</Header>
            </LabelWrapper>
          </Grid.Column>
        </Grid.Row>

        <Divider />

        <Grid.Row>
          <Grid.Column>
            <LabelWrapper label={getTranslation('GlobalCustomerNumber')}>
              <RestrictionFilterValue restrictionFilter={gcns} key="gcns" />
            </LabelWrapper>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <LabelWrapper label={getTranslation('SecurityManagerID')}>
              <RestrictionFilterValue restrictionFilter={smids} key="smids" />
            </LabelWrapper>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <LabelWrapper label={getTranslation('LocalCustomerNumber')}>
              <RestrictionFilterValue restrictionFilter={lcns} key="lcns" />
            </LabelWrapper>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <LabelWrapper label={getTranslation('PCC')}>
              <RestrictionFilterValue restrictionFilter={pccs} key="pccs" />
            </LabelWrapper>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <DateInfo
              createdDate={createdDate}
              createdBy={createdBy}
              updatedDate={updatedDate}
              updatedBy={updatedBy}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default AuditInfo;

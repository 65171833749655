import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import ConfirmEditExportJobModal from './ConfirmEditExportJobModal';
import { createExportJobContext } from './../../../../shared/store/createExportJobContext';
import WarningModal from './../../../../shared/view/Modal/WarningModal';
import { getTranslation } from './../../../../utils/getTranslation';

const EditExportJobModals: React.FC = () => {
  const history = useHistory();
  const { isOpenLeaveCondition, handleCloseLeaveCreateJob, leaveDirection } =
    useContext(createExportJobContext);

  const handleLeave = () => {
    leaveDirection && history.push(leaveDirection);
  };

  return (
    <>
      <ConfirmEditExportJobModal />

      <WarningModal
        isOpen={isOpenLeaveCondition}
        onClose={handleCloseLeaveCreateJob}
        onConfirm={handleLeave}
        titleText={getTranslation('Cancel')}
        contentText={getTranslation('CancelWarningContent')}
        textActionButton={getTranslation('Yes')}
        isSaveBtn={false}
        dataTestId="confirmLeaveCreateEditExportJob-modal"
      />
    </>
  );
};

export default EditExportJobModals;

import React from 'react';
import { Card, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { DashboardReportViewModel } from '../../../models/ReportsAggregate/Report';
import { pathReport } from '../../../routes/constants';
import { ReportIconValue, ReportTypeValue } from '../../../api/graphql/models/ReportsResponse';
import { getTranslation } from '../../../utils/getTranslation';
import { parseReportName } from '../DashboardContent/helper';

import './styles.scss';

interface Props {
  report: DashboardReportViewModel;
  dataTestId?: string;
}

const ReportCard: React.FC<Props> = ({ report, dataTestId }) => {
  const { reportName, icon, reportType, displayName, description } = report;

  return (
    <Card
      as={Link}
      to={pathReport(parseReportName(reportName))}
      className="reportCard"
      data-testid={dataTestId}
      alt={reportName}
    >
      <Card.Content>
        <Icon name={ReportIconValue[icon]} size="huge" />
      </Card.Content>
      <Card.Content>
        <Card.Meta className="reportCard-category">{getTranslation(ReportTypeValue[reportType])}</Card.Meta>
        <Card.Header>{displayName}</Card.Header>
        <Card.Description className="reportCard-description">{description}</Card.Description>
      </Card.Content>
    </Card>
  );
};

export default ReportCard;

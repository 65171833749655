import React, { useContext } from 'react';
import { Dropdown, DropdownItem } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { getTranslation } from '../../../utils/getTranslation';
import { enableExportJobContext } from '../../../shared/store/enableExportJobContext';
import { triggerExportJobContext } from '../../../shared/store/triggerExportJobContext';
import { userContext } from '../../../shared/store/userContext';
import { ExportJobDTO } from '../../../api/graphql/models/ExportJob';
import { pathExportJob, pathHistoryExportJob } from '../../../routes/constants';
import { wrapExportJobDTOWithUpdateDateFromExportJobDTO } from '../exportJobsMapper';

interface ExportJobsDropdownProps {
  exportJob: ExportJobDTO;
}

const ExportJobsDropdown: React.FC<ExportJobsDropdownProps> = (props) => {
  const { exportJob } = props;
  const { handleOpenEnable } = useContext(enableExportJobContext);
  const { handleOpenTriggerExportJob } = useContext(triggerExportJobContext);
  const { email, isScheduledReportsManagementHasReadAccess, isScheduledReportsManagementHasReadWriteAccess } =
    useContext(userContext);

  return (
    <div className="container-controlsDropdownExportJobs">
      <Dropdown icon="ellipsis vertical" direction="left" data-testid="rowControlsExportJobs">
        <Dropdown.Menu>
          {isScheduledReportsManagementHasReadAccess && (
            <DropdownItem
              as={Link}
              to={pathExportJob(exportJob._id)}
              data-testid="openViewExportJob"
              text={getTranslation('ViewDetails')}
            />
          )}
          {isScheduledReportsManagementHasReadWriteAccess && (
            <DropdownItem
              as={Link}
              to={pathExportJob(exportJob._id)}
              data-testid="openEditExportJob"
              text={getTranslation('Edit')}
            />
          )}

          <DropdownItem
            as={Link}
            to={pathHistoryExportJob(exportJob._id)}
            data-testid="openJobHistoryExportJob"
            text={getTranslation('ExportJobs_JobHistory_Title')}
          />

          {isScheduledReportsManagementHasReadWriteAccess && (
            <>
              {exportJob.enabled && (
                <DropdownItem
                  data-testid="openDisableExportJob"
                  text={getTranslation('Disable')}
                  onClick={() =>
                    handleOpenEnable(wrapExportJobDTOWithUpdateDateFromExportJobDTO(exportJob, email), false)
                  }
                />
              )}
              {!exportJob.enabled && (
                <DropdownItem
                  data-testid="openEnableExportJob"
                  text={getTranslation('Enable')}
                  onClick={() =>
                    handleOpenEnable(wrapExportJobDTOWithUpdateDateFromExportJobDTO(exportJob, email), true)
                  }
                />
              )}

              <DropdownItem
                data-testid="openTriggerExportJob"
                text={getTranslation('ExportJobs_Trigger_Control')}
                onClick={() => handleOpenTriggerExportJob(exportJob)}
              />
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default ExportJobsDropdown;

import { DropdownValueType } from '../../../PetForm/types';
import { DropdownViewModel } from '../../../../models/DropdownViewModel';
import {
  buildArrayBySeparator,
  getValidArrayByReq,
  removeClones,
  getLowerCaseArray,
} from '../../../../utils/arrayHelper';

let timeOut: NodeJS.Timeout;

export const getTextFromValue = ({ value, options }: DropdownValueType): string => {
  return (options?.find((item) => item.value === value)?.text as string) || '';
};

export const runToggleByTimeout = (onCallback: () => void, offCallback: () => void, time: number): void => {
  onCallback();
  clearInterval(timeOut);
  timeOut = setTimeout(() => {
    offCallback();
  }, time);
};

export interface IsArrayCroppedProps {
  correctArray: string[];
  validatedArray: string[];
  withoutClonesArray: string[];
}

export const isArrayCropped = ({
  correctArray,
  validatedArray,
  withoutClonesArray,
}: IsArrayCroppedProps): boolean =>
  withoutClonesArray.length !== validatedArray.length || correctArray.length !== validatedArray.length;

export const buildNextMultiDropdownValue = (
  separator: string,
  validateExpression: RegExp,
  insert: string[]
): { result: DropdownViewModel[]; isInvalid: boolean } => {
  const result: DropdownViewModel[] = [];
  let isInvalid = false;

  if (insert.length > 0) {
    const correctArray: string[] = buildArrayBySeparator(insert, separator);
    const validatedArray: string[] = getValidArrayByReq(correctArray, validateExpression);
    const withoutClonesArray: string[] = removeClones(getLowerCaseArray(validatedArray));

    isInvalid = isArrayCropped({ correctArray, validatedArray, withoutClonesArray });

    withoutClonesArray.forEach((item, index) => {
      result.push({
        key: String(index),
        value: item,
        text: item,
      } as DropdownViewModel);
    });
  }

  return {
    result,
    isInvalid,
  };
};

import React, { useContext } from 'react';
import { NavLink, Switch } from 'react-router-dom';
import { Header, Menu } from 'semantic-ui-react';

import DataAuthorizationRoles from '../../features/AdministrationContainer/DataAuthorizationRoles';
import { getTranslation } from '../../utils/getTranslation';
import {
  featureGroupKey,
  pathAdministrationDataAuthRoles,
  pathAdministrationFeatureGroups,
  pathAdministrationFeatureGroupsCreate,
  pathAdministrationFeatureGroupsEdit,
  pathAdministrationFeatureGroupsView,
  pathAdministrationReports,
  pathAdministrationUsers,
  pathAdministrationUsersCreate,
  pathAdministrationUsersEdit,
  pathAdministrationUsersView,
  petUserKey,
} from '../../routes/constants';
import PrivateRoute from '../../features/PrivateRoute/PrivateRoute';
import { userContext } from '../../shared/store/userContext';
import PetUsersWrapper from '../../features/AdministrationContainer/PetUsers/PetUsersWrapper';
import ReportsWrapper from '../../features/AdministrationContainer/Reports/ReportsWrapper';
import FeatureAccessGroupsWrapper from '../../features/AdministrationContainer/FeatureAccessGroups/FeatureAccessGroupsWrapper';
import {
  hasDataAuthorizationManagementAccess,
  hasFeatureAccessManagementAccess,
  hasUserManagementAccess,
} from './helper';

import './styles.scss';

const Administration: React.FC = () => {
  const userContextValue = useContext(userContext);
  const isUserManagementAccess = hasUserManagementAccess(userContextValue);
  const isDataAuthorizationManagementAccess = hasDataAuthorizationManagementAccess(userContextValue);
  const isFeatureAccessManagementAccess = hasFeatureAccessManagementAccess(userContextValue);

  return (
    <div className="administration container" data-testid="administration-page">
      <Header as="h1">{getTranslation('Administration_Administration')}</Header>

      <Menu pointing secondary>
        {isUserManagementAccess && (
          <Menu.Item
            as={NavLink}
            to={pathAdministrationUsers}
            name={getTranslation('Administration_Users')}
            data-testid="administration-user-link"
          />
        )}
        {isDataAuthorizationManagementAccess && (
          <Menu.Item
            as={NavLink}
            to={pathAdministrationDataAuthRoles}
            name={getTranslation('Administration_DataAuthorizationRoles')}
            data-testid="administration-dataAuthorization-link"
          />
        )}
        {isFeatureAccessManagementAccess && (
          <Menu.Item
            as={NavLink}
            to={pathAdministrationFeatureGroups}
            name={getTranslation('Administration_FeatureGroups')}
            data-testid="administration-featureAccess-link"
          />
        )}
        {isUserManagementAccess && (
          <Menu.Item
            as={NavLink}
            to={pathAdministrationReports}
            name={getTranslation('Reports')}
            data-testid="administration-reports-link"
          />
        )}
      </Menu>

      <div className="administrationTabContent">
        <Switch>
          <PrivateRoute
            path={[
              pathAdministrationUsers,
              pathAdministrationUsersCreate,
              pathAdministrationUsersView(`:${petUserKey}`),
              pathAdministrationUsersEdit(`:${petUserKey}`),
            ]}
            exact
            component={PetUsersWrapper}
            isAllow={isUserManagementAccess}
          />

          <PrivateRoute
            path={pathAdministrationDataAuthRoles}
            exact
            component={DataAuthorizationRoles}
            isAllow={isDataAuthorizationManagementAccess}
          />

          <PrivateRoute
            path={[
              pathAdministrationFeatureGroups,
              pathAdministrationFeatureGroupsCreate,
              pathAdministrationFeatureGroupsView(`:${featureGroupKey}`),
              pathAdministrationFeatureGroupsEdit(`:${featureGroupKey}`),
            ]}
            exact
            component={FeatureAccessGroupsWrapper}
            isAllow={isFeatureAccessManagementAccess}
          />

          <PrivateRoute
            path={pathAdministrationReports}
            exact
            component={ReportsWrapper}
            isAllow={isUserManagementAccess}
          />
        </Switch>
      </div>
    </div>
  );
};

export default Administration;

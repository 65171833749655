import React, { SyntheticEvent, ReactElement, useContext, useEffect, useCallback } from 'react';
import { UseFormGetValues } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { FieldErrors, FieldError } from 'react-hook-form/dist/types/errors';

import SelectWithInfo from '../../../PetForm/SelectWithInfo';
import { DropdownValueType } from '../../../PetForm/types';
import { DropdownViewModel } from '../../../../models/DropdownViewModel';
import { sftpProvidersContext } from '../../../../shared/store/sftpProvidersContext';
import { API_REQUEST_STATUS } from '../../../../utils/APIHandlers';
import { Message } from 'semantic-ui-react';
import MessageWithControl from '../../../../shared/controls/MessageWithControl';
import TextButton from '../../../../shared/controls/TextButton';
import { getTranslation } from '../../../../utils/getTranslation';
import { SftpProviderDTO, SftpProvidersListDTO } from '../../../../api/rest/models/SftpProviders';
import { ExportJobFormViewModel } from '../../../../models/ExportJobsAggregate/ExportJobForm';

interface SftpProviderFieldProps {
  onChange: (value: string) => void;
  getValues: UseFormGetValues<ExportJobFormViewModel>;
  errors: FieldErrors<FieldValues>;
  dataTestId?: string;
  required?: boolean;
}

export const getSftpProvidersOptions = (data: SftpProvidersListDTO): DropdownViewModel[] => {
  return data.map(
    (item: SftpProviderDTO, index: number): DropdownViewModel => ({
      key: String(index),
      value: item.provider,
      text: item.provider,
    })
  );
};

const SftpProviderField: React.FC<SftpProviderFieldProps> = (props): ReactElement => {
  const { onChange, getValues, errors, dataTestId, required } = props;
  const { getSftpProviders, sftpProvidersData, requestSftpProvidersStatus } =
    useContext(sftpProvidersContext);

  const handleSftpProviders = useCallback(() => {
    void getSftpProviders();
  }, [getSftpProviders]);

  useEffect(() => {
    if (requestSftpProvidersStatus === API_REQUEST_STATUS.NOT_REQUESTED) {
      handleSftpProviders();
    }
  }, []);

  return (
    <>
      <SelectWithInfo
        defaultValue={String(getValues('provider'))}
        options={getSftpProvidersOptions(sftpProvidersData)}
        label={getTranslation('Edit_ExportJob_Provider')}
        error={errors?.provider as FieldError}
        onChange={(_e: SyntheticEvent, { value }: DropdownValueType) => {
          onChange(value as string);
        }}
        isLoaded={requestSftpProvidersStatus === API_REQUEST_STATUS.REQUESTED}
        dataTestId={dataTestId}
        required={required}
      />

      {requestSftpProvidersStatus === API_REQUEST_STATUS.REQUEST_FAILURE && (
        <Message error data-testid="message-sftpProviders-error">
          <MessageWithControl message={getTranslation('Error')}>
            <TextButton
              dataTestId="retry-sftpProviders-btn"
              onClick={handleSftpProviders}
              text={getTranslation('Retry')}
            />
          </MessageWithControl>
        </Message>
      )}
    </>
  );
};

export default SftpProviderField;

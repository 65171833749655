import React, { useContext, useEffect, useState } from 'react';
import { NormalizedCacheObject, ApolloClient } from '@apollo/client';

import { WithApolloClient } from '../../../features/WithApolloClient/WithApolloClient';
import {
  defaultPetUsersContextValue,
  PetUsersContextProps,
  petUsersContext,
  PetUsersSortingParams,
} from '../../store/PetUser/petUsersContext';
import { API_REQUEST_STATUS, RequestStatus } from '../../../utils/APIHandlers';
import { userContext } from '../../store/userContext';
import {
  countItemsPerPage,
  DEFAULT_PAGE,
  PET_USERS_LIMIT,
  PET_USERS_OFFSET,
} from '../../../pages/Administration/constant';
import { PetUserViewModel } from '../../../models/PetUsersAggregate/PetUser';
import { PetUsersService } from '../../../services/PetUsersService';
import { mapPetUsers } from '../../../features/AdministrationContainer/PetUsers/petUsersMapper';
import { PetUserDTO } from '../../../api/graphql/models/Users';
import {
  comparePetUserFields,
  getFilteredPetUsers,
} from '../../../features/AdministrationContainer/PetUsers/helper';
import { getItemsRange } from '../../../utils/viewHelper';

interface Props {
  children: React.ReactChild;
  client?: ApolloClient<NormalizedCacheObject>;
}

const PetUsers: React.FC<Props> = ({ children, client }) => {
  const { apiBaseUrl } = useContext(userContext);
  const [searchString, setSearchString] = useState<string>(defaultPetUsersContextValue.filterSearchString);
  const [sortParams, setSortParams] = useState<PetUsersSortingParams>(defaultPetUsersContextValue.sortParams);
  const [petUsersDTO, setPetUsersDTO] = useState<PetUserDTO[]>([]);
  const [petUsers, setPetUsers] = useState<PetUserViewModel[]>(defaultPetUsersContextValue.petUsers);
  const [totalPetUsers, setTotalPetUsers] = useState<number>(defaultPetUsersContextValue.totalPetUsers);
  const [activePagePetUsers, setActivePagePetUsers] = useState<number>(
    defaultPetUsersContextValue.activePagePetUsers
  );
  const [requestPetUsersStatus, setRequestPetUsersStatus] = useState<RequestStatus>(
    defaultPetUsersContextValue.requestPetUsersStatus
  );

  const getPetUsers = async () => {
    try {
      const clientApollo = client as ApolloClient<NormalizedCacheObject>;
      const userService: PetUsersService = new PetUsersService(clientApollo, apiBaseUrl);
      setRequestPetUsersStatus(API_REQUEST_STATUS.REQUESTED);

      const { data } = await userService.getAll(
        PET_USERS_LIMIT,
        PET_USERS_OFFSET,
        sortParams.sortBy,
        sortParams.sortOrder
      );

      setRequestPetUsersStatus(API_REQUEST_STATUS.REQUEST_SUCCESS);
      setPetUsersDTO(data);
      setTotalPetUsers(data.length);
      handleSetPetUsers(DEFAULT_PAGE, data, sortParams);
    } catch (error) {
      console.error(error);
      setRequestPetUsersStatus(API_REQUEST_STATUS.REQUEST_FAILURE);
    }
  };

  const handleSetPetUsers = (page: number, data: PetUserDTO[], sortParams: PetUsersSortingParams) => {
    const nextPetUsers = getFilteredPetUsers(data, searchString);
    nextPetUsers.sort((leftValue, rightValue) => comparePetUserFields(leftValue, rightValue, sortParams));
    const { start, end } = getItemsRange(page, countItemsPerPage);

    setActivePagePetUsers(page);
    setPetUsers(mapPetUsers(nextPetUsers.slice(start, end)));
    setTotalPetUsers(nextPetUsers.length);
  };

  const setPetUsersActivePage = (page: number) => {
    handleSetPetUsers(page, petUsersDTO, sortParams);
  };

  useEffect(() => {
    handleSetPetUsers(DEFAULT_PAGE, petUsersDTO, sortParams);
  }, [searchString]);

  useEffect(() => {
    handleSetPetUsers(activePagePetUsers, petUsersDTO, sortParams);
  }, [sortParams]);

  const getPetUsersContext = (): PetUsersContextProps => {
    return {
      ...defaultPetUsersContextValue,
      getPetUsers,
      petUsers,
      totalPetUsers,
      activePagePetUsers,
      requestPetUsersStatus,
      setPetUsersActivePage,
      filterPetUsers: setSearchString,
      filterSearchString: searchString,
      sortPetUsers: setSortParams,
      sortParams,
    };
  };

  return <petUsersContext.Provider value={getPetUsersContext()}>{children}</petUsersContext.Provider>;
};

const WithPetUsers = WithApolloClient(PetUsers);

export { WithPetUsers };

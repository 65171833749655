import React from 'react';
import { Grid } from 'semantic-ui-react';

import { getTranslation } from '../../../../utils/getTranslation';
import { getFormattedDate, getFormattedTime } from '../../../../utils/formatDate';
import LabelWrapper from '../../../../shared/view/LabelWrapper/LabelWrapper';
import plane from '../../../../assets/icon/plane.svg';
import { CouponViewModel } from '../../../../models/TripAggregate/TripTicket';
import CustomLabel from '../../../../shared/view/CustomLabel/CustomLabel';

interface Props {
  coupon: CouponViewModel;
  isCardOpen: boolean;
}

const AirSegmentInfo: React.FC<Props> = ({
  coupon: {
    departureDateTime,
    classOfServiceDescription,
    classOfService,
    fareBasisCode,
    vendorLocator,
    couponStatus,
    ticketDesignator,
    arrivalAirportCountryCode,
    marketingFlightNumber,
    arrivalDateTime,
    arrivalAirportCode,
    departureAirportCode,
    departureAirportCountryCode,
  },
  isCardOpen,
}) => {
  return (
    <div className="airSegment-wrapper" data-testid="airSegment-item">
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={10} tablet={10} computer={8} largeScreen={8} widescreen={8}>
            <div className="airSegment-departure-wrapper">
              <div className="airSegment-departure">
                {isCardOpen ? (
                  <>
                    <span className="sub-header" data-testid="sub-header-departure">
                      {getTranslation('TripAuditInfo_Departure')}
                    </span>
                    <span className="airSegment-time" data-testid="airSegment-time-departure">
                      {(departureDateTime && getFormattedTime(departureDateTime)) || ''}
                    </span>
                    <span className="city-info" data-testid="city-info-departure">
                      {`${departureAirportCode} ${departureAirportCountryCode}`}
                    </span>
                    <span className="airSegment-date" data-testid="airSegment-date-departure">
                      {(departureDateTime && getFormattedDate(departureDateTime)) || ''}
                    </span>
                  </>
                ) : (
                  <span className="city-info" data-testid="city-info-only-departure">
                    {`${departureAirportCode} ${departureAirportCountryCode}`}
                  </span>
                )}
              </div>
              <div className="plane-wrapper">
                <img className="plane" src={plane} />
              </div>
            </div>
          </Grid.Column>
          <Grid.Column mobile={6} tablet={6} computer={8} largeScreen={8} widescreen={8}>
            <div className="airSegment-arrival">
              {isCardOpen ? (
                <>
                  <span className="sub-header" data-testid="sub-header-arrival">
                    {getTranslation('TripAuditInfo_Arrival')}
                  </span>
                  <span className="airSegment-time" data-testid="airSegment-time-arrival">
                    {(arrivalDateTime && getFormattedTime(arrivalDateTime)) || ''}
                  </span>
                  <span className="city-info" data-testid="city-info-arrival">
                    {`${arrivalAirportCode} ${arrivalAirportCountryCode}`}
                  </span>
                  <span className="airSegment-date" data-testid="airSegment-date-arrival">
                    {(arrivalDateTime && getFormattedDate(arrivalDateTime)) || ''}
                  </span>
                </>
              ) : (
                <span className="city-info" data-testid="city-info-only-arrival">
                  {`${arrivalAirportCode} ${arrivalAirportCountryCode}`}
                </span>
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {isCardOpen && (
        <Grid className="air-columns" data-testid="air-segment-details">
          <Grid.Row>
            <Grid.Column mobile={8} tablet={5} computer={4} largeScreen={4} widescreen={4}>
              <LabelWrapper label={getTranslation('TripAuditInfo_FlightNumber')}>
                {marketingFlightNumber}
              </LabelWrapper>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={5} computer={4} largeScreen={4} widescreen={4}>
              <LabelWrapper label={getTranslation('TripAuditInfo_ConfirmationNumber')}>
                {vendorLocator}
              </LabelWrapper>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={5} computer={4} largeScreen={4} widescreen={4}>
              <LabelWrapper label={getTranslation('TripAuditInfo_TicketDesignator')}>
                {ticketDesignator}
              </LabelWrapper>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={5} computer={4} largeScreen={4} widescreen={4}>
              <LabelWrapper label={getTranslation('TripAuditInfo_CouponStatus')}>
                <CustomLabel color="grey" text={couponStatus} dataTestId={'couponStatus-badge'} />
              </LabelWrapper>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={5} computer={4} largeScreen={4} widescreen={4}>
              <LabelWrapper label={getTranslation('TripAuditInfo_FareBasisCode')}>
                {fareBasisCode}
              </LabelWrapper>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={5} computer={4} largeScreen={4} widescreen={4}>
              <LabelWrapper label={getTranslation('TripAuditInfo_ClassOfService')}>
                <>
                  {classOfService && (
                    <div className="round-bg" data-testid="classOfService-badge">
                      {classOfService}
                    </div>
                  )}
                  {classOfServiceDescription}
                </>
              </LabelWrapper>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </div>
  );
};

export default AirSegmentInfo;

import React from 'react';

import './styles.scss';

interface ContentWithLabelProps {
  children: React.ReactChild;
  required?: boolean;
}

export const REQUIRED_CLASS_NAME = 'required';

const FormFieldLabel: React.FC<ContentWithLabelProps> = ({ children, required }) => (
  <label className={`formFieldLabel ${required ? REQUIRED_CLASS_NAME : ''}`} data-testid="formFieldLabel">
    {children}
  </label>
);

export default FormFieldLabel;

import React from 'react';
import logo from '../../../assets/logo.svg';
import logoWhite from '../../../assets/logo-white.svg';
import { getTranslation } from '../../../utils/getTranslation';

import './styles.scss';

interface Props {
  white?: boolean;
}

const Logo: React.FC<Props> = ({ white }) => {
  return (
    <div className={`logoWrapper ${white ? 'whiteLogo' : ''}`} data-testid="logo-wrapper">
      <div className="container-logo">
        <img className="logo" src={white ? logoWhite : logo} alt="logo" data-testid="logo-image" />
        <span className="slogan" data-testid="logo-slogan">
          {getTranslation('Logo_Slogan')}
        </span>
      </div>
      <div className="container-projectName">
        <span className="projectName" data-testid="logo-projectName">
          {getTranslation('Logo_ProjectName')}
        </span>
      </div>
      <a href="https://www.bcdtravel.com/">{`${getTranslation('CompanyName')} ${getTranslation(
        'Logo_ProjectName'
      )}`}</a>
    </div>
  );
};

export default Logo;

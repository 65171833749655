import { ExportJobStatus } from '../../../models/ExportJobsAggregate/ExportJobHistory';

export const MAX_MESSAGE_LENGTH = 160;
export const DEFAULT_CRON_VALUE = '0 10 * * 1';

export const colorExportJobHistoryStatus = {
  [ExportJobStatus.Undefined]: 'black',
  [ExportJobStatus.NotStarted]: 'grey',
  [ExportJobStatus.Running]: 'blue',
  [ExportJobStatus.Succeeded]: 'green',
  [ExportJobStatus.Failed]: 'red',
};

import React, { useContext, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Header, Message } from 'semantic-ui-react';

import { pathAdministrationUsers, petUserKey } from '../../../../routes/constants';
import CloseControl from '../../../../shared/controls/CloseControl';
import TextButton from '../../../../shared/controls/TextButton';
import { petUserContext } from '../../../../shared/store/PetUser/petUserContext';
import EmptyMessage from '../../../../shared/view/EmptyMessage/EmptyMessage';
import { getRequestStatuses } from '../../../../utils/APIHandlers';
import { getTranslation } from '../../../../utils/getTranslation';
import PetUserLoader from '../PetUserLoader/PetUserLoader';
import PetUserDetails from './PetUserDetails';
import { userContext } from '../../../../shared/store/userContext';
import NoAccessMessageWrapper from '../PetUserMessages/NoAccessMessageWrapper';
import { hasUnavailableFields } from '../helper';

const PetUserDetailsWrapper: React.FC = () => {
  const userContextValue = useContext(userContext);
  const isUnavailableFields = hasUnavailableFields(userContextValue);
  const { getPetUser, petUser, requestPetUserStatus } = useContext(petUserContext);
  const requestPetUser = getRequestStatuses(requestPetUserStatus);
  const params = useParams<{ [petUserKey]?: string }>();
  const petUserId = params[petUserKey];

  useEffect(() => {
    petUserId && void getPetUser(petUserId);
  }, [petUserId]);

  return (
    <>
      {requestPetUser.REQUESTED && (
        <div className="auditInfo" data-testid="viewPetUserData-loader">
          <PetUserLoader
            goBackLink={pathAdministrationUsers}
            headerTitle={getTranslation('PetUsers_Details')}
          />
        </div>
      )}

      {requestPetUser.REQUEST_FAILURE && (
        <div className="auditInfo" data-testid="errorLoadViewPetUserData-wrapper">
          <Link to={pathAdministrationUsers}>
            <CloseControl />
          </Link>
          <Header as="h1" data-testid="errorLoadViewPetUserData-title">
            {getTranslation('PetUsers_Details')}
          </Header>
          <Message error data-testid="errorLoadViewPetUserData-message">
            <p>{getTranslation('Error')}</p>
            <TextButton
              text={getTranslation('Retry_Now')}
              onClick={() => petUserId && void getPetUser(petUserId)}
              dataTestId="errorLoadViewPetUserData-retryControl"
            />
          </Message>
        </div>
      )}

      {requestPetUser.REQUEST_SUCCESS && petUser && (
        <>
          {isUnavailableFields && (
            <div className="auditMessage">
              <NoAccessMessageWrapper dataTestId="petUserDataView" userContextValue={userContextValue} />
            </div>
          )}

          <div className="auditInfo" data-testid="petUserDataView-wrapper">
            <PetUserDetails currentUser={petUser} />
          </div>
        </>
      )}

      {requestPetUser.REQUEST_SUCCESS && !petUser && (
        <div className="auditInfo" data-testid="petUserDataView-empty-wrapper">
          <Link to={pathAdministrationUsers}>
            <CloseControl />
          </Link>
          <Header as="h1" data-testid="PetUserDetails-title">
            {getTranslation('PetUsers_Details')}
          </Header>
          <EmptyMessage optionalText={getTranslation('No_PetUser')} />
        </div>
      )}
    </>
  );
};

export default PetUserDetailsWrapper;

import { useState } from 'react';
import { API_REQUEST_STATUS, RequestStatus } from '../../utils/APIHandlers';

export type useModalReturn = [
  {
    isOpen: boolean;
    requestStatus: RequestStatus;
  },
  {
    handleOpen: () => void;
    handleClose: () => void;
    onSuccessAction: () => void;
    onFailAction: () => void;
    setRequestedState: () => void;
    setNotRequestedState: () => void;
    setSuccessState: () => void;
    setFailState: () => void;
  }
];

export const useModal = (): useModalReturn => {
  const [isOpen, setToogleOpen] = useState<boolean>(false);
  const [requestStatus, setRequesStatus] = useState<RequestStatus>(API_REQUEST_STATUS.NOT_REQUESTED);

  const handleOpen = (): void => {
    setNotRequestedState();
    setToogleOpen(true);
  };

  const handleClose = (): void => {
    setNotRequestedState();
    setToogleOpen(false);
  };

  const onSuccessAction = (): void => {
    setSuccessState();
    setToogleOpen(false);
  };

  const onFailAction = (): void => {
    setFailState();
    setToogleOpen(false);
  };

  const setRequestedState = (): void => {
    setRequesStatus(API_REQUEST_STATUS.REQUESTED);
  };

  const setNotRequestedState = (): void => {
    setRequesStatus(API_REQUEST_STATUS.NOT_REQUESTED);
  };

  const setSuccessState = (): void => {
    setRequesStatus(API_REQUEST_STATUS.REQUEST_SUCCESS);
  };

  const setFailState = (): void => {
    setRequesStatus(API_REQUEST_STATUS.REQUEST_FAILURE);
  };

  return [
    {
      isOpen,
      requestStatus,
    },
    {
      handleOpen,
      handleClose,
      onSuccessAction,
      onFailAction,
      setRequestedState,
      setNotRequestedState,
      setSuccessState,
      setFailState,
    },
  ];
};

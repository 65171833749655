import React from 'react';
import { FeatureAccessGroupViewModel } from '../../../models/FeatureAccessGroupsAggregate/FeatureAccessGroup';
import { RequestStatus, API_REQUEST_STATUS } from '../../../utils/APIHandlers';

export interface FeatureAccessGroupsContextProps {
  getFeatureAccessGroups: (limit?: number, offset?: number) => Promise<void>;
  featureAccessGroupsData: FeatureAccessGroupViewModel[];
  requestFeatureAccessGroupsStatus: RequestStatus;
}

export const defaultFeatureAccessGroupsContextValue: FeatureAccessGroupsContextProps = {
  getFeatureAccessGroups: async () => new Promise(() => void 0),
  featureAccessGroupsData: [],
  requestFeatureAccessGroupsStatus: API_REQUEST_STATUS.NOT_REQUESTED,
};

const featureAccessGroupsContext = React.createContext<FeatureAccessGroupsContextProps>(
  defaultFeatureAccessGroupsContextValue
);

export { featureAccessGroupsContext };

import { TicketFormViewModel } from '../../../models/TripAggregate/TripsTickets';

export const getIsFormEmpty = (formData: TicketFormViewModel): boolean => {
  const filledItems = Object.values(formData).filter((value: string | null | [Date | null, Date | null]) =>
    Array.isArray(value) ? !value.includes(null) : value !== null && value.length > 0
  );

  return filledItems.length === 0;
};

export const getIsSearchParamsTheSame = (fieldsParams: string, urlSearchParams: URLSearchParams): boolean => {
  const pathParams = urlSearchParams.toString();

  return pathParams === fieldsParams;
};

export const getTicketNumberFromURL = (urlSearchParams: URLSearchParams): string => {
  return `${urlSearchParams.get('ticketStock') ?? ''}${urlSearchParams.get('ticketNumber') ?? ''}`;
};

import React from 'react';
import { Message } from 'semantic-ui-react';

import MessageWithControl from '../MessageWithControl';
import { getTranslation } from '../../../utils/getTranslation';
import TextButton from '../TextButton';

interface ResponseErrorMessageProps {
  onRetryClick?: () => void;
  dataTestId?: string;
}

const ResponseErrorMessage: React.FC<ResponseErrorMessageProps> = ({ onRetryClick, dataTestId }) => {
  return (
    <Message error data-testid={dataTestId ?? 'response-error-message'}>
      {onRetryClick ? (
        <MessageWithControl message={getTranslation('Error')}>
          <TextButton
            dataTestId={(dataTestId && `${dataTestId}-retry-btn`) || 'response-error-retry-btn'}
            onClick={onRetryClick}
            text={getTranslation('Retry')}
          />
        </MessageWithControl>
      ) : (
        getTranslation('Error')
      )}
    </Message>
  );
};

export default ResponseErrorMessage;

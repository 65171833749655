import React from 'react';

import { getTranslation } from '../../../utils/getTranslation';
import { getDocumentType } from './helper';

import './styles.scss';

interface TableDocumentNameCellProps {
  documentName: string;
  isPositiveDownload?: boolean;
  isNegativeDownload?: boolean;
}

const TableDocumentNameCell: React.FC<TableDocumentNameCellProps> = ({
  documentName,
  isPositiveDownload,
  isNegativeDownload,
}) => {
  return (
    <>
      <div
        className={`icon-document-type ${getDocumentType(documentName)}`}
        data-testid="tableDocumentNameCell"
      />
      <p className="file-name" data-testid="tableDocumentNameCell-name">
        <span>{documentName}</span>
        {isNegativeDownload && (
          <i className="documentsDownloadErrorText" data-testid="tableDocumentNameCell-negative">
            {getTranslation('App_LoadingError')}
          </i>
        )}
        {isPositiveDownload && (
          <i
            className="documentsDownloadErrorText"
            data-testid="tableDocumentNameCell-positive"
          >{`${getTranslation('Document')} ${getTranslation('downloadedMessage')}`}</i>
        )}
      </p>
    </>
  );
};

export default TableDocumentNameCell;

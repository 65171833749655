import React from 'react';

import NotFullAccessMessage from '../../../../shared/view/NotFullAccessMessage/NotFullAccessMessage';
import { getTranslation } from '../../../../utils/getTranslation';
import { UserContextProps } from '../../../../shared/store/userContext';
import { getUnavailablePetUsersFields } from '../helper';

interface NoAccessMessageWrapperProps {
  userContextValue: UserContextProps;
  dataTestId?: string;
}

const NoAccessMessageWrapper: React.FC<NoAccessMessageWrapperProps> = ({ userContextValue, dataTestId }) => (
  <NotFullAccessMessage dataTestId={dataTestId} className="staticNotificationMessage">
    {getTranslation('NotFullAccess_Message')}
    {getUnavailablePetUsersFields(userContextValue)
      .map((item) => getTranslation(item.title))
      .join(', ')}
  </NotFullAccessMessage>
);

export default NoAccessMessageWrapper;

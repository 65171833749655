import React, { useState } from 'react';
import { NormalizedCacheObject, ApolloClient } from '@apollo/client';

import { WithApolloClient } from '../../features/WithApolloClient/WithApolloClient';
import { useModal, useModalReturn } from '../hooks/modalHook';
import {
  defaultExportJobContextValue,
  exportJobContext,
  ExportJobContextProps,
} from '../store/exportJobContext';
import { API_REQUEST_STATUS } from '../../utils/APIHandlers';
import { ExportJobsService } from '../../services/ExportJobsService';
import { ExportJobDTO } from '../../api/graphql/models/ExportJob';
import { mapExportJobDTOFromCorrectExportJobDTO } from '../../features/ExportJobsContainer/exportJobsMapper';

interface Props {
  children: React.ReactChild;
  client?: ApolloClient<NormalizedCacheObject>;
}

const JobWrapper: React.FC<Props> = ({ children, client }) => {
  const [{ requestStatus }, { setFailState, setRequestedState, setSuccessState }]: useModalReturn =
    useModal();
  const [exportJobData, setJobData] = useState<ExportJobDTO | null>(null);

  const getExportJob = async (id: string): Promise<void> => {
    if (requestStatus !== API_REQUEST_STATUS.REQUESTED) {
      try {
        const clientApollo = client as ApolloClient<NormalizedCacheObject>;
        const exportJobsService: ExportJobsService = new ExportJobsService(clientApollo);
        setRequestedState();

        const response = await exportJobsService.getExportJob(id);
        const data =
          (response?.data?.exportJobs[0] &&
            mapExportJobDTOFromCorrectExportJobDTO(response.data.exportJobs[0])) ||
          null;

        setJobData(data);
        setSuccessState();
      } catch (error) {
        console.error(error);
        setFailState();
      }
    }
  };

  const getJobContext = (): ExportJobContextProps => {
    return {
      ...defaultExportJobContextValue,
      exportJobData,
      getExportJob: getExportJob,
      clearExportJobData: () => setJobData(null),
      requestExportJobStatus: requestStatus,
    };
  };

  return <exportJobContext.Provider value={getJobContext()}>{children}</exportJobContext.Provider>;
};

const WithExportJob = WithApolloClient(JobWrapper);

export { WithExportJob };

import React from 'react';
import './styles.scss';
import { Header } from 'semantic-ui-react';
import { supportLink } from './constant';
import { getTranslation } from '../../utils/getTranslation';

const Help: React.FC = () => {
  return (
    <div className="help-container container">
      <div className="contact-information">
        <Header as="h1" data-testid="help-title">
          {getTranslation('Help_SupportTitle')}
        </Header>
        <div className="text">{getTranslation('Help_SupportText')}</div>
        <a
          href={supportLink}
          target="_blank"
          type="button"
          color="blue"
          className="ui basic blue button small"
          rel="noreferrer"
          data-testid="help-supportLink"
        >
          {getTranslation('Help_SupportButton')}
        </a>
      </div>
    </div>
  );
};

export default Help;

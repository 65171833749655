export enum ExportJobStatus {
  Undefined,
  NotStarted,
  Running,
  Succeeded,
  Failed,
}

export const JOBHISTORY_STATUS_UNDEFINED = 'Undefined';
export const JOBHISTORY_STATUS_NOTSTARTED = 'Not Started';
export const JOBHISTORY_STATUS_RUNNING = 'Running';
export const JOBHISTORY_STATUS_SUCCEEDED = 'Succeeded';
export const JOBHISTORY_STATUS_FAILED = 'Failed';

export const JobStatusExportJobValue = {
  [ExportJobStatus.Undefined]: JOBHISTORY_STATUS_UNDEFINED,
  [ExportJobStatus.NotStarted]: JOBHISTORY_STATUS_NOTSTARTED,
  [ExportJobStatus.Running]: JOBHISTORY_STATUS_RUNNING,
  [ExportJobStatus.Succeeded]: JOBHISTORY_STATUS_SUCCEEDED,
  [ExportJobStatus.Failed]: JOBHISTORY_STATUS_FAILED,
};

export enum JobTypeExportJob {
  OnDemand,
  Scheduled,
}

export const JOBHISTORY_TYPE_SCHEDULED = 'Scheduled';
export const JOBHISTORY_TYPE_ONDEMAND = 'OnDemand';

export const JobTypeExportJobValue = {
  [JobTypeExportJob.Scheduled]: JOBHISTORY_TYPE_SCHEDULED,
  [JobTypeExportJob.OnDemand]: JOBHISTORY_TYPE_ONDEMAND,
};

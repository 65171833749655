import React from 'react';
import classNames from 'classnames';
import { Icon, Message } from 'semantic-ui-react';

interface NotFullAccessMessageProps {
  className?: string;
  dataTestId?: string;
}

const NotFullAccessMessage: React.FC<NotFullAccessMessageProps> = ({ dataTestId, className, children }) => (
  <Message
    warning
    className={classNames('NotFullAccessMessage', className)}
    data-testid={`${dataTestId ?? 'Default'}-NotFullAccess-Message`}
  >
    <Icon name="exclamation triangle" />
    {children}
  </Message>
);

export default NotFullAccessMessage;

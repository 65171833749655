import React from 'react';
import { Header, Icon } from 'semantic-ui-react';
import { getTranslation } from '../../utils/getTranslation';

const Private: React.FC = () => {
  return (
    <div className="container" data-testid="private-info">
      <div className="header-icon-container">
        <Icon name="info circle" color="grey" size="large" />
        <Header as="h1">{getTranslation('Private_Title')}</Header>
      </div>
      <div className="text">{getTranslation('Private_Text')}</div>
    </div>
  );
};

export default Private;

import React, { useEffect, useContext } from 'react';

import { userContext } from './../shared/store/userContext';
import Drawer from './../features/Drawer';
import Footer from './../features/Footer';
import Header from './../features/Header';
import Authentication from './../features/Authentication';
import Routes from './../routes';
import { WithApolloProvider } from '../shared/containers/withApolloClient';
import { initializeAPI } from '../utils/APIHandlers';
import FullSizeLoader from '../shared/view/Loader/FullSizeLoader';

import 'semantic-ui-less/semantic.less';
import './App.css';
import './../index.scss';

const PrivateApp: React.FC = () => {
  const { user, isUserLoaded, config, logOut, setCurrentUser, updateUserData } = useContext(userContext);

  useEffect(() => {
    initializeAPI(logOut);

    void updateUserData();
  }, []);

  return (
    <>
      {!isUserLoaded && <FullSizeLoader dataTestId="authenticationLoader-wrapper" />}
      {user && (
        <div className="App" data-testid="privateApp">
          <WithApolloProvider config={config}>
            <>
              <Drawer />
              <div className="main-content">
                <Header />
                <Routes />
                <Footer />
              </div>
            </>
          </WithApolloProvider>
        </div>
      )}
      {!user && (
        <div data-testid="authentication-wrapper">
          <Authentication setUser={setCurrentUser} />
        </div>
      )}
    </>
  );
};
export default PrivateApp;

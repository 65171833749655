import React, { useEffect, useState } from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { WithApolloClient } from '../../features/WithApolloClient/WithApolloClient';
import { TicketsService } from '../../services/TicketsService';

import { getTranslation } from '../../utils/getTranslation';
import { Button, Header, Message } from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom';
import { pathTickets } from '../../routes/constants';
import ViewTripWrapper from '../../features/TripContainer/ViewTrip/ViewTripWrapper';
import { TripViewModel } from '../../models/TripAggregate/Trip';
import { mapTripViewModel } from '../../features/TripContainer/ViewTrip/viewTripMapper';
import FullSizeLoader from '../../shared/view/Loader/FullSizeLoader';

interface Props {
  client?: ApolloClient<NormalizedCacheObject>;
  location: Location;
}

const TripPage: React.FC<Props> = (props) => {
  const [trip, setTrip] = useState<TripViewModel | null>(null);
  const [isLoaderShown, setIsLoaderShown] = useState(false);
  const [error, setError] = useState('');

  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    if (id) {
      void getTicket(id);
    }
  }, [props.location]);

  const getTicket = async (id: string) => {
    setIsLoaderShown(true);
    try {
      const client = props.client as ApolloClient<NormalizedCacheObject>;
      const ticketsService: TicketsService = new TicketsService(client);
      const data = await ticketsService.getById(id);
      if (data?.data?.canonicals?.[0]) {
        const currentTrip = mapTripViewModel(data?.data?.canonicals?.[0]);
        setTrip(currentTrip);
      } else {
        setError(getTranslation('TripAuditInfo_TripError'));
      }
    } catch (error) {
      setTrip(null);
      setError(getTranslation('CommonError'));
    }
    setIsLoaderShown(false);
  };

  return (
    <div className="container">
      {isLoaderShown ? (
        <FullSizeLoader />
      ) : (
        <>
          {trip ? (
            <ViewTripWrapper trip={trip} />
          ) : (
            <>
              <Header as="h1">{getTranslation('TripAuditInfo_Tickets')}</Header>
              <Message error>{error}</Message>
              <Button primary as={Link} to={pathTickets} size="small">
                {getTranslation('TicketSearch')}
              </Button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default WithApolloClient(TripPage);

import React, { useContext, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Button, Message } from 'semantic-ui-react';

import { exportJobContext } from '../../../shared/store/exportJobContext';
import ViewExportJob from './ViewExportJob';
import { API_REQUEST_STATUS } from '../../../utils/APIHandlers';
import FullSizeLoader from '../../../shared/view/Loader/FullSizeLoader';
import { getTranslation } from '../../../utils/getTranslation';
import { pathExportJobsList } from '../../../routes/constants';
import './styles.scss';

interface ViewExportJobWrapperProps {
  exportJobId: string;
}

const ViewExportJobWrapper: React.FC<ViewExportJobWrapperProps> = (props) => {
  const { exportJobId } = props;
  const { getExportJob, exportJobData, requestExportJobStatus } = useContext(exportJobContext);

  useEffect(() => {
    handleGetExportJob();
  }, []);

  const handleGetExportJob = useCallback((): void => {
    void getExportJob(exportJobId);
  }, [getExportJob, exportJobId]);

  return (
    <div className="container-exportJob" data-testid="exportJob">
      {requestExportJobStatus === API_REQUEST_STATUS.REQUESTED && <FullSizeLoader />}

      {requestExportJobStatus === API_REQUEST_STATUS.REQUEST_SUCCESS && exportJobData !== null && (
        <ViewExportJob data={exportJobData} />
      )}

      {requestExportJobStatus === API_REQUEST_STATUS.REQUEST_SUCCESS && exportJobData === null && (
        <>
          <Message warning>{getTranslation('Empty_GetJob_Response')}</Message>
          <Button
            as={Link}
            basic
            size="small"
            to={pathExportJobsList}
            data-testid="viewExportJob-toAllExportJobs-link"
          >
            {getTranslation('Additional_Route_Control_ExportsJobs')}
          </Button>
        </>
      )}

      {requestExportJobStatus === API_REQUEST_STATUS.REQUEST_FAILURE && (
        <>
          <Message warning>{getTranslation('Error')}</Message>
          <Button
            as={Link}
            basic
            size="small"
            to={pathExportJobsList}
            data-testid="viewExportJob-toAllExportJobs-link"
          >
            {getTranslation('Additional_Route_Control_ExportsJobs')}
          </Button>
          <Button primary size="small" onClick={handleGetExportJob} data-testid="viewExportJob-retry-control">
            {getTranslation('Retry_Now')}
          </Button>
        </>
      )}
    </div>
  );
};

export default ViewExportJobWrapper;

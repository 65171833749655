import React, { SyntheticEvent } from 'react';
import { Form, Select } from 'semantic-ui-react';

import { FieldError } from 'react-hook-form/dist/types';
import { DropdownViewModel } from '../../../models/DropdownViewModel';
import FormFieldLabel from '../../view/FormFieldLabel/FormFieldLabel';
import { DropdownValueType } from '../../../features/PetForm/types';

import './styles.scss';

export interface IconsSelectProps {
  label: string | React.ReactChild;
  error?: FieldError | boolean;
  disabled?: boolean;
  defaultValue?: string | number;
  options: DropdownViewModel[];
  onChange: (e: SyntheticEvent, { value, options }: DropdownValueType) => void;
  required?: boolean;
  dataTestId?: string;
}

const IconsSelect: React.FC<IconsSelectProps> = ({
  label,
  onChange,
  options,
  defaultValue,
  error,
  required,
  dataTestId,
}) => {
  return (
    <Form.Field>
      <FormFieldLabel required={required}>{label}</FormFieldLabel>
      <Select
        className="iconsSelect"
        options={options}
        defaultValue={defaultValue}
        onChange={onChange}
        error={!!error}
        search={true}
        data-testid={dataTestId}
        icon="chevron down"
      />
      {!!error && typeof error !== 'boolean' && (
        <div className="formError" data-testid="iconSelectField-errorMessage">
          {error.message}
        </div>
      )}
    </Form.Field>
  );
};

export default IconsSelect;

import React, { ReactElement } from 'react';
import './styles.scss';

interface ShortFieldDescriptionProps {
  content: string;
  warning?: boolean;
  fullwidth?: boolean;
  normal?: boolean;
  dataTestId?: string;
}

const ShortFieldDescription: React.FC<ShortFieldDescriptionProps> = (props): ReactElement => {
  const { content, warning, fullwidth, normal, dataTestId } = props;

  return (
    <div
      className={`shortNotification ${warning ? 'warning-notification' : ''} ${
        fullwidth ? 'fullwidth-notification' : ''
      } ${normal ? 'normal-notification' : ''}`}
      data-testid={dataTestId}
    >
      <i>{content}</i>
    </div>
  );
};

export default ShortFieldDescription;

import React from 'react';

import { DropdownViewModel } from './../../../../models/DropdownViewModel';
import TableLabel from './../../../TableLabel';
import { ConfigType } from './types';

export const getAccessTypesOptions = (accessTypesConfig?: ConfigType): DropdownViewModel[] => {
  const accessTypesOptions = [];
  for (let i = 0; i < Object.entries(accessTypesConfig || {}).length; i++) {
    accessTypesOptions.push({
      key: i.toString(),
      value: i,
      text: <TableLabel accessTypeConfig={accessTypesConfig?.[i]} />,
      content: <TableLabel accessTypeConfig={accessTypesConfig?.[i]} />,
    });
  }

  return accessTypesOptions;
};

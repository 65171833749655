import React, { useContext, useMemo, useCallback } from 'react';

import { getTranslation } from '../../../utils/getTranslation';
import { getRequestStatuses } from '../../../utils/APIHandlers';
import { documentContext } from '../../../shared/store/documentsContext';
import EmptyMessage from '../../../shared/view/EmptyMessage/EmptyMessage';
import ResponseErrorMessage from '../../../shared/controls/ResponseErrorMessage';
import FullSizeLoader from '../../../shared/view/Loader/FullSizeLoader';

const DocumentsNotifications: React.FC = () => {
  const { getDocuments, documents, requestGetDocumentsStatus, requestDownloadDocumentStatus } =
    useContext(documentContext);
  const requestGetDocuments = useMemo(
    () => getRequestStatuses(requestGetDocumentsStatus),
    [requestGetDocumentsStatus]
  );
  const requestDownloadDocument = useMemo(
    () => getRequestStatuses(requestDownloadDocumentStatus),
    [requestDownloadDocumentStatus]
  );
  const handleGetDocuments = useCallback(() => {
    void getDocuments();
  }, [getDocuments]);

  return (
    <>
      {(requestGetDocuments.REQUESTED || requestDownloadDocument.REQUESTED) && (
        <FullSizeLoader dataTestId="GetOrDownloadDocument-loader" />
      )}

      {requestGetDocuments.REQUEST_FAILURE && (
        <ResponseErrorMessage dataTestId="GetDocuments-error" onRetryClick={handleGetDocuments}>
          {getTranslation('App_LoadingError')}
        </ResponseErrorMessage>
      )}

      {requestDownloadDocument.NOT_REQUESTED &&
        requestGetDocuments.REQUEST_SUCCESS &&
        documents.length === 0 && <EmptyMessage dataTestId="EmptyDocuments-message" />}
    </>
  );
};

export default DocumentsNotifications;

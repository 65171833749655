import React, { Dispatch, SetStateAction, useState, useCallback } from 'react';
import { Auth } from 'aws-amplify';
import { Button, Form } from 'semantic-ui-react';
import InputWithInfo from '../../PetForm/InputWithInfo';
import { getTranslation } from '../../../utils/getTranslation';
import { CustomCognitoUser } from '../types';
import { useForm } from 'react-hook-form';
import { getPasswordPattern, getRequiredFieldRegister } from '../../PetForm/helper';

interface ChangePasswordProps {
  email: string;
  userWithoutPassword: CustomCognitoUser | null;
  setUser: Dispatch<SetStateAction<any | null | undefined>>;
}

interface ChangePasswordFormViewModel {
  newPassword: string;
}

const defaultValueChangePasswordFields: ChangePasswordFormViewModel = {
  newPassword: '',
};

const ChangePassword = (props: ChangePasswordProps): JSX.Element => {
  const { userWithoutPassword, email, setUser } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangePasswordFormViewModel>({
    defaultValues: defaultValueChangePasswordFields,
  });

  const requiredFieldRegister = getRequiredFieldRegister();
  const passwordPattern = getPasswordPattern();

  const changePassword = async ({ newPassword }: ChangePasswordFormViewModel) => {
    setIsLoading(true);

    try {
      await Auth.completeNewPassword(userWithoutPassword, newPassword);

      const user = (await Auth.signIn(email, newPassword)) as CustomCognitoUser;

      setUser(user);
    } catch (error: Error | unknown) {
      setAlertMessage((error as Error)?.message);
    }

    setIsLoading(false);
  };

  const onSubmitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>): void => {
      void handleSubmit(changePassword)(event);
    },
    [changePassword]
  );

  return (
    <Form className="form" onSubmit={onSubmitForm} data-testid="changePassword-form">
      <h2>{getTranslation('Authentication_ChangePassword')}</h2>
      <div>{getTranslation('Authentication_ChangeInfo')}</div>

      <InputWithInfo
        required
        label={getTranslation('Authentication_Password')}
        error={errors.newPassword}
        register={register('newPassword', {
          ...requiredFieldRegister,
          ...passwordPattern,
        })}
        type={'password'}
        size={'large'}
      />

      {alertMessage && <div className="formError">{alertMessage}</div>}

      <Button primary type="submit" size="medium" fluid className={isLoading ? 'loading' : ''}>
        {getTranslation('Authentication_Change')}
      </Button>
    </Form>
  );
};

export default ChangePassword;

import React from 'react';
import { Header } from 'semantic-ui-react';

import { getTranslation } from '../../utils/getTranslation';
import { WithDocuments } from '../../shared/containers/withDocuments';
import DocumentsWrapper from '../../features/DocumentsContainer/DocumentsWrapper';

const DocumentsRepository: React.FC = () => {
  return (
    <div className="documentsRepository container">
      <Header as="h1">{getTranslation('DocumentsRepository_Header')}</Header>

      <WithDocuments>
        <DocumentsWrapper />
      </WithDocuments>
    </div>
  );
};

export default DocumentsRepository;

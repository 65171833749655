import React from 'react';
import { Header, Icon, Segment } from 'semantic-ui-react';
import { getTranslation } from '../../../utils/getTranslation';

interface EmptyMessageProps {
  optionalText?: string;
  dataTestId?: string;
}

const EmptyMessage: React.FC<EmptyMessageProps> = ({ optionalText, dataTestId }) => (
  <Segment textAlign="center" padded="very" data-testid={dataTestId}>
    <Header as="h2" icon>
      <Icon name="inbox" size="huge" color="grey" />
      {getTranslation('Empty_Word')}
      <Header.Subheader data-testid="emptyMessage-optionalText">
        {optionalText ?? getTranslation('Empty')}
      </Header.Subheader>
    </Header>
  </Segment>
);

export default EmptyMessage;

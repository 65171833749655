import { UserContextProps } from '../../shared/store/userContext';

export const hasUserManagementAccess = ({
  isUserManagementHasReadAccess,
  isUserManagementHasReadWriteAccess,
}: UserContextProps): boolean => isUserManagementHasReadAccess || isUserManagementHasReadWriteAccess;

export const hasDataAuthorizationManagementAccess = ({
  isDataAuthorizationManagementReadAccess,
  isDataAuthorizationManagementReadWriteAccess,
}: UserContextProps): boolean =>
  isDataAuthorizationManagementReadAccess || isDataAuthorizationManagementReadWriteAccess;

export const hasFeatureAccessManagementAccess = ({
  isFeatureAccessManagementReadAccess,
  isFeatureAccessManagementReadWriteAccess,
}: UserContextProps): boolean =>
  isFeatureAccessManagementReadAccess || isFeatureAccessManagementReadWriteAccess;

export const hasReportsManagementAccess = ({
  isReportsReadAccess,
  isReportsExportAccess,
}: UserContextProps): boolean => isReportsReadAccess || isReportsExportAccess;

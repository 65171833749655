import React, { useState } from 'react';
import { Divider, Grid, Header, Icon, Label } from 'semantic-ui-react';

import { getTranslation } from '../../../../utils/getTranslation';
import { getFormattedDate } from '../../../../utils/formatDate';
import TableLabel from '../../../TableLabel';
import RoundFirstLetterInfo from '../../../../shared/view/RoundFirstLetterInfo/RoundFirstLetterInfo';
import NewTicketInfo from './NewTicketInfo';
import OriginalTicketInfo from './OriginalTicketInfo';
import AirSegmentInfo from './AirSegmentInfo';
import LabelWrapper from '../../../../shared/view/LabelWrapper/LabelWrapper';
import { TripTicketViewModel } from '../../../../models/TripAggregate/TripTicket';
import GtrStatusLabel from '../../../../shared/view/GtrStatusLabel/GtrStatusLabel';

interface Props {
  ticket: TripTicketViewModel;
}

const TicketCard: React.FC<Props> = ({
  ticket: {
    fullTicketNumber,
    fareAmount,
    fareCurrency,
    isConjunctive,
    isRefundable,
    invoiceNumber,
    tourCode,
    formOfPaymentsNumber,
    validatingCarrier,
    lowFareReasonCode,
    ticketIssueDate,
    ticketBranchPcc,
    expiryDate,
    gtrStatus,
    baseFaresAmount,
    endorsements,
    creditAmount,
    fareCalculation,
    commonAmount,
    exchangeInfo,
    travelers,
    coupons,
  },
}) => {
  const [isCardOpen, setIsCardOpen] = useState(false);
  const isExchangeInfo = exchangeInfo.newTicket || exchangeInfo.originalTicket;

  return (
    <div className="ticket-card auditInfo">
      <div
        className="card-header"
        onClick={() => setIsCardOpen(!isCardOpen)}
        data-testid="ticket-card-header-element"
      >
        <div className="container-card-header">
          <Icon
            name="chevron down"
            flipped={isCardOpen ? 'vertically' : undefined}
            size="large"
            data-testid="ticket-card-header-icon"
          />
          <Header as="h2">{fullTicketNumber}</Header>
          <GtrStatusLabel gtrStatus={gtrStatus} dataTestId="ticket-card-status-badge" />
        </div>
        <Divider />
      </div>
      <div className="card-body">
        {travelers.length > 0 && (
          <>
            <div className="card-body-item" data-testid="ticketTravelers-wrapper">
              <Header as="h3">{getTranslation('TripAuditInfo_Traveler')}</Header>
              <Grid className="container-ticketCartTraveler">
                {travelers.map(({ firstName, lastName, middleName, primaryEmailAddress, travelerNumber }) => (
                  <Grid.Row key={travelerNumber} data-testid="ticket-card-traveler">
                    <Grid.Column mobile={8} tablet={4} computer={4} largeScreen={3} widescreen={4}>
                      <div className="initial-wrapper">
                        <RoundFirstLetterInfo first={firstName} second={lastName} />
                        <LabelWrapper label={getTranslation('FirstName')} dataTestId="traveler-firstName">
                          {firstName}
                        </LabelWrapper>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile={8} tablet={4} computer={4} largeScreen={3} widescreen={4}>
                      <LabelWrapper label={getTranslation('LastName')}>{lastName}</LabelWrapper>
                    </Grid.Column>
                    <Grid.Column
                      mobile={16}
                      tablet={4}
                      computer={4}
                      largeScreen={3}
                      widescreen={4}
                      className="paddedContent"
                    >
                      <LabelWrapper label={getTranslation('TripAuditInfo_Middle')}>{middleName}</LabelWrapper>
                    </Grid.Column>
                    <Grid.Column
                      mobile={16}
                      tablet={4}
                      computer={4}
                      largeScreen={7}
                      widescreen={4}
                      className="paddedContent"
                    >
                      <LabelWrapper label={getTranslation('TripAuditInfo_PrimaryEmailAddress')}>
                        {primaryEmailAddress}
                      </LabelWrapper>
                    </Grid.Column>
                  </Grid.Row>
                ))}
              </Grid>
            </div>

            <Divider />
          </>
        )}

        <div className="card-body-item">
          <Grid>
            <Grid.Row>
              <Grid.Column className="card-sub-title">
                <div className="container-titleWrapper">
                  <Header as="h3">{getTranslation('TripAuditInfo_Ticket')}</Header>
                </div>
                <div className="container-badgesWrapper">
                  <Label size="medium" data-testid="ticket-card-tripAuditConjunctive">
                    {getTranslation(
                      isConjunctive ? 'TripAuditInfo_Conjunctive' : 'TripAuditInfo_NotConjunctive'
                    )}
                  </Label>
                  <TableLabel
                    text={getTranslation(
                      isRefundable ? 'TripAuditInfo_Refundable' : 'TripAuditInfo_NonRefundable'
                    )}
                    active={true}
                    roundBg={isRefundable ? 'green' : 'red'}
                    dataTestId="ticket-card-tripAuditRefundable"
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile={8} tablet={9} computer={9} largeScreen={9} widescreen={12}>
                <Grid>
                  <Grid.Row className="container-ticketItems">
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <LabelWrapper label={getTranslation('TripAuditInfo_ValidatingCarrier')}>
                        {validatingCarrier}
                      </LabelWrapper>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <LabelWrapper
                        label={getTranslation('TripAuditInfo_TicketExpiryDate')}
                        dataTestId="ticket-card-expiryDate"
                      >
                        {(expiryDate && getFormattedDate(expiryDate)) || '-'}
                      </LabelWrapper>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                      <LabelWrapper
                        label={getTranslation('TripAuditInfo_TicketIssueDate')}
                        dataTestId="ticket-card-ticketIssueDate"
                      >
                        {(ticketIssueDate && getFormattedDate(ticketIssueDate)) || '-'}
                      </LabelWrapper>
                    </Grid.Column>
                    {isCardOpen && (
                      <>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                          <LabelWrapper
                            label={getTranslation('TripAuditInfo_TourCode')}
                            dataTestId="ticket-card-tripAudit-TourCode"
                          >
                            {tourCode}
                          </LabelWrapper>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                          <LabelWrapper
                            label={getTranslation('TripAuditInfo_TicketBranchPCC')}
                            dataTestId="ticket-card-tripAudit-TicketBranchPCC"
                          >
                            {ticketBranchPcc}
                          </LabelWrapper>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                          <LabelWrapper
                            label={getTranslation('TripAuditInfo_LowFareReasonCode')}
                            dataTestId="ticket-card-tripAudit-LowFareReasonCode"
                          >
                            {lowFareReasonCode}
                          </LabelWrapper>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                          <LabelWrapper
                            label={getTranslation('InvoiceNumber')}
                            dataTestId="ticket-card-tripAudit-InvoiceNumber"
                          >
                            {invoiceNumber}
                          </LabelWrapper>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                          <LabelWrapper
                            label={getTranslation('TripAuditInfo_FormOfPayment')}
                            dataTestId="ticket-card-tripAudit-FormOfPayment"
                          >
                            {formOfPaymentsNumber}
                          </LabelWrapper>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                          <LabelWrapper
                            label={getTranslation('TripAuditInfo_Endorsements')}
                            dataTestId="ticket-card-tripAudit-Endorsements"
                          >
                            {endorsements}
                          </LabelWrapper>
                        </Grid.Column>
                      </>
                    )}
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column mobile={8} tablet={7} computer={7} largeScreen={7} widescreen={4}>
                <div className="calculationAuditInfo">
                  <Grid>
                    <Grid.Row className="arbitraryWidth">
                      <Grid.Column mobile={16}>
                        <LabelWrapper label={getTranslation('TripAuditInfo_FareAmount')}>
                          {`${fareAmount} ${fareCurrency}`}
                        </LabelWrapper>
                      </Grid.Column>
                      <Grid.Column className="divider">
                        <Divider vertical />
                      </Grid.Column>
                      <Grid.Column mobile={16}>
                        <LabelWrapper label={getTranslation('TripAuditInfo_Base')}>
                          {baseFaresAmount}
                        </LabelWrapper>
                      </Grid.Column>
                      <Grid.Column mobile={16}>
                        <LabelWrapper label={getTranslation('TripAuditInfo_TaxesTotal')}>
                          {commonAmount}
                        </LabelWrapper>
                      </Grid.Column>
                    </Grid.Row>
                    {isCardOpen && (
                      <>
                        <Grid.Row>
                          <Grid.Column>
                            <LabelWrapper
                              label={getTranslation('TripAuditInfo_CreditAmountAvailable')}
                              dataTestId="ticket-card-tripAudit-CreditAmountAvailable"
                            >
                              {`${creditAmount.amount} ${creditAmount.currency}`}
                            </LabelWrapper>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column>
                            <LabelWrapper
                              label={getTranslation('TripAuditInfo_FareCalculation')}
                              dataTestId="ticket-card-tripAudit-FareCalculation"
                            >
                              <div className="container-fareCalculation">{fareCalculation}</div>
                            </LabelWrapper>
                          </Grid.Column>
                        </Grid.Row>
                      </>
                    )}
                  </Grid>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>

        {isCardOpen && isExchangeInfo && (
          <>
            <Divider />

            <div className="card-body-item" data-testid="ticket-card-exchangeInfo">
              <Header as="h3">{getTranslation('TripAuditInfo_ExchangeDetails')}</Header>

              <Grid>
                {exchangeInfo?.newTicket && (
                  <Grid.Row data-testid="ticked-card-exchangeInfo-newTicket">
                    <Grid.Column>
                      <Header as="h4">{getTranslation('TripAuditInfo_NewTicket')}</Header>
                      <NewTicketInfo newTicket={exchangeInfo.newTicket} />
                    </Grid.Column>
                  </Grid.Row>
                )}
                {exchangeInfo?.originalTicket && (
                  <Grid.Row data-testid="ticked-card-exchangeInfo-originalTicket">
                    <Grid.Column>
                      <Header as="h4">{getTranslation('TripAuditInfo_OriginalTicket')}</Header>
                      <OriginalTicketInfo originalTicket={exchangeInfo.originalTicket} />
                    </Grid.Column>
                  </Grid.Row>
                )}
              </Grid>
            </div>
          </>
        )}

        {coupons.length > 0 && (
          <>
            <Divider />

            <div className="card-body-item">
              <Header as="h3" data-testid="ticket-card-TripSegmentsHeader">
                {getTranslation('TripAuditInfo_TripSegments')} ({coupons.length})
              </Header>

              <div className="airSegment-info">
                {coupons.map((item) => (
                  <AirSegmentInfo coupon={item} key={item.id} isCardOpen={isCardOpen} />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TicketCard;

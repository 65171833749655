export interface IsAllowLeaveProps {
  isDirty: boolean;
  leaveDirection: string | null;
  isExportJobCreated: boolean;
  callback?: () => void;
}

export const getIsAllowLeave = ({
  isDirty,
  leaveDirection,
  isExportJobCreated,
  callback,
}: IsAllowLeaveProps): boolean => {
  const isAllow = !isDirty || leaveDirection !== null || isExportJobCreated;

  !isAllow && callback && callback();

  return isAllow;
};

import { SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import { ReportTypeDto } from './ReportTypeDto';

export interface GetAllResponse {
  reports: ReportTypeDto[];
}

export interface UpdateResponse {
  replaceOneReport: ReportTypeDto | null;
}

export interface InsertResponse {
  insertOneReport: ReportTypeDto;
}

export enum ReportType {
  Analytical = 1,
  Activity,
  Action,
  Info,
  Tech,
}

export const ReportTypeValue: { [key in ReportType]: string } = {
  [ReportType.Analytical]: 'Report_Type_Analytical',
  [ReportType.Activity]: 'Report_Type_Activity',
  [ReportType.Action]: 'Report_Type_Action',
  [ReportType.Info]: 'Report_Type_Info',
  [ReportType.Tech]: 'Report_Type_Tech',
};

export const ReportTypeColor: { [key in ReportType]: SemanticCOLORS } = {
  [ReportType.Analytical]: 'blue',
  [ReportType.Activity]: 'green',
  [ReportType.Action]: 'brown',
  [ReportType.Info]: 'orange',
  [ReportType.Tech]: 'grey',
};

export enum ReportIcon {
  ChartLine = 1,
  ChartBarOutline,
  ClipboardList,
  Book,
  CalendarAlternate,
  CalendarAlternateOutline,
  FolderOpen,
  FolderOpenOutline,
  Exchange,
  Clock,
  ClockOutline,
  RedoAlternate,
  UndoAlternate,
  SyncAlternate,
  Random,
  CalendarTimes,
  CalendarTimesOutline,
  EuroSign,
  DollarSign,
  MoneyBillAlternate,
  MoneyBillAlternateOutline,
  ExclamationCircle,
  ExclamationTriangle,
  ChartArea,
  ChartBar,
  ChartPie,
  Tasks,
  OrderedList,
  MapMarkerAlternate,
  Pin,
  ClipboardCheck,
  Signal,
  Star,
  StarOutline,
  StickyNote,
  StickyNoteOutline,
}

export const ReportIconValue: { [key in ReportIcon]: SemanticICONS } = {
  [ReportIcon.ChartLine]: 'chart line',
  [ReportIcon.ChartBarOutline]: 'chart bar outline',
  [ReportIcon.ClipboardList]: 'clipboard list',
  [ReportIcon.Book]: 'book',
  [ReportIcon.CalendarAlternate]: 'calendar alternate',
  [ReportIcon.CalendarAlternateOutline]: 'calendar alternate outline',
  [ReportIcon.FolderOpen]: 'folder open',
  [ReportIcon.FolderOpenOutline]: 'folder open outline',
  [ReportIcon.Exchange]: 'exchange',
  [ReportIcon.Clock]: 'clock',
  [ReportIcon.ClockOutline]: 'clock outline',
  [ReportIcon.RedoAlternate]: 'redo alternate',
  [ReportIcon.UndoAlternate]: 'undo alternate',
  [ReportIcon.SyncAlternate]: 'sync alternate',
  [ReportIcon.Random]: 'random',
  [ReportIcon.CalendarTimes]: 'calendar times',
  [ReportIcon.CalendarTimesOutline]: 'calendar times outline',
  [ReportIcon.EuroSign]: 'euro sign',
  [ReportIcon.DollarSign]: 'dollar sign',
  [ReportIcon.MoneyBillAlternate]: 'money bill alternate',
  [ReportIcon.MoneyBillAlternateOutline]: 'money bill alternate outline',
  [ReportIcon.ExclamationCircle]: 'exclamation circle',
  [ReportIcon.ExclamationTriangle]: 'exclamation triangle',
  [ReportIcon.ChartArea]: 'chart area',
  [ReportIcon.ChartBar]: 'chart bar',
  [ReportIcon.ChartPie]: 'chart pie',
  [ReportIcon.Tasks]: 'tasks',
  [ReportIcon.OrderedList]: 'ordered list',
  [ReportIcon.MapMarkerAlternate]: 'map marker alternate',
  [ReportIcon.Pin]: 'pin',
  [ReportIcon.ClipboardCheck]: 'clipboard check',
  [ReportIcon.Signal]: 'signal',
  [ReportIcon.Star]: 'star',
  [ReportIcon.StarOutline]: 'star outline',
  [ReportIcon.StickyNote]: 'sticky note',
  [ReportIcon.StickyNoteOutline]: 'sticky note outline',
};

import React, { useState } from 'react';
import { NormalizedCacheObject, ApolloClient } from '@apollo/client';

import { WithApolloClient } from '../../features/WithApolloClient/WithApolloClient';
import {
  defaultDataAuthorizationRolesValue,
  dataAuthorizationRolesContext,
  DataAuthorizationRolesContextProps,
} from '../store/dataAuthorizationRolesContext';
import { API_REQUEST_STATUS, RequestStatus } from '../../utils/APIHandlers';
import { DataAuthorizationRolesList } from '../../models/DataAuthorizationRolesAggregate/DataAuthorizationRole';
import { DataAuthorizationRolesService } from '../../services/DataAuthorizationRolesService';

interface Props {
  children: React.ReactChild;
  client?: ApolloClient<NormalizedCacheObject>;
}

const DataAuthorizationRoles: React.FC<Props> = ({ children, client }) => {
  const [dataAuthorizationRolesData, setDataAuthorizationRolesData] = useState<DataAuthorizationRolesList>(
    []
  );
  const [requestDataAuthorizationRolesStatus, setRequestDataAuthorizationRolesStatus] =
    useState<RequestStatus>(API_REQUEST_STATUS.NOT_REQUESTED);

  const getDataAuthorizationRoles = async (limit = 100000, offset = 0) => {
    try {
      const clientApollo = client as ApolloClient<NormalizedCacheObject>;
      const dataAuthorizationRolesService: DataAuthorizationRolesService = new DataAuthorizationRolesService(
        clientApollo
      );
      setRequestDataAuthorizationRolesStatus(API_REQUEST_STATUS.REQUESTED);
      const response = await dataAuthorizationRolesService.getAllDataAuthorizationRoles(limit, offset);

      setRequestDataAuthorizationRolesStatus(API_REQUEST_STATUS.REQUEST_SUCCESS);
      setDataAuthorizationRolesData(response || []);
    } catch (error) {
      console.error(error);
      setRequestDataAuthorizationRolesStatus(API_REQUEST_STATUS.REQUEST_FAILURE);
    }
  };

  const getDataAuthorizationRolesContext = (): DataAuthorizationRolesContextProps => {
    return {
      ...defaultDataAuthorizationRolesValue,
      getDataAuthorizationRoles,
      dataAuthorizationRolesData,
      requestDataAuthorizationRolesStatus,
    };
  };

  return (
    <dataAuthorizationRolesContext.Provider value={getDataAuthorizationRolesContext()}>
      {children}
    </dataAuthorizationRolesContext.Provider>
  );
};

const WithDataAuthorizationRoles = WithApolloClient(DataAuthorizationRoles);

export { WithDataAuthorizationRoles };

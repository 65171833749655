import React from 'react';
import { Grid } from 'semantic-ui-react';

import { getTranslation } from '../../../../utils/getTranslation';
import { getFormattedDate } from '../../../../utils/formatDate';
import RoundFirstLetterInfo from '../../../../shared/view/RoundFirstLetterInfo/RoundFirstLetterInfo';
import LabelWrapper from '../../../../shared/view/LabelWrapper/LabelWrapper';
import { NewTicketExchangeInfoViewModel } from '../../../../models/TripAggregate/TripTicket';

interface Props {
  newTicket: NewTicketExchangeInfoViewModel;
}

const NewTicketInfo: React.FC<Props> = ({
  newTicket: { fullTicketNumber, recordLocator, issueDate, expiryDate, firstName, lastName },
}) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column mobile={8} tablet={5} computer={4} largeScreen={4} widescreen={4}>
          <LabelWrapper label={getTranslation('TicketsNumber')} dataTestId="newTicket-ticketNumber">
            {fullTicketNumber}
          </LabelWrapper>
        </Grid.Column>
        <Grid.Column mobile={8} tablet={5} computer={4} largeScreen={4} widescreen={4}>
          <LabelWrapper label={getTranslation('BookingId')}>{recordLocator}</LabelWrapper>
        </Grid.Column>
        <Grid.Column mobile={8} tablet={5} computer={4} largeScreen={4} widescreen={4}>
          <LabelWrapper label={getTranslation('TicketIssueDate')} dataTestId="newTicket-issueDate">
            {(issueDate && getFormattedDate(issueDate)) || '-'}
          </LabelWrapper>
        </Grid.Column>
        <Grid.Column mobile={8} tablet={5} computer={4} largeScreen={4} widescreen={4}>
          <LabelWrapper label={getTranslation('TicketCard_ExpiryDate')} dataTestId="newTicket-expiryDate">
            {(expiryDate && getFormattedDate(expiryDate)) || '-'}
          </LabelWrapper>
        </Grid.Column>
        <Grid.Column mobile={8} tablet={5} computer={4} largeScreen={4} widescreen={4}>
          <div className="initial-wrapper">
            <RoundFirstLetterInfo first={firstName} second={lastName} />
            <LabelWrapper label={getTranslation('FirstName')}>{firstName}</LabelWrapper>
          </div>
        </Grid.Column>
        <Grid.Column mobile={8} tablet={5} computer={4} largeScreen={4} widescreen={4}>
          <LabelWrapper label={getTranslation('LastName')}>{lastName}</LabelWrapper>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default NewTicketInfo;

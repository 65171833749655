import React from 'react';
import { PRIVACY_POLICY, TERMS_AND_CONDITION } from '../../constants/externalLinks';
import './styles.scss';
import { getTranslation } from '../../utils/getTranslation';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      {getTranslation('Footer_text')}
      <br />
      <a href={PRIVACY_POLICY} className="link">
        {getTranslation('Privacy_policy')}
      </a>{' '}
      <a href={TERMS_AND_CONDITION} className="link">
        {getTranslation('Terms_and_conditions')}
      </a>
    </footer>
  );
};

export default Footer;

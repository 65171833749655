import { v4 as uuid } from 'uuid';

import {
  CreateFeatureAccessGroupDTO,
  FeatureAccessGroupDTO,
  UpdateFeatureAccessGroupDTO,
} from '../../../api/graphql/models/FeatureAccessGroups';
import {
  FeatureAccessGroupFormViewModel,
  FeatureAccessGroupGridViewModel,
  FeatureAccessGroupViewModel,
} from '../../../models/FeatureAccessGroupsAggregate/FeatureAccessGroup';
import { AccessType } from '../../../models/FeatureAccessGroupsAggregate/AccessType';
import { ReportAccessType } from '../../../models/FeatureAccessGroupsAggregate/ReportAccessType';

export const mapCreateFeatureAccessGroup = (
  data: FeatureAccessGroupFormViewModel,
  userEmail: string
): CreateFeatureAccessGroupDTO => ({
  _id: String(uuid()),
  groupName: data.groupName,
  reportManagement: data.reportManagement,
  userManagement: data.userManagement,
  ticketsManagement: data.ticketsManagement,
  scheduledReportsManagement: data.scheduledReportsManagement,
  featureAccessManagement: data.featureAccessManagement,
  dataAuthorizationManagement: data.dataAuthorizationManagement,
  createdBy: userEmail,
  createdDate: new Date().toISOString(),
});

export const mapUpdateFeatureAccessGroup = (
  data: FeatureAccessGroupFormViewModel,
  userEmail: string
): UpdateFeatureAccessGroupDTO => ({
  _id: data.id ?? '',
  groupName: data.groupName,
  reportManagement: data.reportManagement,
  userManagement: data.userManagement,
  ticketsManagement: data.ticketsManagement,
  scheduledReportsManagement: data.scheduledReportsManagement,
  featureAccessManagement: data.featureAccessManagement,
  dataAuthorizationManagement: data.dataAuthorizationManagement,
  createdBy: data.createdBy ?? '',
  createdDate: data.createdDate?.toISOString() ?? '',
  updatedBy: userEmail,
  updatedDate: new Date().toISOString(),
});

export const mapFeatureAccessGroup = (data: FeatureAccessGroupDTO): FeatureAccessGroupViewModel => ({
  id: data._id,
  groupName: data.groupName ?? '',
  reportManagement: data.reportManagement?.accessType ?? ReportAccessType.None,
  userManagement: data.userManagement?.accessType ?? AccessType.None,
  ticketsManagement: data.ticketsManagement?.accessType ?? AccessType.None,
  scheduledReportsManagement: data.scheduledReportsManagement?.accessType ?? AccessType.None,
  featureAccessManagement: data.featureAccessManagement?.accessType ?? AccessType.None,
  dataAuthorizationManagement: data.dataAuthorizationManagement?.accessType ?? AccessType.None,
  reports: data.reportManagement?.reports ?? [],
  createdBy: data.createdBy ?? '',
  createdDate: (data.createdDate && new Date(data.createdDate)) || undefined,
  updatedBy: data.updatedBy,
  updatedDate: (data?.updatedDate && new Date(data.updatedDate)) || undefined,
});

export const mapFeatureAccessGroups = (data: FeatureAccessGroupDTO[]): FeatureAccessGroupViewModel[] =>
  data.map((item) => mapFeatureAccessGroup(item));

export const mapGridFeatureAccessGroup = (
  data: FeatureAccessGroupViewModel
): FeatureAccessGroupGridViewModel => ({
  id: data.id,
  groupName: data.groupName,
  reportManagement: data.reportManagement,
  userManagement: data.userManagement,
  ticketsManagement: data.ticketsManagement,
  scheduledReportsManagement: data.scheduledReportsManagement,
  featureAccessManagement: data.featureAccessManagement,
  dataAuthorizationManagement: data.dataAuthorizationManagement,
  countReports: data.reports.length,
});

export const mapFeatureAccessGroupToFormViewModel = (
  data: FeatureAccessGroupViewModel
): FeatureAccessGroupFormViewModel => ({
  id: data.id,
  groupName: data.groupName,
  createdBy: data.createdBy,
  createdDate: data.createdDate,
  updatedBy: data.updatedBy,
  updatedDate: data.updatedDate,
  reportManagement: {
    accessType: data.reportManagement,
    reports: data.reports,
  },
  userManagement: {
    accessType: data.userManagement,
  },
  ticketsManagement: {
    accessType: data.ticketsManagement,
  },
  scheduledReportsManagement: {
    accessType: data.scheduledReportsManagement,
  },
  featureAccessManagement: {
    accessType: data.featureAccessManagement,
  },
  dataAuthorizationManagement: {
    accessType: data.dataAuthorizationManagement,
  },
});

import React from 'react';
import { Header } from 'semantic-ui-react';

import { getTranslation } from '../../utils/getTranslation';
import ExportJobsTableWrapper from './../../features/ExportJobsContainer/ExportJobsTableWrapper';
import ModalsExportJobs from './../../features/ExportJobsContainer/ModalsExportJobs';
import { WithDeleteExportJob } from '../../shared/containers/withDeleteExportJob';
import { WithEnableExportJob } from '../../shared/containers/withEnableExportJob';
import { WithExportJobs } from './../../shared/containers/withExportJobs';
import { WithTriggerExportJob } from '../../shared/containers/withTriggerExportJob';
import { WithExportJobHistory } from '../../shared/containers/withExportJobHistory';

import './styles.scss';

const ExportJobs: React.FC = () => {
  return (
    <div className="exportJobs container">
      <Header as="h1" data-testid="exportJobs-title">
        {getTranslation('ExportJobs_Title')}
      </Header>

      <div className="container-exportJobsWrapper">
        <div className="container-exportJobsTable">
          <WithDeleteExportJob>
            <WithEnableExportJob>
              <WithTriggerExportJob>
                <WithExportJobHistory>
                  <>
                    <WithExportJobs>
                      <ExportJobsTableWrapper />
                    </WithExportJobs>

                    <ModalsExportJobs />
                  </>
                </WithExportJobHistory>
              </WithTriggerExportJob>
            </WithEnableExportJob>
          </WithDeleteExportJob>
        </div>
      </div>
    </div>
  );
};

export default ExportJobs;

import React from 'react';
import { Table } from 'semantic-ui-react';

import { getTranslation } from '../../../utils/getTranslation';
import { getFormattedUTCString } from '../../../utils/formatDate';
import { TripsTicketsSearchViewModel } from '../../../models/TripAggregate/TripsTickets';

import './styles.scss';
import GtrStatusLabel from '../../../shared/view/GtrStatusLabel/GtrStatusLabel';
import CustomLabel from '../../../shared/view/CustomLabel/CustomLabel';

interface TripsTicketsSearchTableProps {
  tripsTickets: TripsTicketsSearchViewModel[];
  onRowClick?: (id: string) => void;
}

const TripsTicketsSearchTable: React.FC<TripsTicketsSearchTableProps> = ({ tripsTickets, onRowClick }) => {
  return (
    <div className={`tripsTicketsSearchTable-container`} data-testid="tripsTicketsSearchTable-wrapper">
      <Table celled>
        <Table.Header>
          <Table.Row>
            {[
              getTranslation('TicketsNumber'),
              getTranslation('BookingId'),
              '',
              getTranslation('GtrStatus'),
              getTranslation('StatusUpdateDate'),
              getTranslation('SecurityManagerID'),
              getTranslation('LastName'),
              getTranslation('FirstName'),
              getTranslation('TicketIssueDate'),
              getTranslation('UpdatedDate'),
            ].map((text) => (
              <Table.HeaderCell key={text}>{text}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tripsTickets.map(
            ({
              id,
              ticketNumber,
              recordLocator,
              gtrStatus,
              statusUpdateDate,
              isCancelled,
              accountId,
              travelerFirstName,
              travelerLastName,
              ticketIssueDate,
              expirationFromDate,
            }: TripsTicketsSearchViewModel) => {
              return (
                <Table.Row
                  key={`${ticketNumber}${id}`}
                  onClick={onRowClick ? () => onRowClick(id) : undefined}
                  data-testid="tripsTicketsSearchTable-ticket-item"
                >
                  <Table.Cell>{ticketNumber}</Table.Cell>
                  <Table.Cell>{recordLocator}</Table.Cell>
                  <Table.Cell>
                    {isCancelled && (
                      <CustomLabel
                        text={getTranslation('Cancelled')}
                        color=""
                        dataTestId="tripsTicketsSearchTable-ticket-item-cancelled"
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <GtrStatusLabel gtrStatus={gtrStatus} />
                  </Table.Cell>
                  <Table.Cell data-testid="tripsTicketsSearchTable-ticket-item-update-date">
                    {statusUpdateDate && getFormattedUTCString(statusUpdateDate)}
                  </Table.Cell>
                  <Table.Cell>{accountId}</Table.Cell>
                  <Table.Cell>{travelerLastName}</Table.Cell>
                  <Table.Cell>{travelerFirstName}</Table.Cell>
                  <Table.Cell data-testid="tripsTicketsSearchTable-ticket-item-ticketIssueDate">
                    {ticketIssueDate && getFormattedUTCString(ticketIssueDate)}
                  </Table.Cell>
                  <Table.Cell data-testid="tripsTicketsSearchTable-ticket-item-expirationFromDate">
                    {getFormattedUTCString(expirationFromDate)}
                  </Table.Cell>
                </Table.Row>
              );
            }
          )}
        </Table.Body>
      </Table>
    </div>
  );
};

export default TripsTicketsSearchTable;

import React, { useState } from 'react';

import { useNotification, useNotificationReturn } from '../hooks/notificationHook';
import { DownloadDocumentService } from '../../services/DownloadDocumentService';
import { API_REQUEST_CODE } from '../../utils/APIHandlers';
import {
  DownloadDocumentRequestDTO,
  DownloadReadableStreamDTO,
  GetDocumentResponse,
  Document,
} from '../../api/rest/models/Document';
import { DocumentsService } from '../../services/DocumentsService';
import { ErrorFetchResponse, RestApiResponse } from '../../api/rest/models/RestApiResponse';
import { getFetchErrorMessage } from '../../utils/errorHandler';
import { defaultDocumentValue, DocumentsProps, documentContext } from '../store/documentsContext';

interface Props {
  children: React.ReactChild;
}

const WithDocuments: React.FC<Props> = ({ children }) => {
  const [documents, setDocuments] = useState<Document[]>(defaultDocumentValue.documents);
  const [downloadDocumentName, setDownloadDocumentName] = useState<string>(
    defaultDocumentValue.downloadDocumentName
  );
  const [
    { notificationStatus: notificationDocumentsStatus },
    {
      setFailNotificationStatus: setFailDocumentsStatus,
      setSuccessNotificationStatus: setSuccessDocumentsStatus,
      setRequestedNotificationStatus: setRequestedDocumentsStatus,
    },
  ]: useNotificationReturn = useNotification();
  const [
    { notificationStatus: notificationDownloadStatus },
    {
      setFailNotificationStatus: setFailDownloadStatus,
      setSuccessNotificationStatus: setSuccessDownloadStatus,
      setRequestedNotificationStatus: setRequestedDownloadStatus,
    },
  ]: useNotificationReturn = useNotification();

  const getDocuments = async () => {
    const documentsService: DocumentsService = new DocumentsService();

    try {
      setRequestedDocumentsStatus();

      const response = await documentsService.getDocumentsNames();
      const successResponse = response as RestApiResponse<Document[]>;

      setDocuments(successResponse.result);
      setSuccessDocumentsStatus();
    } catch (e) {
      console.error(getFetchErrorMessage(e as ErrorFetchResponse));
      setFailDocumentsStatus();
    }
  };

  const downloadDocument = async (payload: DownloadDocumentRequestDTO): Promise<void> => {
    const downloadDocumentService = new DownloadDocumentService();
    const documentsService: DocumentsService = new DocumentsService();

    try {
      setDownloadDocumentName(payload.documentName);
      setRequestedDownloadStatus();

      const documentLinkResponse = await documentsService.getDocumentLink(payload.documentName);
      const documentLink = documentLinkResponse as RestApiResponse<GetDocumentResponse>;

      if (documentLink.result) {
        const response = await downloadDocumentService.getDocument({ fileLink: documentLink.result.link });
        if (response.status === API_REQUEST_CODE.SUCCESS) {
          await response
            .blob()
            .then((blob) => {
              downloadDocumentService.handleDownloadReadableStream({
                blob,
                fileName: payload.documentName,
              } as DownloadReadableStreamDTO);
            })
            .finally(() => setSuccessDownloadStatus());
        } else {
          setFailDownloadStatus();
        }
      }
    } catch (e) {
      console.error(getFetchErrorMessage(e as ErrorFetchResponse));
      setFailDownloadStatus();
    }
  };

  const getDownloadScheduledReportContext = (): DocumentsProps => {
    return {
      ...defaultDocumentValue,
      downloadDocument,
      getDocuments,
      documents,
      downloadDocumentName,
      requestDownloadDocumentStatus: notificationDownloadStatus,
      requestGetDocumentsStatus: notificationDocumentsStatus,
    };
  };

  return (
    <documentContext.Provider value={getDownloadScheduledReportContext()}>
      {children}
    </documentContext.Provider>
  );
};

export { WithDocuments };

import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { mapToCreateExportJobDTOFromExportJobFormViewModel } from '../exportJobsMapper';
import { ExportJobFormViewModel } from '../../../models/ExportJobsAggregate/ExportJobForm';
import { createExportJobContext } from '../../../shared/store/createExportJobContext';
import { userContext } from '../../../shared/store/userContext';
import CreateExportJobForm from './CreateExportJobForm';
import EditExportJobModals from './Modals/EditExportJobModals';
import { pathExportJobsList } from './../../../routes/constants';
import { API_REQUEST_STATUS } from '../../../utils/APIHandlers';
import { WithReports } from '../../../shared/containers/withReports';
import { WithDataAuthorizationRoles } from '../../../shared/containers/withDataAuthorizationRoles';
import { WithSftpProviders } from '../../../shared/containers/withSftpProviders';
import './styles.scss';

interface CreateExportJobFormWrapperProps {
  defaultData?: ExportJobFormViewModel;
}

const CreateExportJobFormWrapper: React.FC<CreateExportJobFormWrapperProps> = (props) => {
  const { defaultData } = props;
  const history = useHistory();
  const { handleOpenCreateJob, notificationCreateJob } = useContext(createExportJobContext);
  const { email } = useContext(userContext);

  const onSubmit = useCallback(
    (submitData: ExportJobFormViewModel): void => {
      handleOpenCreateJob(mapToCreateExportJobDTOFromExportJobFormViewModel(submitData, email));
    },
    [handleOpenCreateJob, email]
  );

  useEffect(() => {
    if (
      notificationCreateJob === API_REQUEST_STATUS.REQUEST_SUCCESS ||
      notificationCreateJob === API_REQUEST_STATUS.REQUESTED
    ) {
      setTimeout(() => {
        history.push(pathExportJobsList);
      }, 2000);
    }
  }, [notificationCreateJob, history]);

  return (
    <>
      <WithReports>
        <WithSftpProviders>
          <WithDataAuthorizationRoles>
            <CreateExportJobForm onSubmit={onSubmit} defaultData={defaultData} />
          </WithDataAuthorizationRoles>
        </WithSftpProviders>
      </WithReports>

      <EditExportJobModals />
    </>
  );
};

export default CreateExportJobFormWrapper;

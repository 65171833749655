import React from 'react';
import ExpressionDescriptor from 'cronstrue';
import { Message, Grid, Divider, Label, Header } from 'semantic-ui-react';

import { getTranslation } from '../../../utils/getTranslation';
import { DeliveryMethod, DeliveryMethodValue } from '../../../models/ExportJobsAggregate/ExportJobDelivery';
import { ExportJobDTO } from '../../../api/graphql/models/ExportJob';
import { getUTCStringFromDate } from '../../../utils/formatDate';
import { FormatValue } from '../../../models/ExportJobsAggregate/ExportJobFormat';
import TableLabel from '../../TableLabel';
import LabelWrapper from '../../../shared/view/LabelWrapper/LabelWrapper';
import { getPeriodLabel } from './helper';
import { getDisplayedValue } from '../../TripContainer/ViewTrip/helper';

import './styles.scss';

interface ViewExportJobProps {
  data: ExportJobDTO;
}

const ViewExportJob: React.FC<ViewExportJobProps> = (props) => {
  const {
    jobName,
    reportDetails,
    format,
    cronExpression,
    activeStartDate,
    activeEndDate,
    dataAuthorisationRoleId,
    deliveryMethod,
    provider,
    userEmails,
    enabled,
    createdDate,
    createdBy,
    updatedDate,
    updatedBy,
  } = props.data;

  return (
    <Grid data-testid="viewExportJob-form">
      <Grid.Row>
        <Grid.Column width={12}>
          <div className="container-viewExportJob">
            <Header as="h2" className="exportJobName">
              <span data-testid="exportJob-jobName">{jobName}</span>
              <span className="container-enabled" data-testid="exportJob-enabled">
                {enabled ? (
                  <TableLabel text={getTranslation('Enabled')} active={true} />
                ) : (
                  <TableLabel text={getTranslation('Disabled')} active={false} />
                )}
              </span>
            </Header>

            <Divider />

            <Grid className="container-viewExportJobMainContent">
              <Grid.Row>
                <Grid.Column width={10}>
                  <LabelWrapper label={getTranslation('View_ExportJob_Reports')}>
                    <p data-testid="exportJob-report">{reportDetails?.reportId.displayName}</p>
                  </LabelWrapper>
                </Grid.Column>
                <Grid.Column width={6}>
                  <LabelWrapper label={getTranslation('View_ExportJob_ReportType')}>
                    <Label data-testid="exportJob-reportType">{FormatValue[format]}</Label>
                  </LabelWrapper>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={10}>
                  <LabelWrapper label={getTranslation('Edit_ExportJob_ActivationPeriod')}>
                    <p data-testid="exportJob-activationPeriod">{`${getUTCStringFromDate(
                      activeStartDate
                    )} - ${getUTCStringFromDate(activeEndDate)}`}</p>
                  </LabelWrapper>
                </Grid.Column>
                <Grid.Column width={6}>
                  <LabelWrapper label={getTranslation('Edit_ExportJob_Period')}>
                    <p data-testid="exportJob-period">{getPeriodLabel(props.data)}</p>
                  </LabelWrapper>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Divider />

            <Grid className="container-viewExportJobSecondaryContent">
              <Grid.Row>
                <Grid.Column width={10}>
                  <LabelWrapper label={getTranslation('Edit_ExportJob_Cron')}>
                    <p data-testid="exportJob-cron">{ExpressionDescriptor.toString(cronExpression)}</p>
                  </LabelWrapper>
                </Grid.Column>

                <Grid.Column width={6}>
                  <LabelWrapper label={getTranslation('Edit_ExportJob_DataAuthRoles')}>
                    <p data-testid="exportJob-dataAuthRoles">{dataAuthorisationRoleId?.roleName}</p>
                  </LabelWrapper>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={9}>
                  <Message className="container-deliveryMethodContentWrapper">
                    <div className="container-deliveryMethod">
                      <LabelWrapper label={getTranslation('Edit_ExportJob_DeliveryMethod_Wrapper')}>
                        <p data-testid="exportJob-deliveryMethod">
                          <b>{DeliveryMethodValue[deliveryMethod]}</b>
                        </p>
                      </LabelWrapper>
                    </div>

                    <div className="conteiner-deliveryMethodValue">
                      {deliveryMethod === DeliveryMethod.Ftp && (
                        <LabelWrapper label={getTranslation('View_ExportJob_Provider')}>
                          <p
                            className="viewExportJobsProvidersList"
                            data-testid="exportJob-deliveryMethodFtp"
                          >
                            {provider}
                          </p>
                        </LabelWrapper>
                      )}

                      {deliveryMethod === DeliveryMethod.Email && (
                        <LabelWrapper label={getTranslation('Edit_ExportJob_UsersEmails')}>
                          <div data-testid="exportJob-deliveryMethodEmail">
                            {userEmails.map((item: string) => (
                              <Label key={item} data-testid="exportJob-deliveryMethodEmail-item">
                                {item}
                              </Label>
                            ))}
                          </div>
                        </LabelWrapper>
                      )}
                    </div>
                  </Message>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Grid.Column>
        <Grid.Column width={4}>
          <div className="container-viewExportJob">
            <Grid>
              <Grid.Row>
                <Grid.Column width={12}>
                  <LabelWrapper label={getTranslation('TripAuditInfo_CreatedBy')}>
                    <p data-testid="exportJob-createdBy">{getDisplayedValue(createdBy)}</p>
                  </LabelWrapper>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={12}>
                  <LabelWrapper label={getTranslation('TripAuditInfo_CreatedDate')}>
                    <p data-testid="exportJob-createdDate">{getUTCStringFromDate(createdDate)}</p>
                  </LabelWrapper>
                </Grid.Column>
              </Grid.Row>

              <Divider />

              <Grid.Row>
                <Grid.Column width={12}>
                  <LabelWrapper label={getTranslation('TripAuditInfo_UpdatedBy')}>
                    <p data-testid="exportJob-updatedBy">{getDisplayedValue(updatedBy || undefined)}</p>
                  </LabelWrapper>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={12}>
                  <LabelWrapper label={getTranslation('TripAuditInfo_UpdatedDate')}>
                    <p data-testid="exportJob-updatedDate">
                      {getDisplayedValue((updatedDate && getUTCStringFromDate(updatedDate)) || undefined)}
                    </p>
                  </LabelWrapper>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ViewExportJob;

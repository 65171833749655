import React, { Dispatch, SetStateAction } from 'react';
import { Form } from 'semantic-ui-react';
import { Controller } from 'react-hook-form';
import { FieldError, UseFormRegister } from 'react-hook-form/dist/types';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import DropdownWithInfo from '../../PetForm/DropdownWithInfo';
import { handleAdditionOptionFactory } from '../../PetForm/helper';
import { getTranslation } from '../../../utils/getTranslation';
import { RecordsAndTickets } from '../types';
import { DropdownViewModel } from '../../../models/DropdownViewModel';
import { FormProps } from '../../PetForm/types';

interface Props extends FormProps<RecordsAndTickets, UseFormRegister<RecordsAndTickets>> {
  client?: ApolloClient<NormalizedCacheObject>;
  errors?: {
    recordLocators: FieldError;
    ticketNumbers: FieldError;
  };
  recordLocatorsOptions: DropdownViewModel[];
  setRecordLocatorsOptions: Dispatch<SetStateAction<DropdownViewModel[]>>;
  ticketNumbersOptions: DropdownViewModel[];
  setTicketNumbersOptions: Dispatch<SetStateAction<DropdownViewModel[]>>;
  ticketNumbers: string[];
  setTicketNumbers: Dispatch<SetStateAction<string[]>>;
  recordLocators: string[];
  setRecordLocators: Dispatch<SetStateAction<string[]>>;
  invalidateTicketNumbers: string[];
  invalidateRecordLocators: string[];
  isRecordLocatorsLimitExceeded: boolean;
  isTicketNumbersLimitExceeded: boolean;
  setInvalidateRecordLocators: Dispatch<SetStateAction<string[]>>;
  setIsRecordLocatorsLimitExceeded: Dispatch<SetStateAction<boolean>>;
  setInvalidateTicketNumbers: Dispatch<SetStateAction<string[]>>;
  setIsTicketNumbersLimitExceeded: Dispatch<SetStateAction<boolean>>;
  ticketNumbersLimit: number;
  recordLocatorsLimit: number;
}

const SystemOndemandForm: React.FC<Props> = (props) => {
  const {
    recordLocatorsOptions,
    setRecordLocatorsOptions,
    ticketNumbersOptions,
    setTicketNumbersOptions,
    invalidateTicketNumbers,
    invalidateRecordLocators,
    isTicketNumbersLimitExceeded,
    isRecordLocatorsLimitExceeded,
    setInvalidateRecordLocators,
    setIsRecordLocatorsLimitExceeded,
    setIsTicketNumbersLimitExceeded,
    setInvalidateTicketNumbers,
    errors,
    control,
    recordLocators,
    ticketNumbers,
    setTicketNumbers,
    setRecordLocators,
    setValue,
    setFormError,
    ticketNumbersLimit,
    recordLocatorsLimit,
  } = props;

  const handleDropdownStateChange = (
    value: string[],
    setDropdownValue: Dispatch<SetStateAction<string[]>>,
    onChange: (value: string[]) => void,
    setIsLimitExceedValue: Dispatch<SetStateAction<boolean>>,
    limit: number
  ): void => {
    setFormError?.('');
    setDropdownValue?.(value);
    onChange(value);
    setIsLimitExceedValue(value.length > limit);
  };

  return (
    <>
      <div className="form-header">{getTranslation('Systems_TriggerTicketEnrichment')}</div>
      <div className="text">{getTranslation('Systems_FormDescription')}</div>

      <Form.Field>
        <Controller
          render={({ field: { onChange, value } }) => (
            <>
              <DropdownWithInfo
                options={recordLocatorsOptions}
                error={errors?.recordLocators || !!isRecordLocatorsLimitExceeded}
                onChange={(e, { value }) =>
                  handleDropdownStateChange(
                    value as string[],
                    setRecordLocators,
                    onChange,
                    setIsRecordLocatorsLimitExceeded,
                    recordLocatorsLimit
                  )
                }
                value={(value as string[]) || []}
                onAddItem={handleAdditionOptionFactory({
                  value: recordLocators,
                  setValue: setRecordLocators,
                  setFormValue: setValue,
                  setFormValueName: 'recordLocators',
                  setOptions: setRecordLocatorsOptions,
                  options: recordLocatorsOptions,
                  separator: /,|\s/,
                  validate: /^([a-z]|\d)+$/i,
                  limit: recordLocatorsLimit,
                  setInvalidateValue: setInvalidateRecordLocators,
                  setIsLimitExceedValue: setIsRecordLocatorsLimitExceeded,
                })}
                isSearched={true}
                isAllowAdditions
                isMultiple={true}
                isSelection={true}
                label={getTranslation('RecordLocators')}
                tip={
                  getTranslation('System_ValidateLetters') +
                  getTranslation('RecordLocators_Limit_Message') +
                  recordLocatorsLimit.toString()
                }
              />
              {!!invalidateRecordLocators.length && (
                <div className="formError">
                  {invalidateRecordLocators.toString()}{' '}
                  {invalidateRecordLocators.length === 1
                    ? getTranslation('Systems_SingleInvalid')
                    : getTranslation('Systems_Invalid')}
                </div>
              )}
              {!!isRecordLocatorsLimitExceeded && (
                <div className="formError">
                  {getTranslation('RecordLocators_Limit_Error_Message')}
                  {recordLocatorsLimit}
                </div>
              )}
            </>
          )}
          name="recordLocators"
          control={control}
        />
      </Form.Field>

      <Form.Field>
        <Controller
          render={({ field: { onChange, value } }) => (
            <>
              <DropdownWithInfo
                options={ticketNumbersOptions}
                error={errors?.ticketNumbers || !!isTicketNumbersLimitExceeded}
                onChange={(e, { value }) =>
                  handleDropdownStateChange(
                    value as string[],
                    setTicketNumbers,
                    onChange,
                    setIsTicketNumbersLimitExceeded,
                    ticketNumbersLimit
                  )
                }
                value={(value as string[]) || []}
                onAddItem={handleAdditionOptionFactory({
                  value: ticketNumbers,
                  setValue: setTicketNumbers,
                  setFormValue: setValue,
                  setFormValueName: 'ticketNumbers',
                  setOptions: setTicketNumbersOptions,
                  options: ticketNumbersOptions,
                  separator: /,|\s/,
                  validate: /^\d{13}$/,
                  limit: ticketNumbersLimit,
                  setInvalidateValue: setInvalidateTicketNumbers,
                  setIsLimitExceedValue: setIsTicketNumbersLimitExceeded,
                })}
                isSearched={true}
                isAllowAdditions
                isMultiple={true}
                isSelection={true}
                label={getTranslation('Systems_TicketNumbers')}
                tip={
                  getTranslation('System_ValidateNumbers') +
                  getTranslation('TicketNumbers_Limit_Message') +
                  ticketNumbersLimit.toString()
                }
              />
              {!!invalidateTicketNumbers.length && (
                <div className="formError">
                  {invalidateTicketNumbers.toString()}{' '}
                  {invalidateTicketNumbers.length === 1
                    ? getTranslation('Systems_SingleInvalid')
                    : getTranslation('Systems_Invalid')}
                </div>
              )}
              {!!isTicketNumbersLimitExceeded && (
                <div className="formError">
                  {getTranslation('TicketNumbers_Limit_Error_Message')}
                  {ticketNumbersLimit}
                </div>
              )}
            </>
          )}
          name="ticketNumbers"
          control={control}
        />
      </Form.Field>
    </>
  );
};

export default SystemOndemandForm;

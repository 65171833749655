import React from 'react';
import { DEFAULT_PAGE } from '../../../pages/Administration/constant';
import { RequestStatus, API_REQUEST_STATUS } from '../../../utils/APIHandlers';
import { FeatureAccessGroupViewModel } from '../../../models/FeatureAccessGroupsAggregate/FeatureAccessGroup';

export interface GetPaginatedFeatureAccessGroupsProps {
  page: number;
}

export interface PaginatedFeatureAccessGroupsContextProps {
  getFeatureAccessGroups: (payload?: GetPaginatedFeatureAccessGroupsProps) => Promise<void>;
  featureAccessGroups: FeatureAccessGroupViewModel[];
  totalFeatureAccessGroups: number;
  activePageFeatureAccessGroups: number;
  requestFeatureAccessGroupsStatus: RequestStatus;
  setFeatureAccessGroupsActivePage: (page: number) => void;
}

export const defaultPaginatedFeatureAccessGroupsContextValue: PaginatedFeatureAccessGroupsContextProps = {
  getFeatureAccessGroups: async () => new Promise(() => void 0),
  featureAccessGroups: [],
  totalFeatureAccessGroups: 0,
  activePageFeatureAccessGroups: DEFAULT_PAGE,
  requestFeatureAccessGroupsStatus: API_REQUEST_STATUS.NOT_REQUESTED,
  setFeatureAccessGroupsActivePage: () => void 0,
};

const paginatedFeatureAccessGroupsContext = React.createContext<PaginatedFeatureAccessGroupsContextProps>(
  defaultPaginatedFeatureAccessGroupsContextValue
);

export { paginatedFeatureAccessGroupsContext };

import { PetApi } from '../constants/apiParams';
import { ErrorFetchResponse, RestApiResponse } from '../api/rest/models/RestApiResponse';
import { EmbedTokenResponseDto } from '../api/rest/models/EmbedTokenResponseAggregate/EmbedTokenResponseDto';
import { APIFetch } from '../utils/APIHandlers';
import { getFetchErrorMessage } from '../utils/errorHandler';
import { ReportsConfigsResponse } from '../models/ReportsAggregate/ReportsConfigs';
import { ReportNameDTO } from '../api/rest/models/Reports';

export class ReportsService {
  private readonly endpoint: string;
  private readonly workSpaceId: string;
  private readonly environment: string;
  private readonly configCacheKey: string;
  private readonly version: string;
  constructor(workSpaceId: string, environment: string, version: string) {
    this.version = version;
    this.workSpaceId = workSpaceId;
    this.environment = environment;
    this.configCacheKey = `${this.workSpaceId}-${this.environment}`;
    this.endpoint = `/v1/tickets/pet/workspaces/${this.workSpaceId}/reports`;
  }

  public resetCache(): void {
    localStorage.removeItem(this.configCacheKey);
  }

  public async loadConfig(): Promise<ReportsConfigsResponse> {
    const cachedConfig = localStorage.getItem(this.configCacheKey);
    let config: ReportsConfigsResponse;

    if (cachedConfig && !this.isTokenExpired(cachedConfig)) {
      config = JSON.parse(cachedConfig) as EmbedTokenResponseDto;
    } else {
      try {
        const response = await this.getConfigFromApi();
        const successResponse = response as RestApiResponse<EmbedTokenResponseDto>;

        config = this.cacheConfig(successResponse);
      } catch (e) {
        config = undefined;
        console.error(getFetchErrorMessage(e as ErrorFetchResponse));
      }
    }

    return config;
  }

  private cacheConfig(response: RestApiResponse<EmbedTokenResponseDto>): EmbedTokenResponseDto {
    const reportConfig = {
      ...response.result,
      reports: response.result.reports.filter((r) => r.reportName.includes(`-${this.version}`)),
    };
    localStorage.setItem(this.configCacheKey, JSON.stringify(reportConfig));

    return reportConfig;
  }

  private isTokenExpired(cachedConfig: string): boolean {
    const configuration = JSON.parse(cachedConfig) as EmbedTokenResponseDto;
    const tokenConfiguration = configuration.embedToken;
    const tokenExpiration = new Date(tokenConfiguration?.expiration);

    return tokenExpiration <= new Date();
  }

  private async getConfigFromApi(): Promise<RestApiResponse<EmbedTokenResponseDto> | ErrorFetchResponse> {
    return await APIFetch<RestApiResponse<EmbedTokenResponseDto>>('get', PetApi, this.endpoint, {});
  }

  public async getAllReportNames(): Promise<RestApiResponse<ReportNameDTO[]> | ErrorFetchResponse> {
    return await APIFetch<RestApiResponse<ReportNameDTO[]>>('get', PetApi, `${this.endpoint}/names`, {});
  }
}

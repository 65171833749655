import React, { useCallback } from 'react';
import { Button, Divider, Form, Grid, Header, Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import { getTranslation } from '../../../utils/getTranslation';
import TicketCard from './TicketCard';
import { pathTickets } from '../../../routes/constants';
import RoundFirstLetterInfo from '../../../shared/view/RoundFirstLetterInfo/RoundFirstLetterInfo';
import LabelWrapper from '../../../shared/view/LabelWrapper/LabelWrapper';
import { getUTCStringFromDate } from '../../../utils/formatDate';
import { TripViewModel } from '../../../models/TripAggregate/Trip';
import CustomLabel from '../../../shared/view/CustomLabel/CustomLabel';

import './styles.scss';

interface Props {
  trip: TripViewModel;
}

const ViewTripWrapper: React.FC<Props> = ({ trip }) => {
  const { tickets, identification, createdBy, createdDate, updatedBy, updatedDate } = trip;
  const history = useHistory();

  const goBack = useCallback(() => {
    history.length > 2 ? history.goBack() : history.push(pathTickets);
  }, [history]);

  return (
    <Form className="ticketsAuditForm readOnlyForm">
      <div className="ticketsAuditForm-header-container">
        <div>
          <Header as="h1">
            {getTranslation('TripAuditInfo_Tickets')} ({tickets.length})
          </Header>
        </div>

        <Button primary size="small" basic onClick={goBack}>
          <Icon name="arrow left" />
          {getTranslation('TripAuditInfo_BackToSearchResults')}
        </Button>
      </div>
      <div className="cards-container">
        <div className="cards-wrapper">
          {tickets.map((ticket) => (
            <div data-testid="tripAuditInfo-tickedCard" key={ticket.fullTicketNumber}>
              <TicketCard ticket={ticket} />
            </div>
          ))}
        </div>

        <div className="auditInfo sticky-block">
          <div className="form-header container-TripAuditInfo">
            <Header as="h2">{getTranslation('TripAuditInfo_TripInfo')}</Header>
            {identification.isCancelled && (
              <CustomLabel
                text={getTranslation('TripAuditInfo_Cancelled')}
                dataTestId={'tripAuditInfo-canceled-badge'}
              />
            )}
          </div>
          <Grid>
            <Grid.Row columns="equal">
              <Grid.Column>
                <LabelWrapper
                  label={getTranslation('TripAuditInfo_BookingSource')}
                  dataTestId="tripAuditInfo-bookingSource"
                >
                  {identification.gds}
                </LabelWrapper>
              </Grid.Column>
              <Grid.Column>
                <LabelWrapper label={getTranslation('BookingId')}>
                  {identification.recordLocator}
                </LabelWrapper>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal">
              <Grid.Column>
                <LabelWrapper label={getTranslation('GlobalCustomerNumber')}>
                  {identification.globalCustomerNumber}
                </LabelWrapper>
              </Grid.Column>
              <Grid.Column>
                <LabelWrapper label={getTranslation('TripAuditInfo_CustomerName')}>
                  {identification.customerName}
                </LabelWrapper>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal">
              <Grid.Column>
                <LabelWrapper label={getTranslation('TripAuditInfo_AccountId')}>
                  {identification.accountId}
                </LabelWrapper>
              </Grid.Column>
              <Grid.Column>
                <LabelWrapper label={getTranslation('TripAuditInfo_CountryCode')}>
                  {identification.accountCountryCode}
                </LabelWrapper>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal">
              <Grid.Column>
                <LabelWrapper label={getTranslation('TripAuditInfo_CreationLocation')}>
                  {identification.creationOfficeId}
                </LabelWrapper>
              </Grid.Column>
              <Grid.Column>
                <LabelWrapper label={getTranslation('TripAuditInfo_PseudoCityCode')}>
                  {identification.pseudoCityCode}
                </LabelWrapper>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal">
              <Grid.Column>
                <LabelWrapper label={getTranslation('TripAuditInfo_CustomerNumber')}>
                  {identification.customerNumber}
                </LabelWrapper>
              </Grid.Column>
            </Grid.Row>

            <Divider />

            <Grid.Row columns="equal">
              <Grid.Column>
                <div className="initial-wrapper">
                  <RoundFirstLetterInfo first={createdBy} />
                  <LabelWrapper label={getTranslation('TripAuditInfo_CreatedBy')}>{createdBy}</LabelWrapper>
                </div>
              </Grid.Column>
              <Grid.Column>
                <LabelWrapper
                  label={getTranslation('TripAuditInfo_CreatedDate')}
                  dataTestId="tripAuditInfo-createdDate"
                >
                  {getUTCStringFromDate(createdDate)}
                </LabelWrapper>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal">
              <Grid.Column>
                <div className="initial-wrapper">
                  <RoundFirstLetterInfo first={updatedBy} />
                  <LabelWrapper label={getTranslation('TripAuditInfo_UpdatedBy')}>{updatedBy}</LabelWrapper>
                </div>
              </Grid.Column>
              <Grid.Column>
                <LabelWrapper
                  label={getTranslation('TripAuditInfo_UpdatedDate')}
                  dataTestId="tripAuditInfo-updatedDate"
                >
                  {(updatedDate && getUTCStringFromDate(updatedDate)) || '-'}
                </LabelWrapper>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    </Form>
  );
};

export default ViewTripWrapper;

export const removeClones = (insert: string[]): string[] => {
  const set: Set<string> = new Set(insert);

  return Array.from(set);
};

export const getLowerCaseArray = (insert: string[]): string[] => {
  return insert.map((item) => item.toLowerCase());
};

export const buildArrayBySeparator = (insert: string[], separator: string): string[] => {
  let clearValue: string[] = [];

  if (insert.length > 0) {
    let stringValue = '';

    stringValue = insert.join(separator);
    stringValue = stringValue.replace(/\s+/g, '');
    clearValue = stringValue.split(separator);
  }

  return clearValue;
};

export const getValidArrayByReq = (insert: string[], expression: RegExp): string[] => {
  const validValue: string[] = [];

  if (insert.length > 0) {
    insert.forEach((item) => {
      expression.test(String(item)) && validValue.push(item);
    });
  }

  return validValue;
};

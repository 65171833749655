import React, { useState } from 'react';
import { NormalizedCacheObject, ApolloClient } from '@apollo/client';
import { Auth } from 'aws-amplify';
import * as Realm from 'realm-web';

import { UserContextProps, defaultUserContextValue, userContext } from '../store/userContext';
import { AmplifyConfigurationModel } from '../../models/AmplifyConfigurationModel';
import { User } from '../../types';
import { AccessType } from '../../models/FeatureAccessGroupsAggregate/AccessType';
import { ReportsService } from '../../services/ReportsService';
import { ReportAccessType } from '../../models/FeatureAccessGroupsAggregate/ReportAccessType';

interface Props {
  children: React.ReactChild;
  client?: ApolloClient<NormalizedCacheObject>;
  config: AmplifyConfigurationModel;
}
export type CurrentUser = User | null | undefined;

const WithUser: React.FC<Props> = ({ children, config }) => {
  const [currentUser, setCurrentUser] = useState<CurrentUser>();
  const [isUserLoaded, setIsUserLoaded] = useState<boolean>(false);
  const reportService = new ReportsService(config.workSpaceId, config.environment, config.version);

  const logOut = async () => {
    const app: Realm.App = new Realm.App({ id: config.realmAppId });

    try {
      setIsUserLoaded(false);

      await app.currentUser?.logOut();
      reportService.resetCache();
      await Auth.signOut();
    } finally {
      setCurrentUser(null);
      setIsUserLoaded(true);
    }
  };

  const updateUserData = async () => {
    try {
      const user = (await Auth.currentAuthenticatedUser()) as User;
      setCurrentUser(user);
    } catch (error) {
      setCurrentUser(null);
      console.log('user is not authorized');
    }
    setIsUserLoaded(true);
  };

  const createUserContext = (): UserContextProps => {
    const idToken = currentUser?.signInUserSession.idToken;
    const userManagementAccessType = Number(idToken?.payload['userManagement:accessType'] ?? AccessType.None);
    const dataAuthorizationManagementAccessType = Number(
      idToken?.payload['dataAuthorizationManagement:accessType'] ?? AccessType.None
    );
    const featureAccessManagementAccessType = Number(
      idToken?.payload['featureAccessManagement:accessType'] ?? AccessType.None
    );
    const scheduledReportsManagementAccessType = Number(
      idToken?.payload['scheduledReportsManagement:accessType'] ?? AccessType.None
    );
    const ticketsManagementAccessType = Number(
      idToken?.payload['ticketsManagement:accessType'] ?? AccessType.None
    );
    const reportsAccessType = Number(
      idToken?.payload['reportManagement:accessType'] ?? ReportAccessType.None
    );
    return {
      ...defaultUserContextValue,
      user: currentUser,
      isUserLoaded: isUserLoaded,
      setCurrentUser,
      username: idToken?.payload['cognito:username'] || '',
      isScheduledReportsManagementHasReadAccess: scheduledReportsManagementAccessType === AccessType.Read,
      isScheduledReportsManagementHasReadWriteAccess:
        scheduledReportsManagementAccessType === AccessType.ReadWrite,
      isUserManagementHasReadAccess: userManagementAccessType === AccessType.Read,
      isUserManagementHasReadWriteAccess: userManagementAccessType === AccessType.ReadWrite,
      isDataAuthorizationManagementReadAccess: dataAuthorizationManagementAccessType === AccessType.Read,
      isDataAuthorizationManagementReadWriteAccess:
        dataAuthorizationManagementAccessType === AccessType.ReadWrite,
      isFeatureAccessManagementReadAccess: featureAccessManagementAccessType === AccessType.Read,
      isFeatureAccessManagementReadWriteAccess: featureAccessManagementAccessType === AccessType.ReadWrite,
      isTicketsManagementHasReadAccess: ticketsManagementAccessType === AccessType.Read,
      isTicketsManagementHasReadWriteAccess: ticketsManagementAccessType === AccessType.ReadWrite,
      isReportsReadAccess: reportsAccessType !== ReportAccessType.None,
      isReportsExportAccess:
        reportsAccessType === ReportAccessType.ReadExport ||
        reportsAccessType === ReportAccessType.ReadWriteExport,
      email: currentUser?.attributes['email'] || '',
      firstName: idToken?.payload['user:firstName'] || '',
      lastName: idToken?.payload['user:lastName'] || '',
      workSpaceId: config.workSpaceId,
      environment: config.environment,
      version: config.version,
      apiBaseUrl: config.apiBaseUrl,
      config: config,
      recordLocatorsLimit: config.recordLocatorsLimit || 15,
      ticketNumbersLimit: config.ticketNumbersLimit || 100,
      logOut,
      updateUserData,
    };
  };

  return <userContext.Provider value={createUserContext()}>{children}</userContext.Provider>;
};

export { WithUser };

import React, { useContext, useCallback } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';

import { pathHome, pathProfile } from '../../routes/constants';
import { getTranslation } from '../../utils/getTranslation';
import Logo from '../../shared/view/Logo/Logo';
import { userContext } from '../../shared/store/userContext';

import './styles.scss';

export const getInitials = (firstName: string, lastName: string): string => {
  return (firstName[0] ?? '') + (lastName[0] ?? '') || '-';
};

const Header: React.FC = () => {
  const { lastName, firstName, logOut } = useContext(userContext);
  const history = useHistory();
  const onLogout = useCallback(async () => {
    await logOut();

    history.push(pathHome);
  }, [history, logOut]);

  const handleLogout = useCallback(() => void onLogout(), [onLogout]);

  return (
    <div className="header-container">
      <div className="container-headerLogo">
        <Logo />
      </div>
      <Dropdown
        text={getInitials(firstName, lastName)}
        icon={null}
        button
        className="user-logo"
        simple
        data-testid="header-user-dropdown"
      >
        <Dropdown.Menu className="left menu dropdown">
          <Dropdown.Item>
            <Link to={pathProfile}>{getTranslation('Header_Profile')}</Link>
          </Dropdown.Item>
          <Dropdown.Item onClick={handleLogout} data-testid="header-logout-dropdown-item">
            {' '}
            {getTranslation('Header_Logout')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Header;

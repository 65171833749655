import { GtrStatusValue } from '../../../api/graphql/models/TripTicket';
import {
  TripsTicketsSearchItemDTO,
  TripsTicketsSearchRequestFilter,
  TripsTicketsSearchRequestDTO,
} from '../../../api/rest/models/TripsTickets';
import { TicketFormViewModel, TripsTicketsSearchViewModel } from '../../../models/TripAggregate/TripsTickets';
import { COUNT_TICKETS_ITEMS_ON_PAGE } from './constants';

function toDate(value?: string): Date | null {
  if (!value) {
    return null;
  }

  const parsedDate = new Date(value);

  return isNaN(parsedDate.getTime()) ? null : parsedDate;
}

function checkIsFullTicketNumber(ticketNumber: string): boolean {
  return /^\d{13}[\d-]*$/.test(ticketNumber);
}

export const mapViewModelFromDTO = (data: TripsTicketsSearchItemDTO[]): TripsTicketsSearchViewModel[] => {
  const response: TripsTicketsSearchViewModel[] = [];

  data.forEach((trip) => {
    const tripTickets = trip.tickets.map((ticket): TripsTicketsSearchViewModel => {
      const traveler = trip.travelers?.find((traveler) => traveler.travelerNumber === ticket.travelerNumber);

      return {
        id: trip._id,
        ticketNumber: `${ticket.ticketStock ?? ''}${ticket.ticketNumber ?? ''}`,
        recordLocator: trip.identification?.recordLocator ?? '',
        gtrStatus: ticket.gtrStatus?.value ?? GtrStatusValue.Unknown,
        statusUpdateDate: toDate(ticket.gtrStatusUpdatedDate),
        isCancelled: ticket.isCancelled ?? null,
        accountId: trip.identification?.accountId ?? '',
        travelerFirstName: traveler?.firstName ?? '',
        travelerLastName: traveler?.lastName ?? '',
        ticketIssueDate: toDate(ticket.ticketIssueDate),
        expirationFromDate: new Date(trip.expirationFromDate),
      };
    });

    response.push(...tripTickets);
  });

  return response;
};

export const mapRequestDTOFromFormModel = (
  page: number,
  ticketFormModel: TicketFormViewModel
): TripsTicketsSearchRequestDTO => {
  const filterFromData = mapFilterFromFormModel(ticketFormModel);

  return {
    limit: COUNT_TICKETS_ITEMS_ON_PAGE,
    offset: page > 0 ? (page - 1) * COUNT_TICKETS_ITEMS_ON_PAGE : 0,
    filter: filterFromData,
  };
};

export const mapFilterFromFormModel = ({
  ticketNumber,
  travelerEmailAddress,
  recordLocator,
  firstName,
  lastName,
}: TicketFormViewModel): TripsTicketsSearchRequestFilter => {
  const isFullTicketNumber = checkIsFullTicketNumber(ticketNumber);

  return {
    ticketNumber: isFullTicketNumber ? ticketNumber.substring(3) : ticketNumber || undefined,
    ticketStock: isFullTicketNumber ? ticketNumber.substring(0, 3) : undefined,
    travelerEmailAddress: travelerEmailAddress || undefined,
    recordLocator: recordLocator || undefined,
    firstName: firstName || undefined,
    lastName: lastName || undefined,
  };
};

export const mapSearchParamsFromRequestDTO = (ticketFormModel: TicketFormViewModel): string => {
  const urlParams = new URLSearchParams();
  const tripsTicketsTableFilterData = mapFilterFromFormModel(ticketFormModel);

  Object.entries(tripsTicketsTableFilterData).forEach((filterItem: [string, string]) => {
    filterItem[1] && urlParams.append(filterItem[0], filterItem[1]);
  });

  return urlParams.toString();
};

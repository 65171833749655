import React from 'react';
import { PetUserDTO } from '../../../api/graphql/models/Users';
import {
  DEFAULT_PET_USERS_SORT_BY,
  DEFAULT_PET_USERS_SORT_ORDER,
} from '../../../features/AdministrationContainer/PetUsers/constants';
import { PetUserViewModel } from '../../../models/PetUsersAggregate/PetUser';
import { DEFAULT_PAGE } from '../../../pages/Administration/constant';
import { RequestStatus, API_REQUEST_STATUS } from '../../../utils/APIHandlers';
import { SortOrder } from '../../../utils/viewHelper';

export interface PetUsersSortingParams {
  sortBy: keyof PetUserDTO;
  sortOrder: SortOrder;
}

export interface PetUsersContextProps {
  getPetUsers: () => Promise<void>;
  petUsers: PetUserViewModel[];
  totalPetUsers: number;
  activePagePetUsers: number;
  requestPetUsersStatus: RequestStatus;
  setPetUsersActivePage: (page: number) => void;
  filterPetUsers: (search: string) => void;
  filterSearchString: string;
  sortPetUsers: (sortingParams: PetUsersSortingParams) => void;
  sortParams: PetUsersSortingParams;
}

export const defaultPetUsersContextValue: PetUsersContextProps = {
  getPetUsers: async () => new Promise(() => void 0),
  petUsers: [],
  totalPetUsers: 0,
  activePagePetUsers: DEFAULT_PAGE,
  requestPetUsersStatus: API_REQUEST_STATUS.NOT_REQUESTED,
  setPetUsersActivePage: () => void 0,
  filterPetUsers: () => void 0,
  filterSearchString: '',
  sortPetUsers: () => void 0,
  sortParams: {
    sortBy: DEFAULT_PET_USERS_SORT_BY,
    sortOrder: DEFAULT_PET_USERS_SORT_ORDER,
  },
};

const petUsersContext = React.createContext<PetUsersContextProps>(defaultPetUsersContextValue);

export { petUsersContext };

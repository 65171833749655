import { PetApi } from '../constants/apiParams';
import { ErrorFetchResponse, RestApiResponse } from '../api/rest/models/RestApiResponse';
import { SftpProvidersListDTO } from '../api/rest/models/SftpProviders';
import { APIFetch } from '../utils/APIHandlers';

export class SftpProvidersService {
  private readonly endpoint: string;

  constructor() {
    this.endpoint = '/v1/tickets/pet/exports/sftpproviders';
  }

  public async getSftpProviders(): Promise<RestApiResponse<SftpProvidersListDTO> | ErrorFetchResponse> {
    return await APIFetch<RestApiResponse<SftpProvidersListDTO>>('get', PetApi, this.endpoint, {});
  }
}

export const getFormattedTime = (date: Date): string => {
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const getFormattedDate = (date: Date): string => {
  const day = date.getDate();
  const month = date.toLocaleString('en-US', { month: 'short' });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

export const formatDateTimeUTC = (value: Date | string | null | undefined): string => {
  if (!value) {
    return '-';
  }
  return new Date(value).toUTCString();
};

export const getUTCStringFromDate = (date: Date): string => {
  return date.toUTCString();
};

export const getFormattedUTCString = (date: Date): string => {
  return getFormattedDate(getUTCDateFromDate(date));
};

export const setEndTime = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 999);

  return newDate;
};

export const setStartTime = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);

  return newDate;
};

export const getDateFromUTCDate = (date: Date): Date => {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )
  );
};

export const getUTCDateFromDate = (date: Date): Date => {
  return new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
};

import React, { useContext, useState, useRef, MutableRefObject } from 'react';
import { models, Report, Embed } from 'powerbi-client';
import { EventHandler, PowerBIEmbed } from 'powerbi-client-react';
import { Dimmer, Icon } from 'semantic-ui-react';
import * as pbi from 'powerbi-client';

import { userContext } from '../../shared/store/userContext';
import { ReactComponent as LoaderImage } from '../../assets/loaderImage.svg';
import { getTranslation } from '../../utils/getTranslation';
import { ReportsConfigsResponse } from '../../models/ReportsAggregate/ReportsConfigs';

import './styles.scss';

export interface BasicReportProps {
  embedUrl: string;
  token: string;
  updateToken: (props: GetReportsConfigProps) => Promise<ReportsConfigsResponse>;
  powerBiId: string;
  displayName: string;
  isReportLoaderShown?: boolean;
  reportClassName?: string;
}

interface GetReportsConfigProps {
  workSpaceId: string;
  environment: string;
  version: string;
}

const BasicReport: React.FC<BasicReportProps> = (props) => {
  const { isReportsExportAccess, workSpaceId, environment, version } = useContext(userContext);
  const [report, setReport] = useState<Report>();
  const [isReportFullScreen, setIsReportFullScreen] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState(props.isReportLoaderShown ?? true);
  const refContainer = useRef(report);
  refContainer.current = report;

  const [sampleReportConfig] = useState<models.IReportEmbedConfiguration>({
    type: 'report',
    tokenType: models.TokenType.Embed,
    permissions: models.Permissions.Read,
    viewMode: models.ViewMode.View,
    embedUrl: props.embedUrl,
    accessToken: props.token,
    settings: {
      panes: {
        pageNavigation: {
          visible: false,
        },
        filters: {
          expanded: false,
        },
      },
      visualSettings: {
        visualHeaders: [
          {
            settings: { visible: isReportsExportAccess },
          },
        ],
      },
      hideErrors: true,
    },
    theme: {
      themeJson: {
        name: 'Table theme',
        background: '#FFFFFF',
        foreground: '#19191A' /*text color*/,
        tableAccent: '#E1E3E6' /*border between header and table's body*/,
        secondLevelElements: '#19191A' /*text current Account*/,
      },
    },
  });

  const powerbi = new pbi.service.Service(
    pbi.factories.hpmFactory,
    pbi.factories.wpmpFactory,
    pbi.factories.routerFactory
  );

  const fullScreenReportHandler = () => {
    setIsReportFullScreen(!isReportFullScreen);
  };

  const eventHandler = new Map([
    [
      'error',
      async function (event: { detail: { message: string } }) {
        if (event.detail.message === 'TokenExpired') {
          const reportsConfig = await props.updateToken({ workSpaceId, environment, version });

          if (refContainer && reportsConfig) {
            const report = powerbi.get((refContainer as MutableRefObject<Report>).current.element);

            await report.setAccessToken(reportsConfig.embedToken.token);

            await report.reload();
          }
        }
      } as unknown as EventHandler,
    ],
    [
      'rendered',
      () => {
        setIsLoaded(false);
      },
    ],
  ]);

  const printReport = () => {
    if (refContainer) {
      const embedReport = powerbi.get((refContainer as MutableRefObject<Report>).current.element);

      void (embedReport as Report)?.print?.();
    }
  };

  return (
    <div
      className={`report-container ${props.reportClassName || ''} ${isLoaded ? 'blur-bg' : ''} ${
        isReportFullScreen ? 'fullscreen' : ''
      }`}
    >
      <div className="btn-container">
        {isReportsExportAccess && (
          <button className="sticky-btn" type="button" color="blue" onClick={printReport}>
            {getTranslation('Reports_Export')}
          </button>
        )}
        <Icon name={isReportFullScreen ? 'compress' : 'expand'} onClick={fullScreenReportHandler} />
      </div>
      {isLoaded && (
        <Dimmer active inverted>
          <LoaderImage />
        </Dimmer>
      )}
      <PowerBIEmbed
        embedConfig={sampleReportConfig}
        cssClassName="report-style-class"
        eventHandlers={eventHandler}
        getEmbeddedComponent={(embedObject: Embed) => setReport(embedObject as Report)}
      />
    </div>
  );
};

export default BasicReport;

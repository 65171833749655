import React, { useState } from 'react';
import { Label, Segment } from 'semantic-ui-react';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';

import {
  JobStatusExportJobValue,
  JobTypeExportJobValue,
} from '../../../models/ExportJobsAggregate/ExportJobHistory';
import { ExportJobHistoryDTO, ExportJobHistoryListDTO } from '../../../api/graphql/models/ExportJob';
import { getUTCStringFromDate } from '../../../utils/formatDate';
import TextButton from '../../../shared/controls/TextButton';
import { getTranslation } from '../../../utils/getTranslation';
import { MAX_MESSAGE_LENGTH, colorExportJobHistoryStatus } from './constants';
import './styles.scss';

interface ExportJobHistoryListProps {
  exportJobHistoryData: ExportJobHistoryListDTO;
}

export const getCroppedMessage = (message: string, maxLength: number): string => {
  return `${message.slice(0, maxLength)}...`;
};

export const isOpenExportJobMessage = (array: string[], id: string): boolean => {
  return array.includes(id);
};

export const croppedMessage = (message: string, isClosed: boolean): string => {
  return (
    (message.length > MAX_MESSAGE_LENGTH && isClosed && getCroppedMessage(message, MAX_MESSAGE_LENGTH)) ||
    message
  );
};

const ExportJobHistoryList: React.FC<ExportJobHistoryListProps> = (props) => {
  const { exportJobHistoryData } = props;
  const [openedExportJobs, setOpenedExportJobs] = useState<string[]>([]);

  const closeExportJobMessage = (itemId: string): void => {
    setOpenedExportJobs(openedExportJobs.filter((id) => id !== itemId));
  };

  const openExportJobMessage = (itemId: string): void => {
    setOpenedExportJobs([...openedExportJobs, itemId]);
  };

  return (
    <>
      {exportJobHistoryData.map((item: ExportJobHistoryDTO) => {
        return (
          <Segment
            raised
            className="jobHistoryExportJobItemContent"
            key={item._id}
            data-testid="jobHistoryItem"
            color={colorExportJobHistoryStatus[item.status] as SemanticCOLORS}
          >
            <div className="container-jobHistoryTime">
              <p className="jobHistoryTime" data-testid="exportJobHistoryTime">
                <span>{item.startDateTime && getUTCStringFromDate(new Date(item.startDateTime) || '-')}</span>
                <span className="container-JobHistorySeporator">&nbsp;-&nbsp;</span>
                <span>{item.endDateTime && getUTCStringFromDate(new Date(item.endDateTime) || '-')}</span>
              </p>

              <div className="container-jobHistoryStatus">
                <Label
                  color={colorExportJobHistoryStatus[item.status] as SemanticCOLORS}
                  data-testid="jobHistoryStatus"
                >
                  {JobStatusExportJobValue[item.status]}
                </Label>
              </div>
            </div>

            <div className="container-jobHistoryType">
              <h2 className="jobHistoryType" data-testid="jobHistoryType">
                <span>{JobTypeExportJobValue[item.type]}</span>
              </h2>
            </div>

            {item?.resultMessage && (
              <div className="container-jobHistoryMessage">
                <p>
                  <span className="jobHistoryMessage" data-testid="jobHistoryMessage">
                    {croppedMessage(item.resultMessage, !isOpenExportJobMessage(openedExportJobs, item._id))}
                  </span>
                  {item.resultMessage.length > MAX_MESSAGE_LENGTH && (
                    <span className="container-jobHistoryControl">
                      <TextButton
                        dataTestId="jobHistoryMessageControl"
                        onClick={() => {
                          isOpenExportJobMessage(openedExportJobs, item._id)
                            ? closeExportJobMessage(item._id)
                            : openExportJobMessage(item._id);
                        }}
                        text={getTranslation(
                          !isOpenExportJobMessage(openedExportJobs, item._id)
                            ? 'JobHistory_ExportJob_DetailsMessage_Control'
                            : 'JobHistory_ExportJob_HideDetailsMessage_Control'
                        )}
                      />
                    </span>
                  )}
                </p>
              </div>
            )}
          </Segment>
        );
      })}
    </>
  );
};

export default ExportJobHistoryList;

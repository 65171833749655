import React from 'react';
import { Header, Placeholder } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import CloseControl from '../../../../shared/controls/CloseControl';

import './styles.scss';

interface Props {
  goBackLink?: string;
  headerTitle?: string;
}

const FeatureAccessGroupLoader: React.FC<Props> = ({ goBackLink, headerTitle }) => {
  return (
    <div className="featureAccessGroupDetailsLoader">
      {goBackLink && (
        <Link to={goBackLink}>
          <CloseControl />
        </Link>
      )}
      {headerTitle && (
        <Header as="h1" data-testid="featureAccessGroupDetailsDetails-title">
          {headerTitle}
        </Header>
      )}

      <Placeholder>
        <Placeholder.Header>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Paragraph key="userManagementField">
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
        <Placeholder.Paragraph key="ticketsManagementField">
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
        <Placeholder.Paragraph key="scheduledReportsManagementField">
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
        <Placeholder.Paragraph key="reportsManagementField">
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
        <Placeholder.Paragraph key="reportsField">
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
      </Placeholder>
    </div>
  );
};

export default FeatureAccessGroupLoader;

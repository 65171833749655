import React from 'react';
import { RequestStatus, API_REQUEST_STATUS } from '../../utils/APIHandlers';
import { ExportJobDTO } from '../../api/graphql/models/ExportJob';

export interface ExportJobContextProps {
  exportJobData: ExportJobDTO | null;
  getExportJob: (id: string) => Promise<void>;
  clearExportJobData: () => void;
  requestExportJobStatus: RequestStatus;
}

export const defaultExportJobContextValue: ExportJobContextProps = {
  exportJobData: null,
  getExportJob: async () => new Promise(() => void 0),
  clearExportJobData: () => void 0,
  requestExportJobStatus: API_REQUEST_STATUS.NOT_REQUESTED,
};

const exportJobContext = React.createContext<ExportJobContextProps>(defaultExportJobContextValue);

export { exportJobContext };

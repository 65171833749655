import React, { useContext, useEffect } from 'react';
import { Header, Message } from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom';

import { getTranslation } from '../../../../utils/getTranslation';
import CloseControl from '../../../../shared/controls/CloseControl';
import { getRequestStatuses } from '../../../../utils/APIHandlers';
import TextButton from '../../../../shared/controls/TextButton/TextButton';
import FeatureAccessGroupDetails from './FeatureAccessGroupDetails';
import { featureAccessGroupContext } from '../../../../shared/store/FeatureAccessGroups/featureAccessGroupContext';
import { featureGroupKey, pathAdministrationFeatureGroups } from '../../../../routes/constants';
import FeatureAccessGroupLoader from '../FeatureAccessGroupLoader/FeatureAccessGroupLoader';
import EmptyMessage from '../../../../shared/view/EmptyMessage/EmptyMessage';
import { WithReports } from '../../../../shared/containers/withReports';
import { hasReportsManagementAccess } from '../../../../pages/Administration/helper';
import { userContext } from '../../../../shared/store/userContext';
import NoAccessMessageWrapper from '../FeatureAccessGroupsMessages/NoAccessMessageWrapper';

import './../../../PetForm/commonAuditInfoStyles.scss';

const FeatureAccessGroupDetailsWrapper: React.FC = () => {
  const {
    getFeatureAccessGroup,
    clearFeatureAccessGroupData,
    requestFeatureAccessGroupStatus,
    featureAccessGroupData,
  } = useContext(featureAccessGroupContext);
  const userContextValue = useContext(userContext);
  const requestFeatureAccessGroup = getRequestStatuses(requestFeatureAccessGroupStatus);
  const params = useParams<{ [featureGroupKey]?: string }>();
  const featureAccessGroupId = params[featureGroupKey];

  useEffect(() => {
    if (featureAccessGroupId) {
      clearFeatureAccessGroupData();
      void getFeatureAccessGroup(featureAccessGroupId);
    }
  }, [featureAccessGroupId]);

  return (
    <>
      {requestFeatureAccessGroup.REQUESTED && (
        <div className="auditInfo" data-testid="viewFeatureAccessGroupData-loader">
          <FeatureAccessGroupLoader
            goBackLink={pathAdministrationFeatureGroups}
            headerTitle={getTranslation('FeatureAccessGroups_Details')}
          />
        </div>
      )}
      {requestFeatureAccessGroup.REQUEST_FAILURE && (
        <div className="auditInfo" data-testid="errorLoadViewFeatureAccessGroupData-wrapper">
          <Link to={pathAdministrationFeatureGroups}>
            <CloseControl />
          </Link>
          <Header as="h1" data-testid="errorLoadViewFeatureAccessGroupData-title">
            {getTranslation('FeatureAccessGroups_Details')}
          </Header>
          <Message error data-testid="errorLoadViewFeatureAccessGroupData-message">
            <p>{getTranslation('Error')}</p>
            <TextButton
              text={getTranslation('Retry_Now')}
              onClick={() => featureAccessGroupId && void getFeatureAccessGroup(featureAccessGroupId)}
              dataTestId="errorLoadViewFeatureAccessGroupData-retryControl"
            />
          </Message>
        </div>
      )}

      {requestFeatureAccessGroup.REQUEST_SUCCESS && featureAccessGroupData && (
        <>
          {!hasReportsManagementAccess(userContextValue) && (
            <div className="auditMessage">
              <NoAccessMessageWrapper />
            </div>
          )}

          <div className="auditInfo" data-testid="featureAccessGroupDataView-wrapper">
            <WithReports>
              <FeatureAccessGroupDetails viewFeatureAccessGroup={featureAccessGroupData} />
            </WithReports>
          </div>
        </>
      )}

      {requestFeatureAccessGroup.REQUEST_SUCCESS && !featureAccessGroupData && (
        <div className="auditInfo" data-testid="featureAccessGroupDataView-empty-wrapper">
          <Link to={pathAdministrationFeatureGroups}>
            <CloseControl />
          </Link>
          <Header as="h1" data-testid="FeatureAccessGroupDetails-title">
            {getTranslation('FeatureAccessGroups_Details')}
          </Header>
          <EmptyMessage optionalText={getTranslation('No_FeatureAccessGroup')} />
        </div>
      )}
    </>
  );
};

export default FeatureAccessGroupDetailsWrapper;

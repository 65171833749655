export const pathProfile = '/profile';
export const pathTicketInfo = '/tickets/ticketInfo';
export const pathReports = '/reports';
export const pathReport = (id: string): string => `${pathReports}/${id}`;
export const pathHome = '/';
export const pathDocumentRepository = '/documentRepository';
export const pathSystem = '/system/ondemand';
export const pathAdministration = '/administration';
export const pathAdministrationReports = `${pathAdministration}/reports`;

export const pathAdministrationUsers = `${pathAdministration}/users`;
export const pathAdministrationUsersCreate = `${pathAdministrationUsers}/create`;
export const pathAdministrationUsersView = (id: string) => `${pathAdministrationUsers}/${id}`;
export const pathAdministrationUsersEdit = (id: string) => `${pathAdministrationUsers}/${id}/edit`;

export const pathAdministrationDataAuthRoles = `${pathAdministration}/dataAuthorization`;

export const pathAdministrationFeatureGroups = `${pathAdministration}/featureGroups`;
export const pathAdministrationFeatureGroupsCreate = `${pathAdministrationFeatureGroups}/create`;
export const pathAdministrationFeatureGroupsView = (id: string) => `${pathAdministrationFeatureGroups}/${id}`;
export const pathAdministrationFeatureGroupsEdit = (id: string) =>
  `${pathAdministrationFeatureGroups}/${id}/edit`;

export const pathHelp = '/help';
export const pathTickets = '/tickets';
export const pathExportJobsList = '/exportJobs';
export const pathCreateExportJob = `${pathExportJobsList}/create`;
export const pathExportJob = (id: string): string => `${pathExportJobsList}/${id}`;
export const pathHistoryExportJob = (id: string): string => `${pathExportJobsList}/${id}/history`;
export const pathGetScheduledReport = `/downloadReport`;

export const URL_PARAMS_SESSION_NAME = 'urlParams';
export const REDIRECT_URL_SESSION_NAME = 'redirectUrl';

export const reportNameKey = 'reportName';
export const petUserKey = 'petUserId';
export const featureGroupKey = 'featureGroupId';
export const ExportJobIdKey = 'exportJobId';
export const GetScheduledReportTokenKey = 'token';

import React, { useContext } from 'react';
import { Loader } from 'semantic-ui-react';

import GetReportMfaCodeForm from './GetReportMfaCodeForm';
import { API_REQUEST_STATUS } from './../../../../utils/APIHandlers';
import { getTranslation } from './../../../../utils/getTranslation';
import { getScheduledReportContext } from './../../../../shared/store/getScheduledReportContext';

import './styles.scss';

const VerifyGetReportMFACode: React.FC = () => {
  const { getScheduledReportStatus, getMFAGetScheduledReportStatus } = useContext(getScheduledReportContext);
  const isGetReportRequested = getScheduledReportStatus === API_REQUEST_STATUS.REQUESTED;
  const isGetCodeRequested = getMFAGetScheduledReportStatus === API_REQUEST_STATUS.REQUESTED;

  return (
    <>
      {isGetReportRequested && (
        <div className="container-mfaLoader" data-testid="mfaVerifyMFACodeLoader-wrapper">
          <Loader active size="large">
            {getTranslation('MFA_VerificationCode')}
          </Loader>
        </div>
      )}
      {isGetCodeRequested && (
        <div className="container-mfaLoader" data-testid="mfaVerifyTokenLoader-wrapper">
          <Loader active size="large">
            {getTranslation('MFA_WaitingCode')}
          </Loader>
        </div>
      )}
      {!isGetReportRequested && !isGetCodeRequested && (
        <div data-testid="getScheduledReportForm-wrapper">
          <GetReportMfaCodeForm />
        </div>
      )}
    </>
  );
};

export default VerifyGetReportMFACode;

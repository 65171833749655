import React from 'react';
import { EmbedTokenResponseDto } from '../../api/rest/models/EmbedTokenResponseAggregate/EmbedTokenResponseDto';
import { ReportDetailsDto } from '../../api/rest/models/EmbedTokenResponseAggregate/ReportDetailsDto';
import { ReportsConfigsResponse } from '../../models/ReportsAggregate/ReportsConfigs';
import { API_REQUEST_STATUS, RequestStatus } from '../../utils/APIHandlers';

export interface ReportPaneInterface {
  menuItem: {
    (): JSX.Element;
    displayName: string;
  };
  render: {
    (): JSX.Element;
    displayName: string;
  };
  displayName: string;
  id: number;
}
export type ReportPanesType = ReportPaneInterface[] | undefined;

export interface ReportsPageContextProps {
  getReportsConfig: () => Promise<ReportsConfigsResponse>;
  paneFactory: (reportDetails: ReportDetailsDto, token: string, index: number) => void;
  openReport: () => void;
  reportsConfig: EmbedTokenResponseDto | null;
  requestReportsStatus: RequestStatus;
  activeReportIndex: number | undefined;
  isReportExist: boolean | null;
  allPanes: ReportPanesType;
}

export const defaultReportsPageContextValue: ReportsPageContextProps = {
  getReportsConfig: async () => new Promise(() => void 0),
  paneFactory: () => void 0,
  openReport: () => void 0,
  reportsConfig: null,
  requestReportsStatus: API_REQUEST_STATUS.NOT_REQUESTED,
  activeReportIndex: 0,
  isReportExist: null,
  allPanes: undefined,
};

const reportsPageContext = React.createContext<ReportsPageContextProps>(defaultReportsPageContextValue);

export { reportsPageContext };

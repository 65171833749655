import React, { useContext, useState } from 'react';
import { NormalizedCacheObject, ApolloClient } from '@apollo/client';

import { WithApolloClient } from '../../../features/WithApolloClient/WithApolloClient';
import { API_REQUEST_STATUS, RequestStatus } from '../../../utils/APIHandlers';
import { userContext } from '../../store/userContext';
import { PetUserViewModel } from '../../../models/PetUsersAggregate/PetUser';
import { PetUsersService } from '../../../services/PetUsersService';
import { mapPetUser } from '../../../features/AdministrationContainer/PetUsers/petUsersMapper';
import {
  defaultPetUserContextValue,
  PetUserContextProps,
  petUserContext,
} from '../../store/PetUser/petUserContext';

interface Props {
  children: React.ReactChild;
  client?: ApolloClient<NormalizedCacheObject>;
}

const PetUser: React.FC<Props> = ({ children, client }) => {
  const { apiBaseUrl } = useContext(userContext);
  const [petUser, setPetUser] = useState<PetUserViewModel | null>(defaultPetUserContextValue.petUser);
  const [requestPetUserStatus, setRequestPetUserStatus] = useState<RequestStatus>(
    API_REQUEST_STATUS.NOT_REQUESTED
  );

  const getPetUser = async (id: string) => {
    try {
      const clientApollo = client as ApolloClient<NormalizedCacheObject>;
      const userService: PetUsersService = new PetUsersService(clientApollo, apiBaseUrl);
      setRequestPetUserStatus(API_REQUEST_STATUS.REQUESTED);
      setPetUser(null);

      const response = await userService.getById(id);

      setRequestPetUserStatus(API_REQUEST_STATUS.REQUEST_SUCCESS);
      setPetUser(response && mapPetUser(response));
    } catch (error) {
      console.error(error);
      setRequestPetUserStatus(API_REQUEST_STATUS.REQUEST_FAILURE);
    }
  };

  const getPetUserContext = (): PetUserContextProps => {
    return {
      ...defaultPetUserContextValue,
      getPetUser,
      petUser,
      requestPetUserStatus,
    };
  };

  return <petUserContext.Provider value={getPetUserContext()}>{children}</petUserContext.Provider>;
};

const WithPetUser = WithApolloClient(PetUser);

export { WithPetUser };

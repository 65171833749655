import React from 'react';

import './styles.scss';

interface CustomLabelProps {
  text: string;
  color?: string;
  dataTestId?: string;
}

const CustomLabel: React.FC<CustomLabelProps> = ({ text, color, dataTestId }) => {
  return (
    <span className={`customLabel ${color ?? ''}`} data-testid={dataTestId}>
      {text}
    </span>
  );
};

export default CustomLabel;

import React from 'react';

import { GtrStatusValue } from '../../../api/graphql/models/TripTicket';
import { getTicketStatus } from '../../../features/TripContainer/ViewTrip/TicketCard/helper';
import { getTranslation } from '../../../utils/getTranslation';
import CustomLabel from '../CustomLabel/CustomLabel';

interface GtrStatusLabelProps {
  gtrStatus: GtrStatusValue;
  dataTestId?: string;
}

const GtrStatusLabel: React.FC<GtrStatusLabelProps> = ({ gtrStatus, dataTestId }) => {
  const status = getTicketStatus[gtrStatus] ?? getTicketStatus[GtrStatusValue.Unknown];

  return <CustomLabel text={getTranslation(status.text)} color={status.bgColor} dataTestId={dataTestId} />;
};

export default GtrStatusLabel;

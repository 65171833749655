import * as React from 'react';
import { FunctionComponent } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import Private from '../Private';

interface PrivateRouteProps extends RouteProps {
  component: FunctionComponent<any> | React.ComponentClass<any>;
  isAllow: boolean;
  componentProps?: { [key: string]: string };
}

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
  const { component: Component, isAllow, componentProps, ...rest } = props;

  return (
    <Route {...rest} render={() => (isAllow ? <Component {...componentProps} {...rest} /> : <Private />)} />
  );
};

export default PrivateRoute;

import { CreatePetUserDTO, PetUserDTO, UpdatePetUserDTO } from '../../../api/graphql/models/Users';
import {
  PetUserFormViewModel,
  PetUserGridViewModel,
  PetUserViewModel,
} from '../../../models/PetUsersAggregate/PetUser';

export const mapUpdatePetUser = (data: PetUserFormViewModel, userEmail: string): UpdatePetUserDTO => ({
  ...data,
  _id: data.id ?? '',
  createdBy: data.createdBy ?? '',
  createdDate: (data.createdDate && data.createdDate.toISOString()) || '',
  updatedBy: userEmail,
  updatedDate: new Date().toISOString(),
});

export const mapCreatePetUser = (data: PetUserFormViewModel, userEmail: string): CreatePetUserDTO => ({
  firstName: data.firstName,
  lastName: data.lastName,
  email: data.email,
  dataAuthRoleId: data.dataAuthRoleId,
  featureAccessGroupId: data.featureAccessGroupId,
  isEnabled: data.isEnabled,
  isFederated: data.isFederated,
  createdBy: userEmail,
  createdDate: new Date().toISOString(),
});

export const mapPetUserFormFields = (data: PetUserViewModel): PetUserFormViewModel => ({
  id: data.id,
  firstName: data.firstName,
  lastName: data.lastName,
  email: data.email,
  dataAuthRoleId: data.dataAuthRole?._id ?? '',
  featureAccessGroupId: data.featureAccessGroup?._id ?? '',
  isEnabled: data.isEnabled,
  isFederated: data.isFederated,
  userName: data.userName,
  createdBy: data.createdBy,
  createdDate: data.createdDate,
  updatedBy: data.updatedBy,
  updatedDate: data.updatedDate,
});

export const mapPetUser = (data: PetUserDTO): PetUserViewModel => ({
  id: data._id,
  firstName: data.firstName,
  lastName: data.lastName,
  email: data.email,
  dataAuthRole: data.dataAuthRoleId,
  featureAccessGroup: data.featureAccessGroupId,
  isEnabled: data.isEnabled,
  isFederated: data.isFederated,
  userName: data.userName ?? '',
  createdBy: data.createdBy,
  createdDate: new Date(data.createdDate),
  updatedBy: data.updatedBy,
  updatedDate: (data?.updatedDate && new Date(data.updatedDate)) || undefined,
});

export const mapPetUsers = (data: PetUserDTO[]): PetUserViewModel[] => data.map((item) => mapPetUser(item));

export const mapGridPetUser = (data: PetUserViewModel): PetUserGridViewModel => ({
  id: data.id,
  name: `${data.firstName} ${data.lastName}`,
  email: data.email,
  dataAuthRole: data.dataAuthRole?.roleName ?? '',
  featureAccessGroup: data.featureAccessGroup?.groupName ?? '',
  isEnabled: data.isEnabled,
  isFederated: data.isFederated,
});

export const mapGridPetUsers = (data: PetUserViewModel[]): PetUserGridViewModel[] =>
  data.map((item) => mapGridPetUser(item));

import { Icon } from 'semantic-ui-react';
import React, { ReactElement } from 'react';
import { RestrictionFilter } from './../../../../models/DataAuthorizationRolesAggregate/RestrictionFilter';
import { maxCellLength } from './../constants';
import { getTranslation } from './../../../../utils/getTranslation';

interface Props {
  restrictionFilter: RestrictionFilter;
}

const DataAuthTableCell: React.FC<Props> = ({ restrictionFilter }: Props) => {
  let cellValue = '';

  if (restrictionFilter?.values) {
    const stringValue = restrictionFilter?.values?.join(', ');

    if (stringValue.length > maxCellLength) {
      let str = '';
      let count = 0;
      const valuesArr = restrictionFilter?.values;

      while (valuesArr[count] && str.length + valuesArr[count].length < maxCellLength) {
        str += `${valuesArr[count]}, `;
        count++;
      }

      cellValue = `${str}...+${valuesArr?.length - count}`;
    } else {
      cellValue = stringValue;
    }
  }

  let isInclusiveText: false | ReactElement | string;

  if (restrictionFilter?.values?.length) {
    isInclusiveText = typeof restrictionFilter?.isInclusive === 'boolean' && (
      <Icon name={`${restrictionFilter?.isInclusive ? 'check' : 'times'} circle outline`} />
    );
  } else {
    isInclusiveText = getTranslation(
      restrictionFilter?.isInclusive ? 'DataAuthTableCell_None' : 'DataAuthTableCell_All'
    );
  }

  return (
    <>
      {cellValue} <b>{isInclusiveText}</b>
    </>
  );
};

export default DataAuthTableCell;

import { gql } from '@apollo/client';

export const GET_BY_ID = gql`
  query ($id: String) {
    canonicals(query: { _id: $id }) {
      identification {
        accountCountryCode
        accountId
        creationOfficeId
        customerName
        customerNumber
        gds
        globalCustomerNumber
        isCancelled
        pseudoCityCode
        recordLocator
      }
      createdBy
      createdDate
      expirationFromDate
      numericId
      updatedBy
      updatedDate
      tickets {
        creditAmount {
          amount
          currency
          description
        }
        expiryDate
        fareAmount
        fareCalculation
        fareCurrency
        invoiceNumber
        isConjunctive
        isRefundable
        lowFareReasonCode
        ticketBranchPcc
        ticketIssueDate
        ticketStock
        ticketNumber
        tourCode
        endorsements
        travelerNumber
        validatingCarrier
        formOfPayments {
          paymentNumber
        }
        gtrStatus {
          value
        }
        fares {
          amount
          currency
          description
        }
        taxes {
          fare {
            amount
            currency
            description
          }
        }
        exchangeInfo {
          newTicket {
            ticketStock
            ticketNumber
            recordLocator
            lastName
            issueDate
            firstName
            expiryDate
          }
          originalTicket {
            amount {
              amount
              currency
              description
            }
            ticketNumber
            ticketStock
            recordLocator
            issueDate
          }
        }
        coupons {
          departureDateTime
          departureAirportCode
          departureAirportCountryCode
          arrivalDateTime
          arrivalAirportCode
          arrivalAirportCountryCode
          marketingFlightNumber
          vendorLocator
          ticketDesignator
          couponStatus
          fareBasisCode
          classOfService
          classOfServiceDescription
        }
      }
      travelers {
        firstName
        lastName
        middleName
        primaryEmailAddress
        travelerNumber
      }
    }
  }
`;

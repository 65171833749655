import React from 'react';
import {
  FeatureAccessGroupFormViewModel,
  FeatureAccessGroupViewModel,
} from '../../../models/FeatureAccessGroupsAggregate/FeatureAccessGroup';

import { RequestStatus, API_REQUEST_STATUS } from '../../../utils/APIHandlers';

export interface UpdateFeatureAccessGroupContextProps {
  updateFeatureAccessGroup: (data: FeatureAccessGroupFormViewModel) => Promise<string | undefined | void>;
  requestUpdateFeatureAccessGroupMessage: string;
  requestUpdateFeatureAccessGroupStatus: RequestStatus;
  clearUpdateFeatureAccessGroupState: () => void;

  setFeatureAccessGroupToUpdate: (data: FeatureAccessGroupViewModel | null) => void;
  clearFeatureAccessGroupToUpdate: () => void;
  petUserToUpdate: FeatureAccessGroupViewModel | null;
}

export const defaultUpdateFeatureAccessGroupContextValue: UpdateFeatureAccessGroupContextProps = {
  updateFeatureAccessGroup: async () => new Promise(() => void 0),
  requestUpdateFeatureAccessGroupMessage: '',
  requestUpdateFeatureAccessGroupStatus: API_REQUEST_STATUS.NOT_REQUESTED,
  clearUpdateFeatureAccessGroupState: () => void 0,

  setFeatureAccessGroupToUpdate: () => void 0,
  clearFeatureAccessGroupToUpdate: () => void 0,
  petUserToUpdate: null,
};

const updateFeatureAccessGroupContext = React.createContext<UpdateFeatureAccessGroupContextProps>(
  defaultUpdateFeatureAccessGroupContextValue
);

export { updateFeatureAccessGroupContext };

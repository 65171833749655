import { DownloadReadableStreamDTO, GetDocumentRequestDTO } from '../api/rest/models/Document';

export class DownloadDocumentService {
  public getDocument(payload: GetDocumentRequestDTO): Promise<Response> {
    return fetch(payload.fileLink, {
      method: 'GET',
      cache: 'no-cache',
    });
  }
  public handleDownloadReadableStream = (payload: DownloadReadableStreamDTO): void => {
    const url = window.URL.createObjectURL(payload.blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = payload.fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };
}

import React from 'react';
import { Button, Form, Radio } from 'semantic-ui-react';
import { UseFormGetValues } from 'react-hook-form/dist/types/form';
import { isMobile } from 'react-device-detect';

import { FormatValue, FormatExportJob } from '../../../../models/ExportJobsAggregate/ExportJobFormat';
import FormFieldLabel from '../../../../shared/view/FormFieldLabel/FormFieldLabel';
import { ExportJobFormViewModel } from '../../../../models/ExportJobsAggregate/ExportJobForm';

interface ReportTypeFieldProps {
  onChange: (value: FormatExportJob) => void;
  getValues: UseFormGetValues<ExportJobFormViewModel>;
  label: string;
}

const ReportTypeField: React.FC<ReportTypeFieldProps> = (props) => {
  const { onChange, getValues, label } = props;

  return (
    <Form.Field>
      <FormFieldLabel required>{label}</FormFieldLabel>

      <div className="container-reportTypeControlsList" data-testid="exportJob-reportType-field">
        {Object.keys(FormatValue).map((key) => {
          const value: FormatExportJob = parseInt(key);
          const isActive = getValues('format') === value;

          return isMobile ? (
            <Radio
              key={parseInt(key)}
              label={FormatValue[value]}
              checked={isActive}
              onClick={
                !isActive
                  ? () => {
                      onChange(value);
                    }
                  : undefined
              }
              data-testid={
                isActive ? 'exportJob-reportType-activeControl-field' : 'exportJob-reportType-control-field'
              }
            />
          ) : (
            <Button
              type="button"
              key={parseInt(key)}
              size="small"
              color={isActive ? 'blue' : 'grey'}
              data-testid={
                isActive ? 'exportJob-reportType-activeControl-field' : 'exportJob-reportType-control-field'
              }
              onClick={
                !isActive
                  ? () => {
                      onChange(value);
                    }
                  : undefined
              }
            >
              {FormatValue[value]}
            </Button>
          );
        })}
      </div>
    </Form.Field>
  );
};

export default ReportTypeField;

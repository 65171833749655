import React, { useContext } from 'react';
import { Button, Icon, Message } from 'semantic-ui-react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';

import { WithApolloClient } from './../../WithApolloClient/WithApolloClient';
import { getTranslation } from './../../../utils/getTranslation';
import { userContext } from './../../../shared/store/userContext';
import { paginatedFeatureAccessGroupsContext } from '../../../shared/store/FeatureAccessGroups/paginatedFeatureAccessGroupsContext';
import { getRequestStatuses } from '../../../utils/APIHandlers';
import FeatureAccessGroupsTable from './FeatureAccessGroupsTable/FeatureAccessGroupsTable';
import { createFeatureAccessGroupContext } from '../../../shared/store/FeatureAccessGroups/createFeatureAccessGroupContext';
import { updateFeatureAccessGroupContext } from '../../../shared/store/FeatureAccessGroups/updateFeatureAccessGroupContext';
import {
  featureGroupKey,
  pathAdministrationFeatureGroups,
  pathAdministrationFeatureGroupsCreate,
  pathAdministrationFeatureGroupsEdit,
  pathAdministrationFeatureGroupsView,
} from '../../../routes/constants';
import CreateFeatureAccessGroupWrapper from './FeatureAccessGroupsForm/CreateFeatureAccessGroupWrapper';
import { WithFeatureAccessGroup } from '../../../shared/containers/FeatureAccessGroups/withFeatureAccessGroup';
import EditFeatureAccessGroupWrapper from './FeatureAccessGroupsForm/EditFeatureAccessGroupWrapper';
import FeatureAccessGroupDetailsWrapper from './FeatureAccessGroupDetails/FeatureAccessGroupDetailsWrapper';

import './styles.scss';

const FeatureAccessGroups: React.FC = () => {
  const { isFeatureAccessManagementReadWriteAccess } = useContext(userContext);
  const { requestFeatureAccessGroupsStatus } = useContext(paginatedFeatureAccessGroupsContext);
  const { requestCreateFeatureAccessGroupStatus, requestCreateFeatureAccessGroupMessage } = useContext(
    createFeatureAccessGroupContext
  );
  const { requestUpdateFeatureAccessGroupStatus, requestUpdateFeatureAccessGroupMessage } = useContext(
    updateFeatureAccessGroupContext
  );
  const requestFeatureAccessGroups = getRequestStatuses(requestFeatureAccessGroupsStatus);
  const requestCreateFeatureAccessGroup = getRequestStatuses(requestCreateFeatureAccessGroupStatus);
  const requestUpdateFeatureAccessGroup = getRequestStatuses(requestUpdateFeatureAccessGroupStatus);
  const history = useHistory();

  return (
    <div className="administration-tab featureAccessGroups" data-testid="administration-featureAccess-tab">
      <div className="buttons-container">
        {requestCreateFeatureAccessGroup.REQUEST_SUCCESS && (
          <Message
            success
            content={requestCreateFeatureAccessGroupMessage}
            data-testid="createFeatureAccessGroupResponse-message"
          />
        )}
        {requestUpdateFeatureAccessGroup.REQUEST_SUCCESS && (
          <Message
            success
            content={requestUpdateFeatureAccessGroupMessage}
            data-testid="updateFeatureAccessGroupResponse-message"
          />
        )}

        {isFeatureAccessManagementReadWriteAccess && (
          <Button
            as={Link}
            to={pathAdministrationFeatureGroupsCreate}
            primary
            size="small"
            disabled={
              !requestFeatureAccessGroups.REQUEST_SUCCESS ||
              history.location.pathname !== pathAdministrationFeatureGroups
            }
            data-testid="createFeatureAccessGroup-control"
          >
            <Icon name="plus circle" /> {getTranslation('FeatureAccessGroupsForm_AddFeatureGroup')}
          </Button>
        )}
      </div>
      <div className="administration-tab-container">
        <div className="administration-tabContent-container">
          <FeatureAccessGroupsTable />
        </div>

        <div className="administration-tabSidebar-container">
          <Switch>
            <Route
              path={[pathAdministrationFeatureGroupsCreate]}
              exact
              component={CreateFeatureAccessGroupWrapper}
            />

            <Route
              path={[
                pathAdministrationFeatureGroupsEdit(`:${featureGroupKey}`),
                pathAdministrationFeatureGroupsCreate,
              ]}
              exact
            >
              <WithFeatureAccessGroup>
                <EditFeatureAccessGroupWrapper />
              </WithFeatureAccessGroup>
            </Route>

            <Route path={pathAdministrationFeatureGroupsView(`:${featureGroupKey}`)}>
              <WithFeatureAccessGroup>
                <FeatureAccessGroupDetailsWrapper />
              </WithFeatureAccessGroup>
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default WithApolloClient(FeatureAccessGroups);

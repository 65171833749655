import React from 'react';
import { Icon } from 'semantic-ui-react';

import {
  ReportIconValue,
  ReportTypeColor,
  ReportTypeValue,
} from './../../../api/graphql/models/ReportsResponse';
import { ReportViewModel } from './../../../models/ReportsAggregate/Report';
import { getTranslation } from './../../../utils/getTranslation';
import { AdministrationTableBodyColumns } from './../../AdministrationTable/AdministrationTable';
import TableLabel from './../../TableLabel';

export const getReportHeaderColumns = (isReadWriteAccess: boolean): string[] => {
  const defaultCols: string[] = [
    '',
    getTranslation('Reports_DisplayName'),
    getTranslation('Reports_ReportName'),
    getTranslation('Reports_Type'),
    getTranslation('Reports_Paginated'),
    getTranslation('Reports_Description'),
  ];

  return (isReadWriteAccess && [...defaultCols, '']) || defaultCols;
};

export const getReportBodyColumns = (row: ReportViewModel): AdministrationTableBodyColumns => {
  const { displayName, reportName, reportType, icon, isPaginated, description } = row;
  const isPaginatedText = getTranslation(isPaginated ? 'Yes' : 'No');
  return {
    icon: <Icon name={ReportIconValue[icon]} className="reportIcon" />,
    displayName: displayName,
    reportName: reportName,
    reportType: (
      <TableLabel text={getTranslation(ReportTypeValue[reportType])} roundBg={ReportTypeColor[reportType]} />
    ),
    isPaginated: <TableLabel key="paginated" text={isPaginatedText} active={isPaginated} />,
    description: description,
  };
};

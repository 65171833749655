import React, { Dispatch, SetStateAction, useEffect, useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Button, Form, Header } from 'semantic-ui-react';
import { CustomCognitoUser } from '../types';
import { useForm } from 'react-hook-form';
import InputWithInfo from '../../PetForm/InputWithInfo';
import { getTranslation } from '../../../utils/getTranslation';
import { getEmailPattern, getRequiredFieldRegister } from '../../PetForm/helper';
import ChangePassword from '../ChangePassword';
import { getUIErrorMessage } from '../../../utils/errorHandler';
import { REDIRECT_URL_SESSION_NAME, URL_PARAMS_SESSION_NAME } from '../../../routes/constants';
import {
  ERROR_DESCRIPTION_NAME,
  getHashItem,
  isHashHasErrorsFields,
  isHashHasSuccessField,
  removeSessionStorageField,
} from './helper';
import FullSizeLoader from '../../../shared/view/Loader/FullSizeLoader';
import TextButton from '../../../shared/controls/TextButton/TextButton';
import FederatedSignIn from '../FederatedSignIn/FederatedSignIn';

interface Props {
  setUser: Dispatch<SetStateAction<any | null | undefined>>;
}

const SignIn = ({ setUser }: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailLogin, setIsEmailLogin] = useState(false);
  const [federatedSignInErrorMessage, setFederatedSignInErrorMessage] = useState<boolean>(false);
  const [signInErrorMessage, setSignInErrorMessage] = useState<string>('');
  const [email, setEmail] = useState('');
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [userWithoutPassword, setUserWithoutPassword] = useState<CustomCognitoUser | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const requiredFieldRegister = getRequiredFieldRegister();
  const emailPattern = getEmailPattern();
  const history = useHistory();
  const pathname = history.location.pathname;

  useEffect((): (() => void) => {
    const session = sessionStorage.getItem(URL_PARAMS_SESSION_NAME);

    if (session && isHashHasErrorsFields(session)) {
      console.error(getHashItem(session, ERROR_DESCRIPTION_NAME));
      setFederatedSignInErrorMessage(true);
    } else if (session && isHashHasSuccessField(session)) {
      setIsLoading(true);
    }

    return () => removeSessionStorageField(URL_PARAMS_SESSION_NAME);
  }, []);

  const hideNotifications = useCallback(() => {
    removeSessionStorageField(URL_PARAMS_SESSION_NAME);
    setFederatedSignInErrorMessage(false);
    setSignInErrorMessage('');
  }, [federatedSignInErrorMessage, signInErrorMessage]);

  const signIn = async ({ email, password }: { email: string; password: string }) => {
    setIsLoading(true);

    try {
      const trimEmail = email.trim();

      setEmail(trimEmail);
      const user = (await Auth.signIn(trimEmail, password)) as CustomCognitoUser;

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setIsChangePassword(true);
        setUserWithoutPassword(user);
      } else {
        setUser(user);
      }
      setIsLoading(false);
    } catch (error: Error | unknown) {
      setSignInErrorMessage(getUIErrorMessage((error as Error)?.message, 'userOrPassword'));
      setIsLoading(false);
    }
  };

  const onSubmitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>): void => {
      hideNotifications();

      void handleSubmit(signIn)(event);
    },
    [signIn]
  );

  const federatedSignIn = useCallback(async () => {
    hideNotifications();
    setIsLoading(true);
    sessionStorage.setItem(REDIRECT_URL_SESSION_NAME, pathname);

    try {
      await Auth.federatedSignIn({ customProvider: 'BCD-AzureAD' });
    } catch (error: Error | unknown) {
      setIsLoading(false);
      setFederatedSignInErrorMessage(true);
      sessionStorage.removeItem(REDIRECT_URL_SESSION_NAME);
    }
  }, [pathname]);

  const handleFederatedSignIn = useCallback((): void => void federatedSignIn(), [federatedSignIn]);

  return (
    <>
      {!isChangePassword ? (
        <div data-testid="signIn-container">
          <Header as="h2">{getTranslation('Authentication_SignInHeader')}</Header>
          <Header as="h3">{getTranslation('Logo_Slogan')}</Header>

          {isEmailLogin && (
            <Form className="form" onSubmit={onSubmitForm} data-testid="emailSignIn-container">
              <InputWithInfo
                required
                label={getTranslation('PetUserForm_EmailLogin')}
                error={errors.email}
                register={register('email', {
                  ...requiredFieldRegister,
                  ...emailPattern,
                })}
                size={'large'}
                dataTestId="signIn-email-field"
              />

              <InputWithInfo
                required
                label={getTranslation('Authentication_Password')}
                error={errors.password}
                register={register('password', {
                  ...requiredFieldRegister,
                })}
                type={'password'}
                size={'large'}
                dataTestId="signIn-password-field"
              />
              {signInErrorMessage && (
                <div className="formError" data-testid="signIn-error-message">
                  {signInErrorMessage}
                </div>
              )}

              <Button primary type="submit" size="medium" fluid data-testid="signIn-btn">
                {getTranslation('Authentication_Login')}
              </Button>

              <div className="center-content">
                <Link to="/forgotPassword" className="link-text">
                  {getTranslation('Authentication_ForgotPassword')}
                </Link>
                <div className="separator">{getTranslation('Authentication_or')}</div>
                <FederatedSignIn
                  handleSignIn={handleFederatedSignIn}
                  isBasic
                  isErrorMessage={federatedSignInErrorMessage}
                />
              </div>
            </Form>
          )}

          {!isEmailLogin && (
            <div className="container-defaultSignIn" data-testid="defaultSignIn-container">
              <FederatedSignIn
                handleSignIn={handleFederatedSignIn}
                isErrorMessage={federatedSignInErrorMessage}
              />
              <div className="separator">
                <TextButton
                  text={getTranslation('Authentication_EmailSignIn')}
                  onClick={() => setIsEmailLogin(true)}
                  dataTestId="chooseEmailSignIn-control"
                />
              </div>
            </div>
          )}

          {isLoading && <FullSizeLoader dataTestId="signIn-form-loader" />}
        </div>
      ) : (
        <ChangePassword email={email} userWithoutPassword={userWithoutPassword} setUser={setUser} />
      )}
    </>
  );
};

export default SignIn;

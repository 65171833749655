export const DEFAULT_NUMBER_OF_ITEMS = 6;

export interface IsShowAllRestrictionItemsProps {
  numberDisplayedValues?: number;
  countResultItems: number;
}

export const isShowAllRestrictionItems = ({
  numberDisplayedValues,
  countResultItems,
}: IsShowAllRestrictionItemsProps): boolean => {
  return numberDisplayedValues
    ? countResultItems > numberDisplayedValues
    : countResultItems > DEFAULT_NUMBER_OF_ITEMS;
};

export interface GetRestrictionsToViewProps {
  numberDisplayedValues?: number;
  isResultOpen: boolean;
  restrictions: string[];
}

export const getRestrictionsToView = ({
  numberDisplayedValues,
  isResultOpen,
  restrictions,
}: GetRestrictionsToViewProps): string[] => {
  return isResultOpen
    ? restrictions
    : restrictions.slice(0, numberDisplayedValues || DEFAULT_NUMBER_OF_ITEMS);
};

export const searchRestrictions = (values: string[], searchString: string): string[] => {
  const re = new RegExp(searchString, 'i');

  return values.filter((restrictionFilterValue) => re.test(restrictionFilterValue));
};

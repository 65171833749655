import { gql } from '@apollo/client';

export const GET_ALL = gql`
  query ($limit: Int, $sortBy: ExportJobSortByInput) {
    exportJobs(limit: $limit, sortBy: $sortBy) {
      _id
      activeEndDate
      activeStartDate
      createdBy
      createdDate
      cronExpression
      dataAuthorisationRoleId {
        _id
        roleName
      }
      deliveryMethod
      enabled
      format
      jobName
      provider
      reportDetails {
        period {
          type
          offsetDays
        }
        reportId {
          _id
          displayName
          reportName
          isPaginated
        }
      }
      updatedBy
      updatedDate
      userEmails
    }
  }
`;

export const GET_JOB_DETAILS_BY_ID = gql`
  query ($limit: Int, $id: String, $sortBy: ExportJobRunDetailSortByInput) {
    exportJobRunDetails(query: { jobId: { _id: $id } }, limit: $limit, sortBy: $sortBy) {
      _id
      resultMessage
      startDateTime
      endDateTime
      status
      type
    }
  }
`;

export const GET_JOB_BY_ID = gql`
  query ($id: String) {
    exportJobs(query: { _id: $id }) {
      _id
      activeEndDate
      activeStartDate
      createdBy
      createdDate
      cronExpression
      dataAuthorisationRoleId {
        _id
        roleName
      }
      deliveryMethod
      enabled
      format
      jobName
      provider
      reportDetails {
        period {
          type
          offsetDays
        }
        reportId {
          _id
          displayName
          reportName
        }
      }
      updatedBy
      updatedDate
      userEmails
    }
  }
`;

export const DELETE_JOB_BY_ID = gql`
  query ($id: String) {
    response {
      id
    }
  }
`;
export const SEND_JOB_BY_ID = gql`
  query ($id: String) {
    response {
      id
    }
  }
`;

import React, { useContext, useState } from 'react';
import { NormalizedCacheObject, ApolloClient } from '@apollo/client';

import { WithApolloClient } from '../../../features/WithApolloClient/WithApolloClient';
import { userContext } from '../../store/userContext';
import {
  FeatureAccessGroupFormViewModel,
  FeatureAccessGroupViewModel,
} from '../../../models/FeatureAccessGroupsAggregate/FeatureAccessGroup';
import { useNotification, useNotificationReturn } from '../../hooks/notificationHook';
import { getTranslation } from '../../../utils/getTranslation';
import {
  UpdateFeatureAccessGroupContextProps,
  defaultUpdateFeatureAccessGroupContextValue,
  updateFeatureAccessGroupContext,
} from '../../store/FeatureAccessGroups/updateFeatureAccessGroupContext';
import { FeatureAccessGroupService } from '../../../services/FeatureAccessGroupService';
import { mapUpdateFeatureAccessGroup } from '../../../features/AdministrationContainer/FeatureAccessGroups/featureAccessGroupsMapper';
import { getUIErrorMessage } from './../../../utils/errorHandler';

interface Props {
  children: React.ReactChild;
  client?: ApolloClient<NormalizedCacheObject>;
}

const UpdateFeatureAccessGroup: React.FC<Props> = ({ children, client }) => {
  const { email } = useContext(userContext);
  const [petUserToUpdate, setFeatureAccessGroupToUpdate] = useState<FeatureAccessGroupViewModel | null>(null);
  const [requestUpdateFeatureAccessGroupMessage, setRequestUpdateFeatureAccessGroupMessage] =
    useState<string>(defaultUpdateFeatureAccessGroupContextValue.requestUpdateFeatureAccessGroupMessage);
  const [
    { notificationStatus: requestUpdateFeatureAccessGroupStatus },
    {
      setNotRequestedNotificationStatus: setNotRequestedUpdateFeatureAccessGroup,
      setRequestedNotificationStatus: setRequestedUpdateFeatureAccessGroup,
      setSuccessNotificationStatus: setSuccessUpdateFeatureAccessGroup,
      setFailNotificationStatus: setFailUpdateFeatureAccessGroup,
    },
  ]: useNotificationReturn = useNotification();

  const updateFeatureAccessGroup = async (
    data: FeatureAccessGroupFormViewModel
  ): Promise<string | undefined | void> => {
    try {
      const clientApollo = client as ApolloClient<NormalizedCacheObject>;
      const featureGroupsService: FeatureAccessGroupService = new FeatureAccessGroupService(clientApollo);
      setRequestedUpdateFeatureAccessGroup();

      await featureGroupsService.update(mapUpdateFeatureAccessGroup(data, email));

      setSuccessUpdateFeatureAccessGroup();
      setRequestUpdateFeatureAccessGroupMessage(
        `${getTranslation('FeatureAccessGroupsForm_FeatureGroup')} ${data.groupName} ${getTranslation(
          'successEditMessage'
        )}`
      );
    } catch (error) {
      setFailUpdateFeatureAccessGroup();

      return getUIErrorMessage(
        (error as Error)?.message,
        'duplicate',
        `${getTranslation('FeatureGroup')} ${data.groupName}`
      );
    }
  };

  const clearUpdateFeatureAccessGroupState = () => {
    setNotRequestedUpdateFeatureAccessGroup();
    setRequestUpdateFeatureAccessGroupMessage(
      defaultUpdateFeatureAccessGroupContextValue.requestUpdateFeatureAccessGroupMessage
    );
  };

  const clearFeatureAccessGroupToUpdate = () => {
    setFeatureAccessGroupToUpdate(null);
    setNotRequestedUpdateFeatureAccessGroup();
    setRequestUpdateFeatureAccessGroupMessage(
      defaultUpdateFeatureAccessGroupContextValue.requestUpdateFeatureAccessGroupMessage
    );
  };

  const getUpdateFeatureAccessGroupContext = (): UpdateFeatureAccessGroupContextProps => {
    return {
      ...defaultUpdateFeatureAccessGroupContextValue,
      updateFeatureAccessGroup,
      requestUpdateFeatureAccessGroupMessage,
      requestUpdateFeatureAccessGroupStatus,
      clearUpdateFeatureAccessGroupState,

      setFeatureAccessGroupToUpdate,
      clearFeatureAccessGroupToUpdate,
      petUserToUpdate,
    };
  };

  return (
    <updateFeatureAccessGroupContext.Provider value={getUpdateFeatureAccessGroupContext()}>
      {children}
    </updateFeatureAccessGroupContext.Provider>
  );
};

const WithUpdateFeatureAccessGroup = WithApolloClient(UpdateFeatureAccessGroup);

export { WithUpdateFeatureAccessGroup };

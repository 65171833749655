import React, { useContext, useEffect } from 'react';

import { getTranslation } from '../../../../utils/getTranslation';
import { mapPetUserFormFields } from '../petUsersMapper';
import { updatePetUserContext } from '../../../../shared/store/PetUser/updatePetUserContext';
import WarningModal from '../../../../shared/view/Modal/WarningModal';
import {
  getDeletePetUserControl,
  getDeletePetUserMessage,
  getToggleEnablePetUserControl,
  getToggleEnablePetUserMessage,
  getToggleEnablePetUserTitle,
} from '../helper';
import { getRequestStatuses } from '../../../../utils/APIHandlers';

import './styles.scss';

const PetUsersTableModals: React.FC = () => {
  const {
    updatePetUser,
    requestUpdatePetUserStatus,
    setPetUserToDelete,
    petUserToDelete,
    deletePetUser,
    clearPetUserToDelete,
    setPetUserToUpdate,
    clearPetUserToUpdate,
    petUserToUpdate,
    requestDeletePetUserStatus,
  } = useContext(updatePetUserContext);
  const requestUpdatePetUser = getRequestStatuses(requestUpdatePetUserStatus);
  const requestDeletePetUser = getRequestStatuses(requestDeletePetUserStatus);

  useEffect(() => {
    if (requestDeletePetUser.REQUEST_SUCCESS) {
      setPetUserToDelete(null);
    }
    if (requestUpdatePetUser.REQUEST_SUCCESS) {
      setPetUserToUpdate(null);
    }
  }, [requestUpdatePetUser, requestDeletePetUser, setPetUserToDelete, setPetUserToUpdate]);

  return (
    <>
      <WarningModal
        isOpen={Boolean(petUserToDelete)}
        onClose={clearPetUserToDelete}
        onConfirm={() => petUserToDelete && void deletePetUser(petUserToDelete)}
        titleText={getTranslation('Delete')}
        contentText={getDeletePetUserMessage(requestDeletePetUser.REQUEST_FAILURE, petUserToDelete)}
        textActionButton={getDeletePetUserControl(requestDeletePetUser.REQUEST_FAILURE)}
        isLoading={requestDeletePetUser.REQUESTED}
        dataTestId="petUsersDeleteModal"
      />

      <WarningModal
        isOpen={Boolean(petUserToUpdate)}
        onClose={clearPetUserToUpdate}
        onConfirm={() =>
          petUserToUpdate &&
          void updatePetUser(
            mapPetUserFormFields({ ...petUserToUpdate, isEnabled: !petUserToUpdate.isEnabled })
          )
        }
        titleText={getToggleEnablePetUserTitle(petUserToUpdate?.isEnabled)}
        contentText={getToggleEnablePetUserMessage(
          requestUpdatePetUser.REQUEST_FAILURE,
          petUserToUpdate?.isEnabled
        )}
        textActionButton={getToggleEnablePetUserControl(
          requestUpdatePetUser.REQUEST_FAILURE,
          petUserToUpdate?.isEnabled
        )}
        isLoading={requestUpdatePetUser.REQUESTED}
        dataTestId="petUsersUpdateEnableModal"
      />
    </>
  );
};

export default PetUsersTableModals;

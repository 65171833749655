import React from 'react';
import { RequestStatus, API_REQUEST_STATUS } from '../../utils/APIHandlers';
import { ExportJobDTO } from '../../api/graphql/models/ExportJob';
import { Location as HistoryLocation } from 'history';

export interface CreateExportJobContextProps {
  isOpenCreateCondition: boolean;
  exportJobData: ExportJobDTO | null;
  isEdit: boolean;
  createExportJob: () => Promise<void>;
  editJob: () => Promise<void>;
  requestCreateJobStatus: RequestStatus;
  notificationCreateJob: RequestStatus;
  handleOpenCreateJob: (input: ExportJobDTO, isEdit?: boolean) => void;
  handleCloseCreateJob: () => void;

  isOpenLeaveCondition: boolean;
  leaveDirection: string | null;
  handleOpenLeaveCreateJob: (direction: HistoryLocation) => void;
  handleCloseLeaveCreateJob: () => void;
}

export const defaultCreateExportJobContextValue: CreateExportJobContextProps = {
  isOpenCreateCondition: false,
  exportJobData: null,
  isEdit: false,
  createExportJob: async () => new Promise(() => void 0),
  editJob: async () => new Promise(() => void 0),
  requestCreateJobStatus: API_REQUEST_STATUS.NOT_REQUESTED,
  notificationCreateJob: API_REQUEST_STATUS.NOT_REQUESTED,
  handleOpenCreateJob: () => void 0,
  handleCloseCreateJob: () => void 0,

  isOpenLeaveCondition: false,
  leaveDirection: null,
  handleOpenLeaveCreateJob: () => void 0,
  handleCloseLeaveCreateJob: () => void 0,
};

const createExportJobContext = React.createContext<CreateExportJobContextProps>(
  defaultCreateExportJobContextValue
);

export { createExportJobContext };

import { EmbedTokenResponseDto } from '../../api/rest/models/EmbedTokenResponseAggregate/EmbedTokenResponseDto';
import { ReportsConfigsResponse } from '../../models/ReportsAggregate/ReportsConfigs';
import { mockedReportDetailsDTO } from '../AdministrationContainer/Reports/mockReports';

export const mockedReportsConfigs: EmbedTokenResponseDto = {
  reports: [],
  embedToken: {
    token: '1',
    tokenId: '2',
    expiration: new Date(),
  },
};

export const mockedReportsConfigsResponse: ReportsConfigsResponse = {
  reports: [
    {
      ...mockedReportDetailsDTO[0],
      id: 1,
      reportName: 'dev01-tickets-expiring-report-0.22.0',
    },
    {
      ...mockedReportDetailsDTO[0],
      id: 2,
      reportName: 'dev01-tickets-expired-report-0.22.0',
    },
  ],
  embedToken: {
    token: '',
    tokenId: '',
    expiration: new Date(),
  },
};

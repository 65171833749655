import React from 'react';

import NotFullAccessMessage from '../../../../shared/view/NotFullAccessMessage/NotFullAccessMessage';
import { getTranslation } from '../../../../utils/getTranslation';

interface NoAccessMessageWrapperProps {
  dataTestId?: string;
}

const NoAccessMessageWrapper: React.FC<NoAccessMessageWrapperProps> = ({ dataTestId }) => (
  <NotFullAccessMessage dataTestId={dataTestId} className="staticNotificationMessage">
    {getTranslation('NotFullAccess_Message')}
    {getTranslation('Reports')}
  </NotFullAccessMessage>
);

export default NoAccessMessageWrapper;

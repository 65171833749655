import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { GetAllResponse, InsertResponse, UpdateResponse } from '../api/graphql/models/ReportsResponse';
import { GET_ALL } from '../api/graphql/queries/ReportsQueries';
import { ReportTypeDto } from '../api/graphql/models/ReportTypeDto';
import { ApolloClientResponse } from '../api/graphql/models/common';
import { INSERT, UPDATE } from '../api/graphql/mutations/ReportMutations';

export class ReportTypesService {
  private readonly apolloClient: ApolloClient<NormalizedCacheObject>;

  constructor(apolloClient: ApolloClient<NormalizedCacheObject>) {
    this.apolloClient = apolloClient;
  }

  public async getReportTypes(isNotIncludedPaginated?: boolean): Promise<ReportTypeDto[]> {
    const result = await this.apolloClient.query<GetAllResponse>({
      query: GET_ALL,
      variables: { isNotIncludedPaginated },
    });

    return result.data.reports;
  }

  public async insert(report: ReportTypeDto): Promise<ReportTypeDto | undefined | null> {
    const result = await this.apolloClient.mutate<InsertResponse>({
      mutation: INSERT,
      variables: { report },
    });

    return result.data?.insertOneReport;
  }

  public async update(id: number, report: ReportTypeDto): Promise<ApolloClientResponse<UpdateResponse>> {
    return await this.apolloClient.mutate<UpdateResponse>({
      mutation: UPDATE,
      variables: { id, report },
    });
  }
}

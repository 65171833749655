import { PetUserDTO } from '../../../../api/graphql/models/Users';

export type PetUsersTableHeaderType = {
  field: keyof PetUserDTO;
  title: string;
};

export const petUsersTableHeader: PetUsersTableHeaderType[] = [
  {
    field: 'firstName',
    title: 'PetUsers_Name',
  },
  {
    field: 'email',
    title: 'PetUsers_Email',
  },
  {
    field: 'dataAuthRoleId',
    title: 'DataAuthorizationRole',
  },
  {
    field: 'featureAccessGroupId',
    title: 'FeatureGroup',
  },
  {
    field: 'isEnabled',
    title: 'PetUsers_Enabled',
  },
  {
    field: 'isFederated',
    title: 'PetUsers_Federated',
  },
];

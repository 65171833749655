import { GetScheduledReportTokenKey } from '../../../../routes/constants';
import { DownloadReportTokenType } from '../../../../shared/store/getScheduledReportContext';

export const getDownloadReportTokenValue = (search: string): DownloadReportTokenType => {
  const query = new URLSearchParams(search);

  if (!query.has(GetScheduledReportTokenKey)) {
    return null;
  }

  const token = encodeURIComponent(query.get(GetScheduledReportTokenKey) as string);

  return token.length > 0 ? token : null;
};

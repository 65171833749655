import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  GET_ALL,
  GET_BY_ID,
  GET_FEATURE_ACCESS_GROUP,
} from './../api/graphql/queries/FeatureAccessGroupsQueries';
import { INSERT, UPDATE } from './../api/graphql/mutations/FeatureAccessGroupsMutations';

import {
  CreateFeatureAccessGroupDTO,
  CreateFeatureAccessGroupResponse,
  FeatureAccessGroupDTO,
  FeatureAccessGroupsRequestDTO,
  GetFeatureAccessGroupByIdResponse,
  GetFeatureAccessGroupsResponse,
  UpdateFeatureAccessGroupDTO,
  UpdateFeatureAccessGroupResponse,
} from '../api/graphql/models/FeatureAccessGroups';
import { ApolloClientResponse, PaginatedResponse } from '../api/graphql/models/common';
import { ASCENDING_VALUE } from '../utils/constants';

export class FeatureAccessGroupService {
  private readonly apolloClient: ApolloClient<NormalizedCacheObject>;

  constructor(apolloClient: ApolloClient<NormalizedCacheObject>) {
    this.apolloClient = apolloClient;
  }

  public async getAll({
    limit,
    offset,
    sortBy = 'groupName',
    sortOrder = ASCENDING_VALUE,
  }: FeatureAccessGroupsRequestDTO): Promise<PaginatedResponse<FeatureAccessGroupDTO>> {
    const result = await this.apolloClient.query<GetFeatureAccessGroupsResponse>({
      query: GET_ALL,
      variables: { limit, offset, sortBy, sortOrder },
    });

    return result.data.paginatedFeatureAccessGroups;
  }

  public async getById(id: string): Promise<FeatureAccessGroupDTO> {
    const result = await this.apolloClient.query<GetFeatureAccessGroupByIdResponse>({
      query: GET_BY_ID,
      variables: { id },
    });

    return result.data.featureAccessGroup;
  }

  public async insert(
    group: CreateFeatureAccessGroupDTO
  ): Promise<ApolloClientResponse<CreateFeatureAccessGroupResponse>> {
    return await this.apolloClient.mutate<CreateFeatureAccessGroupResponse>({
      mutation: INSERT,
      variables: { group },
    });
  }

  public async update(
    group: UpdateFeatureAccessGroupDTO
  ): Promise<ApolloClientResponse<UpdateFeatureAccessGroupResponse>> {
    return await this.apolloClient.mutate<UpdateFeatureAccessGroupResponse>({
      mutation: UPDATE,
      variables: { id: group._id, group },
    });
  }

  public async getAllFeatureAccessGroups(
    limit: number,
    offset: number,
    sortBy = 'groupName',
    sortOrder = ASCENDING_VALUE
  ): Promise<FeatureAccessGroupDTO[]> {
    const result = await this.apolloClient.query<GetFeatureAccessGroupsResponse>({
      query: GET_FEATURE_ACCESS_GROUP,
      variables: { limit, offset, sortBy, sortOrder },
    });

    return result.data.paginatedFeatureAccessGroups.data;
  }
}

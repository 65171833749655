import React, { useEffect, useState, useContext, useMemo, useCallback } from 'react';
import { Button, Form, Header, Icon } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import InputWithInfo from '../../PetForm/InputWithInfo';
import { getTranslation } from '../../../utils/getTranslation';
import { getIsFormEmpty, getIsSearchParamsTheSame, getTicketNumberFromURL } from './helper';
import { ticketFormDefaultValues, additionalFields } from './constants';
import { TicketFormViewModel } from '../../../models/TripAggregate/TripsTickets';
import {
  tripsTicketsContext,
  defaultTripsTicketsContextValue,
} from '../../../shared/store/tripsTicketsContext';
import {
  mapRequestDTOFromFormModel,
  mapSearchParamsFromRequestDTO,
} from '../TripsTicketsSearchTable/tripsTicketsSearchMapper';
import { getEmailPattern } from '../../PetForm/helper';

import 'react-datepicker/dist/react-datepicker.css';
import './../../../shared/controls/DatePickerField/styles.scss';
import './styles.scss';

const TripsTicketsSearchForm: React.FC = () => {
  const { clearTripsTicketsSearch, searchTripsTickets, setTripsTicketsSearchPage, tripsTicketsSearchPage } =
    useContext(tripsTicketsContext);
  const [submittedFromData, setSubmittedFormData] = useState<TicketFormViewModel>(ticketFormDefaultValues);
  const [isFormErrorShown, setIsFormErrorShown] = useState(false);
  const emailPattern = getEmailPattern();
  const history = useHistory();
  const search = history.location.search;
  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<TicketFormViewModel>({
    defaultValues: ticketFormDefaultValues,
  });
  const watchAllFields = watch();

  const fillFilterWithSearchParams = (urlSearchParams: URLSearchParams): void => {
    (Object.keys(ticketFormDefaultValues) as (keyof TicketFormViewModel)[]).forEach((value) => {
      const queryValue = urlSearchParams.get(value);

      queryValue && additionalFields.includes(value);

      if (value == 'ticketNumber') {
        const ticketNumber = getTicketNumberFromURL(urlSearchParams);
        setValue(value, ticketNumber);
      } else {
        setValue(value, queryValue ?? '');
      }
    });
  };

  useEffect(() => {
    if (Array.from(urlSearchParams.values()).length) {
      fillFilterWithSearchParams(urlSearchParams);
      const formData = getValues();
      if (!getIsFormEmpty(formData)) {
        setSubmittedFormData(formData);
        void searchTripsTickets(mapRequestDTOFromFormModel(tripsTicketsSearchPage, formData));
      }
    }
  }, []);

  useEffect(() => {
    const isSearchParamsTheSame = getIsSearchParamsTheSame(
      mapSearchParamsFromRequestDTO(getValues()),
      urlSearchParams
    );

    if (!isSearchParamsTheSame) {
      fillFilterWithSearchParams(urlSearchParams);
      const formData = getValues();

      onSubmit(formData);
    }
  }, [urlSearchParams]);

  useEffect(() => {
    if (!getIsFormEmpty(getValues()) && isFormErrorShown) {
      setIsFormErrorShown(false);
    }
  }, [watchAllFields, isFormErrorShown]);

  useEffect(() => {
    if (!getIsFormEmpty(submittedFromData)) {
      void searchTripsTickets(mapRequestDTOFromFormModel(tripsTicketsSearchPage, submittedFromData));
    }
  }, [tripsTicketsSearchPage]);

  const onSubmit = useCallback(
    (formData: TicketFormViewModel): void => {
      if (formData.firstName && !formData.lastName) {
        setError('lastName', { type: 'custom', message: getTranslation('TicketsForm_LastNameValidation') });
        return;
      }

      if (!getIsFormEmpty(formData)) {
        isFormErrorShown && setIsFormErrorShown(false);
        const nextSearchParams = mapSearchParamsFromRequestDTO(formData);
        const isSearchParamsTheSame = getIsSearchParamsTheSame(nextSearchParams, urlSearchParams);

        setSubmittedFormData(formData);
        !isSearchParamsTheSame && history.push({ search: nextSearchParams });

        if (tripsTicketsSearchPage !== defaultTripsTicketsContextValue.tripsTicketsSearchPage) {
          setTripsTicketsSearchPage(defaultTripsTicketsContextValue.tripsTicketsSearchPage);
        } else {
          void searchTripsTickets(
            mapRequestDTOFromFormModel(defaultTripsTicketsContextValue.tripsTicketsSearchPage, formData)
          );
        }
      } else {
        setSubmittedFormData(ticketFormDefaultValues);
        !isFormErrorShown && setIsFormErrorShown(true);
      }
    },
    [tripsTicketsSearchPage]
  );

  const checkKeyDown = (e: KeyboardEvent): void => {
    if (e.code === 'Enter') {
      e.preventDefault();
      onSubmit(getValues());
    }
  };

  const resetForm = (): void => {
    history.push({ search: '' });
    isFormErrorShown && setIsFormErrorShown(false);
    setSubmittedFormData(ticketFormDefaultValues);
    reset();
    clearTripsTicketsSearch();
    clearErrors();
  };

  const onSubmitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>): void => {
      void handleSubmit(onSubmit)(event);
    },
    [onSubmit]
  );

  return (
    <Form
      className={`ticketsForm form-container ${isFormErrorShown ? 'form-error' : ''}`}
      onSubmit={onSubmitForm}
      onKeyDown={(e: KeyboardEvent) => checkKeyDown(e)}
      data-testid="ticketsForm"
    >
      <Header as="h1">{getTranslation('TripAuditInfo_TicketsSearch')}</Header>

      <Form.Group>
        <Form.Field width={2}>
          <InputWithInfo
            label={getTranslation('TicketsNumber')}
            error={errors.ticketNumber}
            register={register('ticketNumber')}
            placeholder="2849823591"
            dataTestId="ticketForm-ticketNumber-field"
          />
        </Form.Field>

        <Form.Field width={2}>
          <InputWithInfo
            label={getTranslation('BookingId')}
            register={register('recordLocator')}
            placeholder="VTFDDAQ"
            dataTestId="ticketForm-recordLocator-field"
          />
        </Form.Field>

        <Form.Field width={2}>
          <InputWithInfo
            label={getTranslation('LastName')}
            register={register('lastName')}
            error={errors.lastName}
            placeholder="Lands"
            dataTestId="ticketForm-lastName-field"
          />
        </Form.Field>

        <Form.Field width={2}>
          <InputWithInfo
            label={getTranslation('FirstName')}
            register={register('firstName')}
            placeholder="Geoffrey"
            dataTestId="ticketForm-firstName-field"
          />
        </Form.Field>

        <Form.Field width={2}>
          <InputWithInfo
            label={getTranslation('TravelerEmailAddress')}
            error={errors.travelerEmailAddress}
            register={register('travelerEmailAddress', {
              ...emailPattern,
            })}
            placeholder="LandsG@gmail.com"
            dataTestId="ticketForm-travelerEmailAddress-field"
          />
        </Form.Field>

        <Form.Field>
          <div className="btns-container">
            <div className="container-mainControls">
              <Button
                content={getTranslation('Search')}
                primary
                icon="search"
                size="small"
                labelPosition="left"
                className="searchBtn"
                data-testid="ticketForm-search-control"
              />
              <span className="clear" onClick={resetForm} data-testid="ticketForm-clear-control">
                <Icon name="close" />
              </span>
            </div>
          </div>
        </Form.Field>
      </Form.Group>

      <div className="tips-text">{getTranslation('TicketsForm_Text')}</div>
    </Form>
  );
};

export default TripsTicketsSearchForm;

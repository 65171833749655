import React, { ReactElement } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { getTranslation } from '../../../utils/getTranslation';

interface Props<T> {
  openEditForm: (data: T) => void;
  row: T;
  additionalItems?: {
    text: string;
    disabled: boolean;
    onClick: (data?: T) => void;
  }[];
  dataTestId?: string;
}

const DropdownMenu = <T,>({ row, openEditForm, additionalItems, dataTestId }: Props<T>): ReactElement => {
  return (
    <Dropdown icon="ellipsis vertical" direction="left" data-testid={dataTestId}>
      <Dropdown.Menu>
        <Dropdown.Item text={getTranslation('AdministrationTable_Edit')} onClick={() => openEditForm(row)} />
        {additionalItems?.map(({ text, onClick, disabled }) => (
          <Dropdown.Item text={text} onClick={() => onClick?.(row)} disabled={disabled} key={text} />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownMenu;

import React, { useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Button, Form } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import InputWithInfo from '../../PetForm/InputWithInfo';
import { getTranslation } from '../../../utils/getTranslation';
import { getPasswordPattern, getRequiredFieldRegister } from '../../PetForm/helper';

const ResetPassword: React.FC = () => {
  const [alertMessage, setAlertMessage] = useState('');
  const history = useHistory();

  const { email } = history.location.state as { email: string };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: '',
      password: '',
    },
  });

  const requiredFieldRegister = getRequiredFieldRegister();
  const passwordPattern = getPasswordPattern();

  const forgotPasswordSubmit = async ({ code, password }: { code: string; password: string }) => {
    try {
      await Auth.forgotPasswordSubmit(email, code, password);

      history.push('/');
    } catch (error: Error | unknown) {
      setAlertMessage((error as Error)?.message);
    }
  };

  const onSubmitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>): void => {
      void handleSubmit(forgotPasswordSubmit)(event);
    },
    [forgotPasswordSubmit]
  );

  return (
    <Form onSubmit={onSubmitForm} className="form">
      <h2>{getTranslation('Authentication_PasswordReset')}</h2>

      <InputWithInfo
        required
        label={getTranslation('Authentication_Code')}
        error={errors.code}
        register={register('code', requiredFieldRegister)}
        size={'large'}
      />

      <InputWithInfo
        required
        label={getTranslation('Authentication_NewPassword')}
        error={errors.password}
        register={register('password', {
          ...requiredFieldRegister,
          ...passwordPattern,
        })}
        type={'password'}
        size={'large'}
      />

      {alertMessage && <div className="formError">{alertMessage}</div>}

      <Button primary type="submit" size="medium" fluid>
        {getTranslation('Authentication_ChangePassword')}
      </Button>

      <div className="center-content">
        <Link to="/" className="link-text">
          {getTranslation('Authentication_BackToLogin')}
        </Link>
      </div>
    </Form>
  );
};

export default ResetPassword;

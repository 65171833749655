import React, { useContext, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { FieldError } from 'react-hook-form/dist/types';
import { Link } from 'react-router-dom';
import { Button, Form, Header, Message } from 'semantic-ui-react';

import { getRequiredFieldRegister } from '../../../../../features/PetForm/helper';
import InputWithInfo from '../../../../../features/PetForm/InputWithInfo';
import TextButton from '../../../../../shared/controls/TextButton';
import { getScheduledReportContext } from '../../../../../shared/store/getScheduledReportContext';
import { API_REQUEST_STATUS } from '../../../../../utils/APIHandlers';
import { getTranslation } from '../../../../../utils/getTranslation';

import './styles.scss';

interface MfaCodeViewModel {
  code: string;
}

const GetReportMfaCodeForm: React.FC = () => {
  const {
    token,
    getMFAGetScheduledReportStatus,
    getScheduledReportStatus,
    getMFACodeGetScheduledReport,
    getScheduledReport,
  } = useContext(getScheduledReportContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<MfaCodeViewModel>();
  const requiredFieldRegister = getRequiredFieldRegister();
  const isGetMFAGetStatusFailure = getMFAGetScheduledReportStatus === API_REQUEST_STATUS.REQUEST_FAILURE;
  const isGetMFAGetStatusSuccess = getMFAGetScheduledReportStatus === API_REQUEST_STATUS.REQUEST_SUCCESS;
  const isGetReportFailure = getScheduledReportStatus === API_REQUEST_STATUS.REQUEST_FAILURE;

  const handleGetScheduledReport = (data: MfaCodeViewModel): void => {
    token &&
      void getScheduledReport({
        token,
        code: data.code,
      });
  };

  const handleGetMFACode = (): void => {
    token &&
      void getMFACodeGetScheduledReport({
        token,
      });
  };

  const onSubmitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>): void => {
      void handleSubmit(handleGetScheduledReport)(event);
    },
    [handleGetScheduledReport]
  );

  return (
    <Form
      className="MFAScheduledReportForm"
      onSubmit={onSubmitForm}
      data-testid="MFAScheduledReportForm-form-wrapper"
    >
      <div data-testid="getScheduledReportMFAForm-fields-wrapper">
        <Header as="h2">{getTranslation('MFA_Title')}</Header>
        <span className="MFASubTitle">{getTranslation('MFA_SubTitle')}</span>

        <div className="container-MFACode" data-testid="getScheduledReportMFACode-field-wrapper">
          <InputWithInfo
            required
            label={`${getTranslation('MFA_Code')}:`}
            type="text"
            error={errors.code as FieldError}
            register={register('code', {
              ...requiredFieldRegister,
            })}
            size={'large'}
            dataTestId="getScheduledReportMFACode-field"
            disabled={isGetMFAGetStatusFailure}
          />
        </div>

        <Button
          primary
          type="submit"
          size="medium"
          fluid
          data-testid="submitGotMFACode-control"
          disabled={isGetMFAGetStatusFailure}
        >
          {getTranslation('Submit')}
        </Button>

        <div className="container-additionalControls">
          <Link to="/" className="MFATextControl" data-testid="goHomeFromMFA-control">
            {getTranslation('Cancel')}
          </Link>

          <TextButton
            disabled={isGetMFAGetStatusSuccess}
            className="MFATextControl"
            onClick={handleGetMFACode}
            text={getTranslation('MFA_Resend_Code')}
            dataTestId="authenticateTokenAndGetNewMFACode-control"
          />
        </div>
      </div>

      <div className="container-mfaMessage">
        {isGetMFAGetStatusSuccess && (
          <Message color="green" data-testid="successAuthenticateTokenGetScheduledReport">
            {getTranslation('MFA_CodeHadBeenSend')}
            <br />
            {getTranslation('MFA_CodeHadBeenSend_Valid')}
          </Message>
        )}
        {isGetReportFailure && (
          <Message color="red" data-testid="failureGetScheduledReport">
            {getTranslation('Error')}
          </Message>
        )}
        {isGetMFAGetStatusFailure && (
          <Message color="red" data-testid="failureAuthenticateTokenMFACodeForm">
            {getTranslation('Error_MFAAuthenticateToken')}
          </Message>
        )}
      </div>
    </Form>
  );
};

export default GetReportMfaCodeForm;

import React from 'react';
import { SftpProvidersListDTO } from '../../api/rest/models/SftpProviders';
import { RequestStatus, API_REQUEST_STATUS } from '../../utils/APIHandlers';

export interface SftpProvidersContextProps {
  getSftpProviders: () => Promise<void>;
  sftpProvidersData: SftpProvidersListDTO;
  requestSftpProvidersStatus: RequestStatus;
}

export const defaultSftpProvidersContextValue: SftpProvidersContextProps = {
  getSftpProviders: async () => new Promise(() => void 0),
  sftpProvidersData: [],
  requestSftpProvidersStatus: API_REQUEST_STATUS.NOT_REQUESTED,
};

const sftpProvidersContext = React.createContext<SftpProvidersContextProps>(defaultSftpProvidersContextValue);

export { sftpProvidersContext };

import { PetApi } from '../constants/apiParams';
import { ErrorFetchResponse, RestApiResponse } from '../api/rest/models/RestApiResponse';
import { APIFetch } from '../utils/APIHandlers';
import { Document, GetDocumentResponse } from '../api/rest/models/Document';

export class DocumentsService {
  private readonly endpoint: string;

  constructor() {
    this.endpoint = `/v1/tickets/pet/documents`;
  }

  public async getDocumentsNames(): Promise<RestApiResponse<Document[]> | ErrorFetchResponse> {
    return await APIFetch<RestApiResponse<Document[]>>('get', PetApi, this.endpoint, {});
  }

  public async getDocumentLink(
    name: string
  ): Promise<RestApiResponse<GetDocumentResponse> | ErrorFetchResponse> {
    return await APIFetch<RestApiResponse<GetDocumentResponse>>(
      'get',
      PetApi,
      `${this.endpoint}/${name}`,
      {}
    );
  }
}

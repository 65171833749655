import React, { useState } from 'react';
import { NormalizedCacheObject, ApolloClient } from '@apollo/client';

import { WithApolloClient } from '../../features/WithApolloClient/WithApolloClient';
import {
  defaultExportJobsContextValue,
  exportJobsContext,
  ExportJobsContextProps,
} from '../store/exportJobsContext';
import { API_REQUEST_STATUS, RequestStatus } from '../../utils/APIHandlers';
import { ExportJobsListDTO } from '../../api/graphql/models/ExportJob';
import { ExportJobsService } from '../../services/ExportJobsService';
import {
  mapExportJobDTOFromCorrectExportJobDTO,
  sortExportJobsByCreatedUpdatedDatesDESC,
} from './../../features/ExportJobsContainer/exportJobsMapper';

interface Props {
  children: React.ReactChild;
  client?: ApolloClient<NormalizedCacheObject>;
}

const ExportJobs: React.FC<Props> = ({ children, client }) => {
  const [exportJobsData, setExportJobsData] = useState<ExportJobsListDTO>([]);
  const [requestExportJobsStatus, setRequestExportJobsStatus] = useState<RequestStatus>(
    API_REQUEST_STATUS.NOT_REQUESTED
  );

  const getExportJobsList = async () => {
    try {
      const clientApollo = client as ApolloClient<NormalizedCacheObject>;
      const exportJobsService: ExportJobsService = new ExportJobsService(clientApollo);
      setRequestExportJobsStatus(API_REQUEST_STATUS.REQUESTED);
      const response = await exportJobsService.getAll();
      const data: ExportJobsListDTO =
        (response?.data?.exportJobs &&
          response.data.exportJobs.map(mapExportJobDTOFromCorrectExportJobDTO)) ||
        [];
      sortExportJobsByCreatedUpdatedDatesDESC(data);

      setRequestExportJobsStatus(API_REQUEST_STATUS.REQUEST_SUCCESS);
      setExportJobsData(data);
    } catch (error) {
      console.error(error);
      setRequestExportJobsStatus(API_REQUEST_STATUS.REQUEST_FAILURE);
    }
  };

  const getExportJobsContext = (): ExportJobsContextProps => {
    return {
      ...defaultExportJobsContextValue,
      getExportJobsList,
      exportJobsData,
      requestExportJobsStatus,
    };
  };

  return <exportJobsContext.Provider value={getExportJobsContext()}>{children}</exportJobsContext.Provider>;
};

const WithExportJobs = WithApolloClient(ExportJobs);

export { WithExportJobs };

import React from 'react';
import classNames from 'classnames';
import { Label, SemanticCOLORS } from 'semantic-ui-react';
import './styles.scss';

interface Props {
  text?: string;
  active?: boolean;
  accessTypeConfig?: { access: string; color: string };
  roundBg?: SemanticCOLORS;
  dataTestId?: string;
}

const TableLabel: React.FC<Props> = ({ text, active, accessTypeConfig, roundBg, dataTestId }) => {
  return (
    <Label
      className={classNames('tableLabel', !text && !accessTypeConfig?.access && 'hidden')}
      data-testid={dataTestId}
    >
      <div className={classNames('round', active && 'active', accessTypeConfig?.color, roundBg)} />
      {text ? text : accessTypeConfig?.access}
    </Label>
  );
};

export default TableLabel;

import React from 'react';

import { WithPaginatedFeatureAccessGroups } from '../../../shared/containers/FeatureAccessGroups/withPaginatedFeatureAccessGroups';
import { WithCreateFeatureAccessGroup } from '../../../shared/containers/FeatureAccessGroups/withCreateFeatureAccessGroup';
import { WithUpdateFeatureAccessGroup } from '../../../shared/containers/FeatureAccessGroups/withUpdateFeatureAccessGroup';
import FeatureAccessGroups from './FeatureAccessGroups';

const FeatureAccessGroupsWrapper: React.FC = () => (
  <WithPaginatedFeatureAccessGroups>
    <WithCreateFeatureAccessGroup>
      <WithUpdateFeatureAccessGroup>
        <FeatureAccessGroups />
      </WithUpdateFeatureAccessGroup>
    </WithCreateFeatureAccessGroup>
  </WithPaginatedFeatureAccessGroups>
);

export default FeatureAccessGroupsWrapper;

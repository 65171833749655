import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import { Tab, Header, Message } from 'semantic-ui-react';

import Private from '../Private';
import { getTranslation } from '../../utils/getTranslation';
import { reportsPageContext } from '../../shared/store/reportsPageContext';
import { getRequestStatuses } from '../../utils/APIHandlers';
import EmptyMessage from '../../shared/view/EmptyMessage/EmptyMessage';
import TextButton from '../../shared/controls/TextButton/TextButton';
import MessageWithControl from '../../shared/controls/MessageWithControl/MessageWithControl';
import FullSizeLoader from '../../shared/view/Loader/FullSizeLoader';

import './styles.scss';
import './../PetForm/commonAuditInfoStyles.scss';

const ReportsContainer: React.FC = () => {
  const {
    allPanes,
    reportsConfig,
    activeReportIndex,
    isReportExist,
    getReportsConfig,
    openReport,
    requestReportsStatus,
  } = useContext(reportsPageContext);
  const requestReports = getRequestStatuses(requestReportsStatus);
  const history = useHistory();
  const pathname = history.location.pathname;

  useEffect(() => {
    !reportsConfig && void getReportsConfig();
  }, []);

  useEffect(() => {
    reportsConfig && openReport();
  }, [reportsConfig, pathname]);

  return (
    <div className="reports">
      {!isReportExist && <Header as="h1">{getTranslation('Header_Reports')}</Header>}

      {requestReports.REQUESTED && <FullSizeLoader dataTestId="reports-loader" />}

      {requestReports.REQUEST_SUCCESS && (
        <div>
          {reportsConfig && isReportExist && (
            <Tab
              menu={{ secondary: true }}
              panes={allPanes}
              activeIndex={activeReportIndex}
              data-testid="reports-content"
            />
          )}
          {reportsConfig?.reports.length === 0 && (
            <EmptyMessage optionalText={getTranslation('No_Reports')} dataTestId="reports-empty-message" />
          )}
          {(!reportsConfig || (reportsConfig.reports.length !== 0 && !isReportExist)) && <Private />}
        </div>
      )}

      {requestReports.REQUEST_FAILURE && (
        <Message error data-testid="errorLoadReportsConfigs-Reports-message">
          <MessageWithControl message={getTranslation('Error')}>
            <TextButton
              text={getTranslation('Retry_Now')}
              onClick={() => void getReportsConfig()}
              dataTestId="errorLoadReportsConfigs-Reports-retryControl"
            />
          </MessageWithControl>
        </Message>
      )}
    </div>
  );
};

export default ReportsContainer;

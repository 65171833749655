import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { DataAuthorizationRole } from '../models/DataAuthorizationRolesAggregate/DataAuthorizationRole';
import {
  GET_ALL,
  GET_BY_ID,
  GET_DATA_AUTHORIZATION_ROLES,
} from '../api/graphql/queries/DataAuthorizationRolesQueries';
import { INSERT, UPDATE } from '../api/graphql/mutations/DataAuthorizationRolesMutations';
import {
  GetAllResponse,
  GetByIdResponse,
  InsertResponse,
  UpdateResponse,
} from '../api/graphql/models/DataAuthorizationRolesResponse';
import { PaginatedResponse } from '../api/graphql/models/common';
import { ASCENDING_VALUE } from '../utils/constants';

export class DataAuthorizationRolesService {
  private readonly apolloClient: ApolloClient<NormalizedCacheObject>;

  constructor(apolloClient: ApolloClient<NormalizedCacheObject>) {
    this.apolloClient = apolloClient;
  }

  public async getAll(
    limit: number,
    offset: number,
    name: string | null,
    sortBy = 'roleName',
    sortOrder = ASCENDING_VALUE
  ): Promise<PaginatedResponse<DataAuthorizationRole>> {
    const result = await this.apolloClient.query<GetAllResponse>({
      query: GET_ALL,
      variables: { limit, offset, sortBy, sortOrder, name },
    });

    return result.data.paginatedDataAuthorizationRoles;
  }

  public async getById(id: string): Promise<DataAuthorizationRole> {
    const result = await this.apolloClient.query<GetByIdResponse>({
      query: GET_BY_ID,
      variables: { id },
    });

    return result.data.dataAuthorizationRole;
  }

  public async insert(role: DataAuthorizationRole): Promise<DataAuthorizationRole | null | undefined> {
    const result = await this.apolloClient.mutate<InsertResponse>({
      mutation: INSERT,
      variables: { role },
    });

    return result.data?.insertOneDataAuthorizationRole;
  }

  public async update(
    id: string,
    role: DataAuthorizationRole
  ): Promise<DataAuthorizationRole | null | undefined> {
    const result = await this.apolloClient.mutate<UpdateResponse>({
      mutation: UPDATE,
      variables: { id, role },
    });

    return result.data?.replaceOneDataAuthorizationRole;
  }

  public async getAllDataAuthorizationRoles(
    limit: number,
    offset: number,
    sortBy = 'roleName',
    sortOrder = ASCENDING_VALUE
  ): Promise<DataAuthorizationRole[]> {
    const result = await this.apolloClient.query<GetAllResponse>({
      query: GET_DATA_AUTHORIZATION_ROLES,
      variables: { limit, offset, sortBy, sortOrder },
    });

    return result.data.paginatedDataAuthorizationRoles.data;
  }
}

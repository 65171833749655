import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, Menu, SemanticICONS } from 'semantic-ui-react';
import { drawerTabs } from './constants';
import {
  pathAdministration,
  pathExportJobsList,
  pathReports,
  pathSystem,
  pathTickets,
  pathDocumentRepository,
} from '../../routes/constants';
import './styles.scss';
import { userContext } from '../../shared/store/userContext';
import {
  hasDataAuthorizationManagementAccess,
  hasFeatureAccessManagementAccess,
  hasUserManagementAccess,
} from '../../pages/Administration/helper';

const Drawer: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const userContextValue = useContext(userContext);
  const {
    isTicketsManagementHasReadAccess,
    isTicketsManagementHasReadWriteAccess,
    isScheduledReportsManagementHasReadAccess,
    isScheduledReportsManagementHasReadWriteAccess,
    isReportsReadAccess,
  } = userContextValue;
  const isAdministrationAccess =
    hasUserManagementAccess(userContextValue) ||
    hasDataAuthorizationManagementAccess(userContextValue) ||
    hasFeatureAccessManagementAccess(userContextValue);

  const userDrawerTabs = drawerTabs.filter(({ url }) => {
    if (url === pathAdministration) {
      return isAdministrationAccess;
    } else if (url === pathSystem) {
      return isTicketsManagementHasReadWriteAccess;
    } else if (url.includes(pathTickets)) {
      return isTicketsManagementHasReadAccess || isTicketsManagementHasReadWriteAccess;
    } else if (url === pathExportJobsList) {
      return isScheduledReportsManagementHasReadAccess || isScheduledReportsManagementHasReadWriteAccess;
    } else if (url === pathReports) {
      return isReportsReadAccess;
    } else if (url === pathDocumentRepository) {
      return false;
    } else {
      return true;
    }
  });

  return (
    <div className="drawer">
      <input
        type="checkbox"
        id="ham-menu"
        defaultChecked={isMenuOpen}
        onChange={() => setIsMenuOpen(!isMenuOpen)}
      />

      <label className="ham-menu-container" htmlFor="ham-menu">
        <span className="ham-menu" />
      </label>

      <div className="drawer-container">
        <Menu inverted vertical className="drawer-tabs" data-testid="mainMenu">
          {userDrawerTabs.map(({ name, url, icon }: { name: string; url: string; icon: SemanticICONS }) => (
            <Menu.Item
              key={name}
              name={name}
              as={NavLink}
              to={url}
              exact={url === '/'}
              data-testid="mainMenu-item"
            >
              <Icon name={icon} />
              {name}
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </div>
  );
};

export default Drawer;

import React, { useContext, useCallback } from 'react';
import { Modal, Message, Button } from 'semantic-ui-react';

import { getTranslation } from './../../../../utils/getTranslation';
import { API_REQUEST_STATUS } from './../../../../utils/APIHandlers';
import FullSizeLoader from './../../../../shared/view/Loader/FullSizeLoader';
import { createExportJobContext } from './../../../../shared/store/createExportJobContext';
import ShortExportJobDetails from './../../ViewExportJob/ShortExportJobDetails';

const ConfirmEditExportJobModal: React.FC = () => {
  const {
    isOpenCreateCondition,
    exportJobData,
    isEdit,
    createExportJob,
    editJob,
    handleCloseCreateJob,
    requestCreateJobStatus,
  } = useContext(createExportJobContext);

  const handleEditJob = useCallback((): void => {
    void editJob();
  }, [editJob]);

  const handleCreateExportJob = useCallback((): void => {
    void createExportJob();
  }, [createExportJob]);

  return (
    <Modal
      open={isOpenCreateCondition}
      size="small"
      closeIcon={true}
      closeOnEscape={true}
      closeOnDimmerClick={true}
      onClose={handleCloseCreateJob}
      data-testid="modal-createExportJob"
    >
      <Modal.Header>
        <h3 data-testid="createExportJobModal-title">
          {getTranslation(isEdit ? 'EditJob_Modal_Title' : 'CreateJob_Modal_Title')}
        </h3>
      </Modal.Header>

      {exportJobData && (
        <Modal.Content data-testid="modal-createExportJob-content">
          <div className="container-createExportJobDetails">
            <ShortExportJobDetails data={exportJobData} />
          </div>
        </Modal.Content>
      )}

      <Modal.Content data-testid="modal-createExportJob-content-question">
        {requestCreateJobStatus === API_REQUEST_STATUS.NOT_REQUESTED && (
          <div className="container-createExportJobMessage">
            {getTranslation(isEdit ? 'UpdateJobWarningContent' : 'CreateJobWarningContent')}
          </div>
        )}
        {requestCreateJobStatus === API_REQUEST_STATUS.REQUEST_FAILURE && (
          <Message error>{getTranslation('Error')}</Message>
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button
          data-testid="cancel-createExportJob-btn"
          className="form-button"
          basic
          size="small"
          color="blue"
          onClick={handleCloseCreateJob}
        >
          {getTranslation('Cancel')}
        </Button>
        {isEdit && (
          <Button
            data-testid="edit-createExportJob-btn"
            className="form-button"
            size="small"
            color="blue"
            onClick={handleEditJob}
          >
            {requestCreateJobStatus === API_REQUEST_STATUS.REQUEST_FAILURE
              ? getTranslation('Retry_EditeJob_Control')
              : getTranslation('Confirm')}
          </Button>
        )}
        {!isEdit && (
          <Button
            data-testid="create-createExportJob-btn"
            className="form-button"
            size="small"
            color={'blue'}
            onClick={handleCreateExportJob}
          >
            {requestCreateJobStatus === API_REQUEST_STATUS.REQUEST_FAILURE
              ? getTranslation('Retry_CreateJob_Control')
              : getTranslation('Confirm')}
          </Button>
        )}
      </Modal.Actions>

      {requestCreateJobStatus === API_REQUEST_STATUS.REQUESTED && <FullSizeLoader />}
    </Modal>
  );
};

export default ConfirmEditExportJobModal;

import React, { useContext, useEffect, useCallback } from 'react';
import { Message, Header, Grid, Button, Loader } from 'semantic-ui-react';

import TextButton from '../../../shared/controls/TextButton';
import { getTranslation } from '../../../utils/getTranslation';
import { exportJobHistoryContext } from '../../../shared/store/exportJobHistoryContext';
import { API_REQUEST_STATUS } from '../../../utils/APIHandlers';
import MessageWithControl from '../../../shared/controls/MessageWithControl';
import FullSizeLoader from '../../../shared/view/Loader/FullSizeLoader';
import TriggerExportJobNotifications from '../Notifications/TriggerExportJobNotifications';
import { triggerExportJobContext } from '../../../shared/store/triggerExportJobContext';
import { exportJobContext } from '../../../shared/store/exportJobContext';
import { userContext } from '../../../shared/store/userContext';
import ExportJobHistoryList from './ExportJobHistoryList';

import './styles.scss';

interface ExportJobHistoryListProps {
  exportJobId: string;
}

const ExportJobHistoryListWrapper: React.FC<ExportJobHistoryListProps> = (props) => {
  const { exportJobId } = props;
  const { requestExportJobHistoryStatus, exportJobHistoryData, getExportJobHistory } =
    useContext(exportJobHistoryContext);
  const { exportJobData, requestExportJobStatus } = useContext(exportJobContext);
  const { handleOpenTriggerExportJob } = useContext(triggerExportJobContext);
  const { isScheduledReportsManagementHasReadWriteAccess } = useContext(userContext);

  const handleGetExportJobHistory = useCallback(() => {
    exportJobId && void getExportJobHistory(exportJobId);
  }, [getExportJobHistory, exportJobId]);

  useEffect((): void => {
    handleGetExportJobHistory();
  }, []);

  return (
    <>
      <Grid>
        <Grid.Row className="arbitraryWidth" verticalAlign="middle">
          <Grid.Column floated="left">
            <Header as="h2">{getTranslation('JobHistory_ExportJob_ListTitle')}</Header>
          </Grid.Column>
          <Grid.Column floated="right">
            {isScheduledReportsManagementHasReadWriteAccess && exportJobData && (
              <Button
                color="blue"
                size="small"
                basic
                onClick={() => {
                  handleOpenTriggerExportJob(exportJobData);
                }}
                data-testid="triggerExportJob-control"
              >
                {getTranslation('ExportJobs_Trigger_Control')}
              </Button>
            )}
            {requestExportJobStatus === API_REQUEST_STATUS.REQUESTED && (
              <Loader size="small" data-testid="waitingForExportJobData-loader" />
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="container-triggerExportJobNotifications">
          <TriggerExportJobNotifications />
        </Grid.Row>
        {exportJobHistoryData.length > 0 && (
          <Grid.Row data-test>
            <Grid.Column>
              <ExportJobHistoryList exportJobHistoryData={exportJobHistoryData} />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>

      {exportJobHistoryData.length === 0 &&
        requestExportJobHistoryStatus === API_REQUEST_STATUS.REQUEST_SUCCESS && (
          <Message warning className="emptyJobHistoryMessage" data-testid="message-empty">
            <MessageWithControl message={getTranslation('Empty')}>
              <TextButton
                dataTestId="empty-retry-btn"
                onClick={handleGetExportJobHistory}
                text={getTranslation('Retry')}
              />
            </MessageWithControl>
          </Message>
        )}

      {requestExportJobHistoryStatus === API_REQUEST_STATUS.REQUEST_FAILURE && (
        <Message error data-testid="message-error">
          <MessageWithControl message={getTranslation('Error')}>
            <TextButton
              dataTestId="retry-btn"
              onClick={handleGetExportJobHistory}
              text={getTranslation('Retry')}
            />
          </MessageWithControl>
        </Message>
      )}

      {requestExportJobHistoryStatus === API_REQUEST_STATUS.REQUESTED && <FullSizeLoader />}
    </>
  );
};

export default ExportJobHistoryListWrapper;

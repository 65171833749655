import React, { ReactElement, useEffect } from 'react';
import { Button, Form, Radio } from 'semantic-ui-react';
import { isMobile } from 'react-device-detect';

import { PeriodType, PeriodValue } from '../../../../models/ExportJobsAggregate/ExportJobPeriodRange';
import FormFieldLabel from '../../../../shared/view/FormFieldLabel/FormFieldLabel';
import { getTranslation } from '../../../../utils/getTranslation';

interface PeriodFieldFieldProps {
  onChange: (value: number) => void;
  setCustomResourcePeriod: (type: number) => void;
  customResourcePeriod: PeriodType;
  label: string;
  defaultValue?: PeriodType;
  dataTestId?: string;
}

const PeriodField: React.FC<PeriodFieldFieldProps> = (props): ReactElement => {
  const { onChange, setCustomResourcePeriod, customResourcePeriod, label, defaultValue, dataTestId } = props;

  useEffect(() => {
    if (defaultValue) {
      let currentPeriod = PeriodType.CUSTOM;
      Object.keys(PeriodValue).forEach((_item: string, periodType: PeriodType): void => {
        if (periodType === defaultValue) {
          currentPeriod = periodType;
        }
      });
      setCustomResourcePeriod(currentPeriod);
    }
  }, []);

  return (
    <Form.Field>
      <FormFieldLabel required>{label}</FormFieldLabel>

      <div className="container-periodControlsList" data-testid={dataTestId}>
        {Object.keys(PeriodValue).map((index: string) => {
          const periodType = parseInt(index) as PeriodType;
          const title = getTranslation(PeriodValue[periodType]);
          const isActive = customResourcePeriod === periodType;

          return isMobile ? (
            <Radio
              key={periodType}
              label={title}
              checked={isActive}
              onClick={() => {
                setCustomResourcePeriod(periodType);
                onChange(periodType);
              }}
              data-testid={`exportJob-period-control-${periodType}`}
            />
          ) : (
            <Button
              type="button"
              key={periodType}
              size="small"
              color={isActive ? 'blue' : 'grey'}
              data-testid={`exportJob-period-control-${periodType}`}
              onClick={() => {
                setCustomResourcePeriod(periodType);
                onChange(periodType);
              }}
            >
              {title}
            </Button>
          );
        })}
      </div>
    </Form.Field>
  );
};

export default PeriodField;

import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { WithApolloClient } from '../../../WithApolloClient/WithApolloClient';
import PetForm from '../../../PetForm';
import { getRequestStatuses } from '../../../../utils/APIHandlers';
import FullSizeLoader from '../../../../shared/view/Loader/FullSizeLoader';
import { createFeatureAccessGroupContext } from '../../../../shared/store/FeatureAccessGroups/createFeatureAccessGroupContext';
import { WithReports } from '../../../../shared/containers/withReports';
import { FeatureAccessGroupFormViewModel } from '../../../../models/FeatureAccessGroupsAggregate/FeatureAccessGroup';
import { isLeaveCondition } from '../../PetUsers/helper';
import { emptyFormFeatureAccessGroup } from '../constants';
import FeatureAccessGroupsForm from './FeatureAccessGroupsForm';
import { pathAdministrationFeatureGroups } from '../../../../routes/constants';
import { userContext } from '../../../../shared/store/userContext';
import NoAccessMessageWrapper from '../FeatureAccessGroupsMessages/NoAccessMessageWrapper';
import { hasReportsManagementAccess } from '../../../../pages/Administration/helper';

import './styles.scss';

const CreateFeatureAccessGroupWrapper: React.FC = () => {
  const {
    createFeatureAccessGroup,
    requestCreateFeatureAccessGroupStatus,
    clearCreateFeatureAccessGroupState,
  } = useContext(createFeatureAccessGroupContext);
  const userContextValue = useContext(userContext);
  const history = useHistory();
  const requestCreateFeatureAccessGroup = getRequestStatuses(requestCreateFeatureAccessGroupStatus);

  const handleCancel = () => {
    clearCreateFeatureAccessGroupState();
    history.push(pathAdministrationFeatureGroups);
  };

  useEffect(() => {
    clearCreateFeatureAccessGroupState();
  }, []);

  useEffect(() => {
    if (requestCreateFeatureAccessGroup.REQUEST_SUCCESS) {
      history.push(pathAdministrationFeatureGroups);
    }
  }, [requestCreateFeatureAccessGroup]);

  return (
    <>
      {!hasReportsManagementAccess(userContextValue) && (
        <div className="auditMessage">
          <NoAccessMessageWrapper dataTestId="createFeatureAccessGroup" />
        </div>
      )}

      <div className="featureAccessGroupForm" data-testid="createFeatureAccessGroupDataForm-wrapper">
        {requestCreateFeatureAccessGroup.REQUESTED && (
          <FullSizeLoader dataTestId="createFeatureAccessGroupDataForm-loader" />
        )}

        <WithReports>
          <PetForm<FeatureAccessGroupFormViewModel>
            cancelButtonClicked={handleCancel}
            submitForm={createFeatureAccessGroup}
            isLeaveCondition={(isDirty: boolean, redirectUrlToLeave: string) =>
              isLeaveCondition({
                isDirty,
                redirectUrlToLeave,
                requestCondition: requestCreateFeatureAccessGroup.REQUEST_SUCCESS,
              })
            }
            isRedirectLeave
            isEditForm={false}
            editFormData={emptyFormFeatureAccessGroup}
            isNotEnoughAccess={!hasReportsManagementAccess(userContextValue)}
          >
            <FeatureAccessGroupsForm editFormData={emptyFormFeatureAccessGroup} />
          </PetForm>
        </WithReports>
      </div>
    </>
  );
};

export default WithApolloClient(CreateFeatureAccessGroupWrapper);

import React from 'react';

import './styles.scss';

interface LabelWrapperProps {
  children: React.ReactNode;
  label: string;
  dataTestId?: string;
}

const LabelWrapper: React.FC<LabelWrapperProps> = (props) => {
  const { children, label, dataTestId } = props;

  return (
    <div className="labelWrapper">
      <span
        className="labelWrapper-label"
        data-testid={`${(dataTestId && dataTestId + '-') || ''}labelWrapper-label`}
      >
        {label}
      </span>
      <div
        className="labelWrapper-children"
        data-testid={`${(dataTestId && dataTestId + '-') || ''}labelWrapper-children`}
      >
        {children}
      </div>
    </div>
  );
};

export default LabelWrapper;

import React from 'react';

import {
  DownloadScheduledReportProps,
  defaultDownloadScheduledReportValue,
  downloadScheduledReportContext,
} from '../store/downloadScheduledReportContext';
import { useNotification, useNotificationReturn } from '../hooks/notificationHook';
import { DownloadDocumentService } from '../../services/DownloadDocumentService';
import { DownloadReportRequestDTO } from '../../api/rest/models/GetScheduledReport';
import { API_REQUEST_CODE } from '../../utils/APIHandlers';
import { DownloadReadableStreamDTO } from '../../api/rest/models/Document';
import { getFetchErrorMessage } from '../../utils/errorHandler';
import { ErrorFetchResponse } from '../../api/rest/models/RestApiResponse';

interface Props {
  children: React.ReactChild;
}

const WithDownloadScheduledReport: React.FC<Props> = ({ children }) => {
  const [
    { notificationStatus },
    { setFailNotificationStatus, setSuccessNotificationStatus, setRequestedNotificationStatus },
  ]: useNotificationReturn = useNotification();

  const downloadScheduledReport = async (payload: DownloadReportRequestDTO): Promise<void> => {
    const getScheduledReport = new DownloadDocumentService();

    try {
      setRequestedNotificationStatus();

      const response = await getScheduledReport.getDocument({ fileLink: payload.reportLink });
      if (response.status === API_REQUEST_CODE.SUCCESS) {
        await response.blob().then((blob) => {
          getScheduledReport.handleDownloadReadableStream({
            blob,
            fileName: payload.reportFileName,
          } as DownloadReadableStreamDTO);
        });
        setSuccessNotificationStatus();
      } else {
        setFailNotificationStatus();
      }
    } catch (e) {
      console.error(getFetchErrorMessage(e as ErrorFetchResponse));
      setFailNotificationStatus();
    }
  };

  const getDownloadScheduledReportContext = (): DownloadScheduledReportProps => {
    return {
      ...defaultDownloadScheduledReportValue,
      downloadScheduledReport,
      requestDownloadScheduledReportStatus: notificationStatus,
    };
  };

  return (
    <downloadScheduledReportContext.Provider value={getDownloadScheduledReportContext()}>
      {children}
    </downloadScheduledReportContext.Provider>
  );
};

export { WithDownloadScheduledReport };

import { SemanticICONS } from 'semantic-ui-react';
import {
  pathHome,
  pathReports,
  pathHelp,
  pathDocumentRepository,
  pathSystem,
  pathAdministration,
  pathTickets,
  pathExportJobsList,
} from '../../routes/constants';

export const drawerTabs: Array<{ name: string; url: string; icon: SemanticICONS }> = [
  {
    name: 'Home',
    url: pathHome,
    icon: 'home',
  },
  {
    name: 'Reports',
    url: pathReports,
    icon: 'suitcase',
  },
  {
    name: 'Document repository',
    url: pathDocumentRepository,
    icon: 'folder',
  },
  {
    name: 'System',
    url: pathSystem,
    icon: 'wpforms',
  },
  {
    name: 'Administration',
    url: pathAdministration,
    icon: 'male',
  },
  {
    name: 'Tickets',
    url: pathTickets,
    icon: 'plane',
  },
  {
    name: 'Scheduled Reports',
    url: pathExportJobsList,
    icon: 'pin',
  },
  {
    name: 'Help',
    url: pathHelp,
    icon: 'question circle',
  },
];

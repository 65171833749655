import React, { useContext } from 'react';
import { Divider, Grid, Header, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import TableLabel from '../../../TableLabel';
import DateInfo from '../../../PetForm/DateInfo';
import './../../../PetForm/commonAuditInfoStyles.scss';
import { getTranslation } from '../../../../utils/getTranslation';
import LabelWrapper from '../../../../shared/view/LabelWrapper/LabelWrapper';
import CloseControl from '../../../../shared/controls/CloseControl';
import { pathAdministrationUsers } from '../../../../routes/constants';
import { PetUserViewModel } from '../../../../models/PetUsersAggregate/PetUser';
import {
  hasDataAuthorizationManagementAccess,
  hasFeatureAccessManagementAccess,
} from '../../../../pages/Administration/helper';
import { userContext } from '../../../../shared/store/userContext';
import NoAccessLabel from '../../../../shared/view/NoAccessLabel/NoAccessLabel';

interface Props {
  currentUser: PetUserViewModel;
}

const PetUserDetails: React.FC<Props> = (props) => {
  const {
    firstName,
    lastName,
    email,
    createdDate,
    createdBy,
    updatedDate,
    featureAccessGroup,
    updatedBy,
    isEnabled,
    isFederated,
    dataAuthRole,
  } = props.currentUser;
  const userContextValue = useContext(userContext);
  const dataAuthRoleName = dataAuthRole?.roleName ?? '';
  const featureAccessGroupName = featureAccessGroup?.groupName ?? '';

  return (
    <>
      <Link to={pathAdministrationUsers}>
        <CloseControl />
      </Link>

      <Grid>
        <Grid.Row>
          <Grid.Column>
            <LabelWrapper label={getTranslation('Name')}>
              <Header as="h1">{`${firstName} ${lastName}`}</Header>
            </LabelWrapper>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <LabelWrapper label={getTranslation('AuditInfo_EmailLogin')}>
              <div className="blueEmail">{email}</div>
            </LabelWrapper>
          </Grid.Column>
        </Grid.Row>

        <Divider />

        <Grid.Row>
          <Grid.Column>
            <LabelWrapper label={getTranslation('DataAuthorizationRole')}>
              {!hasDataAuthorizationManagementAccess(userContextValue) ? (
                <NoAccessLabel dataTestId="dataAuthorizationRoleField-ViewPetUser" />
              ) : (
                dataAuthRoleName
              )}
            </LabelWrapper>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <LabelWrapper label={getTranslation('FeatureGroup')}>
              {!hasFeatureAccessManagementAccess(userContextValue) ? (
                <NoAccessLabel dataTestId="featureAccessGroupField-ViewPetUser" />
              ) : (
                <Label basic color="blue">
                  {featureAccessGroupName}
                </Label>
              )}
            </LabelWrapper>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="arbitraryWidth">
          <Grid.Column>
            <LabelWrapper label={getTranslation('AuditInfo_Enabled')}>
              {isEnabled ? (
                <TableLabel
                  text={getTranslation('PetUsers_Enabled')}
                  active={true}
                  dataTestId="auditInfo-enable"
                />
              ) : (
                <TableLabel text={getTranslation('PetUsers_NotEnabled')} dataTestId="auditInfo-enable" />
              )}
            </LabelWrapper>
          </Grid.Column>
          <Grid.Column>
            <LabelWrapper label={getTranslation('AuditInfo_Federated')}>
              {isFederated ? (
                <TableLabel
                  text={getTranslation('PetUsers_Federated')}
                  active={true}
                  dataTestId="auditInfo-federated"
                />
              ) : (
                <TableLabel text={getTranslation('PetUsers_NotFederated')} dataTestId="auditInfo-federated" />
              )}
            </LabelWrapper>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <DateInfo
              createdDate={createdDate}
              createdBy={createdBy}
              updatedDate={updatedDate}
              updatedBy={updatedBy}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default PetUserDetails;

import React from 'react';
import { Button } from 'semantic-ui-react';

import './styles.scss';

interface Props {
  text: string;
  onClick: () => void;
  dataTestId?: string;
  className?: string;
  disabled?: boolean;
}

const TextButton: React.FC<Props> = ({ text, onClick, dataTestId, className, disabled }) => {
  return (
    <Button
      type="button"
      size="small"
      onClick={onClick}
      className={`textButton ${className || ''}`}
      disabled={disabled}
      data-testid={dataTestId}
    >
      {text}
    </Button>
  );
};

export default TextButton;

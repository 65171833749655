import React, { useContext, useState } from 'react';

import {
  defaultGetScheduledReportContextValue,
  GetScheduledReportContextProps,
  getScheduledReportContext,
  DownloadReportTokenType,
  ScheduledReportFieldType,
} from '../store/getScheduledReportContext';
import { GetScheduledReportService } from '../../services/GetScheduledReportService';
import { useNotification, useNotificationReturn } from '../hooks/notificationHook';
import { API_REQUEST_CODE, API_REQUEST_STATUS } from '../../utils/APIHandlers';
import { userContext } from '../../shared/store/userContext';
import { RestApiResponse } from './../../api/rest/models/RestApiResponse';
import {
  GetScheduledReportDTO,
  GetScheduledReportResponse,
  GetReportMfaCodeRequestDTO,
} from '../../api/rest/models/GetScheduledReport';

interface Props {
  children: React.ReactChild;
}

const GET_MFA_CODE_DELAY_MS = 1000 * 60 * 15;

const WithGetScheduledReport: React.FC<Props> = ({ children }) => {
  const { apiBaseUrl } = useContext(userContext);
  const [report, setReport] = useState<ScheduledReportFieldType>(
    defaultGetScheduledReportContextValue.report
  );
  const [getMFARequestCode, setGetMFARequestCode] = useState<number | null>(
    defaultGetScheduledReportContextValue.getMFARequestCode
  );
  const [
    { notificationStatus: notificationCodeStatus },
    {
      setFailNotificationStatus: setFailCodeStatus,
      setSuccessNotificationStatus: setSuccessCodeStatus,
      setRequestedNotificationStatus: setRequestedCodeStatus,
      setNotRequestedNotificationStatus: setNotRequestedCodeStatus,
    },
  ]: useNotificationReturn = useNotification();
  const [
    { notificationStatus: notificationReportStatus },
    {
      setFailNotificationStatus: setFailReportStatus,
      setSuccessNotificationStatus: setSuccessReportStatus,
      setRequestedNotificationStatus: setRequestedReportStatus,
      setNotRequestedNotificationStatus: setNotRequestedReportStatus,
    },
  ]: useNotificationReturn = useNotification();
  const [token, setToken] = useState<DownloadReportTokenType>(defaultGetScheduledReportContextValue.token);

  const handleGetMFACodeGetScheduledReport = async (payload: GetReportMfaCodeRequestDTO) => {
    const getScheduledReport = new GetScheduledReportService(apiBaseUrl);
    try {
      setRequestedCodeStatus();
      notificationReportStatus !== API_REQUEST_STATUS.NOT_REQUESTED && setNotRequestedReportStatus();
      setReport(null);

      const response = await getScheduledReport.getMFACode(payload);

      if (response.status === API_REQUEST_CODE.SUCCESS) {
        setGetMFARequestCode(API_REQUEST_CODE.SUCCESS);
        setSuccessCodeStatus(GET_MFA_CODE_DELAY_MS);
      } else {
        setGetMFARequestCode(response.status);
        setFailCodeStatus();
      }
    } catch (error) {
      console.error(error);
      setGetMFARequestCode((error as Response).status);
      setFailCodeStatus();
    }
  };

  const handleGetScheduledReport = async (payload: GetScheduledReportDTO) => {
    const getScheduledReport = new GetScheduledReportService(apiBaseUrl);
    try {
      setRequestedReportStatus();
      setReport(null);

      const response = await getScheduledReport.getScheduledReport(payload);

      if (response.status === API_REQUEST_CODE.SUCCESS) {
        setSuccessReportStatus();
        const json = (await response.json()) as RestApiResponse<GetScheduledReportResponse>;
        const { link, fileName } = json.result;
        setReport({
          link,
          fileName,
        });
      } else {
        setFailReportStatus();
      }
    } catch (error) {
      console.error(error);
      setFailReportStatus();
    }
  };

  const handleResetMFAAuthentication = () => {
    setReport(defaultGetScheduledReportContextValue.report);
    setGetMFARequestCode(defaultGetScheduledReportContextValue.getMFARequestCode);
    setNotRequestedCodeStatus();
    setNotRequestedReportStatus();
    void handleGetMFACodeGetScheduledReport({ token });
  };

  const handleSetToken = (token: DownloadReportTokenType) => {
    setToken(token);
  };

  const getGetScheduledReportContext = (): GetScheduledReportContextProps => {
    return {
      ...defaultGetScheduledReportContextValue,
      token,
      report,
      getMFARequestCode,
      getMFAGetScheduledReportStatus: notificationCodeStatus,
      getScheduledReportStatus: notificationReportStatus,
      getMFACodeGetScheduledReport: handleGetMFACodeGetScheduledReport,
      getScheduledReport: handleGetScheduledReport,
      resetMFAAuthentication: handleResetMFAAuthentication,
      setToken: handleSetToken,
    };
  };

  return (
    <getScheduledReportContext.Provider value={getGetScheduledReportContext()}>
      {children}
    </getScheduledReportContext.Provider>
  );
};

export { WithGetScheduledReport };

import React from 'react';
import { Grid } from 'semantic-ui-react';

import { getTranslation } from '../../../../utils/getTranslation';
import { getFormattedDate } from '../../../../utils/formatDate';
import LabelWrapper from '../../../../shared/view/LabelWrapper/LabelWrapper';
import { OriginalTicketExchangeInfoViewModel } from '../../../../models/TripAggregate/TripTicket';

interface Props {
  originalTicket: OriginalTicketExchangeInfoViewModel;
}

const OriginalTicketInfo: React.FC<Props> = ({
  originalTicket: { fullTicketNumber, commonAmount, issueDate, recordLocator },
}) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column mobile={8} tablet={5} computer={4} largeScreen={4} widescreen={4}>
          <LabelWrapper label={getTranslation('TicketCard_OriginalTicketNumber')}>
            {fullTicketNumber}
          </LabelWrapper>
        </Grid.Column>
        <Grid.Column mobile={8} tablet={5} computer={4} largeScreen={4} widescreen={4}>
          <LabelWrapper label={getTranslation('BookingId')}>{recordLocator}</LabelWrapper>
        </Grid.Column>
        <Grid.Column mobile={8} tablet={5} computer={4} largeScreen={4} widescreen={4}>
          <LabelWrapper label={getTranslation('TicketCard_Amount')}>{commonAmount}</LabelWrapper>
        </Grid.Column>
        <Grid.Column mobile={8} tablet={5} computer={4} largeScreen={4} widescreen={4}>
          <LabelWrapper label={getTranslation('TicketCard_IssueDate')} dataTestId="originalTicked-issueDate">
            {(issueDate && getFormattedDate(issueDate)) || '-'}
          </LabelWrapper>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default OriginalTicketInfo;

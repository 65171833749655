import React, { useContext, useCallback } from 'react';
import { Button, Header, Loader } from 'semantic-ui-react';

import { getTranslation } from './../../../../utils/getTranslation';
import { getScheduledReportContext } from './../../../../shared/store/getScheduledReportContext';
import { API_REQUEST_STATUS } from './../../../../utils/APIHandlers';

import './styles.scss';

interface VerifyGetReportTokenProps {
  token: string;
}

const VerifyGetReportToken: React.FC<VerifyGetReportTokenProps> = ({ token }) => {
  const { getMFAGetScheduledReportStatus, getMFACodeGetScheduledReport } =
    useContext(getScheduledReportContext);
  const isGetCodeRequested = getMFAGetScheduledReportStatus === API_REQUEST_STATUS.REQUESTED;

  const handleSendCode = useCallback((): void => {
    void getMFACodeGetScheduledReport({ token });
  }, [getMFACodeGetScheduledReport, token]);

  return (
    <>
      {isGetCodeRequested && (
        <div className="container-mfaLoader" data-testid="mfaVerifyTokenLoader-wrapper">
          <Loader active size="large">
            {getTranslation('MFA_WaitingCode')}
          </Loader>
        </div>
      )}

      {!isGetCodeRequested && (
        <div className="sendMFACode-wrapper">
          <Header as="h2">{getTranslation('MFA_Title')}</Header>
          <span className="MFASubTitle">{getTranslation('MFA_SendCode_SubTitle')}</span>

          <div className="container-sendMFACode">
            <Button
              primary
              type="button"
              size="medium"
              fluid
              data-testid="sendMFACode-control"
              onClick={handleSendCode}
            >
              {getTranslation('MFA_Send_Code')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyGetReportToken;

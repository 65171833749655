import React, { ReactElement, useState, useCallback } from 'react';
import classNames from 'classnames';
import { Table } from 'semantic-ui-react';

import PaginationWrapper from '../../shared/controls/Pagination/PaginationWrapper';
import FullSizeLoader from '../../shared/view/Loader/FullSizeLoader';

import './styles.scss';

export type AdministrationTableBodyColumns = { [kei in string]: string | JSX.Element };

interface AdministrationTableProps<T> {
  isCompressedTable: boolean;
  isAllDataLoad: boolean;
  allRows: T[];
  tableHeaders: string[];
  countItemsPerPage?: number;
  totalItems?: number;
  activePage?: number;
  rowGenerate: (row: T) => { columns: AdministrationTableBodyColumns; rowKey: string };
  onPageChange?: (activePage: number) => void;
  openAuditInfo?: (item: T) => void;
  isLastColumnAutoWidth?: boolean;
  className?: string;
  containerClassName?: string;
}

const AdministrationTable = <T,>(props: AdministrationTableProps<T>): ReactElement => {
  const {
    onPageChange,
    countItemsPerPage,
    activePage,
    totalItems,
    isLastColumnAutoWidth,
    className,
    containerClassName,
  } = props;
  const [isSpinnerShow, setIsSpinnerShow] = useState(false);

  const handlePageChange = useCallback(
    (activePage: number) => {
      setIsSpinnerShow(true);

      onPageChange?.(activePage);

      setIsSpinnerShow(false);
    },
    [onPageChange]
  );

  return (
    <div className={classNames('administrationTable', containerClassName)}>
      {!props.isAllDataLoad ? (
        <FullSizeLoader />
      ) : (
        !!props.allRows.length && (
          <div className="table-container">
            <Table
              celled
              className={classNames(
                'table',
                className,
                { 'compressed-table': props.isCompressedTable },
                { 'blur-table': isSpinnerShow },
                { 'last-column-auto-width': isLastColumnAutoWidth }
              )}
              data-testid="administrationTable"
            >
              <Table.Header>
                <Table.Row>
                  {props.tableHeaders.map((title, i) => (
                    <Table.HeaderCell key={title || i} data-testid="administrationTable-headerCol">
                      {title}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {props.allRows.map((row) => {
                  const { columns, rowKey } = props.rowGenerate(row);
                  return (
                    <Table.Row
                      key={rowKey}
                      onClick={() => props.openAuditInfo?.(row)}
                      data-testid="administrationTable-bodyRow"
                    >
                      {Object.keys(columns).map((key, i) => {
                        return (
                          <Table.Cell
                            key={key}
                            className={`administrationTable-col-${i}`}
                            data-testid="administrationTable-bodyCol"
                          >
                            {columns[key]}
                          </Table.Cell>
                        );
                      })}
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>

            {onPageChange && typeof activePage === 'number' && totalItems && countItemsPerPage && (
              <PaginationWrapper
                activePage={activePage}
                countItemsPerPage={countItemsPerPage}
                totalItems={totalItems}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        )
      )}
    </div>
  );
};

export default AdministrationTable;

import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  DownloadReportTokenType,
  getScheduledReportContext,
} from '../../../../shared/store/getScheduledReportContext';
import DownloadReport from './../DownloadReport';
import { getDownloadReportTokenValue } from './helper';
import { errorPageCode, NO_ACCESS_REQUEST_ERROR_CODE } from './constants';
import VerifyGetReportToken from './../VerifyGetReportToken';
import VerifyGetReportMFACode from './../VerifyGetReportMFACode';
import { WithDownloadScheduledReport } from '../../../../shared/containers/withDownloadScheduledReport';
import AccessDeniedMessage from '../../AccessDeniedMessage';

const GetScheduledReportRouter: React.FC = () => {
  const { token, getMFARequestCode, report, setToken } = useContext(getScheduledReportContext);
  const [hasReport, setHasReport] = useState<boolean>(false);
  const [hasAuthenticateError, setHasAuthenticateError] = useState<boolean>(false);
  const { search } = useLocation();

  useEffect(() => {
    const downloadReportToken: DownloadReportTokenType = getDownloadReportTokenValue(search);
    setHasReport(report !== null);
    setToken(downloadReportToken);
  }, [report, setToken, setHasReport, search]);

  useEffect(() => {
    getMFARequestCode &&
      setHasAuthenticateError(NO_ACCESS_REQUEST_ERROR_CODE.includes(getMFARequestCode as errorPageCode));
  }, [getMFARequestCode, setHasAuthenticateError]);

  return (
    <>
      {(!token || hasAuthenticateError) && (
        <div data-testid="errorPagePattern-wrapper">
          <AccessDeniedMessage />
        </div>
      )}
      {token && getMFARequestCode === null && !hasReport && (
        <div data-testid="MFAScheduledReportVerifyTokenForm-wrapper">
          <VerifyGetReportToken token={token} />
        </div>
      )}
      {token && getMFARequestCode && !hasAuthenticateError && !hasReport && (
        <div data-testid="MFAScheduledReportVerifyCodeForm-wrapper">
          <VerifyGetReportMFACode />
        </div>
      )}
      {hasReport && (
        <div data-testid="getScheduledReport-wrapper">
          <WithDownloadScheduledReport>
            <DownloadReport />
          </WithDownloadScheduledReport>
        </div>
      )}
    </>
  );
};

export default GetScheduledReportRouter;

import React, { useContext, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import Home from '../pages/Home';
import PrivateRoute from '../features/PrivateRoute';
import Administration from '../pages/Administration';
import Help from '../pages/Help';
import NotFoundPage from '../features/NotFoundPage';
import { userContext } from '../shared/store/userContext';
import {
  pathReports,
  pathDocumentRepository,
  pathTickets,
  pathAdministration,
  pathHelp,
  pathSystem,
  pathHome,
  pathProfile,
  pathTicketInfo,
  pathExportJobsList,
  pathCreateExportJob,
  pathExportJob,
  pathHistoryExportJob,
  ExportJobIdKey,
  REDIRECT_URL_SESSION_NAME,
  pathAdministrationUsers,
  pathAdministrationDataAuthRoles,
  pathAdministrationFeatureGroups,
  pathReport,
  reportNameKey,
} from './constants';
import System from '../pages/System';
import Profile from '../pages/Profile';
import DocumentsRepository from '../pages/DocumentsRepository';
import TripsTicketsSearchPage from '../pages/TripsTicketsSearchPage';
import TripPage from '../pages/TripPage';
import ExportJobs from './../pages/ExportJobs';
import EditExportJob from './../pages/EditExportJob';
import ViewExportJob from './../pages/ViewExportJob';
import Reports from './../pages/Reports';
import { WithProfileUserInfo } from '../shared/containers/withProfileUserInfo';
import {
  hasDataAuthorizationManagementAccess,
  hasFeatureAccessManagementAccess,
  hasUserManagementAccess,
} from '../pages/Administration/helper';

const Routes: React.FC = () => {
  const userContextValue = useContext(userContext);
  const {
    isTicketsManagementHasReadWriteAccess,
    isTicketsManagementHasReadAccess,
    isScheduledReportsManagementHasReadAccess,
    isScheduledReportsManagementHasReadWriteAccess,
    isReportsReadAccess,
  } = userContextValue;
  const isUserManagementAccess = hasUserManagementAccess(userContextValue);
  const isDataAuthorizationManagementAccess = hasDataAuthorizationManagementAccess(userContextValue);
  const isFeatureAccessManagementAccess = hasFeatureAccessManagementAccess(userContextValue);
  const isAdministrationAccess =
    isUserManagementAccess || isDataAuthorizationManagementAccess || isFeatureAccessManagementAccess;
  const { pathname } = useLocation();
  const history = useHistory();
  const redirectUrl = sessionStorage.getItem(REDIRECT_URL_SESSION_NAME);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (redirectUrl && redirectUrl !== pathHome) {
      history.push(redirectUrl);
      sessionStorage.removeItem(REDIRECT_URL_SESSION_NAME);
    }
  }, [redirectUrl]);

  return (
    <>
      <Switch>
        <Route path={pathHome} exact component={Home} />
        <PrivateRoute
          exact
          path={[pathReports, pathReport(`:${reportNameKey}`)]}
          component={Reports}
          isAllow={isReportsReadAccess}
        />
        <Route exact path={pathAdministration}>
          {isFeatureAccessManagementAccess && <Redirect to={pathAdministrationFeatureGroups} />}
          {isDataAuthorizationManagementAccess && <Redirect to={pathAdministrationDataAuthRoles} />}
          {isUserManagementAccess && <Redirect to={pathAdministrationUsers} />}
        </Route>
        <PrivateRoute
          path={`${pathAdministration}/`}
          component={Administration}
          isAllow={isAdministrationAccess}
        />
        <Route path={pathHelp} component={Help} exact />
        <Route path={pathProfile} exact>
          <WithProfileUserInfo>
            <Profile />
          </WithProfileUserInfo>
        </Route>
        <Route path={pathDocumentRepository} component={DocumentsRepository} exact />
        <PrivateRoute
          exact
          path={`${pathTicketInfo}/:id`}
          component={TripPage}
          isAllow={isTicketsManagementHasReadAccess || isTicketsManagementHasReadWriteAccess}
        />
        <PrivateRoute
          exact
          path={pathTickets}
          component={TripsTicketsSearchPage}
          isAllow={isTicketsManagementHasReadAccess || isTicketsManagementHasReadWriteAccess}
        />

        <PrivateRoute
          path={pathExportJobsList}
          component={ExportJobs}
          isAllow={
            isScheduledReportsManagementHasReadAccess || isScheduledReportsManagementHasReadWriteAccess
          }
          exact
        />
        <PrivateRoute
          path={pathCreateExportJob}
          component={EditExportJob}
          exact
          isAllow={isScheduledReportsManagementHasReadWriteAccess}
        />
        <PrivateRoute
          path={pathExportJob(`:${ExportJobIdKey}`)}
          component={isScheduledReportsManagementHasReadWriteAccess ? EditExportJob : ViewExportJob}
          isAllow={
            isScheduledReportsManagementHasReadAccess || isScheduledReportsManagementHasReadWriteAccess
          }
          exact
        />
        <PrivateRoute
          path={pathHistoryExportJob(`:${ExportJobIdKey}`)}
          component={ExportJobs}
          isAllow={
            isScheduledReportsManagementHasReadAccess || isScheduledReportsManagementHasReadWriteAccess
          }
          exact
        />
        <PrivateRoute path={pathSystem} component={System} isAllow={isTicketsManagementHasReadWriteAccess} />
        <Route exact component={NotFoundPage} />
      </Switch>
    </>
  );
};
export default Routes;

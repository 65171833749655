import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { APIFetch } from '../utils/APIHandlers';
import { ErrorFetchResponse, RestApiResponse } from '../api/rest/models/RestApiResponse';
import { PetApi } from '../constants/apiParams';
import { RecordsAndTickets } from '../features/SystemOndemand/types';

export class ScheduleXX1Service {
  private readonly apolloClient: ApolloClient<NormalizedCacheObject>;
  private readonly endpoint: string;
  private readonly apiBaseUrl: string;

  constructor(apolloClient: ApolloClient<NormalizedCacheObject>, apiBaseUrl: string) {
    this.apolloClient = apolloClient;
    this.endpoint = `/v1/tickets/pet/schedule`;
    this.apiBaseUrl = apiBaseUrl;
  }

  public async put(
    recordsAndTickets: RecordsAndTickets
  ): Promise<RestApiResponse<string> | ErrorFetchResponse> {
    return await APIFetch<RestApiResponse<string>>('post', PetApi, this.endpoint, {
      body: recordsAndTickets,
    });
  }
}

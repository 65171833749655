import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';

import { AmplifyConfigurationModel } from './models/AmplifyConfigurationModel';
import { applicationLabels } from './glossary';
import { WithUser } from './shared/containers/withUser';
import { pathGetScheduledReport } from './routes/constants';
import GetScheduledReport from './publicApp/pages/GetScheduledReport';
import PrivateApp from './privateApp';

export interface BasicReportProps {
  config: AmplifyConfigurationModel;
}

const App: React.FC<BasicReportProps> = ({ config }) => {
  return (
    <Router>
      <Switch>
        <Route exact path={`${pathGetScheduledReport}`}>
          <GetScheduledReport config={config} />
        </Route>

        <Route path="/">
          <div data-testid="public-app-wrapper">
            <WithUser config={config}>
              <PrivateApp />
            </WithUser>
          </div>
        </Route>
      </Switch>
    </Router>
  );
};

I18n.setLanguage('en');
I18n.putVocabularies(applicationLabels);

export default App;

import { ReportIcon, ReportType } from './../../../api/graphql/models/ReportsResponse';
import { ReportViewModel } from './../../../models/ReportsAggregate/Report';
import { ReportFormViewModel } from './../../../models/ReportsAggregate/ReportForm';

export const IS_OPEN_REPORT_FORM_STATE = false;

export const defaultReportViewModel: ReportViewModel = {
  id: 0,
  displayName: '',
  reportName: '',
  isPaginated: false,
  icon: ReportIcon.ChartLine,
  reportType: ReportType.Analytical,
  description: '',
};

export const defaultReportFormFields: ReportFormViewModel = {
  displayName: '',
  reportName: '',
  isPaginated: false,
  icon: String(ReportIcon.ChartLine),
  reportType: String(ReportType.Analytical),
  description: '',
};

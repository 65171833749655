import React from 'react';
import { Segment } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';

import ExportJobDetailsWrapper from '../ExportJobHistory/ExportJobDetailsWrapper';
import ExportJobHistoryListWrapper from './ExportJobHistoryListWrapper';
import { ExportJobIdKey } from '../../../routes/constants';
import './styles.scss';

const ExportJobHistoryContent: React.FC = () => {
  const params = useParams<{ [ExportJobIdKey]?: string }>();
  const exportJobId = params[ExportJobIdKey] || '';

  return (
    <Segment.Group raised horizontal className="enableExportJobData">
      <Segment className="container-jobHistoryDetails">
        <ExportJobDetailsWrapper exportJobId={exportJobId} />
      </Segment>

      <Segment className="container-jobHistoryList">
        <ExportJobHistoryListWrapper exportJobId={exportJobId} />
      </Segment>
    </Segment.Group>
  );
};

export default ExportJobHistoryContent;

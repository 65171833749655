import React from 'react';
import { Card, Loader } from 'semantic-ui-react';

import './styles.scss';

interface ReportCardWireFrameProps {
  dataTestId?: string;
}

const ReportCardWireFrame: React.FC<ReportCardWireFrameProps> = ({ dataTestId }) => (
  <Card className="loadingReportCard" data-testid={dataTestId}>
    <Card.Content className="loadingReportCard-loader">
      <Loader size="large" />
    </Card.Content>
  </Card>
);

export default ReportCardWireFrame;

import React, { useContext, useState } from 'react';
import { NormalizedCacheObject, ApolloClient } from '@apollo/client';

import { WithApolloClient } from '../../../features/WithApolloClient/WithApolloClient';
import { userContext } from '../../store/userContext';
import { FeatureAccessGroupFormViewModel } from '../../../models/FeatureAccessGroupsAggregate/FeatureAccessGroup';
import { useNotification, useNotificationReturn } from '../../hooks/notificationHook';
import { getTranslation } from '../../../utils/getTranslation';
import {
  CreateFeatureAccessGroupContextProps,
  defaultCreateFeatureAccessGroupContextValue,
  createFeatureAccessGroupContext,
} from '../../store/FeatureAccessGroups/createFeatureAccessGroupContext';
import { FeatureAccessGroupService } from '../../../services/FeatureAccessGroupService';
import { mapCreateFeatureAccessGroup } from '../../../features/AdministrationContainer/FeatureAccessGroups/featureAccessGroupsMapper';
import { getUIErrorMessage } from './../../../utils/errorHandler';

interface Props {
  children: React.ReactChild;
  client?: ApolloClient<NormalizedCacheObject>;
}

const CreateFeatureAccessGroup: React.FC<Props> = ({ children, client }) => {
  const { email } = useContext(userContext);
  const [requestCreateFeatureAccessGroupMessage, setRequestCreateFeatureAccessGroupMessage] =
    useState<string>(defaultCreateFeatureAccessGroupContextValue.requestCreateFeatureAccessGroupMessage);
  const [
    { notificationStatus: requestCreateFeatureAccessGroupStatus },
    {
      setNotRequestedNotificationStatus: setNotRequestedCreateFeatureAccessGroup,
      setRequestedNotificationStatus: setRequestedCreateFeatureAccessGroup,
      setSuccessNotificationStatus: setSuccessCreateFeatureAccessGroup,
      setFailNotificationStatus: setFailCreateFeatureAccessGroup,
    },
  ]: useNotificationReturn = useNotification();

  const createFeatureAccessGroup = async (
    data: FeatureAccessGroupFormViewModel
  ): Promise<string | undefined | void> => {
    try {
      const clientApollo = client as ApolloClient<NormalizedCacheObject>;
      const featureGroupsService: FeatureAccessGroupService = new FeatureAccessGroupService(clientApollo);
      setRequestedCreateFeatureAccessGroup();

      await featureGroupsService.insert(mapCreateFeatureAccessGroup(data, email));

      setSuccessCreateFeatureAccessGroup();
      setRequestCreateFeatureAccessGroupMessage(
        `${getTranslation('FeatureAccessGroupsForm_FeatureGroup')} ${data.groupName} ${getTranslation(
          'successMessage'
        )}`
      );
    } catch (error) {
      setFailCreateFeatureAccessGroup();

      return getUIErrorMessage(
        (error as Error)?.message,
        'duplicate',
        `${getTranslation('FeatureGroup')} ${data.groupName}`
      );
    }
  };

  const clearCreateFeatureAccessGroupState = () => {
    setNotRequestedCreateFeatureAccessGroup();
    setRequestCreateFeatureAccessGroupMessage(
      defaultCreateFeatureAccessGroupContextValue.requestCreateFeatureAccessGroupMessage
    );
  };

  const getCreateFeatureAccessGroupContext = (): CreateFeatureAccessGroupContextProps => {
    return {
      ...defaultCreateFeatureAccessGroupContextValue,
      createFeatureAccessGroup,
      requestCreateFeatureAccessGroupMessage,
      requestCreateFeatureAccessGroupStatus,
      clearCreateFeatureAccessGroupState,
    };
  };

  return (
    <createFeatureAccessGroupContext.Provider value={getCreateFeatureAccessGroupContext()}>
      {children}
    </createFeatureAccessGroupContext.Provider>
  );
};

const WithCreateFeatureAccessGroup = WithApolloClient(CreateFeatureAccessGroup);

export { WithCreateFeatureAccessGroup };

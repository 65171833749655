import React from 'react';

import './styles.scss';

interface RoundFirstLetterInfoProps {
  first?: string;
  second?: string;
}

const RoundFirstLetterInfo: React.FC<RoundFirstLetterInfoProps> = ({ first, second }) => {
  return (
    <div className="round-initial">
      {first?.[0]}
      {second?.[0]}
    </div>
  );
};

export default RoundFirstLetterInfo;

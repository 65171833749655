import React from 'react';
import { getTranslation } from '../../utils/getTranslation';
import './index.scss';

const AuthBannerContent = (): JSX.Element => {
  return (
    <div className="info" data-i18n="[html]content.body">
      <div className="text">
        {getTranslation('Authentication_HeaderInfo1')}
        <br />
        {getTranslation('Authentication_HeaderInfo2')}
      </div>
      <h1> {getTranslation('Authentication_Header')}</h1>
    </div>
  );
};

export default AuthBannerContent;

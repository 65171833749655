import React from 'react';
import { Icon } from 'semantic-ui-react';

import './styles.scss';

interface Props {
  onClick?: () => void;
}

const CloseControl: React.FC<Props> = ({ onClick }) => {
  return (
    <div className="close-icon" onClick={onClick} data-testid="close-icon-control">
      <Icon name="close" />
    </div>
  );
};

export default CloseControl;

import React, { useEffect, useState } from 'react';
import { Container } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';

import { ExportJobIdKey } from '../../../routes/constants';
import CreateExportJobFormWrapper from './CreateExportJobFormWrapper';
import EditExportJobFormWrapper from './EditExportJobFormWrapper';
import { WithCreateJob } from '../../../shared/containers/withCreateExportJob';
import { WithExportJob } from '../../../shared/containers/withExportJob';
import './styles.scss';

const EditExportJob: React.FC = () => {
  const [exportJobId, setReportId] = useState<string | null>(null);
  const params = useParams<{ [ExportJobIdKey]?: string }>();

  useEffect((): void => {
    const exportJobId = params[ExportJobIdKey] || null;

    setReportId(exportJobId);
  }, []);

  return (
    <div className="container-editExportJob" data-testid="exportJob">
      <Container className="container-exportJobContainer">
        <>
          {exportJobId === null && (
            <>
              <WithCreateJob>
                <div data-testid="wrapper-createExportJob-form">
                  <CreateExportJobFormWrapper />
                </div>
              </WithCreateJob>
            </>
          )}

          {exportJobId !== null && (
            <>
              <WithExportJob>
                <WithCreateJob>
                  <div data-testid="wrapper-editExportJob-form">
                    <EditExportJobFormWrapper exportJobId={exportJobId} />
                  </div>
                </WithCreateJob>
              </WithExportJob>
            </>
          )}
        </>
      </Container>
    </div>
  );
};

export default EditExportJob;

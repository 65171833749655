import React from 'react';
import { NormalizedCacheObject, ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { Auth } from 'aws-amplify';
import * as Realm from 'realm-web';

import { AmplifyConfigurationModel } from '../../models/AmplifyConfigurationModel';
import { savedLogout } from '../../utils/APIHandlers';

interface Props {
  children: React.ReactChild;
  config: AmplifyConfigurationModel;
}

export const getApolloClient = (config: AmplifyConfigurationModel): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    link: new HttpLink({
      uri: config.graphqlEndpoint,
      fetch: async (uri, options) => {
        if (options?.headers) {
          const accessToken = await getValidAccessToken(config);
          (options.headers as { [key: string]: string }).Authorization = `Bearer ${accessToken}`;
        }
        return fetch(uri, options);
      },
    }),
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });
};

export const getValidAccessToken = async (config: AmplifyConfigurationModel): Promise<string> => {
  const app: Realm.App = new Realm.App({ id: config.realmAppId });
  const token = await getCognitoJWTToken();

  try {
    if (!token) {
      return '';
    } else if (app.currentUser) {
      await app.currentUser.refreshCustomData();
    } else {
      await app.logIn(Realm.Credentials.jwt(token));
    }
    return app.currentUser?.accessToken || '';
  } catch (e) {
    console.error(e);
    await savedLogout();
    return '';
  }
};

export const WithApolloProvider: React.FC<Props> = ({ children, config }) => {
  return <ApolloProvider client={getApolloClient(config)}>{children}</ApolloProvider>;
};

export const getCognitoJWTToken = async (): Promise<string | undefined> => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (e) {
    console.error(e);
    await savedLogout();
  }
};

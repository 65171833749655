import React from 'react';

import { WithApolloClient } from '../../features/WithApolloClient/WithApolloClient';
import { WithTripsTickets } from '../../shared/containers/withTripsTickets';
import TripsTicketsSearchForm from '../../features/TripsTicketsSearchContainer/TripsTicketsSearchForm';
import TicketTableWrapper from '../../features/TripsTicketsSearchContainer/TripsTicketsSearchTable/TripsTicketsSearchWrapper';

import './styles.scss';

const TripsTicketsSearchPage: React.FC = () => {
  return (
    <div className="tickets-container container">
      <WithTripsTickets>
        <>
          <TripsTicketsSearchForm />

          <TicketTableWrapper />
        </>
      </WithTripsTickets>
    </div>
  );
};

export default WithApolloClient(TripsTicketsSearchPage);

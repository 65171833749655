import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { GET_BY_ID } from '../api/graphql/queries/TicketsQueries';
import { ApolloClientResponse } from '../api/graphql/models/common';
import { GetTripByIdResponse } from '../api/graphql/models/Trip';
import { TripsTicketsSearchItemDTO, TripsTicketsSearchRequestDTO } from '../api/rest/models/TripsTickets';
import { ErrorFetchResponse, RestApiResponse } from '../api/rest/models/RestApiResponse';
import { APIFetch } from '../utils/APIHandlers';
import { PetApi } from '../constants/apiParams';

export class TicketsService {
  private readonly apolloClient: ApolloClient<NormalizedCacheObject>;
  private readonly endpoint: string;

  constructor(apolloClient: ApolloClient<NormalizedCacheObject>) {
    this.apolloClient = apolloClient;
    this.endpoint = '/v1/tickets/pet/search';
  }

  public async searchTripsTickets(
    payload: TripsTicketsSearchRequestDTO
  ): Promise<RestApiResponse<TripsTicketsSearchItemDTO[]> | ErrorFetchResponse> {
    return await APIFetch<RestApiResponse<TripsTicketsSearchItemDTO[]>>('post', PetApi, this.endpoint, {
      body: payload,
    });
  }

  public async getById(id?: string): Promise<ApolloClientResponse<GetTripByIdResponse>> {
    return await this.apolloClient.query({
      query: GET_BY_ID,
      variables: { id },
    });
  }
}

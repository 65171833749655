import React, { useState } from 'react';
import { NormalizedCacheObject, ApolloClient } from '@apollo/client';

import { WithApolloClient } from '../../features/WithApolloClient/WithApolloClient';
import {
  updateReportContext,
  defaultUpdateReportContextValue,
  UpdateReportContextProps,
} from '../store/updateReportContext';
import { API_REQUEST_STATUS, RequestStatus } from '../../utils/APIHandlers';
import { ReportTypesService } from '../../services/ReportTypesService';
import { useNotification, useNotificationReturn } from '../hooks/notificationHook';
import { getTranslation } from '../../utils/getTranslation';
import { getUIErrorMessage } from '../../utils/errorHandler';
import { ReportTypeDto } from '../../api/graphql/models/ReportTypeDto';

interface Props {
  children: React.ReactChild;
  client?: ApolloClient<NormalizedCacheObject>;
}

const UpdateReport: React.FC<Props> = ({ children, client }) => {
  const [requestUpdateReportStatus, setRequestUpdateReportsStatus] = useState<RequestStatus>(
    API_REQUEST_STATUS.NOT_REQUESTED
  );
  const [
    { notificationStatus },
    { setFailNotificationStatus, setSuccessNotificationStatus, setNotRequestedNotificationStatus },
  ]: useNotificationReturn = useNotification();
  const [requestUpdateReportMessage, setRequestUpdateReportMessage] = useState<string>('');

  const updateReport = async ({ id, report }: { id: number; report: ReportTypeDto }) => {
    try {
      const clientApollo = client as ApolloClient<NormalizedCacheObject>;
      const reportTypesService: ReportTypesService = new ReportTypesService(clientApollo);
      setRequestUpdateReportsStatus(API_REQUEST_STATUS.REQUESTED);

      await reportTypesService.update(id, report);

      setRequestUpdateReportsStatus(API_REQUEST_STATUS.REQUEST_SUCCESS);
      setRequestUpdateReportMessage(
        `${getTranslation('Reports_Report')} ${report.displayName} ${getTranslation('successMessage')}`
      );
      setSuccessNotificationStatus();
    } catch (error) {
      console.error(error);
      setRequestUpdateReportsStatus(API_REQUEST_STATUS.REQUEST_FAILURE);
      setRequestUpdateReportMessage(
        getUIErrorMessage(
          (error as Error)?.message,
          'duplicate',
          `${getTranslation('Report')} ${report.displayName}`
        )
      );
      setFailNotificationStatus();
    }
  };

  const clearUpdateReportState = () => {
    setNotRequestedNotificationStatus();
    setRequestUpdateReportMessage('');
    setRequestUpdateReportsStatus(API_REQUEST_STATUS.NOT_REQUESTED);
  };

  const getUpdateReportContext = (): UpdateReportContextProps => {
    return {
      ...defaultUpdateReportContextValue,
      updateReport,
      clearUpdateReportState,
      requestUpdateReportMessage,
      requestUpdateReportStatus,
      notificationUpdateReportStatus: notificationStatus,
    };
  };

  return (
    <updateReportContext.Provider value={getUpdateReportContext()}>{children}</updateReportContext.Provider>
  );
};

const WithUpdateReport = WithApolloClient(UpdateReport);

export { WithUpdateReport };

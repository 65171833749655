import React, { useContext } from 'react';
import { Table } from 'semantic-ui-react';

import { getTranslation } from '../../../utils/getTranslation';
import { documentContext } from '../../../shared/store/documentsContext';
import TableDocumentRow from './TableDocumentRow';

import './styles.scss';

const DocumentsTable: React.FC = () => {
  const { documents } = useContext(documentContext);

  return (
    <Table basic="very" celled collapsing className="documentsTable">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{getTranslation('Name')}</Table.HeaderCell>
          <Table.HeaderCell>{getTranslation('DocumentsRepository_LastModified')}</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">{getTranslation('DocumentsRepository_Size')}</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {documents.map((document) => (
          <TableDocumentRow {...document} key={document.name} />
        ))}
      </Table.Body>
    </Table>
  );
};

export default DocumentsTable;

import React, { useContext, useState } from 'react';
import { Header, Message } from 'semantic-ui-react';
import './styles.scss';
import { getTranslation } from '../../utils/getTranslation';

import { WithApolloClient } from '../WithApolloClient/WithApolloClient';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { RecordsAndTickets } from './types';
import { DropdownViewModel } from '../../models/DropdownViewModel';
import { ScheduleXX1Service } from '../../services/ScheduleXX1Service';
import { RestApiResponse } from '../../api/rest/models/RestApiResponse';
import { userContext } from '../../shared/store/userContext';
import PetForm from '../PetForm';
import SystemOndemandForm from './SystemOndemandForm';
import { recordsAndTicketsValues } from './SystemOndemandForm/constants';
import FullSizeLoader from '../../shared/view/Loader/FullSizeLoader';

interface Props {
  client?: ApolloClient<NormalizedCacheObject>;
}

const SystemOndemand: React.FC<Props> = (props) => {
  const [recordLocators, setRecordLocators] = useState<string[]>([]);
  const [ticketNumbers, setTicketNumbers] = useState<string[]>([]);
  const [recordLocatorsOptions, setRecordLocatorsOptions] = useState<DropdownViewModel[]>([]);
  const [ticketNumbersOptions, setTicketNumbersOptions] = useState<DropdownViewModel[]>([]);
  const [message, setMessage] = useState('');
  const [isMessageShow, setIsMessageShow] = useState(false);
  const [isSpinnerShow, setIsSpinnerShow] = useState(false);
  const [invalidateRecordLocators, setInvalidateRecordLocators] = useState<string[]>([]);
  const [invalidateTicketNumbers, setInvalidateTicketNumbers] = useState<string[]>([]);
  const [isRecordLocatorsLimitExceeded, setIsRecordLocatorsLimitExceeded] = useState<boolean>(false);
  const [isTicketNumbersLimitExceeded, setIsTicketNumbersLimitExceeded] = useState<boolean>(false);

  const { apiBaseUrl, ticketNumbersLimit, recordLocatorsLimit } = useContext(userContext);

  const client = props.client as ApolloClient<NormalizedCacheObject>;
  const scheduleXX1Service: ScheduleXX1Service = new ScheduleXX1Service(client, apiBaseUrl);

  const checkFormValidation = () => {
    if (!recordLocators.length && !ticketNumbers.length) {
      return getTranslation('Systems_RequiredField');
    }
  };

  const onClearHandler = () => {
    setRecordLocators([]);
    setTicketNumbers([]);
    setRecordLocatorsOptions([]);
    setTicketNumbersOptions([]);
    setInvalidateTicketNumbers([]);
    setInvalidateRecordLocators([]);
    setIsRecordLocatorsLimitExceeded(false);
    setIsTicketNumbersLimitExceeded(false);
  };

  const onSaveHandler = async (savedFormData: RecordsAndTickets) => {
    setIsSpinnerShow(true);

    const response = await scheduleXX1Service.put(savedFormData);

    if ((response as RestApiResponse<string>)?.code === 200) {
      setIsSpinnerShow(false);
      setMessage(getTranslation('Systems_SuccessMessage'));

      setIsMessageShow(true);

      setTimeout(() => {
        setIsMessageShow(false);
      }, 4000);
      onClearHandler();
    } else {
      setIsSpinnerShow(false);
      return getTranslation('CommonError');
    }
  };

  return (
    <div className="system container">
      {isSpinnerShow && <FullSizeLoader />}
      <Header as="h1">{getTranslation('Systems_System')}</Header>
      {isMessageShow && <Message success content={message} />}
      <PetForm<RecordsAndTickets>
        cancelButtonClicked={onClearHandler}
        submitForm={onSaveHandler}
        formClass={'form-container left'}
        warningMessage={getTranslation('System_WarningMessage')}
        textActionButtonWarningModal={getTranslation('Submit')}
        textActionButton={getTranslation('Submit')}
        textSecondButton={getTranslation('Clear')}
        isClearBtn={true}
        onClearHandler={onClearHandler}
        checkFormValidation={checkFormValidation}
        isSubmitDisabled={isTicketNumbersLimitExceeded || isRecordLocatorsLimitExceeded}
      >
        <SystemOndemandForm
          editFormData={recordsAndTicketsValues}
          recordLocatorsOptions={recordLocatorsOptions}
          setRecordLocatorsOptions={setRecordLocatorsOptions}
          ticketNumbersOptions={ticketNumbersOptions}
          setTicketNumbersOptions={setTicketNumbersOptions}
          recordLocators={recordLocators}
          ticketNumbers={ticketNumbers}
          setRecordLocators={setRecordLocators}
          setTicketNumbers={setTicketNumbers}
          invalidateRecordLocators={invalidateRecordLocators}
          invalidateTicketNumbers={invalidateTicketNumbers}
          setInvalidateRecordLocators={setInvalidateRecordLocators}
          setInvalidateTicketNumbers={setInvalidateTicketNumbers}
          isRecordLocatorsLimitExceeded={isRecordLocatorsLimitExceeded}
          isTicketNumbersLimitExceeded={isTicketNumbersLimitExceeded}
          setIsRecordLocatorsLimitExceeded={setIsRecordLocatorsLimitExceeded}
          setIsTicketNumbersLimitExceeded={setIsTicketNumbersLimitExceeded}
          ticketNumbersLimit={ticketNumbersLimit}
          recordLocatorsLimit={recordLocatorsLimit}
        />
      </PetForm>
    </div>
  );
};

export default WithApolloClient(SystemOndemand);

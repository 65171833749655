import React from 'react';
import classNames from 'classnames';
import { Form } from 'semantic-ui-react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form/dist/types';

import FormFieldLabel from '../../view/FormFieldLabel/FormFieldLabel';

export interface TextAreaFieldProps {
  required?: boolean;
  label: string | React.ReactChild;
  error?: FieldError;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  dataTestId?: string;
  register?: UseFormRegisterReturn;
}

const TextAreaField: React.FC<TextAreaFieldProps> = ({
  required,
  label,
  error,
  disabled,
  placeholder,
  className,
  dataTestId,
  register,
}) => {
  return (
    <Form.Field error={Boolean(error)}>
      <FormFieldLabel required={required}>{label}</FormFieldLabel>
      <textarea
        className={classNames({ error: error, className: className })}
        placeholder={placeholder}
        disabled={disabled}
        data-testid={dataTestId}
        {...register}
      />
      {error && (
        <div className="formError" data-testid="textAreaField-errorMessage">
          {error.message}
        </div>
      )}
    </Form.Field>
  );
};

export default TextAreaField;

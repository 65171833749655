import React from 'react';

import { PetUserFormViewModel } from '../../../models/PetUsersAggregate/PetUser';
import { RequestStatus, API_REQUEST_STATUS } from '../../../utils/APIHandlers';

export interface CreatePetUserContextProps {
  createPetUser: (data: PetUserFormViewModel) => Promise<string | undefined | void>;
  requestCreatePetUserMessage: string;
  requestCreatePetUserStatus: RequestStatus;
  clearCreatePetUserState: () => void;
}

export const defaultCreatePetUserContextValue: CreatePetUserContextProps = {
  createPetUser: async () => new Promise(() => void 0),
  requestCreatePetUserMessage: '',
  requestCreatePetUserStatus: API_REQUEST_STATUS.NOT_REQUESTED,
  clearCreatePetUserState: () => void 0,
};

const createPetUserContext = React.createContext<CreatePetUserContextProps>(defaultCreatePetUserContextValue);

export { createPetUserContext };

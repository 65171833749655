import React, { useContext, useEffect } from 'react';
import { Icon, Message, Table } from 'semantic-ui-react';
import classNames from 'classnames';
import { useHistory, useParams } from 'react-router-dom';

import TableLabel from '../../../TableLabel';
import DropdownMenu from '../../../AdministrationTable/DropdownMenu';
import { getTranslation } from '../../../../utils/getTranslation';
import { countItemsPerPage } from '../../../../pages/Administration/constant';
import PaginationWrapper from '../../../../shared/controls/Pagination/PaginationWrapper';
import { userContext } from '../../../../shared/store/userContext';
import MessageWithControl from '../../../../shared/controls/MessageWithControl';
import TextButton from '../../../../shared/controls/TextButton';
import EmptyMessage from '../../../../shared/view/EmptyMessage/EmptyMessage';
import { featureAccessGroupsTableAdditionalHeaders, featureAccessGroupsTableHeader } from './constants';
import { paginatedFeatureAccessGroupsContext } from '../../../../shared/store/FeatureAccessGroups/paginatedFeatureAccessGroupsContext';
import { getRequestStatuses } from '../../../../utils/APIHandlers';
import { mapGridFeatureAccessGroup } from '../featureAccessGroupsMapper';
import { getAccessTypesConfig, getAccessTypesReportConfig } from '../constants';
import { FeatureAccessGroupGridViewModel } from '../../../../models/FeatureAccessGroupsAggregate/FeatureAccessGroup';
import {
  featureGroupKey,
  pathAdministrationFeatureGroupsEdit,
  pathAdministrationFeatureGroupsView,
} from '../../../../routes/constants';
import { updateFeatureAccessGroupContext } from '../../../../shared/store/FeatureAccessGroups/updateFeatureAccessGroupContext';
import { createFeatureAccessGroupContext } from '../../../../shared/store/FeatureAccessGroups/createFeatureAccessGroupContext';
import { hasDataAccess } from './../helper';
import { hasReportsManagementAccess } from '../../../../pages/Administration/helper';
import NoAccessMessageWrapper from '../FeatureAccessGroupsMessages/NoAccessMessageWrapper';
import FullSizeLoader from '../../../../shared/view/Loader/FullSizeLoader';

import './styles.scss';

const FeatureAccessGroupsTable: React.FC = () => {
  const userContextValue = useContext(userContext);
  const { isFeatureAccessManagementReadWriteAccess } = userContextValue;
  const {
    getFeatureAccessGroups,
    requestFeatureAccessGroupsStatus,
    featureAccessGroups,
    activePageFeatureAccessGroups,
    totalFeatureAccessGroups,
  } = useContext(paginatedFeatureAccessGroupsContext);
  const { requestUpdateFeatureAccessGroupStatus } = useContext(updateFeatureAccessGroupContext);
  const { requestCreateFeatureAccessGroupStatus } = useContext(createFeatureAccessGroupContext);
  const requestFeatureAccessGroups = getRequestStatuses(requestFeatureAccessGroupsStatus);
  const requestUpdateFeatureAccessGroup = getRequestStatuses(requestUpdateFeatureAccessGroupStatus);
  const requestCreateFeatureAccessGroup = getRequestStatuses(requestCreateFeatureAccessGroupStatus);
  const accessTypesConfig = getAccessTypesConfig();
  const accessTypesReportConfig = getAccessTypesReportConfig();
  const history = useHistory();
  const params = useParams<{ [featureGroupKey]?: string }>();
  const featureAccessGroupId = params[featureGroupKey];

  useEffect(() => {
    void getFeatureAccessGroups();
  }, []);

  const onTablePageChange = (activePage: number) => {
    void getFeatureAccessGroups({ page: activePage });
  };

  useEffect(() => {
    if (requestUpdateFeatureAccessGroup.REQUEST_SUCCESS || requestCreateFeatureAccessGroup.REQUEST_SUCCESS) {
      void getFeatureAccessGroups();
    }
  }, [requestUpdateFeatureAccessGroup, requestCreateFeatureAccessGroup, getFeatureAccessGroups]);

  return (
    <>
      {requestFeatureAccessGroups.REQUEST_FAILURE && (
        <Message error data-testid="errorResponseFeatureAccessGroups-wrapper">
          <MessageWithControl message={getTranslation('App_LoadingError')}>
            <TextButton
              text={getTranslation('Retry_Now')}
              onClick={() => void getFeatureAccessGroups()}
              dataTestId="errorResponseFeatureAccessGroups-retry-control"
            />
          </MessageWithControl>
        </Message>
      )}

      <div className="featureAccessGroupsTable-container">
        {requestFeatureAccessGroups.REQUESTED && (
          <FullSizeLoader dataTestId="loaderFeatureAccessGroups-wrapper" />
        )}

        {(requestFeatureAccessGroups.REQUEST_SUCCESS ||
          (requestFeatureAccessGroups.REQUESTED && featureAccessGroups.length > 0)) && (
          <>
            {!hasReportsManagementAccess(userContextValue) && (
              <NoAccessMessageWrapper dataTestId="tableFeatureAccessGroups" />
            )}

            <Table
              celled
              className={classNames({ 'last-column-auto-width': !isFeatureAccessManagementReadWriteAccess })}
              data-testid="tableFeatureAccessGroups-wrapper"
            >
              <Table.Header>
                <Table.Row data-testid="tableFeatureAccessGroupsHeader">
                  {featureAccessGroupsTableHeader.map((item) => (
                    <Table.HeaderCell key={item.field} data-testid="tableFeatureAccessGroupsHeader-column">
                      {!hasDataAccess(item.field, userContextValue) && (
                        <Icon
                          name="exclamation triangle"
                          color="yellow"
                          className="leftIcon"
                          data-testid={`tableFeatureAccessGroupsHeader-NoAccess-${item.field}-column`}
                        />
                      )}
                      {getTranslation(item.title)}
                    </Table.HeaderCell>
                  ))}
                  {isFeatureAccessManagementReadWriteAccess && (
                    <Table.HeaderCell
                      key={featureAccessGroupsTableAdditionalHeaders[0].field}
                      data-testid="tableFeatureAccessGroupsHeader-column"
                    />
                  )}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {featureAccessGroups.map((group) => {
                  const gridGroup = mapGridFeatureAccessGroup(group);
                  const {
                    id,
                    groupName,
                    reportManagement,
                    userManagement,
                    ticketsManagement,
                    scheduledReportsManagement,
                    featureAccessManagement,
                    dataAuthorizationManagement,
                    countReports,
                  } = gridGroup;

                  return (
                    <Table.Row
                      key={id}
                      active={id === featureAccessGroupId}
                      onClick={() => history.push(pathAdministrationFeatureGroupsView(id))}
                      data-testid="tableFeatureAccessGroupsContent-row"
                    >
                      <Table.Cell>{groupName}</Table.Cell>
                      <Table.Cell>
                        {<TableLabel accessTypeConfig={accessTypesConfig[userManagement]} />}
                      </Table.Cell>
                      <Table.Cell>
                        <TableLabel accessTypeConfig={accessTypesConfig[ticketsManagement]} />
                      </Table.Cell>
                      <Table.Cell>
                        <TableLabel accessTypeConfig={accessTypesConfig[featureAccessManagement]} />
                      </Table.Cell>
                      <Table.Cell>
                        <TableLabel accessTypeConfig={accessTypesConfig[dataAuthorizationManagement]} />
                      </Table.Cell>
                      <Table.Cell>
                        <TableLabel accessTypeConfig={accessTypesConfig[scheduledReportsManagement]} />
                      </Table.Cell>
                      <Table.Cell>
                        <TableLabel accessTypeConfig={accessTypesReportConfig[reportManagement]} />
                      </Table.Cell>
                      <Table.Cell>
                        {hasReportsManagementAccess(userContextValue) && (
                          <span data-testid="tableFeatureAccessGroupsContent-row-countReports-column">
                            {countReports}
                          </span>
                        )}
                      </Table.Cell>
                      {isFeatureAccessManagementReadWriteAccess && (
                        <Table.Cell>
                          <DropdownMenu<FeatureAccessGroupGridViewModel>
                            openEditForm={() => history.push(pathAdministrationFeatureGroupsEdit(id))}
                            row={gridGroup}
                            dataTestId="tableFeatureAccessGroupsContentDropdown-control"
                          />
                        </Table.Cell>
                      )}
                    </Table.Row>
                  );
                })}
                {featureAccessGroups.length === 0 && (
                  <Table.Row disabled data-testid="emptyTableFeatureAccessGroupsContent-wrapper">
                    <Table.Cell
                      colSpan={
                        featureAccessGroupsTableHeader.length +
                        Number(isFeatureAccessManagementReadWriteAccess)
                      }
                    >
                      <EmptyMessage
                        optionalText={getTranslation('No_FeatureAccessGroups')}
                        dataTestId="featureAccessGroups-empty-message"
                      />
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>

            <PaginationWrapper
              activePage={activePageFeatureAccessGroups}
              countItemsPerPage={countItemsPerPage}
              totalItems={totalFeatureAccessGroups}
              onPageChange={onTablePageChange}
            />
          </>
        )}
      </div>
    </>
  );
};

export default FeatureAccessGroupsTable;

import React from 'react';
import classNames from 'classnames';
import { Form, Input } from 'semantic-ui-react';
import { FieldError } from 'react-hook-form/dist/types';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';

import FormFieldLabel from '../../../shared/view/FormFieldLabel/FormFieldLabel';

import './styles.scss';

interface InputWithInfoProps {
  label: string | React.ReactChild;
  error?: FieldError;
  register?: UseFormRegisterReturn;
  disabled?: boolean;
  className?: string;
  type?: string;
  size?: 'big' | 'small' | 'mini' | 'large' | 'huge' | 'massive';
  placeholder?: string;
  required?: boolean;
  dataTestId?: string;
}

const InputWithInfo: React.FC<InputWithInfoProps> = ({
  label,
  error,
  disabled,
  register,
  className,
  type,
  size,
  placeholder,
  required,
  dataTestId,
}) => {
  return (
    <Form.Field>
      <FormFieldLabel required={required}>{label}</FormFieldLabel>
      <Input
        className={classNames({ error: error })}
        disabled={disabled}
        type={type}
        size={size || 'small'}
        placeholder={placeholder || ''}
        data-testid={dataTestId}
      >
        <input {...register} className={className} />
      </Input>
      {error && <div className="formError">{error.message}</div>}
    </Form.Field>
  );
};

export default InputWithInfo;

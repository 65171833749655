import React from 'react';
import { PetUserViewModel } from '../../../models/PetUsersAggregate/PetUser';

import { RequestStatus, API_REQUEST_STATUS } from '../../../utils/APIHandlers';

export interface PetUserContextProps {
  getPetUser: (id: string) => Promise<void>;
  petUser: PetUserViewModel | null;
  requestPetUserStatus: RequestStatus;
}

export const defaultPetUserContextValue: PetUserContextProps = {
  getPetUser: async () => new Promise(() => void 0),
  petUser: null,
  requestPetUserStatus: API_REQUEST_STATUS.NOT_REQUESTED,
};

const petUserContext = React.createContext<PetUserContextProps>(defaultPetUserContextValue);

export { petUserContext };

import React, { useState } from 'react';
import { NormalizedCacheObject, ApolloClient } from '@apollo/client';

import { WithApolloClient } from '../../features/WithApolloClient/WithApolloClient';
import {
  insertReportContext,
  defaultInsertReportContextValue,
  InsertReportContextProps,
} from '../../shared/store/insertReportContext';
import { API_REQUEST_STATUS, RequestStatus } from '../../utils/APIHandlers';
import { ReportTypeDto } from '../../api/graphql/models/ReportTypeDto';
import { ReportTypesService } from '../../services/ReportTypesService';
import { useNotification, useNotificationReturn } from '../hooks/notificationHook';
import { getTranslation } from '../../utils/getTranslation';
import { getUIErrorMessage } from '../../utils/errorHandler';

interface Props {
  children: React.ReactChild;
  client?: ApolloClient<NormalizedCacheObject>;
}

const InsertReport: React.FC<Props> = ({ children, client }) => {
  const [requestInsertReportStatus, setRequestInsertReportsStatus] = useState<RequestStatus>(
    API_REQUEST_STATUS.NOT_REQUESTED
  );
  const [
    { notificationStatus },
    { setFailNotificationStatus, setSuccessNotificationStatus, setNotRequestedNotificationStatus },
  ]: useNotificationReturn = useNotification();
  const [requestInsertReportMessage, setRequestInsertReportMessage] = useState<string>('');

  const insertReport = async ({ report }: { report: ReportTypeDto }) => {
    try {
      const clientApollo = client as ApolloClient<NormalizedCacheObject>;
      const reportTypesService: ReportTypesService = new ReportTypesService(clientApollo);
      setRequestInsertReportsStatus(API_REQUEST_STATUS.REQUESTED);

      await reportTypesService.insert(report);

      setRequestInsertReportsStatus(API_REQUEST_STATUS.REQUEST_SUCCESS);
      setRequestInsertReportMessage(
        `${getTranslation('Reports_Report')} ${report.displayName} ${getTranslation('successMessage')}`
      );
      setSuccessNotificationStatus();
    } catch (error) {
      console.error(error);
      setRequestInsertReportsStatus(API_REQUEST_STATUS.REQUEST_FAILURE);
      setRequestInsertReportMessage(
        getUIErrorMessage(
          (error as Error)?.message,
          'duplicate',
          `${getTranslation('Report')} ${report.displayName}`
        )
      );
      setFailNotificationStatus();
    }
  };

  const clearInsertReportState = () => {
    setNotRequestedNotificationStatus();
    setRequestInsertReportMessage('');
    setRequestInsertReportsStatus(API_REQUEST_STATUS.NOT_REQUESTED);
  };

  const getInsertReportContext = (): InsertReportContextProps => {
    return {
      ...defaultInsertReportContextValue,
      insertReport,
      clearInsertReportState,
      requestInsertReportMessage,
      requestInsertReportStatus,
      notificationInsertReportStatus: notificationStatus,
    };
  };

  return (
    <insertReportContext.Provider value={getInsertReportContext()}>{children}</insertReportContext.Provider>
  );
};

const WithInsertReport = WithApolloClient(InsertReport);

export { WithInsertReport };

import { gql } from '@apollo/client';

export const UPDATE = gql`
  mutation ($id: String!, $input: ExportJobInsertInput!) {
    replaceOneExportJob(query: { _id: $id }, data: $input) {
      _id
    }
  }
`;

export const INSERT_JOB = gql`
  mutation ($input: ExportJobInsertInput!) {
    insertOneExportJob(data: $input) {
      _id
    }
  }
`;

import React, { SyntheticEvent, ReactElement, useState } from 'react';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { FieldErrors, FieldError } from 'react-hook-form/dist/types/errors';

import { USER_EMAILS_LIST_SEPARATOR } from '../constants';
import { DropdownValueType } from '../../../PetForm/types';
import DropdownWithInfo from '../../../PetForm/DropdownWithInfo';
import { getEmailPattern } from '../../../PetForm/helper';
import { DropdownViewModel } from '../../../../models/DropdownViewModel';
import ShortFieldDescription from '../../../../shared/view/ShortFieldDescription';
import { getTranslation } from '../../../../utils/getTranslation';
import { buildNextMultiDropdownValue, runToggleByTimeout } from './helper';

interface UserEmailsFieldProps {
  onChange: (result: DropdownViewModel[]) => void;
  value: DropdownViewModel[];
  errors: FieldErrors<FieldValues>;
  label: string | React.ReactChild;
  required?: boolean;
  dataTestId?: string;
}

const UserEmailsField: React.FC<UserEmailsFieldProps> = (props): ReactElement => {
  const [isNotificationValidate, setNotificationValidate] = useState<boolean>(false);
  const { onChange, value, errors, label, dataTestId, required } = props;
  const emailPattern = getEmailPattern();

  return (
    <>
      <DropdownWithInfo
        label={label}
        value={value.map((item: DropdownViewModel): string => String(item.value))}
        options={value}
        onChange={(_e: SyntheticEvent, { value }: DropdownValueType) => {
          const candidateValue: string[] = (value || []) as string[];
          const { result, isInvalid } = buildNextMultiDropdownValue(
            USER_EMAILS_LIST_SEPARATOR,
            emailPattern.pattern.value,
            candidateValue
          );

          if (isInvalid) {
            runToggleByTimeout(
              () => {
                setNotificationValidate(true);
              },
              () => {
                setNotificationValidate(false);
              },
              2000
            );
          }

          onChange(result);
        }}
        isSearched={true}
        isAllowAdditions
        isMultiple={true}
        isSelection={true}
        required={required}
        error={errors?.userEmails as FieldError | boolean | undefined}
        dataTestId={dataTestId}
      />
      {isNotificationValidate && (
        <ShortFieldDescription
          content={getTranslation('UserEmails_InvalidEmail_Message')}
          warning
          fullwidth
          normal
        />
      )}
    </>
  );
};

export default UserEmailsField;

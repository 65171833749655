import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { NormalizedCacheObject, ApolloClient } from '@apollo/client';
import { Button, Form, Icon, Input, Message } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import { debounce } from 'throttle-debounce';

import { DataAuthorizationRolesService } from './../../../services/DataAuthorizationRolesService';
import { WithApolloClient } from './../../WithApolloClient/WithApolloClient';
import { countItemsPerPage } from './../../../pages/Administration/constant';
import AdministrationTable from './../../AdministrationTable';
import { DataAuthorizationRole } from './../../../models/DataAuthorizationRolesAggregate/DataAuthorizationRole';
import DropdownMenu from './../../AdministrationTable/DropdownMenu';
import { getTranslation } from './../../../utils/getTranslation';
import DataAuthTableCell from './DataAuthTableCell/DataAuthTableCell';
import { getCreatedFormData, getUpdatedFormData } from './../../PetForm/helper';
import {
  DEBOUNCE_INPUT_DELAY,
  dataAuthorizationTableAdditionalHeaders,
  dataAuthorizationTableHeaders,
  emptyDataAuthorizationRoles,
} from './constants';
import DataAuthorizationRolesForm from './DataAuthorizationRolesForm';
import { userContext } from './../../../shared/store/userContext';
import { getUIErrorMessage } from './../../../utils/errorHandler';
import AuditInfo from './AuditInfo';
import PetForm from './../../PetForm';
import { AdministrationTableBodyColumns } from './../../AdministrationTable/AdministrationTable';
import FullSizeLoader from '../../../shared/view/Loader/FullSizeLoader';
import MessageWithControl from '../../../shared/controls/MessageWithControl';
import TextButton from '../../../shared/controls/TextButton';

import './index.scss';
import './../../../styles/abministration-tab.scss';

interface Props {
  client?: ApolloClient<NormalizedCacheObject>;
}

const DataAuthorizationRoles: React.FC<Props> = (props: Props) => {
  const client = props.client as ApolloClient<NormalizedCacheObject>;

  const { isDataAuthorizationManagementReadAccess, isDataAuthorizationManagementReadWriteAccess, email } =
    useContext(userContext);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isAllDataLoad, setIsAllDataLoad] = useState<boolean>(false);
  const [allDataAuthRoles, setAllDataAuthRoles] = useState<DataAuthorizationRole[]>([]);
  const [totalDataAuthRoles, setTotalDataAuthRoles] = useState<number>(0);
  const [activeTablePage, setActiveTablePage] = useState<number>(1);
  const [searchString, setSearchString] = useState<string | null>(null);
  const [currentDataRole, setCurrentDataRole] = useState<DataAuthorizationRole>(emptyDataAuthorizationRoles);
  const [message, setMessage] = useState('');
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isSpinnerShow, setIsSpinnerShow] = useState(false);
  const [isShowErrorDataMessage, setIsShowErrorDataMessage] = useState<boolean>(false);
  const [isAuditInfoOpen, setIsAuditInfoOpen] = useState<boolean>(false);
  const roleService: DataAuthorizationRolesService = useMemo(
    () => new DataAuthorizationRolesService(client),
    [client]
  );

  const handleGetDataAuthRolesByPage = useCallback(
    async (customPage?: number) => {
      const activePage = customPage ?? activeTablePage;

      try {
        const { data, total } = await roleService.getAll(
          countItemsPerPage,
          (activePage - 1) * countItemsPerPage,
          searchString
        );

        setAllDataAuthRoles(data);
        setTotalDataAuthRoles(total);

        setIsAllDataLoad(true);
      } catch (error) {
        console.error(error);
        setIsAllDataLoad(true);
        setIsShowErrorDataMessage(true);
      }
    },
    [roleService, activeTablePage, searchString]
  );

  const handleSearch = (roleName: string | null) => {
    setSearchString(roleName);
    setActiveTablePage(1);
  };

  const debounceHandleSearch = useCallback(
    debounce(DEBOUNCE_INPUT_DELAY, (roleName: string | null) => handleSearch(roleName)),
    [handleSearch]
  );

  useEffect(() => {
    void handleGetDataAuthRolesByPage();
  }, []);

  useEffect(() => {
    void handleGetDataAuthRolesByPage();
  }, [searchString, handleGetDataAuthRolesByPage]);

  const onTablePageChange = useCallback(
    (activePage: number) => {
      setActiveTablePage(activePage);

      void handleGetDataAuthRolesByPage(activePage);
    },
    [handleGetDataAuthRolesByPage]
  );

  const submitForm = useCallback(
    async (dataRole: DataAuthorizationRole): Promise<string | undefined> => {
      setIsSpinnerShow(true);

      let response;

      try {
        if (dataRole._id) {
          response = await roleService.update(
            dataRole._id,
            getUpdatedFormData<DataAuthorizationRole>(dataRole, email)
          );
        } else {
          response = await roleService.insert(
            getCreatedFormData<DataAuthorizationRole>(
              {
                ...dataRole,
                _id: String(uuid()),
              },
              email
            )
          );
        }
      } catch (e: Error | unknown) {
        response = e;
      }

      if (!(response as Error)?.message) {
        setIsFormOpen(false);
        setMessage(
          `${getTranslation('DataAuthorizationRole')} ${dataRole.roleName} ${getTranslation(
            dataRole._id ? 'successEditMessage' : 'successMessage'
          )}`
        );
        setIsSpinnerShow(false);
        setIsSuccessMessage(true);
        setTimeout(() => {
          setIsSuccessMessage(false);
        }, 4000);

        void handleGetDataAuthRolesByPage();
      } else {
        setIsSpinnerShow(false);

        return getUIErrorMessage(
          (response as Error)?.message,
          'duplicate',
          `${getTranslation('DataAuthorizationRole')} ${dataRole.roleName}`
        );
      }
    },
    [handleGetDataAuthRolesByPage, roleService]
  );

  const openEditForm = (dataRole: DataAuthorizationRole) => {
    setIsAuditInfoOpen(false);
    setIsFormOpen(true);
    setCurrentDataRole(dataRole);
  };

  const createNewDataAuthRoles = () => {
    setCurrentDataRole(emptyDataAuthorizationRoles);
    setIsAuditInfoOpen(false);
    setIsFormOpen(true);
  };

  const openAuditInfo = (dataRole: DataAuthorizationRole) => {
    setCurrentDataRole(dataRole);
    setIsFormOpen(false);
    setIsAuditInfoOpen(true);
  };

  const closeAuditInfo = () => {
    setIsAuditInfoOpen(false);
  };

  const rowGenerate = (
    row: DataAuthorizationRole
  ): { columns: AdministrationTableBodyColumns; rowKey: string } => {
    const { roleName, smids, lcns, gcns, pccs } = row;

    const tableRow: AdministrationTableBodyColumns = {
      roleName: roleName,
      gcns: <DataAuthTableCell restrictionFilter={gcns} />,
      smids: <DataAuthTableCell restrictionFilter={smids} />,
      lcns: <DataAuthTableCell restrictionFilter={lcns} />,
      pccs: <DataAuthTableCell restrictionFilter={pccs} />,
    };

    if (isDataAuthorizationManagementReadWriteAccess) {
      tableRow['controls'] = (
        <DropdownMenu<DataAuthorizationRole>
          openEditForm={openEditForm}
          row={row}
          key={`${row.roleName}_controls`}
          dataTestId="additional-dataAuthorizationRole-control"
        />
      );
    }

    return { columns: tableRow, rowKey: row._id };
  };

  const tableHeaderGenerate = () => {
    return dataAuthorizationTableHeaders().concat(
      (isDataAuthorizationManagementReadWriteAccess && dataAuthorizationTableAdditionalHeaders) || []
    );
  };

  return (
    <div
      className="dataAuthorizationRoles administration-tab"
      data-testid="administration-dataAuthorization-tab"
    >
      {isSuccessMessage && <Message success content={message} />}

      {isSpinnerShow && <FullSizeLoader />}

      <div className="buttons-container">
        <Form>
          <Input
            icon="search"
            size="small"
            placeholder={getTranslation('Search')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              debounceHandleSearch(event?.target.value ?? null)
            }
            name="filterSearch"
            data-testid="dataAuthFilter-input"
          />
        </Form>

        {isAllDataLoad && !isFormOpen && isDataAuthorizationManagementReadWriteAccess && (
          <Button
            primary
            size="small"
            disabled={isFormOpen}
            onClick={createNewDataAuthRoles}
            data-testid="createDataAuthorizationRole-control"
          >
            <Icon name="plus circle" /> {getTranslation('DataAuthorizationRolesForm_CreateDataAuthorization')}
          </Button>
        )}
      </div>

      {isShowErrorDataMessage && (
        <Message error data-testid="errorResponseDataAuthRoles-wrapper">
          <MessageWithControl message={getTranslation('App_LoadingError')}>
            <TextButton
              text={getTranslation('Retry_Now')}
              onClick={() => void handleGetDataAuthRolesByPage()}
              dataTestId="errorResponsePetUsers-retry-control"
            />
          </MessageWithControl>
        </Message>
      )}
      <div className="administration-tab-container">
        <AdministrationTable<DataAuthorizationRole>
          onPageChange={onTablePageChange}
          isCompressedTable={isFormOpen || isAuditInfoOpen}
          isAllDataLoad={isAllDataLoad}
          allRows={allDataAuthRoles}
          totalItems={totalDataAuthRoles}
          countItemsPerPage={countItemsPerPage}
          activePage={activeTablePage}
          openAuditInfo={openAuditInfo}
          tableHeaders={tableHeaderGenerate()}
          rowGenerate={(row) => rowGenerate(row)}
          className={'right-column'}
          containerClassName={isFormOpen ? 'hidden' : ''}
          isLastColumnAutoWidth={isDataAuthorizationManagementReadAccess}
        />
        {isFormOpen && (
          <PetForm<DataAuthorizationRole>
            cancelButtonClicked={setIsFormOpen}
            editFormData={currentDataRole}
            submitForm={submitForm}
            key={currentDataRole?.roleName + 'form'}
            formClass={'DataAuthorizationRolesForm'}
          >
            <DataAuthorizationRolesForm editFormData={currentDataRole} />
          </PetForm>
        )}
        {isAuditInfoOpen && (
          <AuditInfo
            currentDataAuthorizationRole={currentDataRole}
            closeAuditInfo={closeAuditInfo}
            key={currentDataRole.roleName + 'auditInfo'}
          />
        )}
      </div>
    </div>
  );
};

export default WithApolloClient(DataAuthorizationRoles);

import React from 'react';
import { ReportTypeDto } from '../../api/graphql/models/ReportTypeDto';
import { RequestStatus, API_REQUEST_STATUS } from '../../utils/APIHandlers';

export interface InsertReportContextProps {
  insertReport: (payload: { report: ReportTypeDto }) => Promise<void>;
  clearInsertReportState: () => void;
  requestInsertReportMessage: string;
  requestInsertReportStatus: RequestStatus;
  notificationInsertReportStatus: RequestStatus;
}

export const defaultInsertReportContextValue: InsertReportContextProps = {
  insertReport: async () => new Promise(() => void 0),
  clearInsertReportState: () => void 0,
  requestInsertReportMessage: '',
  requestInsertReportStatus: API_REQUEST_STATUS.NOT_REQUESTED,
  notificationInsertReportStatus: API_REQUEST_STATUS.NOT_REQUESTED,
};

const insertReportContext = React.createContext<InsertReportContextProps>(defaultInsertReportContextValue);

export { insertReportContext };

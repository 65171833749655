import React from 'react';

import { WithReportsPage } from '../../shared/containers/withReportsPage';
import ReportsContainer from '../../features/ReportsContainer/ReportsContainer';

const Reports: React.FC = () => {
  return (
    <WithReportsPage>
      <ReportsContainer />
    </WithReportsPage>
  );
};

export default Reports;

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Icon, Message } from 'semantic-ui-react';

import AdministrationTable from './../../AdministrationTable';
import ReportForm from './ReportForm';
import { getTranslation } from './../../../utils/getTranslation';
import { defaultReportFormFields, IS_OPEN_REPORT_FORM_STATE } from './constants';
import PetForm from './../../PetForm';
import { userContext } from './../../../shared/store/userContext';
import DropdownMenu from './../../AdministrationTable/DropdownMenu';
import { reportsContext } from './../../../shared/store/reportsContext';
import { insertReportContext } from './../../../shared/store/insertReportContext';
import { updateReportContext } from './../../../shared/store/updateReportContext';
import { getRequestStatuses } from './../../../utils/APIHandlers';
import MessageWithControl from './../../../shared/controls/MessageWithControl';
import TextButton from './../../../shared/controls/TextButton';
import { ReportFormViewModel } from './../../../models/ReportsAggregate/ReportForm';
import { ReportViewModel } from './../../../models/ReportsAggregate/Report';
import { mapReportDTOFromForm, mapReportFormFromViewModel } from './reportsMapper';
import { getReportBodyColumns, getReportHeaderColumns } from './helper';
import { AdministrationTableBodyColumns } from './../../AdministrationTable/AdministrationTable';
import FullSizeLoader from '../../../shared/view/Loader/FullSizeLoader';

import './../../../styles/abministration-tab.scss';
import './styles.scss';

const Reports: React.FC = () => {
  const [isFormOpen, setIsFormOpen] = useState(IS_OPEN_REPORT_FORM_STATE);
  const [currentReport, setCurrentReport] = useState<ReportFormViewModel>(defaultReportFormFields);
  const { getReports, requestReportsStatus, reportsData } = useContext(reportsContext);
  const {
    insertReport,
    clearInsertReportState,
    requestInsertReportStatus,
    notificationInsertReportStatus,
    requestInsertReportMessage,
  } = useContext(insertReportContext);
  const {
    updateReport,
    clearUpdateReportState,
    requestUpdateReportStatus,
    notificationUpdateReportStatus,
    requestUpdateReportMessage,
  } = useContext(updateReportContext);
  const { isUserManagementHasReadWriteAccess } = useContext(userContext);
  const requestReports = getRequestStatuses(requestReportsStatus);
  const notificationInsertReport = getRequestStatuses(notificationInsertReportStatus);
  const notificationUpdateReport = getRequestStatuses(notificationUpdateReportStatus);
  const requestInsertReport = useMemo(
    () => getRequestStatuses(requestInsertReportStatus),
    [requestInsertReportStatus]
  );
  const requestUpdateReport = useMemo(
    () => getRequestStatuses(requestUpdateReportStatus),
    [requestUpdateReportStatus]
  );
  const getHeaderColumns = getReportHeaderColumns(isUserManagementHasReadWriteAccess);

  const openEditForm = (report: ReportViewModel) => {
    setIsFormOpen(true);

    setCurrentReport(mapReportFormFromViewModel(report));
  };

  useEffect(() => {
    void getReports();
  }, []);

  useEffect(() => {
    if (requestInsertReport.REQUEST_SUCCESS || requestUpdateReport.REQUEST_SUCCESS) {
      setIsFormOpen(false);
      void getReports();
    }
  }, [requestInsertReport, requestUpdateReport]);

  const submitForm = async (formData: ReportFormViewModel) => {
    const report = mapReportDTOFromForm(formData, reportsData);

    if (formData.id !== undefined) {
      await updateReport({ id: formData.id, report: report });
    } else {
      await insertReport({ report: report });
    }
  };

  const handleCreateReport = () => {
    setCurrentReport(defaultReportFormFields);
    setIsFormOpen(true);
  };

  const rowGenerate = (row: ReportViewModel): { columns: AdministrationTableBodyColumns; rowKey: string } => {
    const columns = getReportBodyColumns(row);

    if (isUserManagementHasReadWriteAccess) {
      columns['controls'] = (
        <DropdownMenu<ReportViewModel>
          openEditForm={openEditForm}
          row={row}
          dataTestId="administration-tableRow-controls"
        />
      );
    }

    return { columns, rowKey: String(row.id) };
  };

  const handleCancelEditing = useCallback(() => {
    clearUpdateReportState();
    clearInsertReportState();
    setIsFormOpen(false);
  }, [clearUpdateReportState, clearInsertReportState]);

  return (
    <div className="administration-tab" data-testid="administration-reports-tab">
      {notificationInsertReport.REQUEST_SUCCESS && (
        <Message success content={requestInsertReportMessage} data-testid="successInsertReport-message" />
      )}
      {notificationUpdateReport.REQUEST_SUCCESS && (
        <Message success content={requestUpdateReportMessage} data-testid="successUpdateReport-message" />
      )}

      {isUserManagementHasReadWriteAccess && (
        <div className="buttons-container">
          <Button
            primary
            size="small"
            disabled={isFormOpen || requestReports.REQUESTED}
            onClick={handleCreateReport}
            data-testid="open-control-createReportFrom"
          >
            <Icon name="plus circle" /> {getTranslation('Reports_newReport')}
          </Button>
        </div>
      )}

      <div
        className="administration-tab-container administration-reports-tab"
        data-testid="administration-table-wrapper"
      >
        <AdministrationTable<ReportViewModel>
          isCompressedTable={isFormOpen}
          isAllDataLoad={requestReports.REQUEST_SUCCESS}
          allRows={reportsData}
          tableHeaders={getHeaderColumns}
          rowGenerate={rowGenerate}
          containerClassName={(!isUserManagementHasReadWriteAccess && 'readOnlyMode') || undefined}
        />

        {isFormOpen && (
          <PetForm<ReportFormViewModel>
            cancelButtonClicked={handleCancelEditing}
            editFormData={currentReport}
            submitForm={submitForm}
            key={currentReport?.displayName}
            isEditForm={typeof currentReport.id === 'number'}
            formClass={'reportForm'}
            dataTestId="administration-insertUpdateReport-form"
          >
            <ReportForm editFormData={currentReport} />
          </PetForm>
        )}
      </div>

      {requestReports.REQUEST_FAILURE && (
        <Message error data-testid="errorResponseReports">
          <MessageWithControl message={getTranslation('Error')}>
            <TextButton
              text={getTranslation('Retry_Now')}
              onClick={() => void getReports()}
              dataTestId="errorResponseReports-retry-control"
            />
          </MessageWithControl>
        </Message>
      )}

      {(requestInsertReport.REQUESTED || requestUpdateReport.REQUESTED) && (
        <FullSizeLoader dataTestId="insertUpdateReport-loader" />
      )}
    </div>
  );
};

export default Reports;

import React from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import './styles.scss';
import { getTranslation } from '../../../utils/getTranslation';
import LabelWrapper from '../../../shared/view/LabelWrapper/LabelWrapper';
import { formatDateTimeUTC } from '../../../utils/formatDate';

interface Props {
  createdBy?: string;
  createdDate?: Date | string;
  updatedBy?: string;
  updatedDate?: Date | string;
}

const DateInfo: React.FC<Props> = (props) => {
  const { createdDate, updatedDate, updatedBy, createdBy } = props;

  return (
    <Grid className="dateInfo">
      {(createdDate || createdBy) && (
        <Grid.Row>
          <Grid.Column>
            <LabelWrapper label={getTranslation('DateInfo_Created')}>
              <>
                <div className="date">{formatDateTimeUTC(createdDate)}</div>
                <div>
                  <span className="grey">{getTranslation('DateInfo_By')}</span>{' '}
                  <Icon disabled name="user circle" /> {createdBy || '-'}
                </div>
              </>
            </LabelWrapper>
          </Grid.Column>
        </Grid.Row>
      )}

      {(updatedDate || updatedBy) && (
        <Grid.Row>
          <Grid.Column>
            <LabelWrapper label={getTranslation('DateInfo_Updated')}>
              <>
                <div className="date">{formatDateTimeUTC(updatedDate)}</div>
                <div>
                  <span className="grey">{getTranslation('DateInfo_By')}</span>{' '}
                  <Icon disabled name="user circle" /> {updatedBy || '-'}
                </div>
              </>
            </LabelWrapper>
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
};

export default DateInfo;

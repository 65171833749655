import React, { useContext, useEffect, useCallback } from 'react';
import { Message } from 'semantic-ui-react';

import TextButton from '../../../shared/controls/TextButton';
import { getTranslation } from '../../../utils/getTranslation';
import { exportJobContext } from '../../../shared/store/exportJobContext';
import { API_REQUEST_STATUS } from '../../../utils/APIHandlers';
import MessageWithControl from '../../../shared/controls/MessageWithControl';
import ShortExportJobDetails from '../ViewExportJob/ShortExportJobDetails';
import FullSizeLoader from '../../../shared/view/Loader/FullSizeLoader';

import './styles.scss';

interface ExportJobHistoryJobDetailsWrapperProps {
  exportJobId: string;
}

const ExportJobDetailsWrapper: React.FC<ExportJobHistoryJobDetailsWrapperProps> = (props) => {
  const { exportJobId } = props;
  const { requestExportJobStatus, exportJobData, getExportJob } = useContext(exportJobContext);

  const handleGetExportJob = useCallback(() => {
    void getExportJob(exportJobId);
  }, [getExportJob, exportJobId]);

  useEffect((): void => {
    handleGetExportJob();
  }, []);

  return (
    <>
      {exportJobData && (
        <div data-testid="exportJobData-wrapper">
          <ShortExportJobDetails data={exportJobData} />
        </div>
      )}
      {exportJobData === null &&
        requestExportJobStatus !== API_REQUEST_STATUS.REQUESTED &&
        requestExportJobStatus !== API_REQUEST_STATUS.NOT_REQUESTED && (
          <Message warning className="emptyJobHistoryMessage" data-testid="message-empty">
            <MessageWithControl message={getTranslation('Empty')}>
              <TextButton
                dataTestId="empty-retry-btn"
                onClick={handleGetExportJob}
                text={getTranslation('Retry')}
              />
            </MessageWithControl>
          </Message>
        )}
      {requestExportJobStatus === API_REQUEST_STATUS.REQUESTED && <FullSizeLoader />}
    </>
  );
};

export default ExportJobDetailsWrapper;

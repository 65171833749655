import { gql } from '@apollo/client';

export const GET_ALL = gql`
  query ($limit: Int, $offset: Int, $sortBy: String, $sortOrder: String, $name: String) {
    paginatedDataAuthorizationRoles(
      input: { limit: $limit, offset: $offset, sortBy: $sortBy, sortOrder: $sortOrder, name: $name }
    ) {
      total
      data {
        _id
        roleName
        createdBy
        createdDate
        updatedBy
        updatedDate
        gcns {
          isInclusive
          values
        }
        lcns {
          isInclusive
          values
        }
        pccs {
          isInclusive
          values
        }
        smids {
          isInclusive
          values
        }
      }
    }
  }
`;

export const GET_BY_ID = gql`
  query ($id: String) {
    dataAuthorizationRole(query: { _id: $id }) {
      _id
      roleName
      createdBy
      createdDate
      updatedBy
      updatedDate
      gcns {
        values
        isInclusive
      }
      lcns {
        values
        isInclusive
      }
      smids {
        values
        isInclusive
      }
      pccs {
        values
        isInclusive
      }
    }
  }
`;

export const GET_DATA_AUTHORIZATION_ROLES = gql`
  query ($limit: Int, $offset: Int, $sortBy: String, $sortOrder: String) {
    paginatedDataAuthorizationRoles(
      input: { limit: $limit, offset: $offset, sortBy: $sortBy, sortOrder: $sortOrder }
    ) {
      data {
        _id
        roleName
      }
    }
  }
`;

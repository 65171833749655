import React, { useState } from 'react';
import { NormalizedCacheObject, ApolloClient } from '@apollo/client';

import { WithApolloClient } from '../../features/WithApolloClient/WithApolloClient';
import {
  defaultTripsTicketsContextValue,
  TripsTicketsContextProps,
  tripsTicketsContext,
} from '../store/tripsTicketsContext';
import { API_REQUEST_STATUS, RequestStatus } from '../../utils/APIHandlers';
import { TicketsService } from '../../services/TicketsService';
import { TripsTicketsSearchItemDTO, TripsTicketsSearchRequestDTO } from '../../api/rest/models/TripsTickets';
import { ErrorFetchResponse, RestApiResponse } from '../../api/rest/models/RestApiResponse';
import { TripsTicketsSearchViewModel } from '../../models/TripAggregate/TripsTickets';
import { mapViewModelFromDTO } from '../../features/TripsTicketsSearchContainer/TripsTicketsSearchTable/tripsTicketsSearchMapper';
import { getFetchErrorMessage } from '../../utils/errorHandler';

interface Props {
  children: React.ReactChild;
  client?: ApolloClient<NormalizedCacheObject>;
}

const TripsTickets: React.FC<Props> = ({ children, client }) => {
  const [tripsTicketsSearchData, setTripsTicketsSearchData] = useState<TripsTicketsSearchViewModel[]>(
    defaultTripsTicketsContextValue.tripsTicketsSearchData
  );
  const [remainingRecords, setRemainingRecords] = useState<number>(
    defaultTripsTicketsContextValue.remainingRecords
  );
  const [tripsTicketsSearchPage, setTripsTicketsSearchPage] = useState<number>(
    defaultTripsTicketsContextValue.tripsTicketsSearchPage
  );
  const [requestTripsTicketsSearchStatus, setRequestTripsTicketsSearchStatus] = useState<RequestStatus>(
    API_REQUEST_STATUS.NOT_REQUESTED
  );

  const searchTripsTickets = async (payload: TripsTicketsSearchRequestDTO) => {
    try {
      const clientApollo = client as ApolloClient<NormalizedCacheObject>;
      const ticketsService: TicketsService = new TicketsService(clientApollo);

      setRequestTripsTicketsSearchStatus(API_REQUEST_STATUS.REQUESTED);

      const response = await ticketsService.searchTripsTickets(payload);
      const successResponse = response as RestApiResponse<TripsTicketsSearchItemDTO[]>;

      const totalItems =
        (typeof successResponse.remainingRecords === 'number' &&
          successResponse.result.length + successResponse.remainingRecords + payload.offset) ||
        0;

      setRequestTripsTicketsSearchStatus(API_REQUEST_STATUS.REQUEST_SUCCESS);
      setTripsTicketsSearchData(mapViewModelFromDTO(successResponse.result));
      setRemainingRecords(totalItems);
    } catch (e) {
      console.error(getFetchErrorMessage(e as ErrorFetchResponse));
      setTripsTicketsSearchData(defaultTripsTicketsContextValue.tripsTicketsSearchData);
      setRequestTripsTicketsSearchStatus(API_REQUEST_STATUS.REQUEST_FAILURE);
    }
  };

  const clearTripsTicketsSearch = () => {
    setTripsTicketsSearchData([]);
    setRequestTripsTicketsSearchStatus(API_REQUEST_STATUS.NOT_REQUESTED);
    setRemainingRecords(defaultTripsTicketsContextValue.remainingRecords);
    setTripsTicketsSearchPage(defaultTripsTicketsContextValue.tripsTicketsSearchPage);
  };

  const getTripsTicketsContext = (): TripsTicketsContextProps => {
    return {
      ...defaultTripsTicketsContextValue,
      searchTripsTickets,
      clearTripsTicketsSearch,
      setTripsTicketsSearchPage: (page) => setTripsTicketsSearchPage(page),
      tripsTicketsSearchPage,
      remainingRecords,
      tripsTicketsSearchData,
      requestTripsTicketsSearchStatus,
    };
  };

  return (
    <tripsTicketsContext.Provider value={getTripsTicketsContext()}>{children}</tripsTicketsContext.Provider>
  );
};

const WithTripsTickets = WithApolloClient(TripsTickets);

export { WithTripsTickets };

import React from 'react';
import { PetUserFormViewModel, PetUserViewModel } from '../../../models/PetUsersAggregate/PetUser';

import { RequestStatus, API_REQUEST_STATUS } from '../../../utils/APIHandlers';

export interface UpdatePetUserContextProps {
  updatePetUser: (data: PetUserFormViewModel) => Promise<string | undefined | void>;
  requestUpdatePetUserMessage: string;
  requestUpdatePetUserStatus: RequestStatus;
  clearUpdatePetUserState: () => void;

  deletePetUser: (data: PetUserViewModel) => Promise<void>;
  requestDeletePetUserMessage: string;
  requestDeletePetUserStatus: RequestStatus;

  setPetUserToDelete: (data: PetUserViewModel | null) => void;
  clearPetUserToDelete: () => void;
  petUserToDelete: PetUserViewModel | null;

  setPetUserToUpdate: (data: PetUserViewModel | null) => void;
  clearPetUserToUpdate: () => void;
  petUserToUpdate: PetUserViewModel | null;
}

export const defaultUpdatePetUserContextValue: UpdatePetUserContextProps = {
  updatePetUser: async () => new Promise(() => void 0),
  requestUpdatePetUserMessage: '',
  requestUpdatePetUserStatus: API_REQUEST_STATUS.NOT_REQUESTED,
  clearUpdatePetUserState: () => void 0,
  deletePetUser: async () => new Promise(() => void 0),
  requestDeletePetUserMessage: '',
  requestDeletePetUserStatus: API_REQUEST_STATUS.NOT_REQUESTED,

  setPetUserToDelete: () => void 0,
  clearPetUserToDelete: () => void 0,
  petUserToDelete: null,

  setPetUserToUpdate: () => void 0,
  clearPetUserToUpdate: () => void 0,
  petUserToUpdate: null,
};

const updatePetUserContext = React.createContext<UpdatePetUserContextProps>(defaultUpdatePetUserContextValue);

export { updatePetUserContext };

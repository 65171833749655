import { gql } from '@apollo/client';

export const INSERT = gql`
  mutation ($report: ReportInsertInput!) {
    insertOneReport(data: $report) {
      _id
    }
  }
`;

export const UPDATE = gql`
  mutation ($id: Int!, $report: ReportInsertInput!) {
    replaceOneReport(query: { _id: $id }, data: $report) {
      _id
    }
  }
`;

import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Header, Icon } from 'semantic-ui-react';
import './styles.scss';
import { getTranslation } from '../../utils/getTranslation';

const NotFoundPage: React.FC = () => {
  return (
    <div className="notFoundPage container">
      <div className="header-icon-container">
        <Icon name="info circle" color="grey" size="large" />
        <Header as="h1">{getTranslation('NotFoundPage_Header')}</Header>
      </div>

      <div className="text">{getTranslation('NotFoundPage_Text')}</div>
      <Button primary as={Link} to="/" size="small">
        {getTranslation('NotFoundPage_HomePage')}
      </Button>
    </div>
  );
};

export default NotFoundPage;

import React from 'react';
import { ExportJobsListDTO } from '../../api/graphql/models/ExportJob';
import { RequestStatus, API_REQUEST_STATUS } from '../../utils/APIHandlers';

export interface ExportJobsContextProps {
  getExportJobsList: () => Promise<void>;
  exportJobsData: ExportJobsListDTO;
  requestExportJobsStatus: RequestStatus;
}

export const defaultExportJobsContextValue: ExportJobsContextProps = {
  getExportJobsList: async () => new Promise(() => void 0),
  exportJobsData: [],
  requestExportJobsStatus: API_REQUEST_STATUS.NOT_REQUESTED,
};

const exportJobsContext = React.createContext<ExportJobsContextProps>(defaultExportJobsContextValue);

export { exportJobsContext };

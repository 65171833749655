import React, { SyntheticEvent } from 'react';
import { Form, Select } from 'semantic-ui-react';
import { FieldError } from 'react-hook-form/dist/types';
import { DropdownViewModel } from '../../../models/DropdownViewModel';
import { DropdownValueType } from '../types';
import FormFieldLabel from '../../../shared/view/FormFieldLabel/FormFieldLabel';

import './styles.scss';
import NoAccessLabel from '../../../shared/view/NoAccessLabel/NoAccessLabel';

interface SelectWithInfoProps {
  label: string | React.ReactChild;
  error?: FieldError | boolean;
  disabled?: boolean;
  defaultValue: string | number;
  options: DropdownViewModel[];
  onChange: (e: SyntheticEvent, { value, options }: DropdownValueType) => void;
  isLoaded?: boolean;
  required?: boolean;
  dataTestId?: string;
  search?: boolean;
  noAccessLabel?: boolean;
}

const SelectWithInfo: React.FC<SelectWithInfoProps> = ({
  label,
  onChange,
  options,
  defaultValue,
  error,
  isLoaded,
  required,
  dataTestId,
  search = true,
  disabled,
  noAccessLabel,
}) => {
  return (
    <Form.Field>
      <FormFieldLabel required={required}>{label}</FormFieldLabel>
      <Select
        options={options}
        defaultValue={defaultValue}
        ref={undefined}
        onChange={onChange}
        error={!!error}
        loading={isLoaded}
        search={search}
        data-testid={dataTestId}
        icon="chevron down"
        disabled={disabled}
      />
      {!!error && typeof error !== 'boolean' && <div className="formError">{error.message}</div>}

      {noAccessLabel && <NoAccessLabel dataTestId={dataTestId} />}
    </Form.Field>
  );
};

export default SelectWithInfo;

import React, { useContext, useEffect } from 'react';
import { Header, Modal } from 'semantic-ui-react';
import { useParams, useHistory } from 'react-router-dom';

import { getTranslation } from '../../../utils/getTranslation';
import { exportJobHistoryContext } from '../../../shared/store/exportJobHistoryContext';
import ExportJobHistoryContent from './ExportJobHistoryContent';
import { ExportJobIdKey, pathExportJobsList } from '../../../routes/constants';
import { exportJobContext } from '../../../shared/store/exportJobContext';

const ExportJobHistoryModal: React.FC = () => {
  const history = useHistory();
  const params = useParams<{ [ExportJobIdKey]?: string }>();
  const { isOpenJobHistory, handleCloseExportJobHistory, handleOpenExportJobHistory } =
    useContext(exportJobHistoryContext);
  const { clearExportJobData } = useContext(exportJobContext);

  const handleClose = (): void => {
    handleCloseExportJobHistory();
    clearExportJobData();
    history.push(pathExportJobsList);
  };

  useEffect((): void => {
    if ((params[ExportJobIdKey] || '').length > 0) {
      handleOpenExportJobHistory();
    }
  }, [params]);

  return (
    <Modal
      open={isOpenJobHistory}
      size="large"
      closeIcon={true}
      closeOnEscape={true}
      closeOnDimmerClick={true}
      onClose={handleClose}
      data-testid="jobHistoryModal"
    >
      <Modal.Header>
        <Header as="h3">{getTranslation('ExportJobs_JobHistory_Title')}</Header>
      </Modal.Header>

      <Modal.Content data-testid="modal-jobHistory-content">
        <ExportJobHistoryContent />
      </Modal.Content>
    </Modal>
  );
};

export default ExportJobHistoryModal;

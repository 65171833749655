import React from 'react';

import { FeatureAccessGroupFormViewModel } from '../../../models/FeatureAccessGroupsAggregate/FeatureAccessGroup';
import { RequestStatus, API_REQUEST_STATUS } from '../../../utils/APIHandlers';

export interface CreateFeatureAccessGroupContextProps {
  createFeatureAccessGroup: (data: FeatureAccessGroupFormViewModel) => Promise<string | undefined | void>;
  requestCreateFeatureAccessGroupMessage: string;
  requestCreateFeatureAccessGroupStatus: RequestStatus;
  clearCreateFeatureAccessGroupState: () => void;
}

export const defaultCreateFeatureAccessGroupContextValue: CreateFeatureAccessGroupContextProps = {
  createFeatureAccessGroup: async () => new Promise(() => void 0),
  requestCreateFeatureAccessGroupMessage: '',
  requestCreateFeatureAccessGroupStatus: API_REQUEST_STATUS.NOT_REQUESTED,
  clearCreateFeatureAccessGroupState: () => void 0,
};

const createFeatureAccessGroupContext = React.createContext<CreateFeatureAccessGroupContextProps>(
  defaultCreateFeatureAccessGroupContextValue
);

export { createFeatureAccessGroupContext };

import React, { SyntheticEvent, useContext, useEffect, useState, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { FieldError } from 'react-hook-form/dist/types';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Header, Message, Segment } from 'semantic-ui-react';

import { WithApolloClient } from './../../../WithApolloClient/WithApolloClient';
import InputWithInfo from './../../../PetForm/InputWithInfo';
import { getTranslation } from './../../../../utils/getTranslation';
import { getRequiredFieldRegister } from './../../../PetForm/helper';
import { DropdownViewModel } from './../../../../models/DropdownViewModel';
import SelectWithInfo from './../../../PetForm/SelectWithInfo';
import { DropdownValueType, FormProps } from './../../../PetForm/types';
import { reportsContext } from './../../../../shared/store/reportsContext';
import {
  getReportIsPaginated,
  getUnusedReportsNames,
  reportIconsDropdownOption,
  reportNamesDropdownOption,
  reportTypesDropdownOption,
} from './helper';
import TextAreaField from './../../../../shared/controls/TextAreaField';
import IconsSelect from './../../../../shared/controls/IconsSelect';
import { ReportFormViewModel } from './../../../../models/ReportsAggregate/ReportForm';
import { insertReportContext } from './../../../../shared/store/insertReportContext';
import { getRequestStatuses } from './../../../../utils/APIHandlers';
import { updateReportContext } from './../../../../shared/store/updateReportContext';

import './styles.scss';
import './../../../PetForm/commonFormStyles.scss';

interface ReportFormProps extends FormProps<ReportFormViewModel, UseFormRegister<ReportFormViewModel>> {
  client?: ApolloClient<NormalizedCacheObject>;
  errors?: {
    reportName: FieldError;
    displayName: FieldError;
    reportType: FieldError;
    icon: FieldError;
    description: FieldError;
  };
}

const ReportForm: React.FC<ReportFormProps> = ({
  editFormData,
  registerForm,
  errors,
  control,
  watch,
  getValues,
  setValue,
}) => {
  const {
    reportsData,
    getReportsNames,
    reportsNamesData,
    requestReportsNamesStatus,
    resetCachedReportsNames,
  } = useContext(reportsContext);
  const { requestInsertReportStatus, requestInsertReportMessage } = useContext(insertReportContext);
  const { requestUpdateReportStatus, requestUpdateReportMessage } = useContext(updateReportContext);
  const [nameReportOptions, setNameReportOptions] = useState<DropdownViewModel[]>([]);
  const requiredFieldRegister = getRequiredFieldRegister();
  const reportTypeOptions = reportTypesDropdownOption();
  const reportIconOptions = reportIconsDropdownOption();
  const requestInsertReport = useMemo(
    () => getRequestStatuses(requestInsertReportStatus),
    [requestInsertReportStatus]
  );
  const requestUpdateReport = useMemo(
    () => getRequestStatuses(requestUpdateReportStatus),
    [requestUpdateReportStatus]
  );
  const requestReportsNames = useMemo(
    () => getRequestStatuses(requestReportsNamesStatus),
    [requestReportsNamesStatus]
  );
  const isEditForm = typeof editFormData.id === 'number';
  const reportName = (watch?.('reportName') ?? editFormData.reportName) as string;
  const isPaginated = (watch?.('isPaginated') ?? editFormData.isPaginated) as boolean;

  useEffect(() => {
    void getReportsNames();
  }, []);

  useEffect(() => {
    if (requestInsertReport.REQUEST_SUCCESS || requestUpdateReport.REQUEST_SUCCESS) {
      resetCachedReportsNames();
    }
  }, [requestInsertReport, requestUpdateReport]);

  useEffect(() => {
    if (requestReportsNames.REQUEST_SUCCESS) {
      const usedReportsNames = reportsData.map((item) => item.reportName);

      setNameReportOptions(
        reportNamesDropdownOption(
          getUnusedReportsNames(reportsNamesData, usedReportsNames, editFormData.reportName)
        )
      );
    }
  }, [reportsNamesData, requestReportsNames, reportNamesDropdownOption, reportsData]);

  useEffect(() => {
    const nextPaginatedState = getReportIsPaginated(reportsNamesData, reportName);

    if (nextPaginatedState !== undefined && isPaginated !== nextPaginatedState) {
      setValue?.('isPaginated', nextPaginatedState);
    }
  }, [reportsNamesData, reportName, isPaginated, setValue, getValues, reportsData]);

  return (
    <>
      <Header as="h1" data-testid="reportForm-headline">
        {isEditForm ? getTranslation('Reports_editReport') : getTranslation('ReportForm_CreateNew')}
      </Header>

      <Controller
        render={({ field: { onChange } }) => (
          <IconsSelect
            defaultValue={editFormData.icon}
            options={reportIconOptions}
            label={getTranslation('Reports_Icon')}
            error={errors?.reportType}
            onChange={(e: SyntheticEvent, { value }: DropdownValueType) => {
              onChange(String(value));
            }}
            required
          />
        )}
        name="icon"
        control={control}
        rules={requiredFieldRegister}
      />

      <InputWithInfo
        required
        label={getTranslation('Reports_DisplayName')}
        error={errors?.displayName}
        register={registerForm?.('displayName', requiredFieldRegister)}
      />

      <Segment>
        {isPaginated && (
          <Header size="tiny" className="paginatedReportLabel" data-testid="chosePaginatedReport-title">
            {getTranslation('Reports_Paginated')}
          </Header>
        )}
        <Controller
          render={({ field: { onChange } }) => (
            <SelectWithInfo
              dataTestId="reportForm-reportName-field"
              defaultValue={editFormData.reportName}
              options={nameReportOptions}
              label={getTranslation('Reports_ReportName')}
              error={errors?.reportName}
              onChange={(e: SyntheticEvent, { value }: DropdownValueType) => {
                onChange(String(value));
              }}
              isLoaded={requestReportsNames.REQUESTED}
              required
            />
          )}
          name="reportName"
          control={control}
          rules={requiredFieldRegister}
        />
      </Segment>

      <Controller
        render={({ field: { onChange } }) => (
          <SelectWithInfo
            defaultValue={editFormData.reportType}
            options={reportTypeOptions}
            label={getTranslation('Reports_Type')}
            error={errors?.reportType}
            onChange={(e: SyntheticEvent, { value }: DropdownValueType) => {
              onChange(String(value));
            }}
            search={false}
            required
          />
        )}
        name="reportType"
        control={control}
        rules={requiredFieldRegister}
      />

      <TextAreaField
        required
        label={getTranslation('Reports_Description')}
        error={errors?.description}
        register={registerForm?.('description', requiredFieldRegister)}
      />

      {requestInsertReport.REQUEST_FAILURE && (
        <Message error content={requestInsertReportMessage} data-testid="errorInsertReport-message" />
      )}
      {requestUpdateReport.REQUEST_FAILURE && (
        <Message error content={requestUpdateReportMessage} data-testid="errorUpdateReport-message" />
      )}
    </>
  );
};

export default WithApolloClient<ReportFormProps>(ReportForm);

import React, { SyntheticEvent } from 'react';
import { Dropdown, Form } from 'semantic-ui-react';
import { FieldError } from 'react-hook-form/dist/types';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';
import { DropdownViewModel } from '../../../models/DropdownViewModel';
import { DropdownDefaultValueType, DropdownValueType } from '../types';
import FormFieldLabel from '../../../shared/view/FormFieldLabel/FormFieldLabel';
import NoAccessLabel from '../../../shared/view/NoAccessLabel/NoAccessLabel';

import './styles.scss';

interface DropdownWithInfoProps {
  label: string | React.ReactChild;
  value?: DropdownDefaultValueType;
  error?: FieldError | boolean;
  register?: UseFormRegisterReturn;
  disabled?: boolean;
  defaultValue?: DropdownDefaultValueType;
  options: DropdownViewModel[];
  onChange: (e: SyntheticEvent, { value }: DropdownValueType) => void;
  isMultiple?: boolean;
  onAddItem?: (e: any, { value }: any) => void;
  isSearched?: boolean;
  isAllowAdditions?: boolean;
  isSelection?: boolean;
  isOrderedValue?: boolean;
  required?: boolean;
  tip?: string;
  dataTestId?: string;
  isLoading?: boolean;
  noAccessLabel?: boolean;
}

const DropdownWithInfo: React.FC<DropdownWithInfoProps> = ({
  label,
  value,
  onChange,
  options,
  defaultValue,
  error,
  register,
  isMultiple,
  onAddItem,
  isSearched,
  isAllowAdditions,
  isSelection,
  isOrderedValue,
  disabled,
  required,
  tip,
  dataTestId,
  isLoading,
  noAccessLabel,
}) => {
  return (
    <Form.Field>
      <FormFieldLabel required={required}>{label}</FormFieldLabel>
      <Dropdown
        value={value}
        defaultValue={defaultValue}
        {...register}
        onChange={onChange}
        error={!!error}
        options={options}
        multiple={isMultiple}
        onAddItem={onAddItem}
        className={`dropdownWrapper-dropdownWithInfo ${isOrderedValue ? 'dropdownWrapper-orderedValue' : ''}`}
        search={isSearched}
        allowAdditions={isAllowAdditions}
        selection={isSelection}
        noResultsMessage={null}
        disabled={disabled}
        data-testid={dataTestId}
        icon="chevron down"
        loading={isLoading}
      />

      {!!error && typeof error !== 'boolean' && <div className="formError">{error.message}</div>}
      {tip && <div className="dropdownWrapper-dropdown-tip">{tip}</div>}
      {noAccessLabel && <NoAccessLabel dataTestId={dataTestId} />}
    </Form.Field>
  );
};

export default DropdownWithInfo;

import React, { ReactElement } from 'react';
import Cron, { PeriodType, CronType } from 'react-js-cron';
import { FieldError } from 'react-hook-form/dist/types';
import { Form } from 'semantic-ui-react';

import FormFieldLabel from '../../view/FormFieldLabel/FormFieldLabel';

import 'react-js-cron/dist/styles.css';
import './styles.scss';

export const requiredClassName = 'required';

export interface CronFieldProps {
  label: string | React.ReactChild;
  value: string;
  required?: boolean;
  error?: FieldError | FieldError[];
  clearButton?: boolean;
  className?: string;
  dataTestId?: string;
  allowedPeriods?: PeriodType[];
  allowedDropdowns?: CronType[];
  onChange: (value: string) => void;
}

const CronField: React.FC<CronFieldProps> = ({
  label,
  required,
  value,
  error,
  dataTestId,
  clearButton,
  allowedPeriods,
  allowedDropdowns,
  onChange,
}): ReactElement => {
  return (
    <Form.Field>
      <FormFieldLabel required={required}>{label}</FormFieldLabel>
      <div className="container-cronWrapper" data-testid={dataTestId}>
        <Cron
          value={value}
          clearButton={clearButton}
          setValue={onChange}
          allowedPeriods={allowedPeriods}
          allowedDropdowns={allowedDropdowns}
          className={error ? 'react-js-cron-error' : ''}
        />
      </div>

      {!!error && (
        <div className="formError" data-testid="cronField-errorMessage">
          {(error as FieldError).message}
        </div>
      )}
    </Form.Field>
  );
};

export default CronField;

import React, { useCallback } from 'react';
import { Icon, Pagination, PaginationProps, SemanticICONS } from 'semantic-ui-react';

import { getTranslation } from '../../../utils/getTranslation';

interface PaginationWrapperProps {
  activePage: number;
  countItemsPerPage: number;
  totalItems: number;
  onPageChange: (page: number) => void;
}

export const getCurrentPageBounds = ({
  activePage,
  countItemsPerPage,
  totalItems,
}: {
  activePage: number;
  countItemsPerPage: number;
  totalItems: number;
}): string => {
  if (!activePage || !totalItems || !countItemsPerPage) {
    return '';
  }
  const left = countItemsPerPage * (activePage - 1) + 1;
  const upperBound = countItemsPerPage * activePage;
  const right = upperBound > totalItems ? totalItems : upperBound;

  return `${left}-${right}`;
};

const PaginationWrapper: React.FC<PaginationWrapperProps> = ({
  activePage,
  countItemsPerPage,
  totalItems,
  onPageChange,
}) => {
  const totalPages = (totalItems && countItemsPerPage && Math.ceil(totalItems / countItemsPerPage)) || 0;
  const isMoreThanOnePage = totalPages > 1;

  const getControlIcon = (iconName: SemanticICONS): { content: JSX.Element; icon: boolean } | null => {
    return isMoreThanOnePage
      ? {
          content: <Icon data-testid={`pagination-${iconName.replace(/\s/gi, '-')}`} name={iconName} />,
          icon: true,
        }
      : null;
  };

  const handleChangePage = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, { activePage }: PaginationProps) => {
      onPageChange(Number(activePage) ?? 1);
    },
    [onPageChange]
  );

  return (
    <div data-testid="pagination-wrapper">
      <Pagination
        defaultActivePage={1}
        activePage={activePage}
        ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
        prevItem={getControlIcon('angle left')}
        nextItem={getControlIcon('angle right')}
        firstItem={getControlIcon('angle double left')}
        lastItem={getControlIcon('angle double right')}
        pageItem={isMoreThanOnePage && {}}
        totalPages={totalPages}
        onPageChange={handleChangePage}
        className="pagination"
      />
      {totalItems > 0 && (
        <span data-testid="pagination-summary">
          {`${getCurrentPageBounds({ activePage, countItemsPerPage, totalItems })} ${getTranslation(
            'AdministrationTable_Of'
          )} ${totalItems}`}
        </span>
      )}
    </div>
  );
};

export default PaginationWrapper;

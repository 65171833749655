import React, { useContext, useCallback } from 'react';

import WarningModal from '../../shared/view/Modal/WarningModal';
import ExportJobHistoryModal from './ExportJobHistory/ExportJobHistoryModal';
import { getTranslation } from '../../utils/getTranslation';
import { API_REQUEST_STATUS } from '../../utils/APIHandlers';
import { deleteExportJobContext } from '../../shared/store/deleteExportJobContext';
import { enableExportJobContext } from '../../shared/store/enableExportJobContext';
import { triggerExportJobContext } from '../../shared/store/triggerExportJobContext';
import { WithExportJob } from '../../shared/containers/withExportJob';

const ModalsExportJobs: React.FC = () => {
  const { isOpenDeleteCondition, requestDeleteExportJobStatus, handleCloseDelete, deleteExportJob } =
    useContext(deleteExportJobContext);
  const {
    isOpenEnableCondition,
    requestEnableExportJobStatus,
    enableExportJobStatus,
    handleCloseEnable,
    enableExportJob,
  } = useContext(enableExportJobContext);
  const {
    isOpenTriggerExportJobCondition,
    requestTriggerExportJobStatus,
    triggerExportJobData,
    handleCloseTriggerExportJob,
    triggerExportJob,
  } = useContext(triggerExportJobContext);

  const handleDeleteExportJob = useCallback((): void => void deleteExportJob(), [deleteExportJob]);
  const handleEnableExportJob = useCallback((): void => void enableExportJob(), [enableExportJob]);
  const handleTriggerExportJob = useCallback((): void => void triggerExportJob(), [triggerExportJob]);

  return (
    <div data-testid="exportJobs-modals-wrapper">
      <WithExportJob>
        <ExportJobHistoryModal />
      </WithExportJob>

      <WarningModal
        isOpen={isOpenDeleteCondition}
        onClose={handleCloseDelete}
        onConfirm={handleDeleteExportJob}
        titleText={getTranslation('Delete')}
        contentText={getTranslation('DeleteWarningContent')}
        textActionButton={getTranslation('Delete')}
        isSaveBtn={false}
        isLoading={requestDeleteExportJobStatus === API_REQUEST_STATUS.REQUESTED}
      />

      <WarningModal
        isOpen={isOpenEnableCondition}
        onClose={handleCloseEnable}
        onConfirm={handleEnableExportJob}
        titleText={getTranslation(enableExportJobStatus ? 'Enable' : 'Disable')}
        contentText={getTranslation(enableExportJobStatus ? 'EnableWarningContent' : 'DisableWarningContent')}
        textActionButton={getTranslation(enableExportJobStatus ? 'Enable' : 'Disable')}
        isSaveBtn={enableExportJobStatus as boolean}
        isLoading={requestEnableExportJobStatus === API_REQUEST_STATUS.REQUESTED}
      />

      <WarningModal
        isOpen={isOpenTriggerExportJobCondition}
        onClose={handleCloseTriggerExportJob}
        onConfirm={handleTriggerExportJob}
        titleText={getTranslation('ExportJobs_Trigger_Control')}
        contentText={`${getTranslation('RunWarningContent')} '${triggerExportJobData?.jobName || ''}' ?`}
        textActionButton={getTranslation('Run')}
        isSaveBtn={true}
        isLoading={requestTriggerExportJobStatus === API_REQUEST_STATUS.REQUESTED}
      />
    </div>
  );
};

export default ModalsExportJobs;

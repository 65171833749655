import { GetScheduledReportDTO, GetReportMfaCodeRequestDTO } from '../api/rest/models/GetScheduledReport';

export class GetScheduledReportService {
  private readonly endpointGetMFACode: string;
  private readonly endpointGetReportLink: string;
  private readonly apiBaseUrl: string;

  constructor(apiBaseUrl: string) {
    this.endpointGetMFACode = `/v1/tickets/pet/exports/code`;
    this.endpointGetReportLink = `/v1/tickets/pet/exports/link`;
    this.apiBaseUrl = apiBaseUrl;
  }

  public getMFACode(payload: GetReportMfaCodeRequestDTO): Promise<Response> {
    return fetch(`${this.apiBaseUrl}${this.endpointGetMFACode}`, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
  }

  public getScheduledReport(payload: GetScheduledReportDTO): Promise<Response> {
    return fetch(`${this.apiBaseUrl}${this.endpointGetReportLink}`, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
  }
}

import React from 'react';
import { Button, Header, Icon } from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom';

import { getTranslation } from '../../utils/getTranslation';
import ViewExportJobWrapper from '../../features/ExportJobsContainer/ViewExportJob/ViewExportJobWrapper';
import { WithExportJob } from '../../shared/containers/withExportJob';

import './styles.scss';
import { ExportJobIdKey, pathExportJobsList } from '../../routes/constants';

const ViewExportJobPage: React.FC = () => {
  const params = useParams<{ [ExportJobIdKey]?: string }>();
  const exportJobId = params[ExportJobIdKey] || '';

  return (
    <div className="viewExportJobs container">
      <Header as="h1" className="container-viewExportJobHeader" data-testid="viewExportJob-mainTitle">
        <div className="container-viewExportJobTitle">{getTranslation('View_ExportJob_Title')}</div>
        <div className="container-viewExportJobGoBack">
          <Button
            as={Link}
            to={pathExportJobsList}
            basic
            size="small"
            color="blue"
            data-testid="goToExportJobsPage-link"
          >
            <Icon name="arrow left" /> {getTranslation('Edit_ExportJobs_GoBack')}
          </Button>
        </div>
      </Header>

      <div className="container-viewExportJobsWrapper">
        <WithExportJob>
          <ViewExportJobWrapper exportJobId={exportJobId} />
        </WithExportJob>
      </div>
    </div>
  );
};

export default ViewExportJobPage;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Header, Message } from 'semantic-ui-react';

import { WithApolloClient } from '../../../WithApolloClient/WithApolloClient';
import { PetUserFormViewModel } from '../../../../models/PetUsersAggregate/PetUser';
import PetForm from '../../../PetForm';
import { pathAdministrationUsers, petUserKey } from '../../../../routes/constants';
import { petUserContext } from '../../../../shared/store/PetUser/petUserContext';
import { WithDataAuthorizationRoles } from '../../../../shared/containers/withDataAuthorizationRoles';
import { WithFeatureAccessGroups } from '../../../../shared/containers/FeatureAccessGroups/withFeatureAccessGroups';
import { emptyUser } from '../constants';
import PetUserForm from './PetUserForm';
import { getRequestStatuses } from '../../../../utils/APIHandlers';
import CloseControl from '../../../../shared/controls/CloseControl';
import { mapPetUserFormFields } from '../petUsersMapper';
import { getTranslation } from '../../../../utils/getTranslation';
import TextButton from '../../../../shared/controls/TextButton';
import PetUserLoader from '../PetUserLoader/PetUserLoader';
import { updatePetUserContext } from '../../../../shared/store/PetUser/updatePetUserContext';
import FullSizeLoader from '../../../../shared/view/Loader/FullSizeLoader';
import EmptyMessage from '../../../../shared/view/EmptyMessage/EmptyMessage';
import { userContext } from '../../../../shared/store/userContext';
import { hasUnavailableFields, isLeaveCondition } from '../helper';
import NoAccessMessageWrapper from '../PetUserMessages/NoAccessMessageWrapper';

import './styles.scss';
import './../../../PetForm/commonAuditInfoStyles.scss';

const EditPetUserWrapper: React.FC = () => {
  const userContextValue = useContext(userContext);
  const isUnavailableFields = hasUnavailableFields(userContextValue);
  const { getPetUser, petUser, requestPetUserStatus } = useContext(petUserContext);
  const { updatePetUser, requestUpdatePetUserStatus, clearUpdatePetUserState } =
    useContext(updatePetUserContext);
  const [editFormData, setEditFormData] = useState<PetUserFormViewModel>(emptyUser);
  const requestPetUser = useMemo(() => getRequestStatuses(requestPetUserStatus), [requestPetUserStatus]);
  const requestUpdatePetUser = useMemo(
    () => getRequestStatuses(requestUpdatePetUserStatus),
    [requestUpdatePetUserStatus]
  );
  const history = useHistory();
  const params = useParams<{ [petUserKey]?: string }>();
  const petUserId = params[petUserKey];

  const handleCancel = () => {
    clearUpdatePetUserState();
    history.push(pathAdministrationUsers);
  };

  useEffect(() => {
    clearUpdatePetUserState();
  }, []);

  useEffect(() => {
    if (petUserId) {
      setEditFormData(emptyUser);
      void getPetUser(petUserId);
    }
  }, [petUserId]);

  useEffect(() => {
    if (requestPetUser.REQUEST_SUCCESS && petUser && petUserId === petUser.id) {
      setEditFormData(mapPetUserFormFields(petUser));
    }
  }, [requestPetUser, petUser, petUserId]);

  useEffect(() => {
    if (requestUpdatePetUser.REQUEST_SUCCESS) {
      history.push(pathAdministrationUsers);
    }
  }, [requestUpdatePetUser]);

  return (
    <>
      {requestPetUser.REQUESTED && (
        <div className="auditInfo" data-testid="editPetUserData-userLoader">
          <PetUserLoader
            goBackLink={pathAdministrationUsers}
            headerTitle={getTranslation('PetUsers_EditUser')}
          />
        </div>
      )}

      {requestPetUser.REQUEST_FAILURE && (
        <div className="auditInfo" data-testid="errorLoadEditPetUserData-wrapper">
          <Link to={pathAdministrationUsers}>
            <CloseControl />
          </Link>
          <Header as="h1">{getTranslation('PetUsers_EditUser')}</Header>
          <Message error data-testid="errorLoadEditPetUserData-message">
            <p>{getTranslation('Error')}</p>
            <TextButton
              text={getTranslation('Retry_Now')}
              onClick={() => petUserId && void getPetUser(petUserId)}
              dataTestId="errorLoadEditPetUserData-retryControl"
            />
          </Message>
        </div>
      )}

      {petUserId && requestPetUser.REQUEST_SUCCESS && Boolean(editFormData.email) && (
        <>
          {isUnavailableFields && (
            <div className="auditMessage">
              <NoAccessMessageWrapper dataTestId="editPetUser" userContextValue={userContextValue} />
            </div>
          )}

          <div className="petUserForm" data-testid="editPetUserDataForm-wrapper">
            {requestUpdatePetUser.REQUESTED && (
              <FullSizeLoader dataTestId="editPetUserDataForm-updateLoader" />
            )}
            <WithDataAuthorizationRoles>
              <WithFeatureAccessGroups>
                <PetForm<PetUserFormViewModel>
                  cancelButtonClicked={handleCancel}
                  submitForm={updatePetUser}
                  isLeaveCondition={(isDirty: boolean, redirectUrlToLeave: string) =>
                    isLeaveCondition({
                      isDirty,
                      redirectUrlToLeave,
                      requestCondition: requestUpdatePetUser.REQUEST_SUCCESS,
                    })
                  }
                  isRedirectLeave
                  editFormData={editFormData}
                  isEditForm
                  isNotEnoughAccess={isUnavailableFields}
                >
                  <PetUserForm editFormData={editFormData} />
                </PetForm>
              </WithFeatureAccessGroups>
            </WithDataAuthorizationRoles>
          </div>
        </>
      )}
      {petUserId && requestPetUser.REQUEST_SUCCESS && !petUser && (
        <div className="auditInfo" data-testid="editPetUserData-empty-wrapper">
          <Link to={pathAdministrationUsers}>
            <CloseControl />
          </Link>
          <Header as="h1" data-testid="editPetUserData-title">
            {getTranslation('PetUsers_EditUser')}
          </Header>
          <EmptyMessage optionalText={getTranslation('No_PetUser')} />
        </div>
      )}
    </>
  );
};

export default WithApolloClient(EditPetUserWrapper);

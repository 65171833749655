import React, { useContext } from 'react';
import { Message } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import { getTranslation } from '../../../utils/getTranslation';
import { tripsTicketsContext } from '../../../shared/store/tripsTicketsContext';
import { API_REQUEST_STATUS } from '../../../utils/APIHandlers';
import TicketTable from './TripsTicketsSearchTable';
import { COUNT_TICKETS_ITEMS_ON_PAGE } from './constants';
import { pathTicketInfo } from '../../../routes/constants';
import PaginationWrapper from '../../../shared/controls/Pagination/PaginationWrapper';
import FullSizeLoader from '../../../shared/view/Loader/FullSizeLoader';

import './styles.scss';

const TripsTicketsSearchWrapper: React.FC = () => {
  const history = useHistory();
  const {
    tripsTicketsSearchData,
    requestTripsTicketsSearchStatus,
    remainingRecords: totalItems,
    tripsTicketsSearchPage,
    setTripsTicketsSearchPage,
  } = useContext(tripsTicketsContext);

  const redirectToTripInfo = (_id: string) => {
    history.push(`${pathTicketInfo}/${_id}`);
  };

  return (
    <div className="info-container tripsTicketsSearchWrapper" data-testid="ticketsTable-wrapper">
      <div className="ticketsTable-container">
        {tripsTicketsSearchData.length > 0 && (
          <TicketTable tripsTickets={tripsTicketsSearchData} onRowClick={redirectToTripInfo} />
        )}

        {requestTripsTicketsSearchStatus === API_REQUEST_STATUS.REQUESTED && (
          <FullSizeLoader dataTestId="ticketsTable-loader-wrapper" blur={tripsTicketsSearchData.length > 0} />
        )}

        {requestTripsTicketsSearchStatus === API_REQUEST_STATUS.REQUEST_FAILURE && (
          <Message
            error
            content={getTranslation('TicketsListPage_WarningMessage')}
            data-testid="ticketsTable-failure-message"
          />
        )}

        {requestTripsTicketsSearchStatus === API_REQUEST_STATUS.REQUEST_SUCCESS &&
          tripsTicketsSearchData.length === 0 && (
            <Message
              warning
              content={getTranslation('TicketsListPage_NotResult')}
              data-testid="ticketsTable-successEmpty-message"
            />
          )}
      </div>

      <div className="pagination-container">
        <PaginationWrapper
          activePage={tripsTicketsSearchPage}
          countItemsPerPage={COUNT_TICKETS_ITEMS_ON_PAGE}
          totalItems={totalItems}
          onPageChange={setTripsTicketsSearchPage}
        />
      </div>
    </div>
  );
};

export default TripsTicketsSearchWrapper;

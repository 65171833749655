import React from 'react';
import { ApolloConsumer } from '@apollo/client';

export const WithApolloClient = <Props,>(WrappedComponent: React.FC<Props>): React.ComponentClass<Props> =>
  class WithApolloClient extends React.Component<Props> {
    render() {
      return (
        <ApolloConsumer>
          {(client) => <WrappedComponent client={client} {...(this.props as Props)} />}
        </ApolloConsumer>
      );
    }
  };

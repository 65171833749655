import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Header, Message } from 'semantic-ui-react';

import { WithApolloClient } from '../../../WithApolloClient/WithApolloClient';
import PetForm from '../../../PetForm';
import { getRequestStatuses } from '../../../../utils/APIHandlers';
import FullSizeLoader from '../../../../shared/view/Loader/FullSizeLoader';
import { WithReports } from '../../../../shared/containers/withReports';
import { FeatureAccessGroupFormViewModel } from '../../../../models/FeatureAccessGroupsAggregate/FeatureAccessGroup';
import { emptyFormFeatureAccessGroup } from '../constants';
import FeatureAccessGroupsForm from './FeatureAccessGroupsForm';
import { updateFeatureAccessGroupContext } from '../../../../shared/store/FeatureAccessGroups/updateFeatureAccessGroupContext';
import { featureAccessGroupContext } from '../../../../shared/store/FeatureAccessGroups/featureAccessGroupContext';
import { featureGroupKey, pathAdministrationFeatureGroups } from '../../../../routes/constants';
import { mapFeatureAccessGroupToFormViewModel } from '../featureAccessGroupsMapper';
import FeatureAccessGroupLoader from '../FeatureAccessGroupLoader/FeatureAccessGroupLoader';
import { getTranslation } from '../../../../utils/getTranslation';
import CloseControl from '../../../../shared/controls/CloseControl/CloseControl';
import TextButton from '../../../../shared/controls/TextButton/TextButton';
import EmptyMessage from '../../../../shared/view/EmptyMessage/EmptyMessage';
import { isLeaveCondition } from '../../PetUsers/helper';
import { userContext } from '../../../../shared/store/userContext';
import NoAccessMessageWrapper from '../FeatureAccessGroupsMessages/NoAccessMessageWrapper';
import { hasReportsManagementAccess } from '../../../../pages/Administration/helper';

import './styles.scss';

const EditFeatureAccessGroupWrapper: React.FC = () => {
  const {
    updateFeatureAccessGroup,
    requestUpdateFeatureAccessGroupStatus,
    clearUpdateFeatureAccessGroupState,
  } = useContext(updateFeatureAccessGroupContext);
  const { getFeatureAccessGroup, requestFeatureAccessGroupStatus, featureAccessGroupData } =
    useContext(featureAccessGroupContext);
  const userContextValue = useContext(userContext);
  const [editFormData, setEditFormData] =
    useState<FeatureAccessGroupFormViewModel>(emptyFormFeatureAccessGroup);
  const requestUpdateFeatureAccessGroup = useMemo(
    () => getRequestStatuses(requestUpdateFeatureAccessGroupStatus),
    [requestUpdateFeatureAccessGroupStatus]
  );
  const requestFeatureAccessGroup = useMemo(
    () => getRequestStatuses(requestFeatureAccessGroupStatus),
    [requestFeatureAccessGroupStatus]
  );
  const history = useHistory();
  const params = useParams<{ [featureGroupKey]?: string }>();
  const featureAccessGroupId = params[featureGroupKey];
  const handleCancel = () => {
    clearUpdateFeatureAccessGroupState();
    history.push(pathAdministrationFeatureGroups);
  };

  useEffect(() => {
    clearUpdateFeatureAccessGroupState();
  }, []);

  useEffect(() => {
    if (featureAccessGroupId) {
      setEditFormData(emptyFormFeatureAccessGroup);
      void getFeatureAccessGroup(featureAccessGroupId);
    }
  }, [featureAccessGroupId]);

  useEffect(() => {
    if (
      requestFeatureAccessGroup.REQUEST_SUCCESS &&
      featureAccessGroupData &&
      featureAccessGroupId === featureAccessGroupData.id
    ) {
      setEditFormData(mapFeatureAccessGroupToFormViewModel(featureAccessGroupData));
    }
  }, [requestFeatureAccessGroup, featureAccessGroupData, featureAccessGroupId]);

  useEffect(() => {
    if (requestUpdateFeatureAccessGroup.REQUEST_SUCCESS) {
      history.push(pathAdministrationFeatureGroups);
    }
  }, [requestUpdateFeatureAccessGroup]);

  return (
    <>
      {requestFeatureAccessGroup.REQUESTED && (
        <div className="auditInfo" data-testid="updateFeatureAccessGroupData-loader">
          <FeatureAccessGroupLoader
            goBackLink={pathAdministrationFeatureGroups}
            headerTitle={getTranslation('FeatureAccessGroups_EditGroup')}
          />
        </div>
      )}
      {requestFeatureAccessGroup.REQUEST_FAILURE && (
        <div className="auditInfo" data-testid="errorLoadUpdateFeatureAccessGroupData-wrapper">
          <Link to={pathAdministrationFeatureGroups}>
            <CloseControl />
          </Link>
          <Header as="h1" data-testid="errorLoadUpdateFeatureAccessGroupData-title">
            {getTranslation('FeatureAccessGroups_Details')}
          </Header>
          <Message error data-testid="errorLoadUpdateFeatureAccessGroupData-message">
            <p>{getTranslation('Error')}</p>
            <TextButton
              text={getTranslation('Retry_Now')}
              onClick={() => featureAccessGroupId && void getFeatureAccessGroup(featureAccessGroupId)}
              dataTestId="errorLoadUpdateFeatureAccessGroupData-retryControl"
            />
          </Message>
        </div>
      )}

      {featureAccessGroupId &&
        requestFeatureAccessGroup.REQUEST_SUCCESS &&
        Boolean(editFormData.groupName) && (
          <>
            {!hasReportsManagementAccess(userContextValue) && (
              <div className="auditMessage">
                <NoAccessMessageWrapper dataTestId="updateFeatureAccessGroup" />
              </div>
            )}

            <div className="featureAccessGroupForm" data-testid="updateFeatureAccessGroupDataForm-wrapper">
              {requestUpdateFeatureAccessGroup.REQUESTED && (
                <FullSizeLoader dataTestId="updateFeatureAccessGroupDataForm-loader" />
              )}

              <WithReports>
                <PetForm<FeatureAccessGroupFormViewModel>
                  cancelButtonClicked={handleCancel}
                  submitForm={updateFeatureAccessGroup}
                  isLeaveCondition={(isDirty: boolean, redirectUrlToLeave: string) =>
                    isLeaveCondition({
                      isDirty,
                      redirectUrlToLeave,
                      requestCondition: requestUpdateFeatureAccessGroup.REQUEST_SUCCESS,
                    })
                  }
                  isRedirectLeave
                  editFormData={editFormData}
                  isEditForm
                  isNotEnoughAccess={!hasReportsManagementAccess(userContextValue)}
                >
                  <FeatureAccessGroupsForm editFormData={editFormData} />
                </PetForm>
              </WithReports>
            </div>
          </>
        )}

      {featureAccessGroupId && requestFeatureAccessGroup.REQUEST_SUCCESS && !featureAccessGroupData && (
        <div className="auditInfo" data-testid="updateFeatureAccessGroupData-empty-wrapper">
          <Link to={pathAdministrationFeatureGroups}>
            <CloseControl />
          </Link>
          <Header as="h1" data-testid="updateFeatureAccessGroupData-title">
            {getTranslation('FeatureAccessGroups_EditGroup')}
          </Header>
          <EmptyMessage optionalText={getTranslation('No_FeatureAccessGroup')} />
        </div>
      )}
    </>
  );
};

export default WithApolloClient(EditFeatureAccessGroupWrapper);

import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Button, Message } from 'semantic-ui-react';

import {
  mapToEditExportJobDTOFromExportJobFormViewModel,
  mapToExportJobFieldsFromExportJobDTO,
} from '../exportJobsMapper';
import { ExportJobFormViewModel } from '../../../models/ExportJobsAggregate/ExportJobForm';
import { createExportJobContext } from '../../../shared/store/createExportJobContext';
import { exportJobContext } from '../../../shared/store/exportJobContext';
import { userContext } from '../../../shared/store/userContext';
import CreateExportJobForm from './CreateExportJobForm';
import EditExportJobModals from './Modals/EditExportJobModals';
import { API_REQUEST_STATUS } from '../../../utils/APIHandlers';
import FullSizeLoader from '../../../shared/view/Loader/FullSizeLoader';
import { getTranslation } from '../../../utils/getTranslation';
import { pathExportJobsList, pathCreateExportJob } from '../../../routes/constants';
import { WithReports } from '../../../shared/containers/withReports';
import { WithDataAuthorizationRoles } from '../../../shared/containers/withDataAuthorizationRoles';
import { WithSftpProviders } from '../../../shared/containers/withSftpProviders';
import './styles.scss';

interface EditExportJobFormWrapperProps {
  exportJobId: string;
}

const EditExportJobFormWrapper: React.FC<EditExportJobFormWrapperProps> = (props) => {
  const { exportJobId } = props;
  const history = useHistory();
  const { handleOpenCreateJob, notificationCreateJob } = useContext(createExportJobContext);
  const { getExportJob, exportJobData, requestExportJobStatus } = useContext(exportJobContext);
  const [jobFormData, setJobFormData] = useState<ExportJobFormViewModel | null>(null);
  const { email } = useContext(userContext);

  const handleGetExportJob = useCallback((): void => {
    void getExportJob(exportJobId);
  }, [getExportJob, exportJobId]);

  useEffect(() => {
    handleGetExportJob();
  }, []);

  useEffect(() => {
    if (
      notificationCreateJob === API_REQUEST_STATUS.REQUEST_SUCCESS ||
      notificationCreateJob === API_REQUEST_STATUS.REQUESTED
    ) {
      setTimeout(() => {
        history.push(pathExportJobsList);
      }, 2000);
    }
  }, [notificationCreateJob, history]);

  useEffect(() => {
    exportJobData && setJobFormData(mapToExportJobFieldsFromExportJobDTO(exportJobData));
  }, [exportJobData]);

  const onSubmit = useCallback(
    (submitData: ExportJobFormViewModel): void => {
      exportJobData &&
        handleOpenCreateJob(
          mapToEditExportJobDTOFromExportJobFormViewModel(submitData, exportJobData, email),
          true
        );
    },
    [handleOpenCreateJob, exportJobData, email]
  );

  return (
    <>
      {requestExportJobStatus === API_REQUEST_STATUS.REQUESTED && <FullSizeLoader />}

      {requestExportJobStatus === API_REQUEST_STATUS.REQUEST_SUCCESS && jobFormData !== null && (
        <WithReports>
          <WithSftpProviders>
            <WithDataAuthorizationRoles>
              <CreateExportJobForm onSubmit={onSubmit} defaultData={jobFormData || undefined} isEdit />
            </WithDataAuthorizationRoles>
          </WithSftpProviders>
        </WithReports>
      )}

      {requestExportJobStatus === API_REQUEST_STATUS.REQUEST_SUCCESS && jobFormData === null && (
        <div data-testid="emptyExportJob-message">
          <Message warning>{getTranslation('Empty_GetJob_Response')}</Message>
          <Button as={Link} basic size="small" to={pathExportJobsList}>
            {getTranslation('Additional_Route_Control_ExportsJobs')}
          </Button>
          <Button as={Link} primary size="small" to={pathCreateExportJob}>
            {getTranslation('Create_ExportJob_Title')}
          </Button>
        </div>
      )}

      {requestExportJobStatus === API_REQUEST_STATUS.REQUEST_FAILURE && (
        <div data-testid="errorExportJob-message">
          <Message warning>{getTranslation('Error')}</Message>
          <Button as={Link} basic size="small" to={pathExportJobsList}>
            {getTranslation('Additional_Route_Control_ExportsJobs')}
          </Button>
          <Button primary size="small" onClick={handleGetExportJob} data-testid="retryGetExportJob-control">
            {getTranslation('Retry_Now')}
          </Button>
        </div>
      )}

      <EditExportJobModals />
    </>
  );
};

export default EditExportJobFormWrapper;

import React, { useEffect, useContext, useCallback } from 'react';

import { documentContext } from '../../shared/store/documentsContext';
import DocumentsNotification from './DocumentsNotifications/DocumentsNotifications';
import DocumentsTable from './DocumentsTable/DocumentsTable';

const DocumentsWrapper: React.FC = () => {
  const { getDocuments } = useContext(documentContext);
  const handleGetDocuments = useCallback(() => {
    void getDocuments();
  }, [getDocuments]);

  useEffect(() => {
    handleGetDocuments();
  }, []);

  return (
    <>
      <DocumentsNotification />
      <DocumentsTable />
    </>
  );
};

export default DocumentsWrapper;

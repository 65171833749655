import React, { Dispatch, SetStateAction } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import SignIn from './SignIn';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import Logo from '../../shared/view/Logo/Logo';
import AuthFooter from './AuthFooter';
import AuthBannerContent from './AuthBannerContent';

import './index.scss';

interface Props {
  setUser: Dispatch<SetStateAction<any | null | undefined>>;
}

const Authentication = ({ setUser }: Props): JSX.Element => {
  return (
    <div className="authentication" data-testid="authentication">
      <div className="form-container">
        <div className="container-logoWrapper">
          <Logo />
        </div>

        <Router>
          <Switch>
            <Route exact path="/forgotPassword">
              <ForgotPassword />
            </Route>
            <Route exact path="/resetPassword">
              <ResetPassword />
            </Route>
            <Route exact path="*">
              <SignIn setUser={setUser} />
            </Route>
          </Switch>
        </Router>

        <AuthFooter />
      </div>

      <div className="banner">
        <AuthBannerContent />
      </div>
    </div>
  );
};

export default Authentication;

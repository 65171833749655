import React, { useState } from 'react';
import { NormalizedCacheObject, ApolloClient } from '@apollo/client';

import { WithApolloClient } from '../../../features/WithApolloClient/WithApolloClient';
import {
  defaultFeatureAccessGroupsContextValue,
  featureAccessGroupsContext,
  FeatureAccessGroupsContextProps,
} from '../../store/FeatureAccessGroups/featureAccessGroupsContext';
import { API_REQUEST_STATUS, RequestStatus } from '../../../utils/APIHandlers';
import { FeatureAccessGroupService } from '../../../services/FeatureAccessGroupService';
import { FeatureAccessGroupViewModel } from '../../../models/FeatureAccessGroupsAggregate/FeatureAccessGroup';
import { mapFeatureAccessGroups } from '../../../features/AdministrationContainer/FeatureAccessGroups/featureAccessGroupsMapper';

interface Props {
  children: React.ReactChild;
  client?: ApolloClient<NormalizedCacheObject>;
}

const FeatureAccessGroups: React.FC<Props> = ({ children, client }) => {
  const [featureAccessGroupsData, setFeatureAccessGroupsData] = useState<FeatureAccessGroupViewModel[]>([]);
  const [requestFeatureAccessGroupsStatus, setRequestFeatureAccessGroupsStatus] = useState<RequestStatus>(
    API_REQUEST_STATUS.NOT_REQUESTED
  );

  const getFeatureAccessGroups = async (limit = 100000, offset = 0) => {
    try {
      const clientApollo = client as ApolloClient<NormalizedCacheObject>;
      const featureAccessGroupsService: FeatureAccessGroupService = new FeatureAccessGroupService(
        clientApollo
      );
      setRequestFeatureAccessGroupsStatus(API_REQUEST_STATUS.REQUESTED);

      const response = await featureAccessGroupsService.getAllFeatureAccessGroups(limit, offset);

      setRequestFeatureAccessGroupsStatus(API_REQUEST_STATUS.REQUEST_SUCCESS);
      setFeatureAccessGroupsData(mapFeatureAccessGroups(response));
    } catch (error) {
      console.error(error);
      setRequestFeatureAccessGroupsStatus(API_REQUEST_STATUS.REQUEST_FAILURE);
    }
  };

  const getFeatureAccessGroupsContext = (): FeatureAccessGroupsContextProps => {
    return {
      ...defaultFeatureAccessGroupsContextValue,
      getFeatureAccessGroups,
      featureAccessGroupsData,
      requestFeatureAccessGroupsStatus,
    };
  };

  return (
    <featureAccessGroupsContext.Provider value={getFeatureAccessGroupsContext()}>
      {children}
    </featureAccessGroupsContext.Provider>
  );
};

const WithFeatureAccessGroups = WithApolloClient(FeatureAccessGroups);

export { WithFeatureAccessGroups };

import React, { useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Button, Form } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import { getEmailPattern, getRequiredFieldRegister } from '../../PetForm/helper';
import InputWithInfo from '../../PetForm/InputWithInfo';
import { getTranslation } from '../../../utils/getTranslation';
import { getUIErrorMessage } from '../../../utils/errorHandler';

const ForgotPassword: React.FC = () => {
  const [alertMessage, setAlertMessage] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const requiredFieldRegister = getRequiredFieldRegister();
  const emailPattern = getEmailPattern();

  const history = useHistory();

  const forgotPassword = async ({ email }: { email: string }) => {
    try {
      await Auth.forgotPassword(email);

      history.push({
        pathname: '/resetPassword',
        state: {
          email: email,
        },
      });
    } catch (error: Error | unknown) {
      setAlertMessage(getUIErrorMessage((error as Error)?.message, 'user'));
    }
  };

  const onSubmitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>): void => {
      void handleSubmit(forgotPassword)(event);
    },
    [forgotPassword]
  );

  return (
    <Form className="form" onSubmit={onSubmitForm}>
      <h2>{getTranslation('Authentication_ForgotPassword')}</h2>
      <div className="">{getTranslation('Authentication_ForgotPasswordInfo')}</div>

      <InputWithInfo
        required
        label={getTranslation('Authentication_Email')}
        error={errors.email}
        register={register('email', {
          ...requiredFieldRegister,
          ...emailPattern,
        })}
        size={'large'}
      />

      {alertMessage && <div className="formError">{alertMessage}</div>}

      <Button primary type="submit" size="medium" fluid>
        {getTranslation('Authentication_VerificationCodeBtn')}
      </Button>

      <div className="center-content">
        <Link to="/" className="link-text">
          {getTranslation('Authentication_GoBack')}
        </Link>
      </div>
    </Form>
  );
};

export default ForgotPassword;

import React, { useState, useEffect } from 'react';
import { NormalizedCacheObject, ApolloClient } from '@apollo/client';
import { Location as HistoryLocation } from 'history';

import { WithApolloClient } from '../../features/WithApolloClient/WithApolloClient';
import { useModal, useModalReturn } from '../hooks/modalHook';
import { useNotification, useNotificationReturn } from '../hooks/notificationHook';
import {
  defaultCreateExportJobContextValue,
  CreateExportJobContextProps,
  createExportJobContext,
} from '../store/createExportJobContext';
import { API_REQUEST_STATUS } from '../../utils/APIHandlers';
import { ExportJobsService } from '../../services/ExportJobsService';
import { ExportJobDTO } from '../../api/graphql/models/ExportJob';
import {
  mapToCreateExportJobDTOFromExportJobDTO,
  mapToEditExportJobDTOFromExportJobDTO,
} from '../../features/ExportJobsContainer/exportJobsMapper';

interface Props {
  children: React.ReactChild;
  client?: ApolloClient<NormalizedCacheObject>;
}

const CreateJobWrapper: React.FC<Props> = ({ children, client }) => {
  const [isEdit, setEdit] = useState<boolean>(false);
  const [
    { isOpen, requestStatus },
    { handleOpen, handleClose, onSuccessAction, setFailState, setRequestedState },
  ]: useModalReturn = useModal();
  const [
    { notificationStatus },
    { setFailNotificationStatus, setNotRequestedNotificationStatus, setSuccessNotificationStatus },
  ]: useNotificationReturn = useNotification();
  const [exportJobData, setJobData] = useState<ExportJobDTO | null>(null);
  const [isOpenLeaveCondition, setIsOpenLeaveCondition] = useState<boolean>(false);
  const [leaveDirection, setLeaveDirection] = useState<string | null>(null);

  useEffect(() => {
    !isOpen && isEdit && setEdit(false);
  }, [isOpen]);

  const createExportJob = async (): Promise<void> => {
    if (requestStatus !== API_REQUEST_STATUS.REQUESTED && exportJobData !== null) {
      try {
        const clientApollo = client as ApolloClient<NormalizedCacheObject>;
        const exportJobsService: ExportJobsService = new ExportJobsService(clientApollo);
        setRequestedState();

        await exportJobsService.createExportJob(mapToCreateExportJobDTOFromExportJobDTO(exportJobData));

        onSuccessAction();
        setSuccessNotificationStatus();
      } catch (error) {
        console.error(error);
        setFailState();
        setFailNotificationStatus();
      }
    }
  };

  const editJob = async (): Promise<void> => {
    if (requestStatus !== API_REQUEST_STATUS.REQUESTED && exportJobData !== null) {
      try {
        const clientApollo = client as ApolloClient<NormalizedCacheObject>;
        const exportJobsService: ExportJobsService = new ExportJobsService(clientApollo);
        setRequestedState();

        await exportJobsService.updateExportJob(
          exportJobData._id,
          mapToEditExportJobDTOFromExportJobDTO(exportJobData)
        );

        onSuccessAction();
        setSuccessNotificationStatus();
      } catch (error) {
        console.error(error);
        setFailState();
        setFailNotificationStatus();
      }
    }
  };

  const getJobContext = (): CreateExportJobContextProps => {
    return {
      ...defaultCreateExportJobContextValue,
      isOpenCreateCondition: isOpen,
      exportJobData,
      isEdit,
      createExportJob,
      editJob,
      requestCreateJobStatus: requestStatus,
      notificationCreateJob: notificationStatus,
      handleOpenCreateJob: (input: ExportJobDTO, isEditExportJob?: boolean): void => {
        isEditExportJob && setEdit(true);
        setNotRequestedNotificationStatus();

        setJobData(input);
        handleOpen();
      },
      handleCloseCreateJob: (): void => {
        setNotRequestedNotificationStatus();
        setJobData(null);
        handleClose();
      },
      isOpenLeaveCondition,
      leaveDirection,
      handleOpenLeaveCreateJob: (direction: HistoryLocation): void => {
        setIsOpenLeaveCondition(true);
        setLeaveDirection(direction?.pathname);
      },
      handleCloseLeaveCreateJob: (): void => {
        setIsOpenLeaveCondition(false);
        setLeaveDirection(null);
      },
    };
  };

  return (
    <createExportJobContext.Provider value={getJobContext()}>{children}</createExportJobContext.Provider>
  );
};

const WithCreateJob = WithApolloClient(CreateJobWrapper);

export { WithCreateJob };

import { v4 as uuidv4 } from 'uuid';

import {
  ExportJobDTO,
  CreateEditExportJobDTO,
  ExportJobsListDTO,
  CorrectExportJobDTO,
  ReportDetailsLinkType,
} from '../../api/graphql/models/ExportJob';
import { USER_EMAILS_LIST_SEPARATOR, defaultValueEditExportJobFields } from './EditExportJob/constants';
import { ExportJobFormViewModel } from '../../models/ExportJobsAggregate/ExportJobForm';
import { getEmailPattern } from '../PetForm/helper';
import { getDateFromUTCDate, getUTCDateFromDate, setEndTime, setStartTime } from '../../utils/formatDate';
import { PeriodType } from '../../models/ExportJobsAggregate/ExportJobPeriodRange';
import { buildNextMultiDropdownValue } from './EditExportJob/Fields/helper';

const emailPattern = getEmailPattern();

export const mapCreateEditExportJobDTOFromExportJobFormFields = (
  data: ExportJobFormViewModel
): ExportJobDTO => ({
  deliveryMethod: data.deliveryMethod,
  enabled: data.enabled,
  format: data.format,
  provider: data?.provider || '',
  cronExpression: data.cronExpression,
  jobName: data.jobName,
  userEmails: data.userEmails.map((item) => String(item.value)),
  activeStartDate: getDateFromUTCDate(
    setStartTime((data?.activeDateRange && data.activeDateRange[0]) || new Date())
  ),
  activeEndDate: getDateFromUTCDate(
    setEndTime((data?.activeDateRange && data.activeDateRange[1]) || new Date())
  ),
  dataAuthorisationRoleId: {
    _id: data?.dataAuthorisationRoleId || '',
    roleName: data.dataAuthorisationRoleName,
  },
  reportDetails: {
    period: {
      type: data.periodType,
      offsetDays: data.periodType === PeriodType.CUSTOM ? Number(data.periodOffsetDays) : undefined,
    },
    reportId: {
      _id: data?.report || 0,
      displayName: data.reportName,
      reportName: '',
      isPaginated: false,
    },
  },
  createdBy: '',
  createdDate: new Date(),
  updatedBy: '',
  updatedDate: null,
  _id: '',
});

export const mapToCreateExportJobDTOFromExportJobFormViewModel = (
  data: ExportJobFormViewModel,
  email: string
): ExportJobDTO => ({
  ...mapCreateEditExportJobDTOFromExportJobFormFields(data),
  _id: String(uuidv4()),
  createdDate: new Date(),
  createdBy: email,
  updatedBy: null,
  updatedDate: null,
});

export const mapToEditExportJobDTOFromExportJobFormViewModel = (
  data: ExportJobFormViewModel,
  exportJobData: ExportJobDTO,
  email: string
): ExportJobDTO => ({
  ...mapCreateEditExportJobDTOFromExportJobFormFields(data),
  _id: exportJobData._id,
  createdBy: exportJobData.createdBy,
  createdDate: exportJobData.createdDate,
  updatedBy: email,
  updatedDate: new Date(),
});

export const wrapExportJobDTOWithUpdateDateFromExportJobDTO = (
  exportJobData: ExportJobDTO,
  email: string
): ExportJobDTO => ({
  ...exportJobData,
  updatedBy: email,
  updatedDate: new Date(),
});

export const mapToCreateExportJobDTOFromExportJobDTO = (data: ExportJobDTO): CreateEditExportJobDTO => ({
  ...data,
  activeStartDate: data.activeStartDate.toISOString(),
  activeEndDate: data.activeEndDate.toISOString(),
  createdDate: data.createdDate.toISOString(),
  updatedDate: null,
  dataAuthorisationRoleId:
    (data.dataAuthorisationRoleId && {
      link: data.dataAuthorisationRoleId._id,
    }) ||
    null,
  reportDetails: data.reportDetails
    ? ({
        period: data.reportDetails.period,
        reportId: {
          link: data.reportDetails.reportId._id ?? '',
        },
      } as ReportDetailsLinkType)
    : null,
});

export const mapToEditExportJobDTOFromExportJobDTO = (data: ExportJobDTO): CreateEditExportJobDTO => ({
  ...mapToCreateExportJobDTOFromExportJobDTO(data),
  updatedDate: (data?.updatedDate && data.updatedDate.toISOString()) || null,
});

export const mapToExportJobFieldsFromExportJobDTO = (data: ExportJobDTO): ExportJobFormViewModel => ({
  cronExpression: data.cronExpression,
  dataAuthorisationRoleId: data.dataAuthorisationRoleId?._id || null,
  dataAuthorisationRoleName: data.dataAuthorisationRoleId?.roleName || '',
  deliveryMethod: data.deliveryMethod,
  format: data.format,
  jobName: data.jobName,
  provider: data.provider,
  enabled: data.enabled,
  periodType: data.reportDetails?.period.type ?? PeriodType.DAY,
  periodOffsetDays: data.reportDetails?.period.offsetDays ?? defaultValueEditExportJobFields.periodOffsetDays,
  report: data.reportDetails?.reportId._id || null,
  reportName: data.reportDetails?.reportId.displayName || '',
  userEmails: buildNextMultiDropdownValue(
    USER_EMAILS_LIST_SEPARATOR,
    emailPattern.pattern.value,
    data.userEmails
  ).result,
  activeDateRange: [getUTCDateFromDate(data.activeStartDate), getUTCDateFromDate(data.activeEndDate)],
});

export const sortExportJobsByCreatedUpdatedDatesDESC = (data: ExportJobsListDTO): void => {
  data.sort((a: ExportJobDTO, b: ExportJobDTO) => {
    const ADate = (a.updatedDate && new Date(a.updatedDate)) || new Date(a.createdDate);
    const BDate = (b.updatedDate && new Date(b.updatedDate)) || new Date(b.createdDate);

    return BDate.getTime() - ADate.getTime();
  });
};

export const mapExportJobDTOFromCorrectExportJobDTO = (data: CorrectExportJobDTO): ExportJobDTO => ({
  ...data,
  activeStartDate: new Date(data.activeStartDate),
  activeEndDate: new Date(data.activeEndDate),
  createdDate: new Date(data.createdDate),
  updatedDate: (data?.updatedDate && new Date(data.updatedDate)) || null,
});

import { ReportIcon, ReportType } from './../../../api/graphql/models/ReportsResponse';
import { ReportTypeDto } from './../../../api/graphql/models/ReportTypeDto';
import { ReportDetailsDto } from './../../../api/rest/models/EmbedTokenResponseAggregate/ReportDetailsDto';
import { DashboardReportViewModel, ReportViewModel } from './../../../models/ReportsAggregate/Report';
import { ReportFormViewModel } from './../../../models/ReportsAggregate/ReportForm';

export const DEFAULT_REPORT_ICON = ReportIcon.ChartLine;
export const DEFAULT_REPORT_TYPE = ReportType.Analytical;

export const mapReportFromDTO = (data: ReportTypeDto): ReportViewModel => ({
  ...data,
  id: data._id,
  isPaginated: data.isPaginated ?? false,
});

export const mapReportsFromDTO = (data: ReportTypeDto[]): ReportViewModel[] =>
  data.map((item) => mapReportFromDTO(item));

export const mapReportFormFromViewModel = (data: ReportViewModel): ReportFormViewModel => ({
  ...data,
  icon: String(data.icon),
  reportType: String(data.reportType),
});

export const mapReportDTOFromForm = (
  data: ReportFormViewModel,
  reports: ReportViewModel[]
): ReportTypeDto => ({
  _id: data.id ?? Math.max(...reports.map(({ id }) => id)) + 1,
  displayName: data.displayName,
  reportName: data.reportName,
  isPaginated: data.isPaginated,
  icon: Number(data.icon),
  reportType: Number(data.reportType),
  description: data.description,
});

export const mapDashboardReportFromDTO = ({
  id,
  displayName,
  reportName,
  icon,
  reportType,
  description,
}: ReportDetailsDto): DashboardReportViewModel => ({
  id,
  displayName,
  reportName,
  icon,
  reportType,
  description,
});

export const mapDashboardReportsFromDTO = (data: ReportDetailsDto[]): DashboardReportViewModel[] =>
  data.map((item) => mapDashboardReportFromDTO(item));

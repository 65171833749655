import React, { useContext, useEffect } from 'react';
import { Icon, Message, Table } from 'semantic-ui-react';
import classNames from 'classnames';

import TableLabel from '../../../TableLabel';
import DropdownMenu from '../../../AdministrationTable/DropdownMenu';
import { getTranslation } from '../../../../utils/getTranslation';
import { PetUserGridViewModel } from '../../../../models/PetUsersAggregate/PetUser';
import { countItemsPerPage } from '../../../../pages/Administration/constant';
import PaginationWrapper from '../../../../shared/controls/Pagination/PaginationWrapper';
import {
  pathAdministrationUsersEdit,
  pathAdministrationUsersView,
  petUserKey,
} from '../../../../routes/constants';
import { mapGridPetUser } from '../petUsersMapper';
import { petUsersContext } from '../../../../shared/store/PetUser/petUsersContext';
import { userContext } from '../../../../shared/store/userContext';
import { useHistory, useParams } from 'react-router-dom';
import { updatePetUserContext } from '../../../../shared/store/PetUser/updatePetUserContext';
import { getRequestStatuses } from '../../../../utils/APIHandlers';
import { createPetUserContext } from '../../../../shared/store/PetUser/createPetUserContext';
import MessageWithControl from '../../../../shared/controls/MessageWithControl';
import TextButton from '../../../../shared/controls/TextButton';
import EmptyMessage from '../../../../shared/view/EmptyMessage/EmptyMessage';
import { petUsersTableHeader } from './constants';
import {
  nextFieldSortOrder,
  sortIconDirection,
  isFilteredValueIncludesSearch as isFoundInCell,
} from '../../../../utils/viewHelper';
import {
  hasDataAuthorizationManagementAccess,
  hasFeatureAccessManagementAccess,
} from '../../../../pages/Administration/helper';
import { hasDataAccess, hasUnavailableFields } from './../helper';
import NoAccessMessageWrapper from '../PetUserMessages/NoAccessMessageWrapper';
import FullSizeLoader from '../../../../shared/view/Loader/FullSizeLoader';

import './styles.scss';

const PetUsersTable: React.FC = () => {
  const userContextValue = useContext(userContext);
  const { isUserManagementHasReadWriteAccess, isUserManagementHasReadAccess } = userContextValue;
  const {
    getPetUsers,
    petUsers,
    totalPetUsers,
    activePagePetUsers,
    setPetUsersActivePage,
    requestPetUsersStatus,
    sortPetUsers,
    sortParams,
    filterSearchString,
  } = useContext(petUsersContext);
  const { requestUpdatePetUserStatus, setPetUserToDelete, setPetUserToUpdate, requestDeletePetUserStatus } =
    useContext(updatePetUserContext);
  const { requestCreatePetUserStatus } = useContext(createPetUserContext);
  const requestPetUsers = getRequestStatuses(requestPetUsersStatus);
  const requestCreatePetUser = getRequestStatuses(requestCreatePetUserStatus);
  const requestUpdatePetUser = getRequestStatuses(requestUpdatePetUserStatus);
  const requestDeletePetUser = getRequestStatuses(requestDeletePetUserStatus);
  const history = useHistory();
  const params = useParams<{ [petUserKey]?: string }>();
  const petUserId = params[petUserKey];
  const getHeaderColumnSortIcon = (field: typeof sortParams.sortBy): JSX.Element => (
    <Icon
      name={'long arrow alternate up'}
      className={`${field === sortParams.sortBy ? 'active' : ''} ${sortIconDirection(field, sortParams)}`}
      color={field === sortParams.sortBy ? 'blue' : 'grey'}
      onClick={() => sortPetUsers({ sortBy: field, sortOrder: nextFieldSortOrder(field, sortParams) })}
      data-testid={`petUsersTableHeaderSort-${field}-control`}
    />
  );

  useEffect(() => {
    void getPetUsers();
  }, []);

  useEffect(() => {
    if (
      requestCreatePetUser.REQUEST_SUCCESS ||
      requestUpdatePetUser.REQUEST_SUCCESS ||
      requestDeletePetUser.REQUEST_SUCCESS
    ) {
      void getPetUsers();
    }
  }, [requestCreatePetUser, requestUpdatePetUser, requestDeletePetUser, getPetUsers]);

  return (
    <>
      {requestPetUsers.REQUEST_FAILURE && (
        <Message error data-testid="errorResponsePetUsers-wrapper">
          <MessageWithControl message={getTranslation('App_LoadingError')}>
            <TextButton
              text={getTranslation('Retry_Now')}
              onClick={() => void getPetUsers()}
              dataTestId="errorResponsePetUsers-retry-control"
            />
          </MessageWithControl>
        </Message>
      )}

      <div className="petUsersTable-container">
        {requestPetUsers.REQUESTED && <FullSizeLoader dataTestId="loaderPetUsers-wrapper" />}
        {(requestPetUsers.REQUEST_SUCCESS || (requestPetUsers.REQUESTED && petUsers.length > 0)) && (
          <>
            {hasUnavailableFields(userContextValue) && (
              <NoAccessMessageWrapper dataTestId="tablePetUsers" userContextValue={userContextValue} />
            )}

            <Table
              celled
              className={classNames({ 'last-column-auto-width': isUserManagementHasReadAccess })}
              data-testid="tablePetUsers-wrapper"
            >
              <Table.Header>
                <Table.Row data-testid="tablePetUsersHeader">
                  {petUsersTableHeader.map((item) => (
                    <Table.HeaderCell key={item.field}>
                      {!hasDataAccess(item.field, userContextValue) && (
                        <Icon
                          name="exclamation triangle"
                          color="yellow"
                          className="leftIcon"
                          data-testid={`tablePetUsersHeader-NoAccess-${item.field}-column`}
                        />
                      )}
                      {getTranslation(item.title)}
                      {hasDataAccess(item.field, userContextValue) && getHeaderColumnSortIcon(item.field)}
                    </Table.HeaderCell>
                  ))}
                  {isUserManagementHasReadWriteAccess && <Table.HeaderCell />}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {petUsers.map((user) => {
                  const gridUser = mapGridPetUser(user);
                  const { id, name, email, dataAuthRole, featureAccessGroup, isEnabled, isFederated } =
                    gridUser;

                  return (
                    <Table.Row
                      key={id}
                      active={id === petUserId}
                      onClick={() => history.push(pathAdministrationUsersView(id))}
                      data-testid="tablePetUsersContent-row"
                    >
                      <Table.Cell positive={isFoundInCell(name, filterSearchString)}>{name}</Table.Cell>
                      <Table.Cell positive={isFoundInCell(email, filterSearchString)}>{email}</Table.Cell>
                      {hasDataAuthorizationManagementAccess(userContextValue) ? (
                        <Table.Cell
                          positive={isFoundInCell(dataAuthRole, filterSearchString)}
                          data-testid="tablePetUsersContent-row-DataAuthRole-field"
                        >
                          {dataAuthRole}
                        </Table.Cell>
                      ) : (
                        <Table.Cell></Table.Cell>
                      )}
                      {hasFeatureAccessManagementAccess(userContextValue) ? (
                        <Table.Cell
                          positive={isFoundInCell(featureAccessGroup, filterSearchString)}
                          data-testid="tablePetUsersContent-row-FeatureAccessGroup-field"
                        >
                          {featureAccessGroup}
                        </Table.Cell>
                      ) : (
                        <Table.Cell></Table.Cell>
                      )}
                      <Table.Cell>
                        <TableLabel
                          dataTestId="tablePetUsersContent-enabledColumn"
                          text={getTranslation(isEnabled ? 'PetUsers_Enabled' : 'PetUsers_NotEnabled')}
                          active={isEnabled}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <TableLabel
                          dataTestId="tablePetUsersContent-federatedColumn"
                          text={getTranslation(isFederated ? 'PetUsers_Federated' : 'PetUsers_NotFederated')}
                          active={isFederated}
                        />
                      </Table.Cell>

                      {isUserManagementHasReadWriteAccess && (
                        <Table.Cell>
                          <DropdownMenu<PetUserGridViewModel>
                            openEditForm={() => history.push(pathAdministrationUsersEdit(id))}
                            row={gridUser}
                            additionalItems={[
                              {
                                text: isEnabled
                                  ? getTranslation('AdministrationTable_Disable')
                                  : getTranslation('AdministrationTable_Enable'),
                                disabled: false,
                                onClick: () => setPetUserToUpdate(user),
                              },
                              {
                                text: getTranslation('Delete'),
                                disabled: false,
                                onClick: () => setPetUserToDelete(user),
                              },
                            ]}
                            dataTestId="tablePetUsersContentDropdown-control"
                          />
                        </Table.Cell>
                      )}
                    </Table.Row>
                  );
                })}
                {petUsers.length === 0 && (
                  <Table.Row disabled data-testid="emptyTablePetUsersContent-wrapper">
                    <Table.Cell
                      colSpan={petUsersTableHeader.length + Number(isUserManagementHasReadWriteAccess)}
                    >
                      <EmptyMessage
                        optionalText={getTranslation('No_PetUsers')}
                        dataTestId="petUsers-empty-message"
                      />
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>

            <PaginationWrapper
              activePage={activePagePetUsers}
              countItemsPerPage={countItemsPerPage}
              totalItems={totalPetUsers}
              onPageChange={setPetUsersActivePage}
            />
          </>
        )}
      </div>
    </>
  );
};

export default PetUsersTable;

import React from 'react';

import { WithReports } from '../../../shared/containers/withReports';
import { WithUpdateReport } from '../../../shared/containers/withUpdateReport';
import { WithInsertReport } from '../../../shared/containers/withInsertReport';
import Reports from './Reports';

const ReportsWrapper: React.FC = () => (
  <WithReports>
    <WithUpdateReport>
      <WithInsertReport>
        <Reports />
      </WithInsertReport>
    </WithUpdateReport>
  </WithReports>
);

export default ReportsWrapper;

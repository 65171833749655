import React from 'react';
import { Button } from 'semantic-ui-react';
import { getTranslation } from '../../../utils/getTranslation';

interface Props {
  handleSignIn: () => void;
  isErrorMessage: boolean;
  isBasic?: boolean;
}

const FederatedSignIn = ({ handleSignIn, isErrorMessage, isBasic }: Props): JSX.Element => {
  return (
    <>
      <Button
        type="button"
        onClick={handleSignIn}
        fluid
        color="blue"
        basic={isBasic}
        data-testid="federatedSignIn-btn"
      >
        {getTranslation('Authentication_BCDSignIn')}
      </Button>

      {isErrorMessage && (
        <div className="formError" data-testid="federatedSignIn-error-message">
          {getTranslation('CommonError')}
        </div>
      )}
    </>
  );
};

export default FederatedSignIn;

import { getTranslation } from '../../../utils/getTranslation';
import { CRON_FIELD_VALIDATION_REGEXP } from './constants';

export const getRulePeriodIdRegister = (): {
  required: { value: boolean; message: string };
  max: { value: number; message: string };
  min: { value: number; message: string };
} => ({
  required: {
    value: true,
    message: getTranslation('FieldRequired'),
  },
  max: {
    value: 365,
    message: getTranslation('FieldAllowDaysOfYear'),
  },
  min: {
    value: 1,
    message: getTranslation('FieldAllowDaysOfYear'),
  },
});

export const getRuleDatePickerRegister = (): {
  required: { value: boolean; message: string };
  validate: (value: [Date, Date] | null) => string | undefined;
} => ({
  required: {
    value: true,
    message: getTranslation('FieldRequired'),
  },
  validate: (value) => {
    return !!value && value[0] !== null && value[1] !== null ? undefined : getTranslation('FieldRequired');
  },
});

export const getRuleCronRegister = (): {
  required: { value: boolean; message: string };
  validate: (value: string) => string | undefined;
} => ({
  required: {
    value: true,
    message: getTranslation('FieldRequired'),
  },
  validate: (value) => {
    const reg = new RegExp(CRON_FIELD_VALIDATION_REGEXP);
    return !reg.test(value) ? getTranslation('Edit_ExportJob_Cron_Validate_Message') : undefined;
  },
});

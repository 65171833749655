export enum FormatExportJob {
  CSV = 6,
  XLSX = 4,
}

export const FORMAT_CSV = 'CSV';
export const FORMAT_XLSX = 'XLSX';

export const FormatValue = {
  [FormatExportJob.CSV]: FORMAT_CSV,
  [FormatExportJob.XLSX]: FORMAT_XLSX,
};

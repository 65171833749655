import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { Tab } from 'semantic-ui-react';

import { userContext } from '../../shared/store/userContext';
import {
  defaultReportsPageContextValue,
  ReportsPageContextProps,
  reportsPageContext,
  ReportPanesType,
  ReportPaneInterface,
} from '../store/reportsPageContext';
import { EmbedTokenResponseDto } from '../../api/rest/models/EmbedTokenResponseAggregate/EmbedTokenResponseDto';
import { ReportsService } from '../../services/ReportsService';
import { pathReport, pathReports, reportNameKey } from '../../routes/constants';
import { ReportDetailsDto } from '../../api/rest/models/EmbedTokenResponseAggregate/ReportDetailsDto';
import BasicReport from '../../features/BasicReport';
import { API_REQUEST_STATUS, RequestStatus } from '../../utils/APIHandlers';
import { ReportsConfigsResponse } from '../../models/ReportsAggregate/ReportsConfigs';
import { parseReportName } from '../../features/Dashboard/DashboardContent/helper';
import { mockedReportsConfigs } from '../../features/ReportsContainer/mockReportsData';
import { getDefaultReportForView } from '../../pages/Reports/helper';

interface Props {
  children: React.ReactChild;
}

const WithReportsPage: React.FC<Props> = ({ children }) => {
  const [requestReportsStatus, setRequestReportsStatus] = useState<RequestStatus>(
    defaultReportsPageContextValue.requestReportsStatus
  );
  const [reportsConfig, setReportsConfig] = useState<EmbedTokenResponseDto | null>(
    defaultReportsPageContextValue.reportsConfig
  );
  const [activeReportIndex, setActiveReportIndex] = useState<number | undefined>(
    defaultReportsPageContextValue.activeReportIndex
  );
  const [isReportExist, setIsReportExist] = useState<boolean | null>(null);
  const [allPanes, setAllPanes] = useState<ReportPanesType>(defaultReportsPageContextValue.allPanes);
  const { workSpaceId, environment, version } = useContext(userContext);
  const history = useHistory();
  const match = useRouteMatch<{ reportName: string }>(pathReport(`:${reportNameKey}`));
  const reportService = new ReportsService(workSpaceId, environment, version);

  const getReportsConfig = async () => {
    try {
      setRequestReportsStatus(API_REQUEST_STATUS.REQUESTED);
      const reports: ReportsConfigsResponse = await reportService.loadConfig();

      setReportsConfig(reports ?? mockedReportsConfigs);
      setRequestReportsStatus(API_REQUEST_STATUS.REQUEST_SUCCESS);

      return reports;
    } catch (e) {
      setReportsConfig(null);
      setRequestReportsStatus(API_REQUEST_STATUS.REQUEST_FAILURE);
      return;
    }
  };

  const openReport = () => {
    const pathname = history.location.pathname;
    let urlReportName = match?.params?.reportName;

    if ((pathname === pathReports || pathname === `${pathReports}/`) && !urlReportName) {
      urlReportName = getDefaultReportForView(reportsConfig);
      history.push(`${pathReports}/${urlReportName}`);
    }

    const reportIndex = reportsConfig?.reports?.findIndex(
      ({ reportName }) => urlReportName && reportName.includes(urlReportName)
    );
    setActiveReportIndex(reportIndex);

    if (reportIndex !== -1) {
      setIsReportExist(true);
    } else {
      setIsReportExist(false);
    }
  };

  useEffect(() => {
    const panes = reportsConfig?.reports?.map((report, index) => {
      return paneFactory(report, reportsConfig?.embedToken?.token, index);
    });

    panes && setAllPanes(panes);
  }, [reportsConfig, setAllPanes]);

  const paneFactory = (
    reportDetails: ReportDetailsDto,
    token: string,
    index: number
  ): ReportPaneInterface => {
    const { id, displayName, reportName } = reportDetails;
    const menuItem = () => (
      <NavLink
        to={`${pathReports}/${parseReportName(reportName)}`}
        key={reportName}
        className={classNames('ui label')}
        onClick={() => {
          setActiveReportIndex(index);
        }}
      >
        {displayName}
      </NavLink>
    );
    const CreatePane = () => (
      <Tab.Pane attached={false}>
        <BasicReport
          embedUrl={reportDetails.embedUrl}
          displayName={displayName}
          powerBiId={reportDetails.powerBiId}
          token={token}
          key={reportName}
          isReportLoaderShown
          updateToken={getReportsConfig}
        />
      </Tab.Pane>
    );

    menuItem.displayName = 'menuItem';
    CreatePane.displayName = 'render';

    return {
      menuItem,
      render: CreatePane,
      displayName,
      id,
    };
  };

  const getUserInfoContext = (): ReportsPageContextProps => {
    return {
      ...defaultReportsPageContextValue,
      getReportsConfig,
      paneFactory,
      openReport,
      reportsConfig,
      requestReportsStatus,
      activeReportIndex,
      isReportExist,
      allPanes,
    };
  };

  return <reportsPageContext.Provider value={getUserInfoContext()}>{children}</reportsPageContext.Provider>;
};

export { WithReportsPage };

import React, { ReactElement } from 'react';

import './styles.scss';

interface Props {
  children: ReactElement;
  message: string | ReactElement;
}

const MessageWithControl: React.FC<Props> = ({ children, message }): ReactElement => {
  return (
    <div className="container-messageWithControlContent">
      {message}
      <div className="container-messageWithControl-wrapper">{children}</div>
    </div>
  );
};

export default MessageWithControl;

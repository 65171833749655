import React from 'react';

import { WithPetUsers } from '../../../shared/containers/PetUser/withPetUsers';
import { WithCreatePetUser } from '../../../shared/containers/PetUser/withCreatePetUser';
import { WithUpdatePetUser } from '../../../shared/containers/PetUser/withUpdatePetUser';
import PetUsers from './PetUsers';

const PetUsersWrapper: React.FC = () => (
  <WithPetUsers>
    <WithCreatePetUser>
      <WithUpdatePetUser>
        <PetUsers />
      </WithUpdatePetUser>
    </WithCreatePetUser>
  </WithPetUsers>
);

export default PetUsersWrapper;

import React from 'react';
import {
  GetScheduledReportDTO,
  GetReportMfaCodeRequestDTO,
  GetScheduledReportResponse,
} from '../../api/rest/models/GetScheduledReport';
import { RequestStatus, API_REQUEST_STATUS } from '../../utils/APIHandlers';

export type DownloadReportTokenType = string | null;

export interface PayloadDownloadScheduledReport {
  reportLink: string;
}

export type ScheduledReportFieldType = GetScheduledReportResponse | null;

export interface GetScheduledReportContextProps {
  token: DownloadReportTokenType;
  report: ScheduledReportFieldType;
  getMFARequestCode: number | null;
  getMFAGetScheduledReportStatus: RequestStatus;
  getScheduledReportStatus: RequestStatus;
  getMFACodeGetScheduledReport: (payload: GetReportMfaCodeRequestDTO) => Promise<void>;
  getScheduledReport: (payload: GetScheduledReportDTO) => Promise<void>;
  resetMFAAuthentication: () => void;
  setToken: (token: DownloadReportTokenType) => void;
}

export const defaultGetScheduledReportContextValue: GetScheduledReportContextProps = {
  token: null,
  report: null,
  getMFARequestCode: null,
  getMFAGetScheduledReportStatus: API_REQUEST_STATUS.NOT_REQUESTED,
  getScheduledReportStatus: API_REQUEST_STATUS.NOT_REQUESTED,
  getMFACodeGetScheduledReport: () => new Promise(() => void 0),
  getScheduledReport: () => new Promise(() => void 0),
  resetMFAAuthentication: () => void 0,
  setToken: () => void 0,
};

const getScheduledReportContext = React.createContext<GetScheduledReportContextProps>(
  defaultGetScheduledReportContextValue
);

export { getScheduledReportContext };

import React from 'react';
import { Icon, Label } from 'semantic-ui-react';

import { getTranslation } from '../../../utils/getTranslation';

interface NoAccessLabelProps {
  dataTestId?: string;
}

const NoAccessLabel: React.FC<NoAccessLabelProps> = ({ dataTestId }) => (
  <Label color="yellow" data-testid={`${dataTestId ?? 'Default'}-NoAccess-Label`}>
    <Icon name="exclamation triangle" />
    {getTranslation('Private_Title')}
  </Label>
);

export default NoAccessLabel;

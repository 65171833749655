import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { ApolloClientResponse } from '../api/graphql/models/common';
import {
  GetExportJobsResponse,
  GetExportJobResponse,
  GetExportJobHistoryResponse,
  DeleterExportJobResponse,
  UpdateExportJobResponse,
  CreateExportJobResponse,
  CreateEditExportJobDTO,
} from '../api/graphql/models/ExportJob';
import { UPDATE, INSERT_JOB } from '../api/graphql/mutations/ExportJobsMutations';
import {
  GET_ALL,
  GET_JOB_BY_ID,
  GET_JOB_DETAILS_BY_ID,
  DELETE_JOB_BY_ID,
} from '../api/graphql/queries/ExportJobsQueries';
import { TriggerExportJobResponse } from '../api/rest/models/ExportJob';
import { ErrorFetchResponse, RestApiResponse } from '../api/rest/models/RestApiResponse';
import { PetApi } from '../constants/apiParams';
import { APIFetch } from '../utils/APIHandlers';
import { EXPORT_JOBS_LIMIT, EXPORT_JOB_HISTORY_LIMIT } from '../pages/ExportJobs/constant';

export class ExportJobsService {
  private readonly apolloClient: ApolloClient<NormalizedCacheObject>;
  private readonly endpointRunExportJobTrigger: string;

  constructor(apolloClient: ApolloClient<NormalizedCacheObject>) {
    this.apolloClient = apolloClient;
    this.endpointRunExportJobTrigger = '/v1/tickets/pet/exports/schedule';
  }

  public async getAll(): Promise<ApolloClientResponse<GetExportJobsResponse>> {
    return this.apolloClient.query({
      query: GET_ALL,
      variables: { limit: EXPORT_JOBS_LIMIT, sortBy: 'UPDATEDDATE_DESC' },
    });
  }

  public async getJobHistory(id: string): Promise<ApolloClientResponse<GetExportJobHistoryResponse>> {
    return this.apolloClient.query({
      query: GET_JOB_DETAILS_BY_ID,
      variables: { id, limit: EXPORT_JOB_HISTORY_LIMIT, sortBy: 'STARTDATETIME_DESC' },
    });
  }

  public async getExportJob(id: string): Promise<ApolloClientResponse<GetExportJobResponse>> {
    return this.apolloClient.query({
      query: GET_JOB_BY_ID,
      variables: { id },
    });
  }

  public async updateExportJob(
    id: string,
    input: CreateEditExportJobDTO
  ): Promise<ApolloClientResponse<UpdateExportJobResponse>> {
    return this.apolloClient.mutate({
      mutation: UPDATE,
      variables: { id, input },
    });
  }

  public async createExportJob(
    input: CreateEditExportJobDTO
  ): Promise<ApolloClientResponse<CreateExportJobResponse>> {
    return this.apolloClient.mutate({
      mutation: INSERT_JOB,
      variables: { input },
    });
  }

  public async deleteExportJob(id: string): Promise<ApolloClientResponse<DeleterExportJobResponse>> {
    return this.apolloClient.query({
      query: DELETE_JOB_BY_ID,
      variables: { id },
    });
  }

  public async triggerExportJob(
    jobId: string
  ): Promise<RestApiResponse<TriggerExportJobResponse> | ErrorFetchResponse> {
    return await APIFetch<RestApiResponse<TriggerExportJobResponse>>(
      'post',
      PetApi,
      this.endpointRunExportJobTrigger,
      {
        body: { jobId },
      }
    );
  }
}

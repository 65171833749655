import { FeatureAccessGroupGridViewModel } from '../../../models/FeatureAccessGroupsAggregate/FeatureAccessGroup';
import {
  hasFeatureAccessManagementAccess,
  hasReportsManagementAccess,
} from '../../../pages/Administration/helper';
import { UserContextProps } from '../../../shared/store/userContext';
import {
  FeatureAccessGroupsTableHeaderType,
  featureAccessGroupsTableHeader,
} from './FeatureAccessGroupsTable/constants';

export const hasDataAccess = (
  field: keyof FeatureAccessGroupGridViewModel,
  userContextValue: UserContextProps
): boolean =>
  field === 'countReports'
    ? hasReportsManagementAccess(userContextValue)
    : hasFeatureAccessManagementAccess(userContextValue);

export const getUnavailableFeatureAccessGroupsFields = (
  userContextValue: UserContextProps
): FeatureAccessGroupsTableHeaderType[] =>
  featureAccessGroupsTableHeader.filter((item) => !hasDataAccess(item.field, userContextValue));

import React from 'react';
import { DownloadReportRequestDTO } from '../../api/rest/models/GetScheduledReport';
import { RequestStatus, API_REQUEST_STATUS } from '../../utils/APIHandlers';

export interface DownloadScheduledReportProps {
  downloadScheduledReport: (payload: DownloadReportRequestDTO) => Promise<void>;
  requestDownloadScheduledReportStatus: RequestStatus;
}

export const defaultDownloadScheduledReportValue: DownloadScheduledReportProps = {
  downloadScheduledReport: async () => new Promise(() => void 0),
  requestDownloadScheduledReportStatus: API_REQUEST_STATUS.NOT_REQUESTED,
};

const downloadScheduledReportContext = React.createContext<DownloadScheduledReportProps>(
  defaultDownloadScheduledReportValue
);

export { downloadScheduledReportContext };

import React, { SyntheticEvent, useEffect, useContext, ReactElement, useCallback } from 'react';
import { Message } from 'semantic-ui-react';
import { UseFormGetValues } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { FieldErrors, FieldError } from 'react-hook-form/dist/types/errors';

import SelectWithInfo from '../../../PetForm/SelectWithInfo';
import { getTranslation } from '../../../../utils/getTranslation';
import { DropdownValueType } from '../../../PetForm/types';
import { DropdownViewModel } from '../../../../models/DropdownViewModel';
import { reportsContext } from '../../../../shared/store/reportsContext';
import { API_REQUEST_STATUS } from '../../../../utils/APIHandlers';
import TextButton from '../../../../shared/controls/TextButton';
import MessageWithControl from '../../../../shared/controls/MessageWithControl';
import { getTextFromValue } from './helper';
import { ExportJobFormViewModel } from '../../../../models/ExportJobsAggregate/ExportJobForm';
import { ReportViewModel } from '../../../../models/ReportsAggregate/Report';

interface ReportsFieldProps {
  onChange: (value: string, text: string) => void;
  errors: FieldErrors<FieldValues>;
  getValues: UseFormGetValues<ExportJobFormViewModel>;
  dataTestId?: string;
  required?: boolean;
}

const ReportsField: React.FC<ReportsFieldProps> = (props): ReactElement => {
  const { onChange, errors, getValues, dataTestId, required } = props;
  const { getReports, reportsData, requestReportsStatus } = useContext(reportsContext);

  const handleGetNotPaginatedReport = useCallback(() => {
    void getReports(false);
  }, [getReports]);

  useEffect(() => {
    if (requestReportsStatus !== API_REQUEST_STATUS.REQUESTED) {
      handleGetNotPaginatedReport();
    }
  }, []);

  return (
    <>
      <SelectWithInfo
        defaultValue={String(getValues('report'))}
        options={reportsData.map(
          (item: ReportViewModel, index: number): DropdownViewModel => ({
            key: String(index),
            value: String(item?.id),
            text: item.displayName,
          })
        )}
        label={getTranslation('Edit_ExportJob_Reports')}
        error={errors?.report as FieldError}
        onChange={(_e: SyntheticEvent, value: DropdownValueType) => {
          onChange(String(value.value), getTextFromValue(value));
        }}
        isLoaded={requestReportsStatus === API_REQUEST_STATUS.REQUESTED}
        dataTestId={dataTestId}
        required={required}
      />
      {requestReportsStatus === API_REQUEST_STATUS.REQUEST_FAILURE && (
        <Message error data-testid="message-reports-error">
          <MessageWithControl message={getTranslation('Error')}>
            <TextButton
              dataTestId="retry-reports-btn"
              onClick={handleGetNotPaginatedReport}
              text={getTranslation('Retry')}
            />
          </MessageWithControl>
        </Message>
      )}
    </>
  );
};

export default ReportsField;

import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { WithApolloClient } from '../../../WithApolloClient/WithApolloClient';
import { PetUserFormViewModel } from '../../../../models/PetUsersAggregate/PetUser';
import PetForm from '../../../PetForm';
import { pathAdministrationUsers } from '../../../../routes/constants';
import { WithDataAuthorizationRoles } from '../../../../shared/containers/withDataAuthorizationRoles';
import { WithFeatureAccessGroups } from '../../../../shared/containers/FeatureAccessGroups/withFeatureAccessGroups';
import { emptyUser } from '../constants';
import PetUserForm from './PetUserForm';
import { createPetUserContext } from '../../../../shared/store/PetUser/createPetUserContext';
import { getRequestStatuses } from '../../../../utils/APIHandlers';
import FullSizeLoader from '../../../../shared/view/Loader/FullSizeLoader';
import { hasUnavailableFields, isLeaveCondition } from '../helper';
import { userContext } from '../../../../shared/store/userContext';
import NoAccessMessageWrapper from '../PetUserMessages/NoAccessMessageWrapper';

import './styles.scss';

const CreatePetUserWrapper: React.FC = () => {
  const userContextValue = useContext(userContext);
  const isUnavailableFields = hasUnavailableFields(userContextValue);
  const { createPetUser, clearCreatePetUserState, requestCreatePetUserStatus } =
    useContext(createPetUserContext);
  const history = useHistory();
  const requestCreatePetUser = getRequestStatuses(requestCreatePetUserStatus);

  const handleCancel = () => {
    clearCreatePetUserState();
    history.push(pathAdministrationUsers);
  };

  useEffect(() => {
    clearCreatePetUserState();
  }, []);

  useEffect(() => {
    if (requestCreatePetUser.REQUEST_SUCCESS) {
      history.push(pathAdministrationUsers);
    }
  }, [requestCreatePetUser]);

  return (
    <>
      {isUnavailableFields && (
        <div className="auditMessage">
          <NoAccessMessageWrapper dataTestId="createPetUser" userContextValue={userContextValue} />
        </div>
      )}

      <div className="petUserForm" data-testid="createPetUserDataForm-wrapper">
        {requestCreatePetUser.REQUESTED && <FullSizeLoader dataTestId="createPetUserDataForm-loader" />}
        <WithDataAuthorizationRoles>
          <WithFeatureAccessGroups>
            <PetForm<PetUserFormViewModel>
              cancelButtonClicked={handleCancel}
              submitForm={createPetUser}
              isLeaveCondition={(isDirty: boolean, redirectUrlToLeave: string) =>
                isLeaveCondition({
                  isDirty,
                  redirectUrlToLeave,
                  requestCondition: requestCreatePetUser.REQUEST_SUCCESS,
                })
              }
              isRedirectLeave
              isEditForm={false}
              editFormData={emptyUser}
              isNotEnoughAccess={isUnavailableFields}
            >
              <PetUserForm editFormData={emptyUser} />
            </PetForm>
          </WithFeatureAccessGroups>
        </WithDataAuthorizationRoles>
      </div>
    </>
  );
};

export default WithApolloClient(CreatePetUserWrapper);

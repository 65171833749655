import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { getTranslation } from './../../../utils/getTranslation';
import FullSizeLoader from '../../../shared/view/Loader/FullSizeLoader';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  titleText: string;
  contentText: string;
  textActionButton: string;
  isSaveBtn?: boolean;
  isLoading?: boolean;
  dataTestId?: string;
}

const WarningModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onConfirm,
  titleText,
  contentText,
  textActionButton,
  isSaveBtn,
  isLoading,
  dataTestId,
}) => {
  return (
    <Modal
      open={isOpen}
      size="small"
      className="warningModal"
      closeIcon={true}
      closeOnEscape={true}
      closeOnDimmerClick={true}
      onClose={onClose}
      data-testid={dataTestId}
    >
      <Modal.Header>
        <h3>{titleText}</h3>
      </Modal.Header>
      <Modal.Content data-testid="modal-content">{contentText}</Modal.Content>
      <Modal.Actions>
        <Button
          data-testid="cancel-btn"
          className="form-button"
          basic
          size="small"
          color="blue"
          onClick={onClose}
        >
          {getTranslation('Cancel')}
        </Button>
        <Button
          data-testid="submit-btn"
          className="form-button"
          size="small"
          color={isSaveBtn ? 'blue' : 'red'}
          onClick={onConfirm}
        >
          {textActionButton}
        </Button>
      </Modal.Actions>

      {isLoading && <FullSizeLoader />}
    </Modal>
  );
};

export default WarningModal;
